import { GOOGLE_SEARCH_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'

var AWS = require('aws-sdk');

axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const searchInstagram = (keywords) => 
{

    return (dispatch) => {
        dispatch({ type: 'INSTA_SEARCH_INIT'});
        AWS.config.update({accessKeyId: 'AKIAS2XXDUF4RP4AEJLD', secretAccessKey: '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', region: 'us-east-1'});
        var lambda = new AWS.Lambda();
        var params = {
            FunctionName: "serverlessrepo-Insta-MyFunction-1ACBJR7WZWC0P", 
            Payload: JSON.stringify({"tag":keywords.replace(/\s/g,"")}), 
           };

           lambda.invoke(params, function(err, data) {
               console.log(data)
             if (err || typeof(JSON.parse(data.Payload).errorMessage) == undefined)
             {
                console.log(err); // an error occurred
                console.log("Error")
             } 
             else
             {
                dispatch({ type: 'INSTA_SEARCH', data: JSON.parse(data.Payload)});

             }          // successful response

           });
    }
}

export const searchGoogle = (keywords) => 
{

    return (dispatch) => {
        dispatch({ type: 'GOOGLE_SEARCH_INIT'});
        axios.get(GOOGLE_SEARCH_ENDPOINT + keywords).then(response => {
            dispatch({ type: 'GOOGLE_SEARCH', data: response.data.items.map(x=>x.link)});
        })
    }
}