import React, { Component } from 'react'
import {Col, Image} from 'react-bootstrap'
import '../../stylesheets/visual-moodboard.css'
import cancel from '../../images/icons/selected.svg'

class SearchUploadOption extends Component{
    handleSelect = (e) => {
        console.log("Selecting the image")
        e.preventDefault()
        this.props.onClick(this.props.photo.src)
    }

    handleDeselect = (e) => {
        console.log("Deselecting the image")
        e.preventDefault()
        e.stopPropagation()
        this.props.onRemove(this.props.photo.src)
    }

    render()
    {
        let cont = {
            backgroundColor: "#eee",
            cursor: "pointer",
            overflow: "hidden",
            position: "relative"
          };
        cont.position = "absolute";
        cont.left = this.props.left;
        cont.top = this.props.top;
        // const selectedImgStyle = {
        //     transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
        //     transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
        //   };
        // const sx = (100 - (30 / this.props.photo.width) * 100) / 100;
        // const sy = (100 - (30 / this.props.photo.height) * 100) / 100;
        // selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;
        let style = { margin: this.props.margin, height: this.props.photo.height, width: this.props.photo.width, ...cont }
        return (
            <div className='search-upload-option option' onClick={this.handleSelect} style={style}>
                <img src={this.props.photo.src}></img>
                {this.props.selected && <img onClick={this.handleDeselect} src={cancel} style={{"height": "20px", "position":"absolute", "top":"0.5rem", "right": "0.5rem", "left":"unset", "bottom":"unset"}}></img>}
            </div>
        )
    }
}

export default SearchUploadOption