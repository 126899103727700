import { API_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'
import path from 'path'
import { getCanvas } from './canvasActions'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const createMoodboard = (data) => 
{

    return (dispatch) => {
        //Create Answer
        const CREATE_MOODBOARD_DATA_URI = `${API_ENDPOINT}/api/moodboard`
        const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas/moodboard`

        axios.post(CREATE_MOODBOARD_DATA_URI, data, config)
            .then(response => {
                console.log(response)
                data["moodboard"] = response.data.id
                axios.post(UPDATE_CANVAS_DATA_URI, data)
                .then(response => {
                    console.log("Added moodboard to canvas")
                    dispatch(getCanvas())
                })
                .catch(e => {
                    console.log(e)
                    console.log("Error")
                })
            }).catch(e => {
                console.log(e)
                console.log("Error")
            })
        dispatch({ type: 'CREATE_MOODBOARD', data});
    }
}

export const editMoodboard = (data) => 
{
    return (dispatch) => {
        //Create Answer
        const EDIT_MOODBOARD_DATA_URI = `${API_ENDPOINT}/api/moodboard`
        axios.put(EDIT_MOODBOARD_DATA_URI, data, config)
            .then(response => {
                console.log(response)
                dispatch(getCanvas())
            }).catch(e => {
                console.log(e)
            })
    }
}

export const deleteMoodboard = (data) => 
{
    return (dispatch) => {
        //Create Answer
        const DELETE_MOODBOARD_DATA_URI = `${API_ENDPOINT}/api/moodboard`
        axios.delete(DELETE_MOODBOARD_DATA_URI, {params:data}, config)
            .then(response => {
                console.log(response)
                dispatch(getCanvas())
            }).catch(e => {
                console.log(e)
            })
    }
}