// Navbar for splash screen
import React, { Component } from "react";
import { Navbar, Nav, Col, Row } from "react-bootstrap";
import './splashNav.css';

class Navlinks extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.closeNav = this.closeNav.bind(this);
        this.setNavExpanded = this.setNavExpanded.bind(this);
        this.state = {
            className: "flex-row-reverse navbar-collapse collapse",
            scrollPos: 0,
            navExpanded: false,
        };
    }

    handleClick(event) {
        this.setState({
            className: "flex-row-reverse navbar-collapse collapse",
        });
        event.preventDefault();
    }

    handleScroll(event) {
        const scrollTop = window.scrollY;
        if ((this.state.scrollPos - 100) * (scrollTop - 100) < 0) {
            this.setState({ scrollPos: scrollTop });
        }
    }

    componentDidMount() {
        this.setState({ token: this.props.token });
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    setNavExpanded(expanded) {
        this.setState({ navExpanded: expanded });
    }

    closeNav() {
        console.log("Closing nav");
        this.setState({ navExpanded: false });
    }

    renderNavList = (light) => {
        return (
            <Nav>
                {/* <li>
                    <Link
                        to="#why-naya"
                        className="nav-link"
                        onClick={this.closeNav}
                        
                    >
                        why naya
                    </Link>
                </li>
                <li>
                    <Link
                        to="#hiw"
                        className="nav-link"
                        onClick={this.closeNav}
                        
                    >
                        how it works
                    </Link>
                </li> */}
                
                <li>
                    <a href="https://ecosystem.naya.studio/" className="nav-link"
                        >
                        for designers and makers
                    </a>
                </li>
                <li>
                    <a href="/and" className="nav-link"
                        >
                        for interior designers
                    </a>
                </li>
                <li>
                    <a href="/login" className="nav-link"
                        >
                        login
                    </a>
                </li>
            </Nav>
        );
    };

    render() {
        let navbar;
        const scrollPos = this.state.scrollPos;

        if (scrollPos < 100) {
            navbar = (
                <Navbar
                    fixed="top"
                    expand="lg"
                    id="navbar-light"
                    className="navbar-light col-12 light"
                    onToggle={this.setNavExpanded}
                    expanded={this.state.navExpanded}
                    style={{ 
                        // paddingLeft: 0
                    // backgroundColor: 'rgba(0,0,0,0.5)', 
                    // boxShadow : '0 2px 4px 0 rgba(0,0,0,.1)' 
                }}
                >
                    <Col xs={12}>
                        <Row style={{ justifyContent: "space-between" }}>
                            <Navbar.Brand
                                href="https://www.naya.studio/"
                            >
                                naya
                            </Navbar.Brand>
                            <Navbar.Toggle
                                aria-controls="collapsible_navbar"
                                onSelect={() => null}
                            />
                            <Navbar.Collapse
                                id="collapsible_navbar"
                                className={this.state.className}
                            >
                                {this.renderNavList(true)}
                            </Navbar.Collapse>
                        </Row>
                    </Col>
                </Navbar>
            );
        } else {
            navbar = (
                <Navbar
                    fixed="top"
                    expand="lg"
                    className="navbar-on-scroll col-12"
                    id="navbar-dark"
                    onToggle={this.setNavExpanded}
                    expanded={this.state.navExpanded}
                >
                    <Col xs={12}>
                        <Row style={{ justifyContent: "space-between" }}>
                            <Navbar.Brand
                                href="https://www.naya.studio/"
                            >
                                naya
                            </Navbar.Brand>
                            <Navbar.Toggle
                                aria-controls="collapsible_navbar"
                                onSelect={() => null}
                            />
                            <Navbar.Collapse
                                id="collapsible_navbar"
                                className={this.state.className}
                            >
                                {this.renderNavList(false)}
                            </Navbar.Collapse>
                        </Row>
                    </Col>
                </Navbar>
            );
        }

        return navbar;
    }
}

export default Navlinks;
