import React, { Component } from 'react'
import {Col, Image} from 'react-bootstrap'
import '../../stylesheets/visual-moodboard.css'
import checkmark from '../../images/icons/selected.svg'

class VisualMoodboardOption extends Component{
    handleSelect = (e) => {
        console.log("here")
        e.preventDefault()
        this.props.onClick(this.props.id)
    }
    render()
    {
        return (
            <Col className={this.props.selected? 'visual-moodboard-option option selected':'visual-moodboard-option option'} xs={4} onClick={this.handleSelect}>
                <Image src={this.props.link} fluid></Image>
                {this.props.selected && <img alt="" src={checkmark} style={{"height": "20px", "position":"absolute", "top":"0.5rem", "right": "1rem"}}></img>}
            </Col>
        )
    }
}

export default VisualMoodboardOption