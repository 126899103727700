import userIcon from '../images/icons/userIcon.png'

// var Chance = require('chance')
// var chance = new Chance()


const initUser = {
    id                  : '',
    email               : '',
    user_type           : '',
    user_canvas         : [],
    design_canvas       : [],
    fabrication_canvas  : [],
    archived_canvas     : [],
    profile_pic         : userIcon,
    name                : '',
    profile_name        : ('').split('@')[0],
    loggedIn            : false,
    isVerified          : false,
    last_name: '',
    external_type:'',
    acceptedTermsOfService: {
        designer: false,
        maker: false,
        client: false
    },
}

let DEBUG = false

const userReducer = (state = initUser, action) => {
    let newState

    if(DEBUG) console.debug("USER REDUCER:")
    if(DEBUG) console.debug("ACTION:")
    if(DEBUG) console.debug(action)

    if(DEBUG) console.debug("CURRENT STATE:")
    if(DEBUG) console.debug(state)
    let user
    switch(action.type)
    {
        case "LOGIN_LOCAL":
            localStorage.setItem('nayaJwtToken', action.data.token)
            user = action.data.user
            newState = {
                        ...state, 
                        id                  : user._id,
                        email               : user.email, 
                        user_type           : user.user_type === undefined? "USER": user.user_type, 
                        user_canvas         : user.user_canvas, 
                        design_canvas       : user.design_canvas, 
                        fabrication_canvas  : user.fabrication_canvas,
                        archived_canvas     : user.archived_canvas,
                        name                : user.name,
                        last_name: user.last_name,
                        loggedIn            : true,
                        isVerified          : user.isVerified,
                        external_type:user.external_type
                    }
            break
        
        case "LOGIN_TOKEN":
                user = action.data
                newState = {
                            ...state, 
                            id                  : user._id,
                            email               : user.email, 
                            user_type           : user.user_type === undefined? "USER": user.user_type, 
                            user_canvas         : user.user_canvas, 
                            design_canvas       : user.design_canvas, 
                            fabrication_canvas  : user.fabrication_canvas,
                            archived_canvas  : user.archived_canvas,
                            name                : user.name,
                            last_name: user.last_name,
                            profile_pic         : user.profile_pic,
                            loggedIn            : true,
                            isVerified          : user.isVerified,
                            external_type:user.external_type
                        }
                break
        case 'LOGIN_COOKIE':
            user = action.data;
            newState = {
                ...state,
                id: user._id,
                email: user.email,
                user_type: user.user_type === undefined ? "USER" : user.user_type,
                user_canvas: user.user_canvas,
                design_canvas: user.design_canvas,
                fabrication_canvas: user.fabrication_canvas,
                archived_canvas: user.archived_canvas,
                name: user.name,
                last_name: user.last_name,
                profile_pic: user.profile_pic,
                loggedIn: true,
                isVerified: user.isVerified,
                external_type: user.external_type,
                acceptedTermsOfService: user.acceptedTermsOfService
            }
            break;
        case 'SIGN_OUT':
            newState = initUser
            break
        case 'SET_PROFILE_PIC':
                if(action.link !== undefined)
                {
                    newState = {
                                ...state,
                                profile_pic: action.link
                    }

                }
                break

        case 'SET_PROFILE_NAME':
                if(action.name !== undefined)
                {
                    newState = {
                                ...state,
                                profile_name: action.name
                    }

                }
                break
        case 'SIGN_UP_LOCAL':
            localStorage.setItem('nayaJwtToken', action.data.token)
            newState = {...state, ...action.data.user, id: action.data.user._id}
            break
        case 'LEAD':
            console.log(action)
            newState = {email: action.data.email}
            break
        case 'REFRESH_USER':
            user = action.user
            newState = {
                        ...state, 
                        id                  : user._id,
                        email               : user.email, 
                        user_type           : user.user_type === undefined? "USER": user.user_type, 
                        ...user
                    }
            break
        case 'VERIFY_SUCCESS':
            newState = {
                ...state,
                isVerified: true
            }
            break
        default:
            newState = state
    }

    if(DEBUG) console.debug("NEW STATE:")
    if(DEBUG) console.debug(newState)

    return newState
}

export default userReducer