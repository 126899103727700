import React from 'react';
import {setAuthCallback} from '../../actions/authActions';
import ProgressBar from '../utilities/ProgressBar'
import BackButton from '../utilities/BackButton'
import Progress from '../utilities/Progress'
import CancelButton from '../utilities/CancelButton'
import { connect } from 'react-redux'   
import {Container, Row, Col, Button, Card} from 'react-bootstrap'
import './../../stylesheets/additionalDetails.css'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { API_ENDPOINT } from  '../../constants/secrets';
import Tool from '../tools/Tool';
const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

class AdditionalDetails extends React.Component{
  state = {
    showToolModal:false,
    toolContentType:'',
  }

  // toggles the tool modal
  toggleToolModal = () => {
    this.setState({showToolModal:!this.state.showToolModal})
}

// get the content type from the canvas tile to pass it to the tool to add answer/details
getContentType = (type) => {
    this.setState({toolContentType:type},()=>{this.setState({showToolModal:!this.state.showToolModal})})
}


  handleContinue = () => {
    if(this.props.user && this.props.user.id)
    {
        const ADD_CANVAS_TO_USER_URI = `${API_ENDPOINT}/api/user/canvas`
        
        const data ={
            canvas_id: this.props.canvas_id,
            user_id: this.props.user.id
        }

        axios.post(ADD_CANVAS_TO_USER_URI, data , config)
        .then(response => {
            if(response.data.status)
            {
                this.props.history.push('/canvas')

            }
        }).catch(e => {
            console.log(e)
        })
    }
    else{
      this.props.setAuthCallback(this.props.nextPage)
      this.props.history.push("/login");
    }
  }

  renderDimensions = () => {
    return(
      <Card>
        <Card.Body style={{padding:10}} onClick={()=>{this.getContentType('DIMENSIONS')}}>
          <Card.Title style={{textAlign:'center'}}><h5>What are the dimensions of the piece?</h5></Card.Title>
          <Card.Text>
            {
              this.props.dimensions && this.props.dimensions.length && this.props.dimensions.width && this.props.dimensions.height ? (
                <>
                  <p className="col-12" style={{textAlign:"center", fontSize: "0.9rem", marginBottom: "0rem", fontWeight:100}}>Length</p>
                  <p className="col-12" style={{textAlign:"center", fontWeight:100}}>{this.props.dimensions.length} {this.props.dimensions.units}</p>
                  <p className="col-12" style={{textAlign:"center", fontSize: "0.9rem", marginBottom: "0rem", fontWeight:100}}>Width</p>
                  <p className="col-12" style={{textAlign:"center", fontWeight:100}}>{this.props.dimensions.width} {this.props.dimensions.units}</p>
                  <p className="col-12" style={{textAlign:"center", fontSize: "0.9rem", marginBottom: "0rem", fontWeight:100}}>Height</p>
                  <p className="col-12" style={{textAlign:"center", fontWeight:100}}>{this.props.dimensions.height} {this.props.dimensions.units}</p>
                </>
              ) : (
                <h5 style={{textAlign: 'center',fontSize: 100, fontWeight: 100}}>+</h5>
              )
            }
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }

  renderBudget = () => {
    return(
      <Card>
        <Card.Body style={{padding:10}} onClick={()=>{this.getContentType('BUDGET')}}>
          <Card.Title style={{textAlign:'center'}}><h5>What kind of budget do you have?</h5></Card.Title>
          <Card.Text>
            {
              this.props.budget && this.props.budget.maximum ? (
                <>
                  <p className="col-12" style={{textAlign:"center", fontSize: "0.9rem", marginBottom: "0rem", fontWeight:100}}>Minimum</p>
                  <p className="col-12" style={{textAlign:"center", fontWeight:100}}>$ {this.props.budget.minimum}</p>
                  <p className="col-12" style={{textAlign:"center", fontSize: "0.9rem", marginBottom: "0rem", fontWeight:100}}>Maximum</p>
                  <p className="col-12" style={{textAlign:"center", fontWeight:100}}>$ {this.props.budget.maximum}</p>
                </>
              ) : (
                <h5 style={{textAlign: 'center',fontSize: 100, fontWeight: 100}}>+</h5>
              )
            }
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }

  renderShipping = () => {
    return(
      <Card>
        <Card.Body style={{padding:10}} onClick={()=>{this.getContentType('SHIPPING')}}>
          <Card.Title style={{textAlign:'center'}}><h5>Shipping Address</h5></Card.Title>
          <Card.Text>
            {
              this.props.shipping && this.props.shipping.address ? (
                <>
                  <p className="col-12" style={{textAlign:"center", fontSize:13, fontWeight:'bold'}}>{this.props.shipping.address.address_name}</p>
                  <p className="col-12" style={{textAlign:"center", fontSize:12, margin:0, fontWeight:100}}>{this.props.shipping.address.address_line_1}</p>
                  <p className="col-12" style={{textAlign:"center", fontSize:12, margin:0, fontWeight:100}}>{this.props.shipping.address.address_line_2}</p>
                  <p className="col-12" style={{textAlign:"center", fontSize:12, margin:0, fontWeight:100}}>{this.props.shipping.address.locality}</p>
                  <p className="col-12" style={{textAlign:"center", fontSize:12, margin:0, fontWeight:100}}>{this.props.shipping.address.city}</p>
                  <p className="col-12" style={{textAlign:"center", fontSize:12, margin:0, fontWeight:100}}>{this.props.shipping.address.zip}</p>
                  <p className="col-12" style={{textAlign:"center", fontSize:12, margin:0, fontWeight:100}}>Phone : {this.props.shipping.address.phone}</p>
                </>
              ) : (
                <h5 style={{textAlign: 'center',fontSize: 100, fontWeight: 100}}>+</h5>
              )
            }
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }

  renderFurnitureStyle = () => {
    return(
      <Card>
        <Card.Body style={{padding:10}} onClick={()=>{this.getContentType('TEXT_MOODBOARD')}}>
          <Card.Title style={{textAlign:'center'}}><h5>Select the words that represent your furniture style</h5></Card.Title>
          <Card.Text>
            {
              this.props.textMoodboard!==undefined ? (
                <>
                  <Row className="justify-content-md-center">
                            {this.props.textMoodboard.options.map(option => 
                            <Col  key={option} xs={6}>
                                <p className="option-text" style={{fontWeight:100, textAlign:'center'}}>{option}</p>                        
                            </Col>)}
                        </Row>
                </>
              ) : (
                <h5 style={{textAlign: 'center',fontSize: 100, fontWeight: 100}}>+</h5>
              )
            }
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }

  render(){
    return(
      <Container fluid={true} id="additional-details">
        <ProgressBar width={(this.props.step*100)/this.props.stepMax}></ProgressBar>
        <Row>
          <BackButton></BackButton>
          <Progress step={this.props.step} step_max={this.props.stepMax}></Progress>
                            
          {/* {window.innerHeight >= document.body.clientHeight && window.innerHeight < window.innerWidth && ( */}
                                <CancelButton></CancelButton>
                            {/* )} */}
        </Row> 
                {this.state.showToolModal && <Tool isOpen={this.state.showToolModal} toggleModal={this.toggleToolModal} contentType={this.state.toolContentType}/>}
        <Container id="details">
          <Row>
            <Col>
              <Row>
                <h2 className="col-12 question-h2" style={{marginBottom:4}}>Do you know anything else about your idea? (Optional)</h2>
                <h4
                    className="col-12 question-h4"
                    style={{ textAlign: "center" }}
                >You can fill these in at a later stage too</h4>
              </Row>
              <Row style={{justifyContent:'center'}}>
                {this.renderDimensions()}
                {this.props.user.id && this.renderShipping()}
                {this.renderFurnitureStyle()}
                {this.renderBudget()}
              </Row>
            </Col>
            <Col xs={12}>
              <Row className='justify-content-md-center'>
                <Button variant="dark" onClick={this.handleContinue} style={{marginTop:'70px'}}>Continue</Button>
              </Row>
            </Col>   
          </Row>
    </Container>

    </Container>
    )
  }
}

const mapStateToProps = (state) => {
  let dimensions = state.canvas.dimensions
  let shipping = state.canvas.shipping
  let budget = state.canvas.budget
  let textMoodboard = state.canvas.moodboards.filter(
    (moodboard) => moodboard.isText
  )[0];

  return { 
      canvas_id                   : state.canvas.id,
      canvasName                  : state.canvas.name,
      canvasStory                 : state.canvas.story,
      step                        : Object.keys(state.UI.flow).indexOf('additional-details')+1,
      stepMax                     : Object.keys(state.UI.flow).length,
      nextPage                    : state.UI.flow['additional-details'],
      user                        : state.user,
      pathway                     : state.UI.pathway,
      track                       : state.UI.track,
      user                        : state.user,
      dimensions                  : dimensions,
      canvas: state.canvas,
      shipping:shipping,
      textMoodboard:textMoodboard,
      budget:budget
  }
}

const mapDispatchToProps = (dispatch) => {
  
  return { 
      setAuthCallback: (data) => dispatch(setAuthCallback(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdditionalDetails))