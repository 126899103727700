import React from 'react';
/** 
 * Redux Actions
 */
import { addDimensions, editDimensions } from '../../actions/dimensionsActions'

/**
 * Higher Level Components
 */
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

/**
 * Style
 */
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import '../../stylesheets/dimension.css'

/**
 * Images and Media
 */
import dimensionsMan from '../../images/man_standing.svg'
import dimensionsWoman from '../../images/woman_sitting.svg'

class Dimensions extends React.Component{
  state = {
    dimensions: this.props.dimensions,
    showTooltip: false,
    init: true,
    units: this.props.dimensions.units ? this.props.dimensions.units :"in",
    metreConversion: 0.0254,
    inchConversion:39.3701
  }

  /** 
   * Changes between metric and imperial system
   */
  toggleUnits = (e) => {
    e.preventDefault()
    let newUnits
    let dimensions = this.state.dimensions
    if (this.state.units === "in") {
        newUnits = "m"
        dimensions['width']=(dimensions.width*this.state.metreConversion).toFixed(2)
        dimensions['height']=(dimensions.height*this.state.metreConversion).toFixed(2)
        dimensions['length']=(dimensions.length*this.state.metreConversion).toFixed(2)
    }
    else {
        newUnits = "in"
        dimensions['width']=(dimensions.width*this.state.inchConversion).toFixed(0)
        dimensions['height']=(dimensions.height*this.state.inchConversion).toFixed(0)
        dimensions['length']=(dimensions.length*this.state.inchConversion).toFixed(0)
    }
    this.setState({ units: newUnits, dimensions })
  }

  /** 
   * Handles change in form inputs
   */
  handleChange = (e) => {
    e.preventDefault()
    let dimensions = this.state.dimensions
    if(this.state.units==='in'){
      dimensions[e.target.name] = e.target.value > 120 ? 120 : e.target.value < 1 ? 1 : e.target.value;
    }
    else{
      dimensions[e.target.name] = e.target.value > 3.05 ? 3.05 : e.target.value < 0.05 ? 0.05 : e.target.value;
    }
    this.setState({
      dimensions
    })
  }

  /**
   * Handles submission of form - Continue button
   */
  handleSubmit = (e) => {
    e.preventDefault()

    if(this.props.mode==='CREATE'){

      const data = {
        units: this.state.units,
        length: parseFloat(this.state.dimensions.length),
        width: parseFloat(this.state.dimensions.width),
        height: parseFloat(this.state.dimensions.height),
        id: this.props.canvas_id,
      }

      this.props.addDimensions(data)
      this.props.toggleModal()
    }
    else{
      const data = {
        units: this.state.units,
        length: parseFloat(this.state.dimensions.length),
        width: parseFloat(this.state.dimensions.width),
        height: parseFloat(this.state.dimensions.height),
        id: this.props.dimensions._id,
      }
      this.props.editDimensions(data)
      this.props.toggleModal()
    }
  }

  /**
   * Called after component is mounted to draw initial cube
   */
  componentDidMount() {
    this.redrawCanvas()
  }

  static getDerivedStateFromProps(props, state) {
    if (state.init) {
      if (props.length !== state.length || props.width !== state.width || props.height !== state.height) {
        return {
          dimensions: props.dimensions,
          init: false
        }
      }
    }
    return null
  }
  ///////////////////////////////////////////////////////////////////////////
  /**
   * Redraws cube based on user inpit
   */
  redrawCanvas() {
    const canvas = this.refs.canvas
    canvas.style.width = '100%';
    canvas.style.height = '50vh';
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    const ctx = canvas.getContext('2d')
    let x, h, y, wx, wy, color
    let magnifier = 0.15
    if (this.state.init) {
      x = canvas.width / 2
      h = (this.state.dimensions.height) * magnifier
      y = canvas.height / 2 + h / 2
      wx = (this.state.dimensions.length) * magnifier
      wy = (this.state.dimensions.width) * magnifier
      color = "#aaaaaa"
    }
    else {
      x = canvas.width / 2
      h = (this.props.dimensions.height) * magnifier
      y = canvas.height / 2 + h / 2
      wx = (this.props.dimensions.length) * magnifier
      wy = (this.props.dimensions.width) * magnifier
      color = "#aaaaaa"
    }

    ctx.clearRect(0, 0, 400, 400);
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x - wx, y - wx * 0.5);
    ctx.lineTo(x - wx, y - h - wx * 0.5);
    ctx.lineTo(x, y - h * 1);
    ctx.closePath();
    ctx.fillStyle = this.shadeColor(color, -10);
    ctx.strokeStyle = this.shadeColor(color, 50);
    ctx.stroke();
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + wy, y - wy * 0.5);
    ctx.lineTo(x + wy, y - h - wy * 0.5);
    ctx.lineTo(x, y - h * 1);
    ctx.closePath();
    ctx.fillStyle = this.shadeColor(color, 10);
    ctx.strokeStyle = this.shadeColor(color, 50);
    ctx.stroke();
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(x, y - h);
    ctx.lineTo(x - wx, y - h - wx * 0.5);
    ctx.lineTo(x - wx + wy, y - h - (wx * 0.5 + wy * 0.5));
    ctx.lineTo(x + wy, y - h - wy * 0.5);
    ctx.closePath();
    ctx.fillStyle = this.shadeColor(color, 20);
    ctx.strokeStyle = this.shadeColor(color, 60);
    ctx.stroke();
    ctx.fill();
  }

  /**
   * Colors the cube
   */
  shadeColor = (color, percent) => {
    color = color.substr(1);
    var num = parseInt(color, 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = (num >> 8 & 0x00FF) + amt,
      B = (num & 0x0000FF) + amt;
    return '#' + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 + (B < 255 ? B < 1 ? 0 : B : 255)).toString(16).slice(1);
  }

  ///////////////////////////////////////////////////////////////////////////

  /**
   * Main Render Method
   */
  render(){
    return(
      <Container fluid={true}>
        <Container>
            <Row>
              <h2 className="col-12 question-h2">What are the dimensions of the piece?</h2>
              <h4 className="col-12 question-h4" style={{ "textAlign": "center" }}>Use the tool below to give us the dimensions of your piece</h4>
              {this.state.showTooltip && <h4>Labore duis aliqua eiusmod fugiat nulla est id quis velit cillum est id adipisicing sit.</h4>}
            
              <Col xs={12} lg={{ span: 6 }} style={{ "minHeight": "min-content", "paddingTop": "10vh" }}>
                <Row className="dimensions-img-wrapper">
                  <Col xs={12} style={{ "textAlign": "center", "position": "absolute", "top": "-10vh" }}>
                    <h5>Front View</h5>
                  </Col>
                  <Image src={dimensionsMan} className="human-img human-img-2" fluid></Image>
                  <div 
                    className="front-view" 
                    style={{ 
                      "height": this.state.units === "in" ? (this.state.dimensions.height / 2.4) + "vh" : (this.state.dimensions.height * this.state.inchConversion / 2.4) + "vh", 
                      "width": this.state.units === "in" ? (this.state.dimensions.length / 1.2) + "%" : (this.state.dimensions.length * this.state.inchConversion / 1.2) + "%" }}>
                    </div>
                </Row>

                <Col xs={{ span: 12, offset: 0 }}>
                  <Row>
                    <Col xs={2}>
                      <label htmlFor="length">Length</label>
                    </Col>
                    <Col xs={3}>
                      {this.state.dimensions.length} {this.state.units}
                    </Col>
                    <Col xs={7}>
                      <input type="number"
                        name="length"
                        className="dimensions-number-input"
                        value={this.state.dimensions.length}
                        onChange={this.handleChange}
                        min={this.state.units==='in' ? '1' : '0.05'}
                        max={this.state.units==='in' ? '120' : '3.05'}
                        step={this.state.units==='in' ? '1' : '0.05'}
                        id="length" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={{ span: 12, offset: 0 }}>
                  <Row>
                    <Col xs={2}>
                      <label htmlFor="height">Height</label>
                    </Col>
                    <Col xs={3}>
                      {this.state.dimensions.height} {this.state.units}
                    </Col>
                    <Col xs={7}>
                      <input type="number"
                        name="height"
                        className="dimensions-number-input"
                        value={this.state.dimensions.height}
                        onChange={this.handleChange}
                        min={this.state.units==='in' ? '1' : '0.05'}
                        max={this.state.units==='in' ? '120' : '3.05'}
                        step={this.state.units==='in' ? '1' : '0.05'}
                        id="height" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={{ span: 6, offset: 3 }} style={{ "textAlign": "center", "color": "#fff", "backgroundColor": "black", "padding": "0.5rem", "cursor": "pointer", "marginTop": "1rem" }}>
                  <a onClick={this.toggleUnits}>Switch to {this.state.units == "in" ? "Metric" : "Imperial"} units</a>
                </Col>
              </Col>

              <Col xs={12} lg={{ span: 6 }} style={{ "minHeight": "min-content", "paddingTop": "10vh" }}>
                <Row className="dimensions-img-wrapper">
                  <Col xs={12} style={{ "textAlign": "center", "position": "absolute", "top": "-10vh" }}>
                    <h5 >Side View</h5>
                  </Col>
                  <Image src={dimensionsWoman} className="human-img human-img-1" fluid></Image>
                  <div 
                    className="side-view" 
                    style={{ 
                      "height": this.state.units == "in" ? (this.state.dimensions.height / 2.4) + "vh" : (this.state.dimensions.height * this.state.inchConversion / 2.4) + "vh", 
                      "width": this.state.units == "in" ? (this.state.dimensions.width / 1.2) + "%" : (this.state.dimensions.width * this.state.inchConversion / 1.2) + "%" }}>
                </div>
                </Row>

                <Col xs={{ span: 12, offset: 0 }}>
                  <Row>
                    <Col xs={2}>
                      <label htmlFor="width">Width</label>
                    </Col>
                    <Col xs={3}>
                      {this.state.dimensions.width} {this.state.units}
                      {/* {this.state.dimensions.width/100} ft */}
                    </Col>
                    <Col xs={7}>
                      <input type="number"
                        name="width"
                        className="dimensions-number-input"
                        value={this.state.dimensions.width}
                        onChange={this.handleChange}
                        min={this.state.units==='in' ? '1' : '0.05'}
                        max={this.state.units==='in' ? '120' : '3.05'}
                        step={this.state.units==='in' ? '1' : '0.05'}
                        id="width" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={{ span: 12, offset: 0 }} style={{height:32}}>
                </Col>
                <Col xs={{ span: 6, offset: 3 }} style={{ "textAlign": "center", "color": "#fff", "backgroundColor": "black", "padding": "0.5rem", "cursor": "pointer", "marginTop": "1rem" }}>
                  <a onClick={this.toggleUnits}>Switch to {this.state.units === "in" ? "Metric" : "Imperial"} units</a>
                </Col>
              </Col>

              <Col xs={12} lg={{ span: 4 }} style={{ "display": "none" }}>
                <Col xs={{ span: 12 }} id="canvas-wrapper">
                  <canvas ref="canvas" width='100' height='100'></canvas>
                </Col>
              </Col>

              <Col xs={12}>
                <Row className='justify-content-md-center'>
                  <Button variant="dark" onClick={this.handleSubmit} style={{marginTop:20}}>Submit</Button>
                </Row>
              </Col>
            </Row>
        </Container>
    </Container>
    )
  }
}

/**
 * 
 * @param {*} state
 * Maps Redux state to component props 
 */
const mapStateToProps = (state) => {
  let dimensions = state.canvas.dimensions;
  let mode='CREATE';
  if (Object.keys(dimensions).length === 0) {
      dimensions = {
          length: 36,
          width: 36,
          height: 36,
          units: 'in'
      }
  }
  else{
    mode='EDIT';
  }

  return {
      // Dimension specific 
      dimensions: dimensions,

      //General Function
      canvas_id: state.canvas.id,

      //General UI
      nextPage: state.UI.flow['dimensions'],
      step: Object.keys(state.UI.flow).indexOf('dimensions') + 1,
      stepMax: Object.keys(state.UI.flow).length,
      mode:mode,

  }
}

/**
* Maps Redux actions to component props
* @param {*} dispatch
*  
*/
const mapDispatchToProps = (dispatch) => {

  return {
      addDimensions: (data) => dispatch(addDimensions(data)),
      editDimensions: (data) => dispatch(editDimensions(data)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dimensions))