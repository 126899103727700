import React, { Component } from 'react'
import axios from 'axios'

/** 
 * Higher Level Components
 */
import { connect } from 'react-redux'   
import { withRouter } from 'react-router-dom'

/**
 * Redux Actions
 */
import {loginLocal, signUpLocal, loginToken} from '../../actions/authActions'


/**
 * Stu;e
 */
import {Container, Row, Col, Button, Form, Alert, Modal} from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group';
import '../../stylesheets/style.css'
import '../../stylesheets/instructions.css'

/**
 * Media and Images
 */
import { API_ENDPOINT } from  '../../constants/secrets';


/**
 * Default Config
 */
const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
}

axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

function renderInstructions()
{
    return <Col xs={12} lg={6}>
            <Col xs={12} style={{"marginBottom":"5rem"}}>

            <h2 style={{"fontFamily":"itc-avant-garde-gothic-pro, sans-serif", "fontWeight": "700", "fontSize":"2.5rem", "textAlign":"left"}}>Naya Studio</h2>
            <h1 style={{"fontFamily":"itc-avant-garde-gothic-pro, sans-serif", "fontWeight": "700", "fontSize":"3.5rem", "textAlign":"left"}}>Furniture Design Competition 2020</h1>
            <h3 style={{"fontFamily":"itc-avant-garde-gothic-pro, sans-serif", "fontWeight": "700", "fontSize":"1.5rem", "textAlign":"left"}}>17 February — 29 March 2020</h3>
            </Col>  
        </Col>
}

class Instructions extends Component{
    
    constructor(props)
    {
        super(props)
        this.state = {
            email           : '',
            password        : '',
            showPasswordBox : false,
            emailFound      : false,
            message         : "",
            showError       : false,
            showWarning     : false
        }
    }

    /**
     * Handles Login/SignUp
     */
    handleSubmit = (e) => {
        e.preventDefault()
        const AUTH_GET_USER_URI = `${API_ENDPOINT}/api/user`

        axios.get(AUTH_GET_USER_URI, {params: {email: this.state.email}} , config)
        .then(response => {
            if(response.status === 200)
            {
                if(response.data.type === "USER")
                {
                    // Show password box
                    this.setState({showPasswordBox: true, emailFound: true, message: "We found an account with this email address. Please login to proceed"})
                }
                else if (response.data.type === "LEAD")
                {
                    // Go with lead
                    this.props.setLead({email: response.data.lead.email})
                    this.props.history.push('landing')
                    // this.setState({showPasswordBox: false, emailFound: true, showError: true,  message: "Welcome!"})
                }
                
            }
        })
        .catch(e => {
            this.setState({showError: true, message: "Server Error"})
            console.log("User not found")
            // dispatch({ type: 'SIGNUP_ERROR', message: "Our systems are encountering problems. Please try again in some time"})
        })
    }

    /**
     * Handles functionality for login
     */
    handleLoginToAccount = (e) => {
        e.preventDefault()
            const data = {
                email: this.state.email,
                password: this.state.password
            }
            this.props.loginLocal(data)
            this.setState({nextPage: "/account"})

    }

    /**
     * Handles functionality for login
     */
    handleLoginLocal = (e) => {
        e.preventDefault()
            const data = {
                email: this.state.email,
                password: this.state.password
            }

            this.props.loginLocal(data)
    }

    /**
     * Handles form changes
     */
    handleChange = (e) => {
        // e.preventDefault()
        // e.stopPropagation()
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log(value)

        this.setState({
            [name]: value
        });
    }

    /**
     * Main Render Method
     */
    render()
    {
        return (
        <Container id="instructions" className="vcenter-outer">
                <Row className="vcenter-inner">
                    {renderInstructions()}


                    <Col xs={12} lg={6} className="vcenter-outer">
                        <Col xs={12} lg={{span:10, offset:1}} className="vcenter-inner">
                            <Form>
                                {(this.state.showPasswordBox || this.state.showError) && 
                                    <Alert variant={"warning"}>{this.state.message}</Alert>
                                }
                                {this.props.errorMessage && 
                                    <Alert variant={"warning"}>{this.props.errorMessage}</Alert>
                                }
                                {!this.state.emailFound?
                                
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email"  name='email' value={this.state.email} onChange={this.handleChange}/>
                                </Form.Group>:

                                // OR
                                <div>
                                    <p>Email</p>
                                    <p style={{"fontWeight":"400", "marginBottom":"0.5rem"}}>{this.state.email}</p>
                                    <p style={{"fontWeight":"400", "fontSize":"0.9rem", "color":"grey"}}>Not you? <a href="#" onClick={() => this.setState({showPasswordBox: false, emailFound: false, email: null})}>Change</a></p>
                                </div>
                                }
                                <CSSTransition
                                    in={this.state.showPasswordBox}
                                    timeout={300}
                                    classNames="password-form"
                                    unmountOnExit>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter password"  name='password' value={this.state.password} onChange={this.handleChange}/>
                                        <p style={{"fontWeight":"400", "fontSize":"0.9rem", "color":"grey"}}>Forgot Password? <a href="#" onClick={() => this.props.history.push('/login')}>Click Here</a></p>
                                    </Form.Group>
                                </CSSTransition>

                                <Col xs={12}>
                                    <Row className='justify-content-md-center'>
                                        {this.state.showPasswordBox?
                                        <Col xs={12}>
                                            <Row>
                                            <Button className="col-4 offset-xs-1" variant="dark" onClick={this.handleLoginLocal} style={{"paddingLeft":"1rem", "paddingRight":"1rem"}}>New Project</Button>
                                            <Button className="col-4 offset-xs-1" variant="dark" onClick={this.handleLoginToAccount} style={{"paddingLeft":"1rem", "paddingRight":"1rem"}}>Go to Account</Button>
                                            </Row> 
                                        </Col>
                                        // OR
                                        :
                                        <Button variant="dark" onClick={this.handleSubmit}>Get Started</Button>
                                        }   
                                    </Row>
                                    
                                </Col>
                                </Form>
                            </Col>  
                    </Col>
                </Row>
        </Container>
        )
    }

    componentDidUpdate()
    {
        if(this.props.user.loggedIn)
        {
            if(this.state.nextPage)
            {
                this.props.history.push(this.state.nextPage)
            }
            else
            {
                this.props.history.push("/competition-landing")
            }
        }
    }
}


/**
 * Maps Redux state to component props
 * @param {*} state 
 */
const mapStateToProps = (state) => {
    return { 

        nextPage        : 'canvas',
        canvas          : state.canvas,
        canvas_id       : state.canvas._id,
        step            : Object.keys(state.UI.flow).length-1,
        stepMax         : Object.keys(state.UI.flow).length,
        user            : state.user,
        errorMessage    : state.errors.loginError,
        signUpError     : state.errors.signupError,
    }
}

/**
 * Maps Redux actions to component props
 * @param {*} dispatch 
 */
const mapDispatchToProps = (dispatch) => {
    
    return { 
        signUpLocal: (data) => dispatch(signUpLocal(data)), 
        loginLocal: (data) => dispatch(loginLocal(data)), 
        loginToken: (data) => dispatch(loginToken(data)),
        loginError: (message) => dispatch({type:"LOGIN_ERROR", message: message}),
        signupError: (message) => dispatch({type:"SIGNUP_ERROR", message: message}),
        setLead: (data) => dispatch({type:"LEAD", data})

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Instructions))