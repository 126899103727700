import { API_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  export const getOption = (data) => {
        const GET_OPTION_DATA = `${API_ENDPOINT}/api/option`
        return axios.get(GET_OPTION_DATA, {params: data} , config)
}