import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Container, Row, Col, Button, Form, Alert} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import '../../stylesheets/style.css'
import '../../stylesheets/pre-canvas.css'
import axios from 'axios'
import { API_ENDPOINT } from  '../../constants/secrets'


  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


class Auth extends Component{
    
    constructor(props)
    {
        super(props)
        this.state = {
            password: '',
            confirm_password: '',
            errorMessage: '',
            token: this.props.token,
            successMessage: null
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        if(this.state.password === this.state.confirm_password)
        {
        const RESET_PASSWORD_URI = `${API_ENDPOINT}/auth/reset-password`
        try {
            let resetPasswordStatus = await axios.post(RESET_PASSWORD_URI, this.state)
            resetPasswordStatus = resetPasswordStatus.data
            if(resetPasswordStatus.status)
            {
                this.setState({successMessage: "Password successfully reset. Click on the button below to login"})
            }
            else
            {
                this.setState({errorMessage: resetPasswordStatus.message})
            }
        } catch (error) {
            this.setState({errorMessage: "Could not reset password. Check your internet connection."})

        }
    }
    }

    handleChange = (e) => {
        e.preventDefault()
        this.setState({[e.target.name]: e.target.value})

    }

    
    passwordResetSuccess = () =>
    {
            
            return <Container className="pre-canvas-wrapper">
                        <Row>
                            <Col xs={12}>
                                <div className="pre-canvas-h2">
                                    <h2 className="col-12">Reset password</h2>
                                    <Col xs={12} lg={{span:6, offset:3}}>
                                        {this.state.successMessage  && this.state.successMessage !== "" && <Alert variant={"success"}>{this.state.successMessage}</Alert>}
                                        <Form>
                                            <Col xs={12}>
                                                <Row className='justify-content-md-center'>
                                                    <Button variant="dark" onClick={() => this.props.history.push('/login')}>Login</Button>
                                                </Row>
                                            </Col>
                                            </Form>
                                        </Col>  
                                </div>
                            </Col>
                        </Row>
                    </Container>
    }


    signInModal = () =>
    {
            
            return <Container className="pre-canvas-wrapper">
                        <Row>
                            <Col xs={12}>
                                <div className="pre-canvas-h2">
                                    <h2 className="col-12">Reset password</h2>
                                    <Col xs={12} lg={{span:6, offset:3}}>
                                        {this.state.errorMessage  && this.state.errorMessage !== "" && <Alert variant={"danger"}>{this.state.errorMessage}</Alert>}
                                        <Form>
                                            <Form.Group controlId="formBasicPassword">
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control type="password" placeholder="Password" name='password' value={this.state.password} onChange={this.handleChange} />
                                            </Form.Group>

                                            {this.state.confirm_password !== "" && this.state.confirm_password !== this.state.password && <Alert variant={"warning"}>Passwords do not match</Alert>}
                                            <Form.Group controlId="formBasicPassword">
                                                <Form.Label>Confirm New Password</Form.Label>
                                                <Form.Control type="password" placeholder="Password" name='confirm_password' value={this.state.confirm_password} onChange={this.handleChange} />
                                            </Form.Group>

                                            <Col xs={12}>
                                                <Row className='justify-content-md-center'>
                                                    <Button variant="dark" onClick={this.handleSubmit}>Submit</Button>
                                                </Row>
                                            </Col>
                                            </Form>
                                        </Col>  
                                </div>
                            </Col>
                        </Row>
                    </Container>
    }


    render()
    {   
        let renderedForm
        if(this.state.successMessage)
        {
            renderedForm = this.passwordResetSuccess()
        }
        else{
            renderedForm = this.signInModal()
        }

        return (
            renderedForm
        )
    }
}



// const mapStateToProps = (state) => {
//     return { 

//         nextPage: 'account',
//         user: state.user,
//         loginError: state.errors.loginError,
//         signupError: state.errors.signUpError
//     }
// }

// const mapDispatchToProps = (dispatch) => {
    
//     return { 
//         signUpLocal: (data) => dispatch(signUpLocal(data)), 
//         loginLocal: (data) => dispatch(loginLocal(data)), 
//         clearErrorMessage: () => dispatch({type:'LOGIN_ERROR', data:undefined})
//     }
// }

export default connect(null, null)(withRouter(Auth))