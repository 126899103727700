import local from '../../images/sustainability-page/local.svg';
import traceable from '../../images/sustainability-page/traceable_resized.svg';
import materials from '../../images/sustainability-page/materials.svg';
import durability from '../../images/sustainability-page/durability.svg';
import meaning from '../../images/sustainability-page/meaning.svg';

import adapt from '../../images/sustainability-page/adapt.svg';
import choice from '../../images/sustainability-page/choice.svg';
import measure from '../../images/sustainability-page/measure.svg';

import feature_work from '../../images/sustainability-page/featured_work_web.png';
import featured_designer from '../../images/sustainability-page/footer_web.png';

let PAGE_CONFIG = 
{
    data: [
        {
            type: "HERO",
            title: "Sustainability",
            subtitle: "Giving furniture meaning again.",
            cta: "Subscribe Now",
            redirect_path: "/login"
        },        
        {
            type: "TESTIMONIALS",
            elements_data: [
                {text: "At Naya, we believe that sustainability does not have one definition and it is continuously changing...."},
                {text: "Thus we strive to have a broad view of sustainability and to discover, learn and adapt our approaches over time."},
                {text: "50% Reduction in life expectancy of furniture from 1980-2012"},
                {text: "4.2% Household waste from furniture"},
                {text: "Americans throw out more than 12 million tons of furniture and furnishings, according to the Environmental Protection Agency."}
            ]
        },
        {
            type:"TEXT_ICON",
            title: "How do we think about sustainability?",
            fluid: true,
            xs:{span:8, offset:2},
            lg:{span:10, offset:1},

            elements_data: [
                {
                    title: "Local",
                    image_src: local,
                    description: "Find local designers and makers that we can support."
                },
                {
                    title: "Traceable",
                    image_src: traceable,
                    description: "Trace furniture and materials to their origins"
                },
                {
                    title: "Materials",
                    image_src: materials,
                    description: "Use new & old environment friendly materials"
                },
                {
                    title: "Durability",
                    image_src: durability,
                    description: "Build and buy furniture that could last a lifetime"
                },
                {
                    title: "Meaning",
                    image_src: meaning,
                    description: "Every piece has a story and brings meaning to you"
                }
            ]
        },

        {
            type: "FULL_WIDTH_IMAGE",
            title: "Featured Work",
            css_slug: "featured-work",
            description: "James Shaw, a designer studying at the Royal College of Art, has been working with the American Hardwood Export Council and Marjan van Aubel in creating a new chemical process that turns waste wood back into usable material. To boil it down, waste wood + bio resin + water + heat = expanding foamy stuff, both strong and lightweight.",
            img_src:feature_work
        },
        {
            type:"TEXT_ICON",
            title: "Our Committment",
            fluid: true,
            xs:{span:8, offset:2},
            lg:{span:10, offset:1},
            elements_data: [
                {
                    title: "Adapt",
                    image_src: adapt,
                    description: "We bring the most up-to-date knowledge into our platform, processes and products."
                },
                {
                    title: "Measure",
                    image_src: choice,
                    description: "We strive to find better ways to measure impact and share that information."
                },
                {
                    title: "Your choice",
                    image_src: measure,
                    description: "Our platform helps clients, designers, and fabricators make better choices."
                }
            ]
        },
        {
            type: "FULL_WIDTH_IMAGE",
            title: "Featured Designer",
            css_slug: "featured-designer",
            description: "<h4 style='color:black; font-weight:bold'>Babacar MBodj Niang</h4><p style='color:black;'>Babacar was a Senegalese contemporary furniture designer and artist. He possessed a unique design vision that brought forth sublime designs infused with his trademark lifelike qualities.</p><p style='color:black;'>The designer decided to use his skills and rich imagination to create furniture and objects from his favorite material: wood. But he set himself the rule to use only discarded wood: wood remaining after a forest fire or human use- mostly for cooking.</p><p style='color:black;'>The wood Babacar used for the furniture is an extraordinary hardwood which is a local kind of ebony. The construction of the object is always related to the shapes of the pieces of wood that had been collected.</p>",
            img_src: featured_designer
        }
    ]
}

export default PAGE_CONFIG