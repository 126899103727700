import { API_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'
import { getCanvas } from './canvasActions'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const uploadFile = (data) => 
{

    return (dispatch) => {
        //Create Answer
        const UPLOAD_FILE_DATA_URI = `${API_ENDPOINT}/api/upload`

        axios.post(UPLOAD_FILE_DATA_URI, data, config)
            .then(response => {
                console.log(response)
            }).catch(e => {
                console.log(e)
                console.log("Error")
            })
        dispatch({ type: 'UPLOADING_FILE', data});
    }
}

export const addImagesToCanvas = (data) => 
{

    return (dispatch) => {
        //Create Answer
        const UPLOAD_FILE_DATA_URI = `${API_ENDPOINT}/api/canvas/uploads`
        console.log(data)
        console.log(UPLOAD_FILE_DATA_URI)
        axios.post(UPLOAD_FILE_DATA_URI, data, config)
            .then(response => {
                console.log(response)
                dispatch({ type: 'ADD_FILES_TO_CANVAS', data});
                dispatch(getCanvas())
            }).catch(e => {
                console.log(e)
                console.log("Error")
            })
    }
}

export const addSearchesToCanvas = (data) => 
{

    return (dispatch) => {
        //Create Answer
        const SEARCHES_TO_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas/search`
        console.log(SEARCHES_TO_CANVAS_DATA_URI)
        axios.post(SEARCHES_TO_CANVAS_DATA_URI, data, config)
            .then(response => {
                console.log(response)
                dispatch({ type: 'ADD_FILES_TO_CANVAS', data});
                dispatch(getCanvas())
            }).catch(e => {
                console.log(e)
                console.log("Error")
            })
    }
}