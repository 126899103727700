import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Row, Col, Image} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {setCanvas} from '../../actions/accountActions'
import {updateUser} from '../../actions/authActions'
import '../../stylesheets/account.css'

class CanvasWidget extends Component{

    state = {
        placeholders:[
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_1.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_2.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_3.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_4.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_5.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_6.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_7.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_8.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_9.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_10.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_11.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_13.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_14.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_15.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_16.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_sekka_1.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_sekka_2.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2',
            'https://www.notion.so/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fwoodcuts_sekka_3.jpg?width=400&userId=c5964483-f871-4442-8c23-5139077db974&cache=v2'
        ]
    }

    timeDifference(current, previous) {

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;
    
        var elapsed = current - previous;
    
        if (elapsed < msPerMinute) {
             return Math.round(elapsed/1000) + ' s ago';   
        }
    
        else if (elapsed < msPerHour) {
             return Math.round(elapsed/msPerMinute) + ' mins ago';   
        }
    
        else if (elapsed < msPerDay ) {
             return Math.round(elapsed/msPerHour ) + ' hr ago';   
        }
    
        else if (elapsed < msPerMonth) {
            return '~' + Math.round(elapsed/msPerDay) + ' d ago';   
        }
    
        else if (elapsed < msPerYear) {
            return '~' + Math.round(elapsed/msPerMonth) + ' m ago';   
        }
    
        else {
            return '~' + Math.round(elapsed/msPerYear ) + ' y ago';   
        }
    }

    handleClick = (e) =>
    {
        e.preventDefault()
        localStorage.setItem('nayaCanvasID', this.props.data._id)
        if(!this.props.data.version && this.props.data.version !== "GOOGLE"){
            // this.props.setCanvas(this.props.data._id)
            this.props.history.push('canvas')
        }
        else
        {
            // window.location = `https://grd.naya.studio/canvas/${this.props.data._id}`
            window.open(`https://grd.naya.studio/canvas/${this.props.data._id}`, '_blank')
        }
    }

    getRandomIndex = (min, max) => {
        return Math.floor(Math.random() * (max - min) + min);
    }

    getUnArchiveData = () => {
        let currentCanvas = this.props.user.archived_canvas.filter(x => x.canvas === this.props.data._id);
        let filteredArchivedCanvas = this.props.user.archived_canvas.filter(x => x.canvas !== this.props.data._id);
        let data = {}
        if(currentCanvas[0].prev_canvas_type==='user_canvas'){
            let user_canvas = this.props.user.user_canvas;
            user_canvas = user_canvas.concat(currentCanvas[0].canvas)
            data = {
                id:this.props.user.id,
                user_canvas,
                archived_canvas:filteredArchivedCanvas
            }
        }
        else if(currentCanvas[0].prev_canvas_type==='design_canvas'){
            let design_canvas = this.props.user.design_canvas;
            design_canvas = design_canvas.concat(currentCanvas[0].canvas)
            data = {
                id:this.props.user.id,
                design_canvas,
                archived_canvas:filteredArchivedCanvas
            }
        }
        else if(currentCanvas[0].prev_canvas_type==='fabrication_canvas'){
            let fabrication_canvas = this.props.user.fabrication_canvas;
            fabrication_canvas = fabrication_canvas.concat(currentCanvas[0].canvas)
            data = {
                id:this.props.user.id,
                fabrication_canvas,
                archived_canvas:filteredArchivedCanvas
            }
        }
        return data;
    }

    getArchiveData = () => {
        switch(this.props.type){
            case 'user_canvas':
                let user_canvas = this.props.user.user_canvas.filter(x => x !== this.props.data._id);
                let archived_user_canvas = this.props.user.archived_canvas;
                archived_user_canvas = archived_user_canvas.concat({canvas: this.props.data._id, prev_canvas_type:'user_canvas'});
                return {
                    id:this.props.user.id,
                    user_canvas,
                    archived_canvas:archived_user_canvas
                }
            case 'design_canvas':
                let design_canvas = this.props.user.design_canvas.filter(x => x !== this.props.data._id);
                let archived_design_canvas = this.props.user.archived_canvas;
                archived_design_canvas = archived_design_canvas.concat({canvas: this.props.data._id, prev_canvas_type:'design_canvas'});
                return {
                    id:this.props.user.id,
                    design_canvas,
                    archived_canvas: archived_design_canvas
                }  
            case 'fabrication_canvas':
                let fabrication_canvas = this.props.user.design_canvas.filter(x => x !== this.props.data._id);
                let archived_fabrication_canvas = this.props.user.archived_canvas;
                archived_fabrication_canvas = archived_fabrication_canvas.concat({canvas: this.props.data._id, prev_canvas_type:'fabrication_canvas'});
                return {
                    id:this.props.user.id,
                    fabrication_canvas,
                    archived_canvas: archived_fabrication_canvas
                }  
            case "archived_canvas":
                return this.getUnArchiveData()
            default:
                return null;
        }
    }

    archiveCanvas = () => {
        let data = this.getArchiveData();
        this.props.updateUser(data);
    }

    render()
    {
        let visual_moodboard = this.props.data.moodboards.filter(x => !x.isText)
        if(visual_moodboard.length>0)
        {
            visual_moodboard = visual_moodboard[0].options
        }

        let all_images = []
        try {
            all_images = [...this.props.data.uploads.map(upload => upload.link)]
        } catch (error) {
            all_images = all_images
        }

        try {
            all_images = [...visual_moodboard]
        } catch (error) {
            all_images = all_images
        }
        let src = this.state.placeholders[this.getRandomIndex(0, this.state.placeholders.length)]
        if(all_images.length> 0 )
        {
            src = all_images[Math.floor(Math.random() * all_images.length)]
        }

        let date = new Date(this.props.data.updatedAt)
        let now = new Date()
        let diff = this.timeDifference(now, date)

        return (
            <Col xs={12} lg={4} xl={3} className="canvas-widget" style={{"cursor":"pointer"}}>
                <Row>
                    <Col xs={12} className="img-container">
                        <Image src={src} alt="canvas" className="canvas-image"></Image>
                        <div className="options-container">
                            {/* <Image src={menu} alt="menu" style={{width:'2rem', height:'2rem', marginRight:'4rem'}} fluid></Image> */}
                            {/* <Image src={expand} alt="menu" fluid></Image> */}
                            <div className="options" style={{marginRight:'1rem'}} onClick={this.handleClick}>Open</div>
                            <div className="options" onClick={this.archiveCanvas} style={{backgroundColor:'rgba(234, 59, 39, 1)'}}>{this.props.type==='archived_canvas' ? 'Unarchive' : 'Archive'}</div>
                        </div>
                    </Col>
                    <Col xs={12} style={{"textAlign": "center"}} onClick={this.handleClick}><h5>{this.props.data.name? this.props.data.name: "Canvas"}</h5></Col>
                    <Col xs={12} style={{"textAlign": "center", "fontWeight":"200", "color": "grey"}}>Last updated {diff}</Col>
                </Row>
            </Col>
        )
    }
}



const mapStateToProps = (state) => {
    return { 
        user: state.user,
        canvas: state.canvas
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return { 
        setCanvas: (data) => dispatch(setCanvas(data)),
        updateUser: (data) => dispatch(updateUser(data))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CanvasWidget))
