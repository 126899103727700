import React from 'react';
import {Row, Col, Image, Modal} from 'react-bootstrap';
import maximize from '../../images/icons/maximize.svg'
import download from '../../images/icons/download.svg'
import documentSVG from '../../images/uploads/document.svg'
import audioSVG from '../../images/uploads/audio.svg'
import videoSVG from '../../images/uploads/video.svg'
import rotateIcon from '../../images/icons/rotateIcon.svg';
import deleteIcon from '../../images/icons/delete.png';
import annotateIcon from '../../images/icons/annotate.png';
import next from '../../images/icons/next-page.png'
import prev from '../../images/icons/prev-page.png'
import {createAnnotation} from '../../actions/annotationActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class ImageBox extends React.Component{
    imgBox = React.createRef();
    state = {
        rotation:0, 
        imageBoxWidth:'',
        imageBoxHeight:'auto',
        upload: this.props.upload,
        showNextPrev: false,
        multiple: this.props.multiple?.length > 1 ? this.props.multiple : null,
        positionInMultiple: 0,
        isImage: true,
        aspectRatio: null,
        uploadList: null,
        uploadListIndex: this.props.index,
        colsObject: this.props.colsObject
    }
    nextRef = React.createRef()
    prevRef = React.createRef()


    correctExtension = this.state.upload.ext.split(".")[this.state.upload.ext.split(".").length - 1].toLowerCase()

    componentDidMount(){
        if (![
            "jpg",
            "jpeg",
            "gif",
            "png",
            "svg",
            "raw",
            "webp",
            "tiff",
            "psd",
            "bmp",
            "heif",
        ].includes(this.correctExtension)) {
            this.setState({...this.state, isImage: false})
        }

        if (this.state.isImage) {
            const img = document.createElement('img')
            img.src = this.state.upload.link
            img.onload = () => {
                this.setState({...this.state, aspectRatio: img.naturalWidth/img.naturalHeight})
            }
        }

        const { multiple, upload } = this.state
        if (multiple) {
            const index = multiple.indexOf(upload)
            this.setState({ positionInMultiple: index })
        }
    }

    componentDidUpdate() {
        if (!this.state.uploadList && this.props.aspectRatioList.length > 0) {
            this.setState({...this.state, uploadList: this.props.aspectRatioList})
        }
    }

    rotateImage = () => {
        let newRotation = this.state.rotation + 90;
        if(newRotation == 360){
            newRotation = 0;
        }
        this.setState({
            rotation: newRotation, imageBoxHeight:'auto', imageBoxWidth:''
        })
    }

    lightBoxImageDisplay = () => {
        if(this.state.rotation===90 || this.state.rotation === 270)
        this.setState({imageBoxWidth:`${this.imgBox.current.clientWidth}px`, imageBoxHeight:`${this.imgBox.current.clientHeight}px`})
    }

    getCols = () => {
        // if (this.state.isImage && this.state.aspectRatio > 1.75) {
        //     return 2
        // }
        return this.props.getCols()
    }

    handleAnnotate = () => {
        this.props.createAnnotation({"annotationSrc": this.state.upload.link})
        this.props.history.push("/annotate")
    }

    renderNonImageIcon = () => {
        let defaultIcon = documentSVG
        let styleSVG = { width: '30%', height: '45%' }
    if([
        "mp3",
        "wav",
        "wma",
        "aac",
        "m4a",
        "flac",
        "mpga",
    ].includes(this.correctExtension)) {
            defaultIcon = audioSVG
            styleSVG = {width: '27%', height: '30%'}
    } 
    if([
        "webm",
        "mpg",
        "mp2",
        "mpeg",
        "mpe",
        "mpv",
        "ogg",
        "mp4",
        "m4p",
        "m4v",
        "avi",
        "wmv",
    ].includes(this.correctExtension)) {
                defaultIcon = videoSVG
                styleSVG = { width: '27%', height: '30%'}
        }
    return [ defaultIcon, styleSVG ]
    }

    // determines width ratio of image compared to neighbors
    transformUploadList = () => {
        let uploadListInSpaceTaken = []
        if (this.state.uploadList) {
            uploadListInSpaceTaken = this.state.uploadList.map((aspectRatio) => {
                if (aspectRatio > 1.77)
                    return 2
                return 1
            })
        }
        return uploadListInSpaceTaken
    }

    // determines image col based on neighbors in comment tile
    renderXLCols = () => {
        if (this.state.isImage && this.state.uploadList) {
            let aspectList = this.transformUploadList()
            aspectList.push(this.state.colsObject.reply)
            aspectList.push(this.state.colsObject.text)
            switch (true) {
                case aspectList.length === 1:
                    return 12
                case aspectList.length >= 2:
                    let sum = aspectList.reduce((acc, cur) => acc + cur)
                    if (sum > 4) {
                        sum = 4
                    }
                    // console.log(this.state.upload)
                    // console.log(12/sum * aspectList[this.state.uploadListIndex])
                    return (12/sum) * aspectList[this.state.uploadListIndex]
                default:
                    return 12/this.getCols()
            }
        }
            
        return 12/this.getCols()
    }

    // determines image col based on neighbors in comment tile 
    renderLGCols = () => {
        if (this.state.isImage && this.state.uploadList) {
            let aspectList = this.transformUploadList()
            aspectList.push(this.state.colsObject.reply)
            aspectList.push(this.state.colsObject.text)
            switch (true) {
                case aspectList.length === 1:
                    return 12
                case aspectList.length >= 2:
                    let sum = aspectList.reduce((acc, cur) => acc + cur)
                    if (sum > 4) {
                        sum = 4
                    }
                    // console.log(12/sum * aspectList[this.state.uploadListIndex])
                    return (12/sum) * aspectList[this.state.uploadListIndex]
                default:
                    return this.getCols() < 4 ? 12 / this.getCols() : 4
            }
        }       
        return this.getCols() < 4 ? 12 / this.getCols() : 4
    }

    handleNextPrev = (e) => {
        e.preventDefault()
        const { positionInMultiple, multiple } = this.state
        const next = (positionInMultiple + 1) % multiple.length
        const prev = (positionInMultiple - 1) < 0 ? multiple.length - 1 : positionInMultiple - 1
        switch(e.target.dataset.value) {
            case 'next': 
                this.setState({
                    lightboxImage: multiple[next].link,
                    positionInMultiple: next
                })
                break
            case 'prev':
                this.setState({
                    lightboxImage: multiple[prev].link,
                    positionInMultiple: prev,
                })
                break
            default:
                return
        }
    }

    render(){
        const {rotation, imageBoxWidth, imageBoxHeight, upload} = this.state;
        const [defaultIcon, styleSVG] = this.renderNonImageIcon()
        const imageStyle = this.state.aspectRatio > 1.77 
                            ? {"height": "auto", "width": "100%"}
                            : { "height": "100%", "width": "auto"}
        // if (this.state.uploadList)
        //     console.log(this.state.uploadList)
        console.log(upload)
        // console.log(this.getCols())
        return(
            <Col xs={12} md={this.getCols() < 2? 12/this.getCols(): 6} 
                    lg={this.renderLGCols()} 
                    xl={this.renderXLCols()} 
                    style={{"minHeight": "300px", "padding":"0"}} className="img-wrapper">
            {this.state.isImage ? 
                <img alt='upload' src={upload.link} style={{transform: `rotate(${rotation}deg)`, ...imageStyle}}></img>
                : <a href={upload.link} className='canvas-tile' style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{top: '12%'}}> {this.state.upload.ext} </div>
                    <img alt='default' src={defaultIcon} style={styleSVG} />
                    <img alt='download' src={download} style={{ "width": "2rem", "height": "2rem", "bottom": "12%", "top": "unset", "cursor": "pointer", filter: 'invert(1)'}}></img>
                </a>
            }
            { this.state.isImage ?
                 this.props.edit ? (
                    <img 
                        src={deleteIcon} alt='delete' className="maximizeButton" 
                        style={{"width":"2rem", "height":"2rem", "bottom":"1rem", "right":"1rem", "top":"unset", "left":"unset", "cursor":"pointer"}} 
                        onClick={()=>this.props.removeImage(upload._id)}
                    ></img>
                ) : (
                    <>
                                <img src={maximize} alt='maximize' className="maximizeButton" style={{"width":"2rem", "height":"2rem", "bottom":"1rem",  "right":"1rem", "top":"unset", "left":"unset", "cursor":"pointer"}} onClick={() => this.setState({showLightbox: true, lightboxImage: upload.link})}></img>
                                <a href={upload.link}><img src={download} alt='download' className="maximizeButton" style={{"width":"2rem", "height":"2rem", "bottom":"1rem",  "right":"3.5rem", "top":"unset", "left":"unset", "cursor":"pointer"}}></img></a>
                    <img 
                        src={rotateIcon} alt='rotate' className="maximizeButton" 
                        style={{"width":"2rem", "height":"2rem", "bottom":"1rem",  "right":"6rem", "top":"unset", "left":"unset", "cursor":"pointer"}} 
                        onClick={this.rotateImage}
                    ></img>
                    <img 
                        src={annotateIcon} alt='annotate' className="maximizeButton" 
                        style={{"width":"2rem", "height":"2rem", "bottom":"1rem",  "right":"8.5rem", "top":"unset", "left":"unset", "cursor":"pointer"}} 
                        onClick={this.handleAnnotate}
                    ></img>
                    </>
                ) :
                ''
            }

            <Modal
                size="xl"
                show={this.state.showLightbox && this.state.lightboxImage}
                onHide={() => this.setState({showLightbox: false, lightboxImage: null})}
                aria-labelledby="example-modal-sizes-title-xl"
                id="instructions-modal"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body 
                    style={{overflowY:'auto'}}
                >
                    <Row 
                        className="justify-content-center" 
                        style={{height:imageBoxWidth}}
                        >
                        <img 
                            ref={this.imgBox}
                            key={upload.link}
                            src={this.state.lightboxImage} 
                            onLoad={this.lightBoxImageDisplay}
                            style={{"maxWidth":"100%", "height":imageBoxHeight, transform: `rotate(${rotation}deg)`}} alt="lightbox"></img>
                        {
                            this.state.multiple?.length > 1 && <div className='next-prev_buttons_container'>
                                <div
                                    className={this.state.showNextPrev ? 'prev show' : 'prev'}
                                    data-value='prev'
                                    onClick={this.handleNextPrev}
                                    onMouseEnter={() => this.setState({showNextPrev: true })}
                                    onMouseLeave={() => this.setState({showNextPrev: false})}
                                >
                                    &lt;
                                </div>
                                <div 
                                    className={this.state.showNextPrev ? 'next show' : 'next'}
                                    data-value='next'
                                    onClick={this.handleNextPrev}
                                    onMouseEnter={() => this.setState({showNextPrev: true })}
                                    onMouseLeave={() => this.setState({showNextPrev: false})}
                                >
                                    &gt;       
                                </div>
                            </div>
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        </Col>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
  
    return { 
        createAnnotation: (data) => dispatch(createAnnotation(data))
    }
  }

export default connect(null, mapDispatchToProps)(withRouter(ImageBox))