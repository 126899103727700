import React from 'react';
import { Modal } from 'react-bootstrap'
import Dimensions from '../dimension/Dimension';
import TextMoodboard from '../moodboard/TextMoodboard';
import ShippingTool from './ShippingTool';
import Budget from './Budget.js';
import './../../stylesheets/tools.css';

class Tool extends React.Component{
  renderData = () => {
    switch(this.props.contentType){
      case 'DIMENSIONS':
        return <Dimensions toggleModal={this.props.toggleModal}/>
      case 'TEXT_MOODBOARD':
        return <TextMoodboard toggleModal={this.props.toggleModal}/>
      case 'SHIPPING':
        return <ShippingTool toggleModal={this.props.toggleModal}/>
      default:
        break;
      case 'BUDGET':
        return <Budget toggleModal={this.props.toggleModal}/>
    }
  }
  render(){
    return(
      <Modal
        size="xl"
        show={this.props.isOpen}
        onHide={()=>this.props.toggleModal()}
        aria-labelledby="example-modal-sizes-title-sm"
        id="tools-modal"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body
          style={{padding:'20px'}}
        >
          {this.renderData()}
        </Modal.Body>
      </Modal>
    )
  }
}

export default Tool;