const initErrors = {
    loginError: undefined,
    signupError: undefined
}

let DEBUG = false

const errorsReducer = (state = initErrors, action) => {
    let newState

    if(DEBUG) console.log("CANVAS REDUCER:")
    if(DEBUG) console.log("ACTION:")
    if(DEBUG) console.log(action)

    if(DEBUG) console.log("CURRENT STATE:")
    if(DEBUG) console.log(state)

    switch(action.type)
    {
        case 'LOGIN_ERROR':
            newState = {...state, "loginError": action.message}
            break;
        case 'SIGNUP_ERROR':
            newState = {...state, "signupError": action.message}
            break;
        case 'TOKEN_ERROR':
            newState = {...state}
            localStorage.removeItem('nayaJwtToken')
        case 'SIGNUP_LOCAL':
            newState = {...state, "signupError": undefined}
            break;
        case 'LOGIN_LOCAL':
            newState = {...state, "loginError": undefined}
            break;
        case 'LOGIN_TOKEN':
            newState = {...state, "loginError": undefined}
            break;
        default:
            newState = state
    }

    if(DEBUG) console.log("NEW STATE:")
    if(DEBUG) console.log(newState)

    return newState
}

export default errorsReducer