import qs from 'qs'


export const getRedirectUri = () => {
  const localEndpoint = window.location.href
  //redirect endpoint for auth.naya.studio
  const query = qs.stringify({
    redirect_uri: localEndpoint
  })

  return query
}