import React, { useEffect, useRef } from 'react'
import './ClientAgreement.scss'

const ClientAgreement = ({
    tos,
    onChangeTOS,
    bottom,
    setBottom = () => false
  }) =>  {
  const containerRef = useRef(null)
  const timeout = useRef(null)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(timeout.current)
    }
  }, [])

  const handleScroll = (e) => {
    if (bottom)
      return

    if (timeout.current !== null)
      clearTimeout(timeout.current)


    //checks if user has scrolled to bottom of div
    //the '+ 200' is used to buffer the extra white space at the bottom
    timeout.current = setTimeout(() => {
      const bottomOfDiv = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 225

      if (bottomOfDiv)
        setBottom(true)
      //timeout of 200ms is used for performance
    }, 200)

  }

  return (
    <div class="c29 c36 client-agreement" ref={containerRef} onScroll={handleScroll}>
      <p class="c32"><span class="c2 c11 c12">Client Agreement</span></p>
      <p class="c13 c9"><span class="c2 c11 c12"></span></p>
      <p class="c13 c44"><span class="c0">Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The date in which you have
      clicked through to accept the terms of this Agreement</span></p>
      <p class="c13"><span class="c35">Between:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c35">Naya Studio, Inc. (</span><span
          class="c22">&ldquo;Naya&rdquo;</span><span class="c0">) </span></p>
      <p class="c13"><span
        class="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </p>
      <p class="c13"><span
        class="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;and</span>
      </p>
      <p class="c13"><span
        class="c35">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;you
      (</span><span class="c22">&ldquo;Client&rdquo; or &ldquo;You&rdquo;)</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c18"><span class="c8">The terms and conditions of this Client Agreement (the &ldquo;</span><span
        class="c2">Agreement</span><span class="c8">&rdquo;), together with Naya&rsquo;s Terms of Service located at
      [</span><span class="c23"><a class="c40" target='_blank' rel='noreferrer noopener'
          href="https://www.google.com/url?q=https://docs.google.com/document/d/e/2PACX-1vR0FBgEXprCXloWii0Y3q7zvj27kHpxe9y-y8XriEXE5YCaAyk-EdBTn_ARLVXQww/pub&amp;sa=D&amp;source=editors&amp;ust=1623015782296000&amp;usg=AOvVaw1HxKeAg1P9feDC30F9KXv-">LINK</a></span><span
            class="c8">] shall govern your use of Naya&rsquo;s online platform (the &ldquo;</span><span
              class="c2">Platform</span><span class="c8">&rdquo;) and Client&rsquo;s use of the services provided on the
      Platform. The Platform shall consist of its home page located at </span><span
          class="c2">&ldquo;https://www.naya.studio,&rdquo;</span><span class="c0">&nbsp;as well as any URL that is linked
      or navigable to from the home page. </span></p>
      <p class="c3"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0 start" start="1">
        <li class="c4 c7 li-bullet-0"><span class="c2">Purpose of Naya</span></li>
      </ol>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4" id="h.1fob9te"><span class="c8">Naya has created an online platform for matching you with individuals or
      businesses (&ldquo;</span><span class="c2">Client</span><span class="c8">&rdquo;)</span><span
          class="c2">&nbsp;</span><span class="c8">with design, consulting or fabricating expertise to bring your product
      design concepts and ideas to life (each, a &ldquo;</span><span class="c2">Provider</span><span class="c8">&rdquo;
      and collectively, the &ldquo;</span><span class="c2">Providers</span><span class="c8">&rdquo;). &nbsp;By using the
        Platform, you agree and acknowledge that Naya may connect you with other Providers (including, without limitation,
        &ldquo;Designers,&rdquo; &ldquo;Consultants,&rdquo; and &ldquo;Makers&rdquo;), as may be necessary to achieve your
      goals. &nbsp;Providers and Clients shall collectively be referred to herein as &ldquo;</span><span
          class="c2">Users</span><span class="c8">&rdquo;. &nbsp;On and through the Platform, you may post request(s) for
      custom product design services (&ldquo;</span><span class="c2">Project Requests</span><span class="c8">&rdquo; and
      such deliverables provided in support thereof, &ldquo;</span><span class="c2">Projects</span><span
          class="c8">&rdquo;) and/or product manufacturing services (&ldquo;</span><span class="c2">Maker Project
      Requests</span><span class="c8">&rdquo; and such deliverables provided in support thereof, &ldquo;</span><span
          class="c2">Maker Projects</span><span class="c0">&rdquo;). </span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Naya&rsquo;s Platform enables Users to request the following services:</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c18"><span class="c2 c12 c31">Phase 1: Project Requests: Design &amp; Consulting</span></p>
      <p class="c3"><span class="c2 c31 c12"></span></p>
      <p class="c4"><span class="c8">To the extent you desire to make a Project Request, you shall first indicate the scope
      and specific nature thereof https://www.naya.studio/. Naya may then match individuals with specific expertise,
      including, without limitation, &ldquo;</span><span class="c2">Designers</span><span class="c8">&rdquo; and
      &ldquo;</span><span class="c2">Consultants</span><span class="c8">&rdquo; relevant to your particular Project
      Request Designer and/or Consultant, as may be applicable, to assist with your Project Request (&ldquo;</span><span
          class="c2">Project Assignment</span><span class="c8">&rdquo;). &nbsp;Phase 1 Project Assignments may include
          design and consulting projects requiring expertise in product design, 3D modeling, renderings, engineering,
          technical design, sustainability consulting, sub trades and the like. Designers and Consultants, as applicable,
          will be notified of Project Assignments via a prompt on the Platform that will allow Designer or Consultant to
          either accept or reject the Project Assignment. &nbsp;In order for Designer or Consultant to accept your Project
          Assignment, such Designer or Consultant will have first separately agreed under a separate Provider Agreement with
          Naya to provide guidance to such Client requests for purposes of consulting or design support in respect of the
      Project (a &ldquo;</span><span class="c2">Consultation</span><span class="c8">&rdquo;). Additionally, Consultant
      or Designer shall have either accepted a price quote delivered by Naya (the &ldquo;</span><span class="c2">Naya
      Estimate</span><span class="c8">&rdquo;) or provided a comprehensive price quote for delivery of such services, as
      may be applicable and as determined by Naya in its sole discretion (&ldquo;</span><span class="c2">Project
      Quote</span><span class="c0">&rdquo;). The Consultation and Project Quote shall together constitute the Project
        and shall be delivered to Naya for further distribution to Client for review and payment. In consideration of
        delivering these services to you, you shall make payments to Naya for further distribution to Consultant or
        Designer, as applicable, in accordance with the terms of this Agreement. Notwithstanding the foregoing, in the
        event you make additional requests of Provider or otherwise modify the requests set forth in the Project
        Assignment, Provider may have the opportunity to adjust the pricing set forth in their Project Quote upon the
        approval of Naya and Client; provided, that such additional payments must be completed via the Platform. &nbsp;Any
        price adjustments shall be based on Naya&rsquo;s pricing schedule for such additional requests, which shall be
      distributed by Naya to Client and Provider for payment in connection therewith.</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">For detail regarding payments due to Consultant and Designer for services rendered, see
      &ldquo;Section 7 &ndash; Payment and Fees.&rdquo; </span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c18"><span class="c2 c31 c12">Phase 2: Maker Project Requests: Fabrication &nbsp;</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c8">To the extent you desire to transform and utilize your Project for purposes of creating
      and fabricating a physical manifestation thereof, you shall indicate such interest to Naya by submitting a Maker
      Project Request to Naya, which request may include, among other things, design and material specifications and
      warranty requirements of Maker. &nbsp; Upon receipt of such Maker Project Request, Naya may match Makers with your
      Project, to assist with creating and assembling the Project (such matching and assignment, the &ldquo;</span><span
          class="c2">Maker</span><span class="c8">&nbsp;</span><span class="c2">Project Assignment</span><span
            class="c8">&rdquo;). Maker will be notified of Maker Project Assignments via a prompt on the Platform that will
          allow Maker to either accept or reject the Maker Project Assignment. In order for Maker to accept the Maker
          Project Assignment, Maker will have first agreed under a separate Provider Agreement with Naya to provide guidance
          to such Clients making Maker Project Requests to ensure that the product can be constructed by Maker. This
          guidance may include expertise and insight with respect to materials, colors, finishes, design changes, structural
          changes, questions for you and Designer or Consultant, as applicable, and any other information required for
      manufacturing the product (together, these services are &ldquo;</span><span class="c2">Maker
      Consultation</span><span class="c8">&rdquo;). To accept the Maker Project Assignment, Maker shall sign this
        Agreement and either accept the Naya Estimate or alternatively provide a comprehensive price quote for delivery of
      such fabrication services (such quote, the &ldquo;</span><span class="c2">Manufacturing Quote</span><span
          class="c0">&rdquo;). &nbsp;Upon receiving and confirming the Manufacturing Quote, Naya shall provide you with a
          total project cost and give you the right to review and approve or reject Maker&rsquo;s bid to fabricate the Maker
          Project. &nbsp;If you accept the project specifications and total project cost delivered by Naya and Naya awards
          Maker the Maker Project Assignment, Maker will be obligated under this Agreement to construct and deliver the
          finalized Maker Project to you, subject to your payment in full of all amounts due hereunder on the terms set
          forth in Section 7. &nbsp; In consideration for delivering the Maker Project, you shall pay to Naya in a single
          cash payment an amount equal to the Manufacturing Quote in advance of Maker&rsquo;s commencement of fabricating
          such Maker Project. &nbsp;Notwithstanding the foregoing, in the event you make additional requests of Maker or
          otherwise modify the requests set forth in the Maker Project Assignment, Maker may have the opportunity to adjust
          the pricing set forth in their Manufacturing Quote upon the approval of Naya and Client; provided, that any such
          additional payments must be completed via the Platform. &nbsp;Any price adjustments shall be based on Naya&rsquo;s
          pricing schedule for such additional requests, which shall be distributed by Naya to Client and Provider for
      payment in connection therewith. &nbsp; &nbsp; </span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">For detail regarding payments due to Maker for services rendered, see &ldquo;Section 7
      &ndash; Payment and Fees.&rdquo;</span></p>
      <p class="c3"><span class="c0"></span></p><a id="id.3znysh7"></a>
      <ol class="c14 lst-kix_list_2-0" start="2">
        <li class="c4 c7 li-bullet-0"><span class="c2">Eligibility</span><span class="c8">&nbsp;</span><span class="c2">to
        use the Platform</span></li>
      </ol>
      <p class="c15 c29 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c8">The Platform is available only to legal entities or persons who are at least eighteen
      (18) years old and are otherwise capable of forming legally binding contracts under applicable law. If you are a
      person who agrees to this Agreement on behalf of a company or other legal entity, the terms &ldquo;Client,&rdquo;
      &ldquo;you,&rdquo; and &ldquo;your&rdquo; refer both to you personally and the entity on whose behalf you agree.
      Furthermore, you personally represent and warrant that you have the authority to bind that entity to this
      Agreement. &nbsp;</span><span class="c35">You hereby agree and acknowledge that you shall comply in all respects
        with Naya&rsquo;s guidelines on Provider contact in accordance with the terms set forth at [LINK TO TERMS AND
      CONDITIONS].</span></p>
      <p class="c3"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="3">
        <li class="c4 c7 li-bullet-0"><span class="c2">Client Obligations</span></li>
      </ol>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">You agree that when you submit a Project Request or Maker Project Request, as
      applicable, and such request has been accepted by a Provider, you agree, acknowledge and understand that you shall
      be legally obligated to Naya and such Provider having submitted such Project Request or Maker Project Request, as
      applicable, to make payment in full for services provided thereby in support of your Project or Maker Project on
      the terms set forth herein. </span></p>
      <p class="c15 c9 c29"><span class="c0"></span></p>
      <p class="c4"><span class="c0">You acknowledge, agree and understand that: (1) no User, including you, is an employee,
      agent, or representative of Naya; (2) Naya is not an employee, agent, or representative of any User, including
      you; (3) Naya does not, in any way, supervise, direct, or control any User&rsquo;s work; provided, that Naya
      reserves the right to monitor progress and quality of any Project Assignment or Maker Project Assignment; provided
      further, that Naya shall &nbsp;have no liability for any claims made with respect to any Project or Maker Project;
      (4) Naya shall not have any liability or obligations for any acts or omissions by any User; (5) Naya has no
      control over Users; and (6) Naya makes no representations as to (a) the reliability, capability, honesty,
      qualifications, or other characteristics of any User or any User&rsquo;s statements or conduct; or (b) the
      quality, safety, or legality of any Project or Maker Project.</span></p>
      <p class="c3"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="4">
        <li class="c4 c7 li-bullet-0"><span class="c2">Restrictions on Use</span></li>
      </ol>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">You may not:</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_1-0 start" start="1">
        <li class="c15 c7 c30 li-bullet-0"><span class="c0">post any material on the Platform in violation of
        another&rsquo;s intellectual property rights; or</span></li>
      </ol>
      <p class="c15 c9 c30 c33"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_1-0" start="2">
        <li class="c15 c30 c7 li-bullet-0"><span class="c0">fail to comply with all applicable laws that relate to your use
        of or activities on or in relation to the Platform including but not limited to United States export
        restrictions.</span></li>
      </ol>
      <p class="c3"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="5">
        <li class="c4 c7 li-bullet-0"><span class="c2">Communications with Users</span></li>
      </ol>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">All written communications between Client and other Users relating to Projects and/or
      Maker Projects must be conducted through the Platform or communication devices designated by Naya in writing. All
      such communications may not contain any requests to circumvent the Platform or the communication devices
      designated by Naya in writing.</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Client agrees to respond within 2 business days to any communications or requests made
      by a Provider through the Platform regarding any Projects and/or Maker Projects which the Provider has agreed to
      provide (e.g., if a Provider contacts you on Friday, you must respond by the next Tuesday).</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">All communications hereunder shall be subject to Section 18 and the penalties expressly
      described therein. &nbsp;For the avoidance of doubt, no Client or other User shall communicate on the Platform or
      otherwise to indirectly or directly disintermediate Naya or otherwise poach or solicit any Provider to perform or
      deliver services outside of this Agreement. &nbsp; &nbsp;</span></p>
      <p class="c3"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="6">
        <li class="c4 c7 li-bullet-0"><span class="c2">Posting Requests</span></li>
      </ol>
      <p class="c4 c9"><span class="c2 c11 c12"></span></p>
      <p class="c37 c43"><span class="c0">Client represents and warrants that Design Project Requests and Maker Project
      Requests are and shall be of a professional nature and accurately describe the service requested.&nbsp; Design
      Project Requests and Maker Project Requests may not request any good or service that is illegal or the provision
      of which would infringe upon any third party&rsquo;s intellectual property or other rights.&nbsp; &nbsp;</span>
      </p>
      <p class="c3"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="7">
        <li class="c4 c7 li-bullet-0"><span class="c2">Payment</span></li>
      </ol>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c8">You hereby agree that all payments shall be paid and accepted only through a payment
      mechanism provided or approved by Naya (an &ldquo;</span><span class="c2">Approved Payment Mechanism</span><span
          class="c0">&rdquo;). &nbsp;</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">All payments due to Designers or Consultants shall be due and paid by you to Naya
      &nbsp;immediately upon your confirmation and approval of the Project Quote, unless other payment terms are
      otherwise agreed to by Naya. &nbsp;Client agrees, acknowledges and understands that such amount invoiced to Client
      shall include the amount set forth by the Project Quote plus fees payable to Naya for Platform processing and
      service fees.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">All payments due to Makers shall be due and paid by you to Naya immediately upon your
      confirmation and approval of the Manufacturing Quote, unless otherwise agreed to by Naya. &nbsp;Client agrees,
      acknowledges and understands that such amount invoiced to Client shall include the amount set forth by the
      Manufacturing Quote plus fees payable to Naya for Platform processing and service fees. </span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">All fees shall be determined in respect of each Project and/or Maker Project and shall
      not be changed, modified or amended without the express written consent of Naya. &nbsp;If Client becomes
      responsible under this Agreement to make any payments to Naya or Providers and fails to pay such amounts when due,
      Client shall be responsible to Naya for all amounts due, plus any applicable processing fees, charges or
      penalties, collection costs (including but not limited to attorneys&rsquo; fees), and interest on the unpaid
      amount at the lesser of one and one-half percent (1.5%) per month or the maximum allowed by law. </span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Notwithstanding the foregoing, any additional revisions or amendments to any Project
      Quote or Manufacturing Quote shall be due and payable to immediately upon your and Naya&rsquo;s approval of such
      additional amounts payable, which shall be due and payable to Naya immediately upon such acceptance and prior to
      Provider&rsquo;s processing such additional Project or Maker Project requests. </span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="8">
        <li class="c4 c7 li-bullet-0"><span class="c2">Release</span></li>
      </ol>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c8">Client agrees that by entering into this Agreement, Client, for Client and
      Client&rsquo;s predecessors, successors, and assigns (together, the &ldquo;</span><span class="c2">Client
      Releasors</span><span class="c8">&rdquo;), releases and forever discharges Naya and its predecessors, successors,
        assigns, agents, officers, directors, employees, subsidiaries, parents, affiliates, attorneys, contractors, and
      suppliers (together, the &ldquo;</span><span class="c2">Naya Releasees</span><span class="c0">&rdquo;) from and
        against all actions, causes of action, claims, suits, debts, damages, judgments, liabilities, rights, contracts,
        obligations, and demands, whether now known or unknown, liquidated or unliquidated, that any of the Client
        Releasors has, may have, has asserted, or could have asserted, of any nature and relating to any subject,
      excluding the right of Client to enforce this Agreement according to its terms.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">In addition, without limiting the generality of the foregoing, Client, for the Client
      Releasors, specifically releases and forever discharges the Naya Releasees from and against all actions, causes of
      action, claims, suits, debts, damages, judgments, liabilities, rights, contracts, obligations, and demands,
      whether now known or unknown, liquidated or unliquidated, that any of the Client Releasors has, may have, has
      asserted, or could have asserted, of any nature arising out of or in any way connected with any disputes Client
      may have with any other User of the Platform.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="9">
        <li class="c4 c7 li-bullet-0"><span class="c2">Client Conduct and Content</span></li>
      </ol>
      <p class="c15 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c8">Client agrees to be solely responsible for Client&rsquo;s conduct and activities on and
      regarding the Platform and any and all data, text, information, usernames, graphics, images, photographs, designs,
      renderings, fabrications, profiles, audio, video, items, and links that Client submits, posts, or displays on or
      in connection with the Platform (together, &ldquo;</span><span class="c2">Content</span><span class="c0">&rdquo;).
    </span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Client will not infringe upon or misappropriate any person&rsquo;s or entity&rsquo;s
      copyright, patent, trademark, trade secret or other proprietary or intellectual property rights or rights of
      publicity or privacy.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="10">
        <li class="c4 c7 li-bullet-0"><span class="c2">Intellectual Property Ownership</span></li>
      </ol>
      <p class="c4 c9"><span class="c17 c11 c12"></span></p>
      <p class="c4"><span class="c35 c29">Naya represents and warrants that Provider has granted and assigned all ownership
      rights, </span><span class="c8">copyright, trademark, invention rights, other intellectual property, publicity,
        database rights, and any other legal rights of any kind that Provider has in the Content to Client upon completing
        Client&rsquo;s particular project assignment; provided, that by signing this Agreement and using the Platform, you
        hereby irrevocably agree that Naya shall have a fully paid-up, irrevocable, worldwide, royalty-free, exclusive
        right and license to use and commercialize such intellectual property and related rights for a period of ten (10)
      years unless otherwise agreed to by each of you and any applicable Provider(s).</span><span
          class="c35 c29">&nbsp;</span><span class="c0">You hereby agree to sign any instruments of assignment or other
      transfer documents requested by Naya to facilitate the foregoing transfer, assignment and license.</span></p>
      <p class="c18"><span class="c8">&nbsp;</span></p>
      <ol class="c14 lst-kix_list_2-0" start="11">
        <li class="c4 c7 li-bullet-0"><span class="c2">Warranty Disclaimer</span></li>
      </ol>
      <p class="c15 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Naya makes no express or implied representations or warranties with regard to
      Naya&rsquo;s services, work products, Naya resources, the Platform, any activities or items related to this
      Agreement, or business conducted, or purchases or sales made with the assistance of Naya, all of which are
      provided &ldquo;as is&rdquo; and made at each User&rsquo;s own risk. To the maximum extent permitted by law, Naya
      disclaims all express or implied conditions, representations and warranties including, but not limited to, the
      warranties of merchantability, fitness for a particular purpose, and non-infringement. In addition, Client agrees
      that no advice or information (oral or written) obtained by Users from Naya shall create any warranty of any kind
      not expressly made herein.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Naya makes no representations or warranties of any kind concerning Project Requests or
      Maker Project Requests, including but not limited to representations or warranties concerning the safety of
      Projects and/or Maker Projects, or Users&rsquo; capabilities, honesty, reliability, trustworthiness, or abilities
      to pay. </span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Without limiting the generality of the foregoing, Naya does not warrant or represent
      that your use of the Platform or interactions with other Users, or Project Requests and/or Maker Project Requests
      will be safe. You understand and agree that Naya does not assume, and expressly disclaims, any responsibility or
      liability for any damages to, or viruses or other malware that may infect, your equipment or other property on
      account of your access to or use of the Platform. You further understand and agree that Naya does not assume and
      expressly disclaims, any responsibility or liability for any damage or harm that any of the Projects Requests or
      Maker Project Requests causes to any property or person.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="12">
        <li class="c4 c7 li-bullet-0"><span class="c2">Limitation of Liability</span></li>
      </ol>
      <p class="c15 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">In no event shall Naya, or its subsidiaries, parents, officers, directors, employees,
      agents, consultants, contractors, or suppliers be liable for any damages whatsoever, whether direct, indirect,
      general, special, compensatory, consequential, or incidental, arising out of or relating to Naya&#39;s services,
      your use of Naya&rsquo;s services or the Platform, the conduct of any User (whether tortious or otherwise) in
      connection with the use of the Platform by you or any other User, or this Agreement, including without limitation,
      lost profits, bodily injury, or emotional distress.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Notwithstanding the foregoing, to the extent Naya is ever determined to be liable to
      you, you agree that Naya&#39;s liability, and (as applicable) the liability of Naya&rsquo;s subsidiaries,
      officers, directors, employees, agents, consultants, contractors, or suppliers, shall be limited to the total fees
      paid to Naya in connection with services provided to you hereunder in the twelve (12) months prior to the action
      giving rise to liability.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">These limitations shall apply to any liability arising from any cause of action
      whatsoever, whether in contract, tort (including negligence), strict liability, or otherwise, even if Naya is
      advised of the possibility of such costs or damages, and even if the limited remedies provided herein fail of
      their essential purpose. In no event shall Naya be liable to you, and you waive any right to seek from Naya,
      consequential, punitive, exemplary or special damages, or damages for loss of use, interruption of business, or
      loss of data or profits.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="13">
        <li class="c4 c7 li-bullet-0"><span class="c2">Indemnity</span></li>
      </ol>
      <p class="c15 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Client agrees to indemnify and hold Naya and Naya&rsquo;s parents, subsidiaries,
      affiliates, officers, directors, consultants, suppliers, contractors, agents and employees harmless from any loss,
      expense, and damage, including but not limited to reasonable attorneys&rsquo; fees, arising out of or relating in
      any way to any claim or demand made, asserted, or threatened by any other person or entity and that arises out of
      or relates to Client&rsquo;s conduct or failure to act, Client&rsquo;s use of Naya&rsquo;s services,
      Client&rsquo;s use of the Platform, any failure to perform services by a Designer, Consultant or Maker, any
      resulting losses by any such party or breaches of representations and warranties made thereby in separate Provider
      Agreements with Naya, including but not limited to claims or demands relating to Client&rsquo;s breach of this
      Agreement, failure to make payments for any Project or Maker Project in accordance with the terms set forth
      herein, violation or alleged violation of Naya&rsquo;s or others&rsquo; intellectual property rights, violation or
      alleged violation of any other rights of another, and violation or alleged violation of any law or
      regulation.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="14">
        <li class="c4 c7 li-bullet-0"><span class="c2">No Guarantee</span></li>
      </ol>
      <p class="c9 c15"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Naya may, in its sole discretion, remove, cease operating, change the functionality of,
      or otherwise modify its services and the Platform at any time.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Naya does not guarantee continuous, uninterrupted, ongoing access to the Platform or
      any of the Platform&rsquo;s features, and operation of the Platform may be interfered with or eliminated entirely
      by numerous factors outside Naya&rsquo;s control or at Naya&rsquo;s sole discretion.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="15">
        <li class="c4 c7 li-bullet-0"><span class="c2">Legal Compliance</span></li>
      </ol>
      <p class="c15 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Client agrees to comply with all applicable laws when using and in relation to
      Naya&rsquo;s service or the Platform.</span></p><a id="id.2et92p0"></a>
      <p class="c3"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="16">
        <li class="c4 c7 li-bullet-0"><span class="c2">No Agency</span></li>
      </ol>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Client understands and agrees that Client and Naya are independent entities, and that
      no agency, partnership, joint venture, employee-employer, or franchiser-franchisee relationship is intended or
      created by this Agreement, or by the operation of the Naya service or the Platform.</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Client further understands and agrees that Naya is independent from all other Users,
      and that no agency, partnership, joint venture, employee-employer, or franchiser-franchisee relationship is
      intended or created between Naya and any other User by virtue of any other User&rsquo;s Agreements with Naya, or
      by the operation of the Naya service or the Platform.</span></p>
      <p class="c3"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="17">
        <li class="c4 c7 li-bullet-0"><span class="c2">Term and Termination</span><span class="c8">&nbsp;</span></li>
      </ol>
      <p class="c9 c19"><span class="c5 c11"></span></p>
      <p class="c19"><span class="c39 c35">Termination.</span><span class="c35">&nbsp;</span><span class="c8">Naya may
      terminate the Agreement at any time, in its sole discretion, by providing you notice of termination or by ceasing
      to operate the Platform. Furthermore, Naya may terminate any in-progress Project or Maker Project which you have
      requested under this Agreement for any negligent behavior, untimely communications with Users, unprofessional
      behavior, or any other reason in the full discretion of Naya. If terminated, any fees which you have paid to Naya
      will not be returned and all such fees are nonrefundable.</span></p>
      <p class="c19 c9"><span class="c0"></span></p>
      <p class="c19" id="h.tyjcwt"><span class="c35 c39">Surviving Provisions.</span><span class="c0">&nbsp;In the event
      that this Agreement is terminated, the following Sections shall survive any such termination and remain in effect:
      Section 5 (&ldquo;Communications with Users&rdquo;), Section 7 (&ldquo;Payment&rdquo;), Section 8
      (&ldquo;Release&rdquo;), Section 9 (&ldquo;Client Conduct and Content&rdquo;), Section 10 (&ldquo;Intellectual
      Property Ownership&rdquo;), Section 11 (&ldquo;Warranty Disclaimer&rdquo;), Section 12 (&ldquo;Limitation of
      Liability&rdquo;); Section 13 (&ldquo;Indemnity&rdquo;); Section 15 (&ldquo;Legal Compliance&rdquo;), Section 16
      (&ldquo;No Agency&rdquo;), Section 17 (&ldquo;Term and Termination&rdquo;), Section 18 (&ldquo;Exclusivity; No
      Disintermediation&rdquo;), Section 20 (&ldquo;Dispute Resolution&quot;) and Section 21 (&quot;Other
      Information&quot;). </span></p>
      <p class="c3"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="18">
        <li class="c4 c7 li-bullet-0"><span class="c22">Exclusivity; No Disintermediation</span></li>
      </ol>
      <p class="c4 c9"><span class="c0"></span></p>
      <p class="c4"><span class="c35">During the term of this Agreement and for a period of ten (10) years after its
      expiration or termination (the &ldquo;</span><span class="c22">Exclusivity Period</span><span
          class="c35">&rdquo;), Client shall (i) purchase all services relating to, or in any way connected with, any
          Project Requests or Maker Project Requests received via the Platform in accordance with the terms of this
          Agreement and (ii) not directly or indirectly solicit, contact or engage any Provider for purposes of performing
          services similar to those provided hereunder. &nbsp;Any action during the Exclusivity Period by Client that
          encourages, facilitates or solicits complete or partial payment outside of this Agreement or otherwise solicits
          for service engagement or any other similar business relationship with a Client for services similar to those
      provided hereunder shall constitute a material violation of this Agreement (a &ldquo;</span><span
          class="c22">Disintermediation Violation</span><span class="c35">&rdquo;). &nbsp;The Client hereby agrees to pay to
          Naya liquidated damages for any Disintermediation Violation, in an amount equal to 50% of all fees paid to
      Provider in connection therewith.&nbsp; The parties hereto </span><span class="c8 c29">further acknowledge that
        (a) the amount of loss or damages likely to be incurred by Naya for any Disintermediation Violation by Client is
        impossible or difficult to precisely estimate, (b) the amounts specified herein bear a reasonable proportion and
        are not plainly or grossly disproportionate to the probable loss likely to be incurred by Naya, and (c) the
        parties are sophisticated business parties and have been represented by sophisticated legal and financial counsel
        and negotiated this Agreement at arm&#39;s length.&nbsp; Liquidated damages shall be paid by Client within ten
      (10) business days of Client&rsquo;s breach of this Section of the Agreement.</span><span
          class="c0">&nbsp;&nbsp;&nbsp;</span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="19">
        <li class="c4 c7 li-bullet-0"><span class="c22">License to Use Marks</span></li>
      </ol>
      <p class="c4 c9"><span class="c2 c11 c12"></span></p>
      <p class="c4"><span class="c35">Each party hereto </span><span class="c8 c29">grants the other party a limited
      non-exclusive license to use the other party&rsquo;s name, trademarks, service marks, logos and corporate names in
      connection with the performance of each party&rsquo;s obligations under this Agreement, including the right to use
      such party&rsquo;s name over the telephone, the internet, social media and in written materials in connection with
      the performance of services and business relationship described herein. </span></p>
      <p class="c4 c9"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="20">
        <li class="c4 c7 li-bullet-0"><span class="c2">Dispute</span><span class="c8">&nbsp;</span><span
          class="c2">Resolution</span></li>
      </ol>
      <p class="c19 c9"><span class="c0"></span></p>
      <p class="c21"><span class="c0">This Agreement shall be governed by the laws of the Commonwealth of Massachusetts. If
      any dispute relating to the performance of this Agreement arises, Client agrees to negotiate in good faith with
      Naya to resolve such dispute prior to pursuit of any legal process. If the parties are unable to so resolve the
      dispute, such dispute shall be resolved by binding arbitration in accordance with the following terms.</span></p>
      <p class="c21 c9"><span class="c0"></span></p>
      <p class="c21"><span class="c8">We are not responsible for any disputes or disagreements between you and any third
      party you interact with using the Service. You assume all risk associated with dealing with third parties. You
      agree to resolve disputes directly with the other party, and Naya, at its option, may use commercially reasonable
      efforts to assist in resolving any such disputes. You release Naya of all claims, demands, and damages in disputes
      among Users of the Platform.</span></p>
      <p class="c34 c29 c9"><span class="c0"></span></p>
      <p class="c34 c29"><span class="c0">ALL DISPUTES CONCERNING OR ARISING OUT OF THIS AGREEMENT, INCLUDING WHETHER A
      DISPUTE IS SUBJECT TO ARBITRATION, SHALL BE RESOLVED BY BINDING ARBITRATION BEFORE A SINGLE ARBITRATOR AND
      ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION (&ldquo;AAA&rdquo;), USING THE THEN-CURRENT APPLICABLE AAA
      RULES. THE LOCATION OF THE ARBITRATION SHALL BE THE AAA OFFICE IN BOSTON, MASSACHUSETTS, OR ANOTHER LOCATION IN
      BOSTON, MASSACHUSETTS CHOSEN BY THE AAA OR THE ARBITRATOR.</span></p>
      <p class="c34 c29"><span class="c0">THE FEDERAL ARBITRATION ACT, AS IN EFFECT AT THE TIME OF ANY ARBITRATION DEMAND,
      SHALL APPLY TO ANY ARBITRATION PURSUANT TO THIS AGREEMENT.</span></p>
      <p class="c34 c29"><span class="c0">THE PARTIES SHALL EACH BEAR THEIR OWN EXPENSES RELATED TO ANY ARBITRATION. THE
      COSTS OF THE ARBITRATION TRIBUNAL, INCLUDING BUT NOT LIMITED THE ARBITRATOR&rsquo;S AND THE AAA&rsquo;S FEES,
      SHALL BE SHARED EQUALLY BETWEEN THE PARTIES, REGARDLESS OF WHICH PARTY PREVAILS. EACH PARTY TO THE ARBITRATION
      SHALL BEAR ITS OWN LEGAL FEES, REGARDLESS OF WHICH PARTY PREVAILS. THE ARBITRATOR SHALL NOT AWARD ANY MULTIPLE OR
      PUNITIVE DAMAGES, REGARDLESS OF WHICH PARTY PREVAILS. NOTWITHSTANDING ANYTHING TO THE CONTRARY PERMITTED BY THE
      APPLICABLE RULES OF THE AAA, ANY ARBITRATION PURSUANT TO THIS AGREEMENT SHALL INVOLVE MAKER AND NAYA ONLY IN THEIR
      INDIVIDUAL CAPACITIES, SHALL NOT BE CONSOLIDATED WITH ANY OTHER ARBITRATIONS, AND SHALL NOT BE ARBITRATED AS A
      CLASS OR OTHER FORM OF REPRESENTATIVE ACTION. MAKER AGREES NOT TO PARTICIPATE IN ANY ARBITRATION RELATED TO OR
      ARISING FROM THIS AGREEMENT AS A CLAIMANT OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION.</span>
      </p>
      <p class="c34 c29"><span class="c0">ANY ACTION TO CONFIRM AN ARBITRATION AWARD PURSUANT TO THIS AGREEMENT SHALL BE
      BROUGHT ONLY IN A COURT OF COMPETENT JURISDICTION WITHIN SUFFOLK COUNTY, MASSACHUSETTS. NAYA AND MAKER AGREE TO
      SUBMIT TO THE JURISDICTION OF ANY SUCH COURT, WAIVING ANY OBJECTION BASED ON PERSONAL JURISDICTION OR
      VENUE.</span></p>
      <p class="c29 c34"><span class="c0">AT LEAST FORTY-FIVE (45) DAYS PRIOR TO EITHER PARTY INITIATING ANY ARBITRATION
      PURSUANT TO THIS AGREEMENT, THAT PARTY SHALL SEND TO THE OTHER PARTY A WRITTEN DEMAND DESCRIBING THE NATURE OF THE
      DISPUTE AND SETTING FORTH A PROPOSED RESOLUTION. THE PARTIES SHALL THEN ATTEMPT TO NEGOTIATE IN GOOD FAITH TO
      RESOLVE THE DISPUTE.</span></p>
      <p class="c21"><span class="c0">Notwithstanding the foregoing, in the case of a project issue, Naya recommends that
      Clients work directly with Providers to resolve any issues. &nbsp;Naya is under no obligation to provide dispute
      resolution with respect to any such claim and provides this process as a benefit to Clients and Providers. </span>
      </p>
      <p class="c19 c9"><span class="c0"></span></p>
      <ol class="c14 lst-kix_list_2-0" start="21">
        <li class="c4 c7 li-bullet-0"><span class="c2">Other Information</span></li>
      </ol>
      <p class="c13 c9 c41"><span class="c5 c11"></span></p>
      <p class="c25"><span class="c5">Assignment</span><span class="c0">. Neither party may assign this Agreement to any
      third party except upon the other party&rsquo;s prior written consent. Furthermore, Client may not contract with
      any other party for the completion of any services which Client has agreed to purchase through the Platform,
      unless Naya consents to such contract.</span></p>
      <p class="c25 c9"><span class="c5 c11"></span></p>
      <p class="c25"><span class="c5">Entire Agreement</span><span class="c0">. This Agreement, together with Naya&rsquo;s
      Terms of Services and any additional project orders that may be submitted by Client to Naya represents the entire
      understanding and Agreement between Client and Naya with respect to the subject matter hereof. This Agreement
      supersedes all prior oral and written and all contemporaneous oral negotiations, commitments and understandings
      between Client and Naya. However, this Agreement can be amended or modified by mutual Agreement signed by Client
      and Naya. </span></p>
      <p class="c25 c9"><span class="c0"></span></p>
      <p class="c25"><span class="c5">Notices</span><span class="c0">. All notices under this Agreement shall be in writing
      and delivered via mail or email services to the address set forth above. </span></p>
      <p class="c25 c9"><span class="c0"></span></p>
      <p class="c15 c38"><span class="c46">B</span><span class="c0">y signing this Agreement, Client confirms that Client
      has carefully read the terms and conditions of this Agreement.</span></p>
      <p class="c3"><span class="c0"></span></p><a id="t.44e0be3daa20a6b274524847a639aaf73a4e0edd"></a><a id="t.0"></a>
      <table class="c27">
        <tbody>
          <tr class="c28">
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c2 c11 c12"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c2 c11 c12"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c2 c11 c12"></span></p>
            </td>
          </tr>
          <tr class="c28">
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c2 c11 c12"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c2 c11 c12"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c2 c11 c12"></span></p>
            </td>
          </tr>
          <tr class="c28">
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c0"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c0"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c0"></span></p>
            </td>
          </tr>
          <tr class="c24">
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c0"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c0 c26"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c0"></span></p>
            </td>
          </tr>
          <tr class="c24">
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c0 c26"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c2 c11 c26 c12"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c0"></span></p>
            </td>
          </tr>
          <tr class="c24">
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c0 c26"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c2 c11 c26 c12"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c0"></span></p>
            </td>
          </tr>
          <tr class="c6">
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c0 c26"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c0"></span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c1"><span class="c0"></span></p>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="c9 c37"><span class="c2 c11 c12"></span></p>
      <div>
        <p class="c42 c9"><span class="c11 c12 c45"></span></p>
        <p class="c9 c42"><span class="c46 c11 c12 c47"></span></p>
      </div>
    </div>
  )
}

export default ClientAgreement