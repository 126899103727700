import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import {
    loginLocal,
    loginToken,
    signUpLocal,
    signUpInGoogle,
    signUpInFacebook,
    addCanvasToUser,
} from "../../actions/authActions";
import "../../stylesheets/style.css";
import "../../stylesheets/pre-canvas.css";
import "../../stylesheets/auth.css";

import BounceLoader from "react-spinners/BounceLoader";
import BarLoader from "react-spinners/BarLoader";
import axios from "axios";
import queryString from "query-string";
import { API_ENDPOINT, ADMIN_PANEL_FRONTEND, ECOSYSTEM_APP_ENDPOINT, ECOSYSTEM_SIGNUP_ENDPOINT } from "../../constants/secrets";

import { ReactComponent as GoogleIcon } from "../../images/g-logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/f-logo.svg";
/**
 * Components
 */
import ProgressBar from "../utilities/ProgressBar";
import BackButton from "../utilities/BackButton";
import Progress from "../utilities/Progress";
import CancelButton from "../utilities/CancelButton";

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

class Auth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            confirmPassword: "",
            agreeToTM: false,
            agreeToLoginTM: false,
            showSignupForm: false,
            showPasswordResetRequestForm: false,
            errorMessage: this.props.authError,
        };

        if (props.external === "admin-panel") {
            console.log("External login");
            localStorage.setItem("nayaExternalLogin", "admin-panel");
        } else if (props.external === "ecosystem") {
            localStorage.setItem("nayaExternalLogin", "ecosystem");
        }
    }

    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    showSignUp = (e) => {
        e.preventDefault();
        this.props.clearErrorMessage();
        this.setState({
            showSignupForm: true,
            showPasswordResetRequestForm: false,
        });
    };

    showForgotPassword = (e) => {
        e.preventDefault();
        this.props.clearErrorMessage();
        this.setState({ showPasswordResetRequestForm: true });
    };

    showLogin = (e) => {
        e.preventDefault();
        this.props.clearErrorMessage();
        this.setState({
            showSignupForm: false,
            showPasswordResetRequestForm: false,
        });
    };

    handleChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value, signUpError: null });
    };
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    handleLoginLocal = (e) => {
        e.preventDefault();
        this.setState({ errorMessage: null })
        if (
            this.props.external === 'ecosystem'
            &&
            !this.state.agreeToLoginTM
        )
            return this.setState({ errorMessage: 'You need to agree to terms and conditions.' })

        this.setState({ loading: true });
        this.props.loginLocal(this.state);
    };

    handleInputChange = (event) => {
        const { name } = event.target;
        const value =
            name === "agreeToTM" ? event.target.checked : event.target.value;

        this.setState({
            [name]: value,
            showFunctionError: false,
            showPasswordError: false,
            showRegistrationError: false,
        });
    };

    handleForgotPassword = async (e) => {
        e.preventDefault();
        const FIND_USER_URI = `${API_ENDPOINT}/api/user`;
        try {
            let user = await axios.get(FIND_USER_URI, {
                params: { email: this.state.email },
            });
            user = user.data.user;
            if (user) {
                const SEND_RESET_PASSWORD_EMAIL_URI = `${API_ENDPOINT}/auth/send-reset-email`;
                let email_sent = await axios.post(
                    SEND_RESET_PASSWORD_EMAIL_URI,
                    { user_id: user._id, email: user.email }
                );
                email_sent = email_sent.data.status;
                if (email_sent) {
                    this.setState({
                        forgotPasswordMessage:
                            "The link to reset your password has been sent to your email",
                    });
                } else {
                    this.setState({
                        forgotPasswordMessage:
                            "Error sending email. Please check your internet connection",
                    });
                }
            } else {
                this.setState({
                    forgotPasswordMessage:
                        "We did not find a user with this email id",
                });
            }
        } catch (error) {
            this.setState({
                forgotPasswordMessage:
                    "We did not find a user with this email id",
            });
        }
    };

    handleSignupLocal = (e) => {
        e.preventDefault();
        if (this.state.agreeToTM) {
            if (this.state.password === this.state.confirmPassword) {
                const data = {
                    email: this.state.email,
                    password: this.state.password,
                };
                this.props.signUpLocal(data);
            } else {
                this.setState({ signUpError: "Passwords do not match" });
            }
        } else {
            this.setState({
                signUpError: "Please agree to the terms and conditions",
            });
        }
    };

    handleSignupGoogle = (e) => {
        e.preventDefault();
        // to make sure the saved state is restored when return from oauth
        localStorage.setItem("nayaAppStateRestore", "true");
        this.props.signUpInGoogle();
    };

    handleSignupFacebook = (e) => {
        e.preventDefault();
        // to make sure the saved state is restored when return from oauth
        localStorage.setItem("nayaAppStateRestore", "true");
        this.props.signUpInFacebook();
    };

    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    renderPasswordResetForm = () => {
        return (
            <Container fluid id='auth'>
                {this.props.nextPage !== "account" && (
                    <>
                        <ProgressBar
                            width={(this.props.step * 100) / this.props.stepMax}
                        ></ProgressBar>
                        <Row>
                            <BackButton></BackButton>
                            <Progress
                                step={this.props.step}
                                step_max={this.props.stepMax}
                            ></Progress>
                            {/* {window.innerHeight >=
                                document.body.clientHeight && ( */}
                            <CancelButton></CancelButton>
                            {/* )} */}
                        </Row>
                    </>
                )}
                <Row>
                    <Col
                        xs={{ span: 10, offset: 1 }}
                        lg={{ span: 4, offset: 4 }}
                        className='auth-wrapper'
                    >
                        {this.props.authLoading && (
                            <BarLoader
                                height={6}
                                css={{ width: "100%", borderRadius: "10px" }}
                            ></BarLoader>
                        )}

                        <Col
                            xs={12}
                            style={{ paddingTop: "2rem", marginBottom: "4rem" }}
                        >
                            <h2
                                className='col-12'
                                style={{
                                    marginBottom: "0rem",
                                    color: "#888",
                                    fontSize: "2rem",
                                }}
                            >
                                naya
                            </h2>
                            <h3
                                className='col-12'
                                style={{
                                    marginBottom: "0rem",
                                    textAlign: "center",
                                    color: "black",
                                    fontWeight: "200",
                                    fontSize: "2.2rem",
                                }}
                            >
                                Forgot Password
                            </h3>
                        </Col>
                        <Col
                            xs={12}
                            lg={{ span: 10, offset: 1 }}
                            style={{ fontSize: "1.2rem" }}
                        >
                            <Form>
                                {this.state.forgotPasswordMessage &&
                                    this.state.forgotPasswordMessage !== "" && (
                                        <Alert variant={"warning"}>
                                            {this.state.forgotPasswordMessage}
                                        </Alert>
                                    )}
                                <Form.Group controlId='formBasicEmail'>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type='email'
                                        placeholder='Enter email'
                                        name='email'
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                                {/* <Form.Group controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="I agree to the terms and condtions" />
                                            </Form.Group> */}
                                <Col xs={12}>
                                    <Row>
                                        <Button
                                            variant='dark'
                                            onClick={this.handleForgotPassword}
                                            id='login-btn'
                                        >
                                            Send Email
                                        </Button>
                                    </Row>
                                    <Row
                                        className='justify-content-md-center'
                                        style={{
                                            marginBottom: "8rem",
                                            marginTop: "2rem",
                                        }}
                                    >
                                        <p
                                            className='skip-button'
                                            onClick={this.showLogin}
                                            style={{ fontSize: "1.2rem" }}
                                        >
                                            Go back to Login
                                        </p>
                                        <p
                                            className='skip-button'
                                            onClick={this.showSignUp}
                                            style={{ fontSize: "1.2rem" }}
                                        >
                                            Don't have an account? Sign Up
                                        </p>
                                    </Row>
                                </Col>
                            </Form>
                        </Col>
                    </Col>
                </Row>
            </Container>
        );
    };

    renderLoginForm = () => {
        return (
            <Container fluid id='auth'>
                {/* {
                    (this.props.nextPage!=='account') && (
                        <>
                            <ProgressBar
                                width={(this.props.step * 100) / this.props.stepMax}
                            ></ProgressBar>
                            <Row>
                                <BackButton></BackButton>
                                <Progress
                                    step={this.props.step}
                                    step_max={this.props.stepMax}
                                ></Progress>
                                {window.innerHeight >= document.body.clientHeight && (
                                    <CancelButton></CancelButton>
                                )}
                            </Row>
                        </>
                    )
                } */}
                <Row>
                    <Col
                        xs={{ span: 10, offset: 1 }}
                        lg={{ span: 4, offset: 4 }}
                        className='auth-wrapper'
                    >
                        {this.props.authLoading && (
                            <BarLoader
                                height={6}
                                css={{ width: "100%", borderRadius: "10px" }}
                            ></BarLoader>
                        )}

                        <Col
                            xs={12}
                            style={{ paddingTop: "2rem", marginBottom: "4rem" }}
                        >
                            <h2
                                className='col-12'
                                style={{
                                    marginBottom: "0rem",
                                    color: "#888",
                                    fontSize: "2rem",
                                }}
                            >
                                naya
                            </h2>
                            <h3
                                className='col-12'
                                style={{
                                    marginBottom: "0rem",
                                    textAlign: "center",
                                    color: "black",
                                    fontWeight: "200",
                                    fontSize: "2.2rem",
                                }}
                            >
                                {this.props.nextPage !== "account"
                                    ? "Save your progress to your account"
                                    : "Welcome"}
                            </h3>
                        </Col>

                        <Col
                            xs={12}
                            lg={{ span: 10, offset: 1 }}
                            style={{ fontSize: "1.2rem" }}
                        >
                            {
                                this.state.errorMessage ?
                                    <Alert variant={"danger"}>
                                        {this.state.errorMessage}
                                    </Alert>
                                    : this.props.loginError !== undefined &&
                                    this.props.loginError !== "jwt malformed" &&
                                    this.props.loginError !== "jwt expired" && (
                                        <Alert variant={"danger"}>
                                            {this.props.loginError}
                                        </Alert>
                                    )
                            }
                            <Form>
                                <Form.Group controlId='formBasicEmail'>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type='email'
                                        placeholder='Enter email'
                                        name='email'
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        disabled={this.props.authLoading}
                                    />
                                </Form.Group>

                                <Form.Group controlId='formBasicPassword'>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type='password'
                                        placeholder='Password'
                                        name='password'
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        disabled={this.props.authLoading}
                                    />
                                </Form.Group>
                                {
                                    this.props.external === 'ecosystem' &&
                                    <Form.Check>
                                        <Form.Check.Input
                                            type="checkbox"
                                            name="agreeToTM"
                                            checked={this.state.agreeToLoginTM}
                                            onChange={() => this.setState({ agreeToLoginTM: !this.state.agreeToLoginTM })}
                                        />
                                        <Form.Check.Label style={{ "color": "black" }}>I agree to the <a target="_blank" rel="noopener noreferrer" href="https://app.naya.studio/tos/designer">designer</a> and <a target="_blank" rel="noopener noreferrer" href="https://app.naya.studio/tos/maker">maker</a> terms and conditions while I use the naya platform</Form.Check.Label>
                                    </Form.Check>
                                }
                                <Col xs={12} style={{ padding: "0rem" }}>
                                    <div>
                                        <Button
                                            variant='dark'
                                            className='col-12 col-lg-10 order-1 order-lg-2'
                                            onClick={this.handleLoginLocal}
                                            id='login-btn'
                                        >
                                            Login
                                        </Button>
                                        <Row
                                            className='justify-content-md-center'
                                            style={{
                                                marginTop: "2rem",
                                            }}
                                        >
                                            <p
                                                className='skip-button'
                                                onClick={(e) => {
                                                    if (this.props.external === 'ecosystem')
                                                        return window.location.href = ECOSYSTEM_SIGNUP_ENDPOINT;
                                                    this.showSignUp(e)
                                                }}
                                                style={{ fontSize: "1.2rem" }}
                                            >
                                                Don't have an account? Sign up
                                            </p>
                                        </Row>
                                        <Row
                                            className='justify-content-md-center'
                                            style={{ marginTop: "1rem" }}
                                        >
                                            <Button
                                                className='col-6 order-2 order-lg-1'
                                                onClick={
                                                    this.showForgotPassword
                                                }
                                                id='forgot-password-btn'
                                            >
                                                Forgot Password?
                                            </Button>
                                        </Row>

                                        <p
                                            style={{
                                                borderBottom: "1px solid black",
                                                textAlign: "center",
                                                lineHeight: "0.1em",
                                                marginTop: "1rem",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    backgroundColor: "white",
                                                    padding: "0 1rem",
                                                }}
                                            >
                                                or
                                            </span>
                                        </p>
                                        <Button
                                            onClick={this.handleSignupGoogle}
                                            className='col-12 col-lg-10 order-1 order-lg-2'
                                            style={{
                                                color: "black",
                                                textTransform: "none",
                                                fontSize: "1.1rem",
                                            }}
                                            id='google-btn'
                                        >
                                            <GoogleIcon
                                                style={{
                                                    height: "18px",
                                                    width: "18px",
                                                    marginRight: "15px",
                                                }}
                                            />
                                            Sign in with Google
                                        </Button>
                                        <Button
                                            onClick={this.handleSignupFacebook}
                                            className='col-12 col-lg-10 order-1 order-lg-2'
                                            style={{
                                                textTransform: "none",
                                                fontSize: "1.1rem",
                                                marginBottom: 30,
                                            }}
                                            id='facebook-btn'
                                        >
                                            <FacebookIcon
                                                style={{
                                                    height: "18px",
                                                    width: "18px",
                                                    marginRight: "15px",
                                                }}
                                            />
                                            Sign in with Facebook
                                        </Button>
                                        {this.props.nextPage !== "account" && (
                                            <Row className='justify-content-md-center'>
                                                <p
                                                    className='skip-button'
                                                    style={{
                                                        fontSize: "1.2rem",
                                                    }}
                                                    onClick={() =>
                                                        this.props.history.push(
                                                            this.props.nextPage
                                                        )
                                                    }
                                                >
                                                    Continue without account
                                                </p>
                                            </Row>
                                        )}
                                    </div>
                                </Col>
                            </Form>
                        </Col>
                    </Col>
                </Row>
            </Container>
        );
    };

    renderSignupForm = () => {
        return (
            <Container fluid id='auth'>
                {this.props.nextPage !== "account" && (
                    <>
                        <ProgressBar
                            width={(this.props.step * 100) / this.props.stepMax}
                        ></ProgressBar>
                        <Row>
                            <BackButton></BackButton>
                            <Progress
                                step={this.props.step}
                                step_max={this.props.stepMax}
                            ></Progress>
                            {/* {window.innerHeight >=
                                document.body.clientHeight && ( */}
                            <CancelButton></CancelButton>
                            {/* // )} */}
                        </Row>
                    </>
                )}
                <Row>
                    <Col
                        xs={{ span: 10, offset: 1 }}
                        lg={{ span: 4, offset: 4 }}
                        className='auth-wrapper'
                    >
                        {this.props.authLoading && (
                            <BarLoader
                                height={6}
                                css={{ width: "100%", borderRadius: "10px" }}
                            ></BarLoader>
                        )}

                        <Col
                            xs={12}
                            style={{ paddingTop: "2rem", marginBottom: "4rem" }}
                        >
                            <h2
                                className='col-12'
                                style={{
                                    marginBottom: "0rem",
                                    color: "#888",
                                    fontSize: "2rem",
                                }}
                            >
                                naya
                            </h2>
                            <h3
                                className='col-12'
                                style={{
                                    marginBottom: "0rem",
                                    textAlign: "center",
                                    color: "black",
                                    fontWeight: "200",
                                    fontSize: "2.2rem",
                                }}
                            >
                                Sign Up
                            </h3>
                        </Col>

                        <Col
                            xs={12}
                            lg={{ span: 10, offset: 1 }}
                            style={{ fontSize: "1.2rem" }}
                        >
                            {this.props.signUpError !== undefined && (
                                <Alert variant={"danger"}>
                                    {this.props.signUpError}
                                </Alert>
                            )}
                            {this.state.signUpError !== undefined && (
                                <Alert variant={"danger"}>
                                    {this.state.signUpError}
                                </Alert>
                            )}
                            <Form>
                                <Form.Group controlId='formBasicEmail'>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type='email'
                                        placeholder='Enter email'
                                        name='email'
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId='formBasicPassword'>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type='password'
                                        placeholder='Password'
                                        name='password'
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId='formBasicPassword'>
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type='password'
                                        placeholder='Confirm Password'
                                        name='confirmPassword'
                                        value={this.state.confirmPassword}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId='formBasicCheckbox'>
                                    <Form.Check>
                                        <Form.Check.Input
                                            type='checkbox'
                                            name='agreeToTM'
                                            checked={this.state.agreeToTM}
                                            onChange={this.handleInputChange}
                                            isValid
                                            required
                                        />
                                        <Form.Check.Label
                                            style={{ color: "black" }}
                                        >
                                            I agree to the{" "}
                                            <a
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                href='https://docs.google.com/document/d/e/2PACX-1vTov12Yo4MCqPhMYsXgHgngMOTRAyeqNHCfwnEfMVCvZywTrMROtX9wFF9kBaS7XTPR0x6wSQ8Dj-vV/pub'
                                            >
                                                terms and conditions
                                            </a>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>

                                <Col xs={12} style={{ padding: "0rem" }}>
                                    <div>
                                        <Button
                                            variant='dark'
                                            onClick={this.handleSignupLocal}
                                            className='col-12 col-lg-10 order-1 order-lg-2'
                                            id='login-btn'
                                        >
                                            Sign Up
                                        </Button>
                                        <p
                                            style={{
                                                borderBottom: "1px solid black",
                                                textAlign: "center",
                                                lineHeight: "0.1em",
                                                marginTop: "2rem",
                                                marginBottom: "2rem",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    backgroundColor: "white",
                                                    padding: "0 1rem",
                                                }}
                                            >
                                                or
                                            </span>
                                        </p>
                                        <Button
                                            onClick={this.handleSignupGoogle}
                                            className='col-12 col-lg-10 order-1 order-lg-2'
                                            style={{
                                                color: "black",
                                                textTransform: "none",
                                                fontSize: "1.1rem",
                                            }}
                                            id='google-btn'
                                        >
                                            <GoogleIcon
                                                style={{
                                                    height: "18px",
                                                    width: "18px",
                                                    marginRight: "15px",
                                                }}
                                            />
                                            Sign up with Google
                                        </Button>
                                        <Button
                                            onClick={this.handleSignupFacebook}
                                            className='col-12 col-lg-10 order-1 order-lg-2'
                                            style={{
                                                textTransform: "none",
                                                fontSize: "1.1rem",
                                            }}
                                            id='facebook-btn'
                                        >
                                            <FacebookIcon
                                                style={{
                                                    height: "18px",
                                                    width: "18px",
                                                    marginRight: "15px",
                                                }}
                                            />
                                            Sign up with Facebook
                                        </Button>
                                    </div>
                                    <Row
                                        className='justify-content-md-center'
                                        style={{
                                            marginTop: "2rem",
                                        }}
                                    >
                                        <p
                                            className='skip-button'
                                            style={{ fontSize: "1.2rem" }}
                                            onClick={this.showLogin}
                                        >
                                            Already have an account? Login here
                                        </p>
                                    </Row>
                                    {this.props.nextPage !== "account" && (
                                        <Row className='justify-content-md-center'>
                                            <p
                                                className='skip-button'
                                                style={{ fontSize: "1.2rem" }}
                                                onClick={() =>
                                                    this.props.history.push(
                                                        this.props.nextPage
                                                    )
                                                }
                                            >
                                                Continue without account
                                            </p>
                                        </Row>
                                    )}
                                </Col>
                            </Form>
                        </Col>
                    </Col>
                </Row>
            </Container>
        );
    };

    render() {
        let renderedForm = (
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className='pre-canvas-h2'>
                            <BounceLoader></BounceLoader>
                        </div>
                    </Col>
                </Row>
            </Container>
        );

        if (this.props.user || this.props.user.id) {
            if (!this.state.showSignupForm) {
                if (this.state.showPasswordResetRequestForm) {
                    renderedForm = this.renderPasswordResetForm();
                } else {
                    renderedForm = this.renderLoginForm();
                }
            } else {
                renderedForm = this.renderSignupForm();
            }
        }

        return renderedForm;
    }

    checkForUser = () => {
        if (this.props.user && this.props.user.id) {
            const externalStatus = localStorage.getItem("nayaExternalLogin");
            if (externalStatus === "admin-panel") {
                console.log("redirect to admin panel");
                localStorage.setItem("nayaExternalLogin", null);
                const token = localStorage.getItem("nayaJwtToken");
                window.location.replace(`${ADMIN_PANEL_FRONTEND}/jwt/${token}`);
            }
            else if (externalStatus === "ecosystem") {
                localStorage.setItem("nayaExternalLogin", null);
                const token = localStorage.getItem("nayaJwtToken");
                window.location.replace(`${ECOSYSTEM_APP_ENDPOINT}/jwt/${token}`);
            } else if (this.props.nextPage === "canvas") {
                const data = {
                    user_id: this.props.user.id,
                    canvas_id: this.props.canvas_id,
                };
                this.props.addCanvasToUser(data);
                this.props.history.push(this.props.nextPage);
            } else {
                this.props.history.push(this.props.nextPage);
            }
            // to ensure state isn't retreived from local storage if already logged in
            localStorage.setItem("nayaAppStateRestore", "false");
            this.props.history.push(this.props.nextPage);
        } else {
            // passport-google redirect
            const values = queryString.parse(this.props.location.search);
            if (values.token) {
                localStorage.setItem("nayaJwtToken", values.token);
                this.props.loginToken();
            }
        }
    };

    componentDidUpdate() {
        this.checkForUser();
    }

    /**
     * Called immediately after render
     */
    componentDidMount() {
        this.checkForUser();
    }
}

const mapStateToProps = (state) => {
    const nextPage = state.UI.authCallback || "account";

    // save state to retrieve it after login is complete
    const storeState = {
        ...state,
        oauthPending: true,
    };
    const serializedState = JSON.stringify(storeState);
    localStorage.setItem("nayaAppState", serializedState);

    return {
        canvas_id: state.canvas.id,
        nextPage: nextPage,
        user: state.user,
        authLoading: state.UI.authLoading,
        loginError: state.errors.loginError,
        signupError: state.errors.signUpError,
        step:
            nextPage === "canvas"
                ? Object.keys(state.UI.flow).length
                : Object.keys(state.UI.flow).indexOf(nextPage),
        stepMax: Object.keys(state.UI.flow).length,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signUpInGoogle: () => signUpInGoogle(),
        signUpInFacebook: () => signUpInFacebook(),
        signUpLocal: (data) => dispatch(signUpLocal(data)),
        loginLocal: (data) => dispatch(loginLocal(data)),
        loginToken: () => dispatch(loginToken()),
        addCanvasToUser: (data) => dispatch(addCanvasToUser(data)),
        clearErrorMessage: () =>
            dispatch({ type: "LOGIN_ERROR", data: undefined }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth));
