import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import data from './checkoutData.json';
import Navbar from '../utilities/NavBar';
import Container from 'react-bootstrap/Container'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import selected from '../../images/icons/selected.svg'

import sketch from '../../images/naya_services_icons/00_sketch.png'
import model from '../../images/naya_services_icons/01_model.png'
import render from '../../images/naya_services_icons/02_render.png'
import drawings from '../../images/naya_services_icons/03_drawings.png'
import estimation from '../../images/naya_services_icons/04_estimation.png'
import sustainability from '../../images/naya_services_icons/05_sustainability.png'
import structural from '../../images/naya_services_icons/06_structural.png'
import value from '../../images/naya_services_icons/07_value.png'
import revision from '../../images/naya_services_icons/08_revisions.png'
import fabrication from '../../images/naya_services_icons/09_fabrication.png'
import quality from '../../images/naya_services_icons/10_quality.png'
import packaging from '../../images/naya_services_icons/11_shipping.png'
import white_glove from '../../images/naya_services_icons/12_white_glove.png'

// import updateCanvas from '../../actions/canvasActions'
import axios from 'axios'
import { API_ENDPOINT } from  '../../constants/secrets';
const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


class Submit extends React.Component{
  state = {
    current:'' //will hold the value of which card is being expanded
  }

//   componentDidUpdate(){
//     console.log("checkout:", window.screen.width)

//     //Update canvas to submitted
//     console.log(this.props.canvas)
//     this.handleSubmit({"id": this.props.canvas._id, "submitted": true})
//     //Send email
// }

componentDidMount(){
    console.log("checkout:", window.screen.width)

    //Update canvas to submitted
    // console.log(this.props.canvas)
    // this.handleSubmit({"id": this.props.canvas._id, "submitted": true})
    //Send email
}



  isMobile = () => {
    if(window.orientation > -1){
        return true 
    }
    else{
        return false
    }
  }



  handleSubmit = (data) => {
      console.log(data)
    const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas`

    axios.put(UPDATE_CANVAS_DATA_URI, data, config)
        .then(response => {
            console.log(response)
            this.props.sendUpdateCanvasDispatch()
        }).catch(e => {
            console.log("Error")
        })
  }

  render(){
    let design_services = [sketch, model, render, drawings, estimation]
    let design_services_text = ["Sketch", "Model", "Render", "Drawings", "Estimation"]

    let addl_design_services = [sustainability, structural, value, revision]
    let addl_design_services_text = ["Sustainability", "Structural", "Value Engineering", "Revisions"]

    let fabrication_services = [fabrication, quality, packaging, white_glove]
    let fabrication_services_text = ["Fabrication", "Quality Control", "Packaging", "White Glove"]

    return(
      <>
      <Navbar/>
      <Container style={{"paddingBottom":"4rem"}}>
          <Row>
            <Col xs={12} lg={{span:2, offset:5}}>
                <img src={selected}></img>
            </Col>
            <Col xs={12} lg={{span:8, offset:2}}>
                <h2 style={{marginTop:'20px', marginBottom:'20px'}}>Thank you! We will get back to you shortly.</h2>
                {/* <div style={{"textAlign":"center"}}>
                    <h4>Your project has been submitted.</h4>
                    <h4> We will get back to you shortly</h4>
                </div> */}
            </Col>
            
            <Col xs={12} lg={12}>
                <Row className={"justify-content-center"}>
                <Col xs={12} style={{"textAlign":"center", "marginTop":"2rem"}}>
                    <h3>Design Services</h3>
                </Col>
                {design_services.map((src, i) => <Col xs={6} lg={{span:2}}>
                    <img src={src} style={{width:"100%"}}></img>
                    <p style={{"textAlign":"center"}}>{design_services_text[i]}</p>
                </Col>)}
                </Row>
                <Row className={"justify-content-center"}>
                <Col xs={12} style={{"textAlign":"center", "marginTop":"2rem"}}>
                    <h3>Additional Design Services</h3>
                </Col>
                {addl_design_services.map((src, i) => <Col xs={6} lg={{span:2}}>
                    <img src={src} style={{width:"100%"}}></img>
                    <p style={{"textAlign":"center"}}>{addl_design_services_text[i]}</p>

                </Col>)}
                </Row>
                <Row className={"justify-content-center"}>
                <Col xs={12} style={{"textAlign":"center", "marginTop":"2rem"}}>
                    <h3>Fabrication Services</h3>
                </Col>
                {fabrication_services.map((src, i) => <Col xs={6} lg={{span:2}}>
                    <img src={src} style={{width:"100%"}}></img>
                    <p style={{"textAlign":"center"}}>{fabrication_services_text[i]}</p>

                </Col>)}
                </Row>
            </Col>
            

        </Row>
      </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        canvas: state.canvas
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendUpdateCanvasDispatch: (data) => dispatch({ type: 'ADD_PATHWAY', data: data})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Submit));

// export default Submit;