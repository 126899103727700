import TruePersonalization from '../../images/splash/signature_.svg';
import Access from '../../images/splash/access_.svg';
import NoCompromise from '../../images/splash/compromise_.svg';
import Communicate from '../../images/splash/1.svg';
import Elaborate from '../../images/splash/2.svg';
import Make from '../../images/splash/make.png';

import go_local from '../../images/splash/maker_bg_laptop.jpg';

let PAGE_CONFIG = 
{
    data: [
        {
            type: "HERO",
            title: "Interior Designers",
            subtitle: "We help you deliver your design",
            cta: "Get Started",
            redirect_path: "/",
            css_slug:"and"
        },
        {
            type:"TEXT_ICON",
            title: "Why Naya",
            css_slug: "why-naya",
            fluid: true,
            elements_data: [
                {
                    title: "True personalization",
                    image_src: TruePersonalization,
                    description: "We know clients have unique needs and want to be creative. We provide the opportunity to create truly unique pieces."
                },
                {
                    title: "Access",
                    image_src: Access,
                    description: "We also use technology and our network to ensure the best value for our customers making custom accessible."
                },
                {
                    title: "No compromises",
                    image_src: NoCompromise,
                    description: "We ensure quality by vetting our designers and fabricators and using technology. We also ensure products are delivered on time."
                }
            ]
        },
        {
            type: "TESTIMONIALS",
            elements_data: [
                {text: "I've been looking for a company that simplifies the process of getting unique furniture made."},
                {text: "I never get a chance to work with unique makers that I love."},
                {text: "Naya has pricing that you can trust."}
            ]
        },
        {
            type: "FULL_WIDTH_IMAGE",
            title: "Go Local",
            css_slug: "go-local",
            description: "We have a large network of local makers and fabricators who are eager to work on unique projects. Naya allows you to connect with new makers and support the local industry",
            img_src:go_local
        },
        {
            type:"TEXT_ICON",
            title: "How it works",
            css_slug: "hiw",
            fluid: true,

            elements_data: [
                {
                    title: "Submit Info",
                    image_src: Communicate,
                    description: "Tell us about your idea and the help you need. Are you looking for a quick estimate? Some value engineering? Some structural analysis?"
                },
                {
                    title: "Finalize and Estimate",
                    image_src: Elaborate,
                    description: "We support you to get a final estimate for your project and find the right fabricator. You can then decide if you would like to fabricate."
                },
                {
                    title: "Get it made",
                    image_src: Make,
                    description: "If you choose to get it built, verify quality and time of delivery with one of our trsuted partners. You can track progress at any point."
                }
            ]
        },
        {
            type: "FULL_WIDTH_IMAGE_WITH_BTN",
            title: "Design Meaningful Spaces",
            cta: "Request a Free Estimate",
            redirect_path: "/",
            css_slug:"and"
        }
    ]
}

export default PAGE_CONFIG