import { API_ENDPOINT } from  '../constants/secrets';
import axios from "axios";
import {getCanvas} from './canvasActions';
import {postComment} from './commentActions';

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
};

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export const declineComment = (data) => {
    return (dispatch) => {
        //Create Answer
        const DELETE_COMMENT = `${API_ENDPOINT}/api/approval-comments`;

        axios
            .delete(DELETE_COMMENT, {params:data}, config)
            .then((response) => {
                console.log("Comment added:", response.data)
                if (response.data.status) {
                    console.log("Comment approval declined!")
                    dispatch(getCanvas());
                } 
            })
            .catch((e) => {
                dispatch({
                    type: "ERROR_POST_COMMENT",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const approveComment = (data) => {
    return (dispatch) => {
        //Create Answer
        const APPROVE_COMMENT = `${API_ENDPOINT}/api/approval-comments`;

        axios
            .put(APPROVE_COMMENT, data, config)
            .then((response) => {
                if (response.data.status) {
                    console.log("Comment approval accepted!", response.data.comment)
                    let commentData = response.data.comment;
                    commentData["canvas_id"]=data.canvas_id;
                    dispatch(postComment(commentData));
                    dispatch(declineComment({id:data.id}))
                    // dispatch(getCanvas());
                } 
            })
            .catch((e) => {
                dispatch({
                    type: "ERROR_POST_COMMENT",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

