import React from 'react';
import data from './checkoutData.json';
import Navbar from '../utilities/NavBar';

class Checkout extends React.Component{
  state = {
    current:'' //will hold the value of which card is being expanded
  }

  componentDidMount(){
    console.log("checkout:", window.screen.width)
  }

  isMobile = () => {
    if(window.orientation > -1){
        return true 
    }
    else{
        return false
    }
  }

  handleSubmit = (name) => {
    if(name==="Basic"){
      window.location.href='/pay-design-fee/basic';
    }
    else if(name==="Independent Designer"){
      window.location.href='/pay-design-fee/independent';
    }
    else{
      window.location.href='/pay-design-fee/commercial';
    }
  }

  cardOutline = (type) => {
    return(
      <div 
        style={{width:'300px', minHeight:'300px',padding:'20px', marginLeft:40, marginRight:40, marginTop:20, marginBottom:20, transition:'height 200ms', boxShadow:" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} 
      >
        <div style={{height:window.screen.width<330 ? '400px' : '370px', display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
          <div>
            <h4 style={{textAlign:'center'}}>{type.name}</h4>
            <h3 style={{textAlign:'center',fontFamily: 'sofia-pro-soft, sans-serif'}}>${type.design_fee}</h3>
          </div>
          <p style={{textAlign: 'center',fontWeight: 'lighter',color: '#6b7c8b',marginTop: '20px'}}>{type.description}</p>
          <div style={{alignSelf:'center'}}>
            <button 
              onClick={()=>this.handleSubmit(type.name)}
              style={{
                alignSelf: 'center',
                paddingTop: '0.8rem',
                paddingBottom: '0.8rem',
                paddingLeft: '5rem',
                paddingRight: '5rem',
                backgroundColor: '#000000',
                border: 'none',
                color: '#FFFFFF',
                borderRadius:'0.25rem',
              }}
            >SELECT PLAN</button>
            <p 
              onClick={()=>{this.setState({current:this.state.current===type.name ? '' : type.name})}}
              style={{textAlign: 'center',color: '#a1adb7',fontWeight: 'lighter',fontSize: '13px', cursor:'pointer',marginTop: '10px'}}>
              {
                this.state.current===type.name ? 'VIEW LESS DETAILS' : 'VIEW MORE DETAILS'
              }
            </p>
          </div>
        </div>
        {
          this.state.current===type.name && (
            <div style={{transition:'height 200ms', fontSize:'13px', fontWeight:'lighter'}}>
              <p style={{marginBottom:'3px'}}>MATERIALS:</p>
              {
                type.materials.map((material, index)=>(
                  <li key={index} style={{marginBottom:'5px', paddingLeft:'15px', color: '#6b7c8b'}}>{material}</li>
                ))
              }
              <hr/>
              <p style={{marginBottom:'3px'}}>FEATURES:</p>
              {
                type.features.map((feature, index)=>(
                  <div key={index} style={{marginBottom:'5px', paddingLeft:'15px', color: '#6b7c8b', display:'flex', alignItems:'baseline'}}>
                    	<span style={{fontSize:'large'}}>&#8226;</span>&nbsp;&nbsp;&nbsp;<div dangerouslySetInnerHTML={{__html:feature}}></div>
                  </div>
                ))
              }
              <hr/>
              <p style={{marginBottom:'3px'}}>ADDITIONS:</p>
              {
                type.additions.map((addition, index)=>(
                  <div key={index} style={{marginBottom:'5px', paddingLeft:'15px', color: '#6b7c8b', display:'flex', alignItems:'baseline'}}>
                    	<span style={{fontSize:'large'}}>&#8226;</span>&nbsp;&nbsp;&nbsp;<div dangerouslySetInnerHTML={{__html:addition}}></div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    )
  }

  render(){
    return(
      <>
      <Navbar/>
      <h2 style={{marginTop:'20px', marginBottom:'20px',fontFamily: 'sofia-pro-soft, sans-serif'}}>Select a design plan</h2>
      <div style={{
        display:'flex',
        justifyContent:'center',
        flexWrap: 'wrap',
        margin: 'auto',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        alignItems:'baseline'
      }}>
        {
          data.data.map((type, index)=>(
            this.cardOutline(type)
          ))
        }
      </div>
      </>
    )
  }
}

export default Checkout;