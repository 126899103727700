import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { verifyToken } from '../../actions/accountActions'

class Verify extends Component{
    constructor(props){
        super(props);

        this.state = {
            verified: false,
            error: false
        }
    }

    verify(){

        let { user } = this.props;

        if(localStorage.getItem("nayaJwtToken")){
            if(user.id){
                if(user.isVerified){
                    this.props.history.push("/account")
                }else{

                    const values = queryString.parse(this.props.location.search)
                    if(values.token){
                        this.props.verifyToken(values.token, this.props.user.id)
                            .then(() => {
                                this.setState({
                                    verified: true
                                })
                            })
                            .catch(() => {
                                this.setState({
                                    error: true
                                })
                            })
                            .finally(() => {
                                this.props.history.push("/account")
                            })
    
                    }else{
                        this.props.history.push("/account")
                    }
                }
    
            }

        }else{
            this.props.history.push("/login?redirect="+(window.location.pathname+window.location.search).substr(1))
        }

    }


    componentDidUpdate(){
        this.verify();    
    }

    componentDidMount(){
        this.verify();
    }

    render(){
        let { verified, error } = this.state

        let status = verified ? (<p>Your accout has successfully been verified. Redirecting...</p>)
            : (<p>Verifying your account, please wait...</p>)

        return(<>
            {error ? <p>An error occured, please try again</p>
            : status}
        </>)
    }
}

const mapStateToProps = (state) => {
    return { 
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return { 
        verifyToken: (token, id) => dispatch(verifyToken(token, id))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Verify))