import { API_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'
import { getCanvas } from './canvasActions'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const addPinterestLink = (data) => 
{

    return (dispatch) => {
        const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas/pinterest`
                axios.post(UPDATE_CANVAS_DATA_URI, data, config)
                .then(response => {
                    console.log("Added pinterest to canvas")
                    dispatch(getCanvas())
                })
                .catch(e => {
                    console.log(e)
                    console.log("Error")
                })
                .catch(e => {
                console.log(e)
                console.log("Error")
            })
    }
}