import { API_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'
import { getCanvas } from './canvasActions'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const addBudget = (data) => 
{

    return (dispatch) => {
        //Create Answer
        const CREATE_DIMENSIONS_DATA_URI = `${API_ENDPOINT}/api/budget`
        const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas/budget`

        axios.post(CREATE_DIMENSIONS_DATA_URI, data, config)
            .then(response => {
                data["budget"] = response.data.id
                axios.post(UPDATE_CANVAS_DATA_URI, data)
                .then(response => {
                    console.log("Added budget to canvas")
                    dispatch(getCanvas())
                })
                .catch(e => {
                    console.log(e)
                    console.log("Error")
                })
            }).catch(e => {
                console.log(e)
                console.log("Error")
            })
        dispatch({ type: 'ADD_BUDGET', data});
    }
}

export const editBudget = (data) => 
{

    return (dispatch) => {
        //Create Answer
        const CREATE_ANSWER_DATA_URI = `${API_ENDPOINT}/api/budget`
        console.log(data)
        axios.put(CREATE_ANSWER_DATA_URI, data, config)
            .then(response => {
                dispatch(getCanvas())
            }).catch(e => {
                console.log(e)
            })
        dispatch({ type: 'EDIT_BUDGET', data});
    }
}

export const deleteBudget = (data) => 
{
    return (dispatch) => {
        //Create Answer
        const DELETE_BUDGET_DATA_URI = `${API_ENDPOINT}/api/budget`
        console.log(data)
        axios.delete(DELETE_BUDGET_DATA_URI, {params:data}, config)
            .then(response => {
                dispatch(getCanvas())
            }).catch(e => {
                console.log(e)
            })
        dispatch({ type: 'DELETE_ANSWER', data});
    }
}