import React, { Component } from 'react'
import {Col, Modal, Form, Button, Alert, Row} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'  
/**
 * Redux Actions
 */
import {addCanvasToUserProfile} from '../../actions/authActions' 
import {setLead, updateLead} from '../../actions/leadActions' 

import axios from 'axios'
/**
 * Media and Images
 */
import { API_ENDPOINT } from  '../../constants/secrets';


/**
 * Default Config
 */
const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
}


axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
class CancelButton extends Component{
    state = {
        showPrompt : false,
        email:'',
        showError:false,
        message:"",
    }

    togglePrompt = () => {
        this.setState({showPrompt:!this.state.showPrompt})
    }

    closeApp = (e) => {
        console.log("close app:", this.props.user)
        if (this.props.user !== undefined && this.props.user.id !== null && this.props.user.id !== undefined){
            this.props.history.push("/")
        }
        else{
            this.togglePrompt();
        }
        
    }

    /**
     * Handles form changes
     */
    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    exitPrompt = (e) => {
        e.preventDefault();
        const AUTH_GET_USER_URI = `${API_ENDPOINT}/api/user`

        axios.get(AUTH_GET_USER_URI, {params: {email: this.state.email}} , config)
        .then(response => {
            if(response.status === 200)
            {
                if(response.data.type === "USER")
                {
                    console.log("EMAIL FOUND:",response.data.user._id)   
                    const data={
                        user_id:response.data.user._id,
                        canvas_id:this.props.canvas_id
                    }
                    this.props.addCanvasToUserProfile(data)
                    this.props.history.push('/')
                }
                else if (response.data.type === "LEAD")
                {
                    // Go with lead
                    this.props.updateLead({email: response.data.lead.email, canvas:this.props.canvas_id})
                    this.props.history.push('/')
                }
                
            }
        })
        .catch(e => {
            this.setState({showError: true, message: "Server Error"})
            console.log("User not found")
        })

    }
    
    render()
    {

        return (
                <Col xs={3} id="exit">
                    <Col xs={12} className="cancel">
                        <p className='app-nav' onClick={this.closeApp}>Exit</p>
                    </Col>
                    <Modal
                        size="md"
                        show={this.state.showPrompt}
                        onHide={()=>this.props.history.push("/")}
                        aria-labelledby="example-modal-sizes-title-sm"
                        id="instructions-modal"
                    >
                        <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.showError && 
                                <Alert variant={"info"}>{this.state.message}</Alert>
                            }
                            <h5 style={{marginBottom:10, fontFamily:'sofia-pro-soft,sans-serif'}}>Please provide your email here to save your progress.</h5>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control type="email" placeholder="Enter email"  name='email' value={this.state.email} onChange={this.handleChange}/>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Col xs="12" style={{display:'flex', justifyContent:"center"}}>
                                <Row className='justify-content-md-center'>
                                    <Button variant="dark" onClick={this.exitPrompt} style={{marginTop:20, marginBottom:40}}>Exit</Button>
                                </Row>
                            </Col>
                        </Modal.Footer>
                    </Modal>
                </Col>
        )
    }
}
const mapStateToProps = (state) => {
  
    return { 
        canvas_id                   : state.canvas.id,
        user                        : state.user,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    
    return { 
        setLead: (data) => dispatch(setLead(data)),
        updateLead: (data) => dispatch(updateLead(data)),
        addCanvasToUserProfile:(data)=>dispatch(addCanvasToUserProfile(data))
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CancelButton))