import { API_ENDPOINT } from  '../constants/secrets'
import axios from 'axios'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    
    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const getNotifications = (data) => 
{
    return (dispatch) => {
        const AUTH_GET_NOTIFICATION_URI = `${API_ENDPOINT}/api/pushnotification`
        axios.get(AUTH_GET_NOTIFICATION_URI, {params: data} , config)
        .then(response => {
            if(response.data.status)
            {
                dispatch({type: "GET_NOTIFICATIONS", notifications: response.data.notifications})
            }
        }).catch(e => {
            console.log(e)
        })
    }
}

export const readNotification = (notification_id,userId) => 
{
    return (dispatch) => {
        const AUTH_PUT_NOTIFICATION_URI = `${API_ENDPOINT}/api/pushnotification/${notification_id}`
        axios.put(AUTH_PUT_NOTIFICATION_URI, {"readStatus":true} , config)
        .then(response => {
            if(response.data.status)
            {
                dispatch(getNotifications({user_id:userId}))
            }
        }).catch(e => {
            console.log(e)
        })
    }
}