import { API_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'
import { getCanvas } from './canvasActions'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const saveStory = (data) => 
{

    return (dispatch) => {
        //Create Answer
        const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas`

        axios.put(UPDATE_CANVAS_DATA_URI, data, config)
            .then(response => {
                console.log(response)
                dispatch(getCanvas())
                dispatch({ type: 'ADD_STORY_NAME', data});
            }).catch(e => {
                console.log("Error")
            })
    }
}