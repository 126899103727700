import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { API_ENDPOINT } from "../../constants/secrets";

import "../../stylesheets/shippingtool.css";
import axios from "axios";

/**
 * Higher Level Components
 */
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {addShippingAddress, addExistingAddress} from '../../actions/shippingActions';

const AddressCard = (props) => {
    const className = props.address.selected
        ? "address-card active"
        : "address-card";
    console.log(props.address.selected);
    const addressData = props.address.address.address;
    return (
        <div
            onClick={() => {
                props.onClick(props.address);
            }}
            className={className}
        >
            <p className="address-line" style={{fontWeight:'bold'}}>{addressData.address_name}</p>
            <p className="address-line">{addressData.address_line_1}</p>
            <p className="address-line">{addressData.address_line_2}</p>
            <p className="address-line">{addressData.locality}</p>
            <p className="address-line">{addressData.city}</p>
            <p className="address-line">Phone: {addressData.phone}</p>
        </div>
    );
};

/**
 * A tool to be used on canvas
 * Used to add a shipping address to the canvas
 * @property    userId                  -   The client usedId
 * @property    addShipping(addressId)  -   Called when user selects address
 * @property    show                    -   Boolean specifying whether to show the modal or not
 * @property    close                   -   Function called when modal is closed
 */

class ShippingTool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: this.props.shipping ? this.props.shipping : {},
            addressList: [],
            addAddress: false,
        };
    }

    handleTextChange = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            address: {
                ...this.state.address,
                [name]: value,
            },
        });
    };

    handleNumberChange = (e, length) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const regex = /^[0-9]*$/;
        if (regex.test(value) && value.length <= length) {
            this.setState({
                address: {
                    ...this.state.address,
                    [name]: value,
                },
            });
        } else {
            e.target.value = value.substring(0, value.length - 1);
        }
    };

    componentDidMount() {
        this.getAddress();
    }

    componentDidUpdate(prevProps) {
        if (this.props.user.id && this.props.user.id !== prevProps.user.id) {
            console.log("Id updated");
            this.getAddress();
        }
    }

    getAddress = () => {
        const user_id = this.props.user.id;
        const url = `${API_ENDPOINT}/api/shippingaddress?user_id=${user_id}`;
        if (!user_id) {
            console.log("No user id");
            return;
        }
        console.log("Getting addresses...");
        axios
            .get(url, { crossDomain: true })
            .then((response) => {
                const address = response.data.addresses;
                if (!address) return;
                var addressList = [];
                address.forEach((addressItem, index) => {
                    addressList.push({
                        selected: false,
                        address: addressItem,
                    });
                });
                this.setState({
                    addressList: addressList,
                });
                console.log(addressList);
            })
            .catch((e) => {
                console.log("Error getting addresses");
                console.log(e);
            });
    };

    handleAddressSelect = (e) => {
        e.preventDefault();
        var addressId = null;
        this.state.addressList.forEach((address) => {
            if (address.selected) {
                addressId = address.address._id;
            }
        });
        if (!addressId) return;
        this.props.addExistingAddress({id:this.props.canvas_id, shipping:addressId});
        this.props.toggleModal();
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const user_id = this.props.user.id;
        console.log(user_id);
        if (!user_id) {
            console.log("No user ID for address");
            return;
        }

        const data = {
            user_id: this.props.user.id,
            address: this.state.address,
            canvas_id:this.props.canvas_id
        };
        this.props.addShippingAddress(data)
        this.props.toggleModal();
    };

    renderAddresses = () => {
        var render = [];
        console.log("this.state.addressList.length:",this.state.addressList.length)
        if (this.props.user.id && this.state.addressList.length <= 0) {
            return <p>Add a new address!</p>;
        }
        var btnIndex;
        this.state.addressList.forEach((address, index) => {
            render.push(
                <Row className="justify-content-md-center">
                    <AddressCard
                        key={index}
                        onClick={this.toggleAddress}
                        address={address}
                    ></AddressCard>
                </Row>
            );
            btnIndex = index + 1;
        });
        render.push(
            <Row className="justify-content-md-center" style={{display:'flex', justifyContent:'center'}}>
                <button
                    key={btnIndex}
                    className="add-btn"
                    onClick={this.handleAddressSelect}
                >
                    Set Shipping Address
                </button>
            </Row>
        );
        return <Col style={{ width: "100%" }}>{render}</Col>;
    };

    toggleAddress = (address) => {
        console.log("Select");
        var newAddressList = [];
        this.state.addressList.forEach((addressItem) => {
            if (addressItem.address._id === address.address._id) {
                newAddressList.push({
                    ...addressItem,
                    selected: true,
                });
            } else {
                newAddressList.push({
                    ...addressItem,
                    selected: false,
                });
            }
        });
        console.log(newAddressList);
        this.setState({
            addressList: newAddressList,
        });
    };

    toggleAddMode = () => {
        this.setState({
            addAddress: !this.state.addAddress,
        });
    };

    renderForm = () => {
        const formStyle = {
            display: "flex",
            flexDirection: "column",
            width: "90%",
            maxWidth:'700px'
        };
        return (
            <Form style={formStyle} onSubmit={this.handleSubmit}>
                <hr />
                <label>Name</label>
                <input
                    type="text"
                    name="address_name"
                    placeholder="Enter your name or just label this address"
                    onChange={this.handleTextChange}
                />
                <label>Type</label>
                <select name="address_type" onChange={this.handleTextChange}>
                    <option>--Select One--</option>
                    <option value="HOME">Home</option>
                    <option value="WORK">Work</option>
                </select>
                <label>Address</label>
                <input
                    type="text"
                    name="address_line_1"
                    placeholder="Address Line 1"
                    onChange={this.handleTextChange}
                />
                <input
                    type="text"
                    name="address_line_2"
                    placeholder="Address Line 2"
                    onChange={this.handleTextChange}
                />
                <input
                    type="text"
                    name="locality"
                    placeholder="Locality"
                    onChange={this.handleTextChange}
                />
                <label>City</label>
                <input
                    type="text"
                    name="city"
                    placeholder="City"
                    onChange={this.handleTextChange}
                />
                <label>ZIP Code</label>
                <input
                    type="text"
                    name="zip"
                    placeholder="Zip"
                    onChange={(e) => {
                        this.handleNumberChange(e, 6);
                    }}
                />
                <label>Phone</label>
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    onChange={(e) => {
                        this.handleNumberChange(e, 10);
                    }}
                />
                <button type="submit">Submit</button>
            </Form>
        );
    };

    render() {
        const renderForm = this.renderForm();
        const renderAddress = this.renderAddresses();
        console.log("renderAddress:",renderAddress)
        return (
            <Container id="shipping">
                <Col className="justify-content-md-center">
                    <Row className="justify-content-md-center" style={{display:'flex', justifyContent:'center'}}>
                        <h1>Shipping</h1>
                        <hr />
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col>
                        {
                            (this.props.user && this.props.user.id) && (
                                <>
                                <Row className="justify-content-md-center" style={{display:'flex', justifyContent:'center'}}>
                                    <h3>Select Address</h3>
                                </Row>
                                <Row className="justify-content-md-center" style={{display:'flex', justifyContent:'center'}}>
                                    {!this.props.user.id ? (
                                        <p>Loading your address list...</p>
                                    ) : (
                                        renderAddress
                                    )}
                                </Row>
                                </>
                            )
                        }
                            
                            <Row className="justify-content-md-center" style={{display:'flex', justifyContent:'center'}}>
                                {!this.state.addAddress && (
                                    <button
                                        onClick={this.toggleAddMode}
                                    >
                                        + Add Address
                                    </button>
                                )}
                                {this.state.addAddress && renderForm}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Container>
        );
    }
}

/**
 * 
 * @param {*} state
 * Maps Redux state to component props 
 */
const mapStateToProps = (state) => {
  
    return {
        // Dimension specific 
        // dimensions: dimensions,
  
        //General Function
        shipping: state.canvas.shipping,
        canvas_id: state.canvas.id,
        user: state.user
  
    }
  }
  
  /**
  * Maps Redux actions to component props
  * @param {*} dispatch
  *  
  */
  const mapDispatchToProps = (dispatch) => {
  
    return {
        addShippingAddress: (data) => dispatch(addShippingAddress(data)),
        addExistingAddress: (data) => dispatch(addExistingAddress(data)),
    }
  }
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShippingTool));