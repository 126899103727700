import React from 'react';
/** Components */
import NavBar from '../utilities/NavBar'

/** Layout and Style */
import { Container, Form} from 'react-bootstrap'
import DesignerAgreementData from './DesignerAgreement.json';
import MakerAgreementData from './MakerAgreement.json';

class TOSContainer extends React.Component{
    getContractDetails = (data) => {
        return(
            <>
            <Form.Group style={{display:"flex", alignItems:'center'}}>
                <Form.Label>Date:</Form.Label>
                <Form.Control type="date" placeholder="[Insert Date]" style={{marginLeft:10, maxWidth:200}} disabled={true}/>
            </Form.Group>

            <Form.Group style={{display:"flex", flexWrap:'wrap'}}>
                <Form.Label>Between:</Form.Label>
                    <div style={{marginLeft:10}}>
                        <div dangerouslySetInnerHTML={{__html:data.Between[0]}}/>
                        <Form.Control type="text" placeholder="Address" style={{maxWidth:200, marginBottom:5}} disabled={true}/>
                        <Form.Control type="text" placeholder="Phone" style={{maxWidth:200, marginBottom:5}} disabled={true}/>
                        <Form.Control type="text" placeholder="Email" style={{maxWidth:200, marginBottom:5}} disabled={true}/>
                    </div>
                    <p style={{margin:10, minWidth:200, textAlign:'center', fontWeight:'normal'}}>and</p>
                    <div style={{marginLeft:10}}>
                        <Form.Control type="text" placeholder={data.Between[1]} style={{maxWidth:200, marginBottom:5}} disabled={true}/>
                        <Form.Control type="text" placeholder="Address" style={{maxWidth:200, marginBottom:5}} disabled={true}/>
                        <Form.Control type="text" placeholder="Phone" style={{maxWidth:200, marginBottom:5}} disabled={true}/>
                        <Form.Control type="text" placeholder="Email" style={{maxWidth:200, marginBottom:5}} disabled={true}/>
                    </div>
            </Form.Group>
            </>
        )
    }

    getSigningDetails = (data) => {
        return(
            <Form.Group style={{display:"flex", flexWrap:'wrap', justifyContent:'space-between', marginTop:20}}>
                <div>
                    <Form.Label>Naya Studio, Inc.</Form.Label>
                    <Form.Control type="text" placeholder="[Full Name], [Title]" style={{maxWidth:200}} disabled={true}/>
                </div>
                <div style={{maxWidth:300}}>
                    <Form.Label style={{margin:0}}>{data.type}</Form.Label>
                    <Form.Text style={{marginTop:0,marginBottom:10}}>[Drafting Note: this signature block is for a person not for an entity.]</Form.Text>
                    <Form.Label>By:</Form.Label><br/>
                    <Form.Label style={{margin:0}}>{data.type}</Form.Label>
                    <Form.Text style={{marginTop:0,marginBottom:10}}>[Drafting Note: this signature block is for a person not for an entity.]</Form.Text>
                    <Form.Control type="text" placeholder="[Full Name], [Title]" style={{maxWidth:200}} disabled={true}/>
                </div>
            </Form.Group>
        )
    }

    getAcceptingTerms = (data) => {
        return(
            <>
                {this.getContractDetails(data)}

                <div style={{
                    fontWeight:'normal',
                    fontSize:'large',
                    color:'#858585',
                    fontFamily: "sofia-pro-soft,sans-serif",
                    textAlign:'justify'
                }} dangerouslySetInnerHTML={{__html:data["introductory-text"]}}/>
                <br/>
                <>
                {
                    data.subheaders.map((subheader, index)=>(
                        <div key={index}>
                        <h4 style={{color:'#666666'}}>{subheader.title}</h4>
                        <div style={{
                            fontWeight:'normal',
                            fontSize:'large',
                            color:'#858585',
                            fontFamily: "sofia-pro-soft,sans-serif",
                            textAlign:'justify'
                        }} dangerouslySetInnerHTML={{__html:subheader.data}}/>
                        <br/>
                        </div>
                    ))
                }
                </>
                <br/>
                <div style={{
                    fontWeight:'normal',
                    fontSize:'large',
                    color:'#858585',
                    fontFamily: "sofia-pro-soft,sans-serif",
                    textAlign:'justify'
                }} dangerouslySetInnerHTML={{__html:data["concluding-text"]}}/>

                {this.getSigningDetails(data)}
            </>
        )
    }

    render(){
        return(
            <Container fluid>
                <NavBar/>
                <Container>
                    <h1>Terms of Service</h1>
                    <h5 style={{color:'#cccccc', margin:0, fontFamily:'sofia-pro-soft, sans-serif', fontWeight:'normal'}}>Updated April 2020</h5>
                    <div style={{
                        display:'flex', flexDirection:'row', alignItems:'center',marginTop:30, flexWrap:'wrap'
                    }}>
                        <h3>{this.props.type==="designer" ? "Designer Agreement " : "Maker Agreement "}</h3>
                        <hr style={{
                            flex:1,
                        }}/>
                    </div>
                    {this.getAcceptingTerms(this.props.type==="designer" ? DesignerAgreementData.agreement : MakerAgreementData.agreement)}
                </Container>
                
            </Container>
        )
    }
}

export default TOSContainer;