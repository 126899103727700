import React, { useState } from 'react'
// import logoSrc from '../../../images/naya_logo_symbol.png'
import logoSrc from '../../../images/pride_logo.svg'
import ClientAgreement from './ClientAgreement'
import { motion } from 'framer-motion'
import './ClientAgreement.scss'

const AgreementContainer = ({
    tos,
    onChangeTOS,
    submit,
    hide
  }) => {
    const [ scrolledToBottom, setScrolledToBottom ] = useState(false)

    return (
        <motion.div
          className='splash-modal_body'
          onClick={e => e.stopPropagation()} 
          key='splash-modal_body'
          initial={{ x: 1000 }}
          animate={{ x: 0 }}
          exit={{ x: 1000 }}
          transition={{
            x: {
              type: 'spring',
              stiffness: 600,
              damping: 100,
            },
            duration: 0.3
          }}
        >
          <div name='title'>
            <div className='logo_container'>
              <img src={logoSrc} type='logo' width='100' alt='Naya logo' style={{marginBottom: '2rem'}}/>
              <h2>Naya</h2>
            </div>
            <p>
              Please read through and accept our Client agreement to continue
          </p>
          </div>
          <div name='body'>
            <ClientAgreement
              onChangeTOS={onChangeTOS}
              submit={submit}
              tos={tos}
              bottom={scrolledToBottom}
              setBottom={setScrolledToBottom}
            />
          </div>
          <div name='footer'>
            <div className='checkbox_container'>
              <input type='checkbox' id='client-checkbox' name='client' checked={tos.client} onChange={onChangeTOS} /> <label htmlFor='client-checkbox'>I agree to the client terms and conditions</label>
              <br />
            </div>
            <div className='main-btn_container' >
              <button className='main-btn' type='accept' disabled={!scrolledToBottom || !(tos.client)} onClick={submit}>
                I accept
            </button>
              <span type='not-accept' onClick={hide}>
                I do not accept
            </span>
            </div>
          </div>
        </motion.div>
    )
  }

  export default AgreementContainer