import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spinner, Form } from 'react-bootstrap'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { updateUser } from '../../actions/accountActions'
// import logoSrc from '../../images/naya_logo_symbol.png'
import logoSrc from '../../images/pride_logo.svg'
import AgreementContainer from './Agreement/AgreementContainer'
import { motion, AnimatePresence } from 'framer-motion'
import './SplashAuth.scss'

const PreAgreement = ({
    setModalVisible,
  }) => {

  return (
    <motion.div 
      className='pre-agreement-container'
      key='pre-agreement-container'
      initial={{ x: -1000 }}
      animate={{ x: 0 }}
      exit={{ x: -1000  }}
      transition={{
        x: {
          type: 'spring',
          stiffness: 600,
          damping: 100
        },
        duration: 0.3
      }}
    >
      <img src={logoSrc} alt='logo' width='100' style={{ marginBottom: '2rem' }} />
      <h2> Welcome! </h2>
      <p>
        Thank you for choosing Naya. We are excited to work with you and can't wait to see your finished product.
      </p>
      <p style={{ marginTop: '1rem' }}>
        Before we can begin, we need you to agree to the terms of service. Please read through and accept our Client agreement to continue
      </p>
      <p style={{ marginTop: '1rem' }}>
        After accepting, you will still be able to access the Client agreement and terms of service in the 'account' section.
      </p>
      <button className='main-btn' style={{ marginTop: '2rem', marginBottom: '0', padding: '1rem 2rem' }} onClick={() => setModalVisible(true)}>
        View the Client Agreement
      </button>
    </motion.div>
  )
}

const SplashAuth = ({ 
    checkingAuthStatus = true, 
    authenticated = false,
    setState
  }) => {

  const acceptedTermsOfService = useSelector(state => state.user.acceptedTermsOfService)
  const _id = useSelector(state => state.user.id)
  const dispatch = useDispatch()
  const [ acceptedTOS, setAcceptedTOS ] = useState(acceptedTermsOfService)
  const [ modalVisible, setModalVisible ] = useState(false)
  const splashRef = useRef()

  useEffect(() => {
    setAcceptedTOS(acceptedTermsOfService)
  }, [acceptedTermsOfService])

  // disables body scrolling when in auth screen
  useEffect(() => {
    disableBodyScroll(splashRef)

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  const agreeToTOS = async () => {
    //find timestamps
    const { timestamps: oldTimestamps, client: oldClient } = acceptedTermsOfService
    const { client } = acceptedTOS
  
    let timestamps = {}

    if (oldTimestamps) {
      timestamps = oldTimestamps
    }

    //designer timestamps, only fires when going from false -> true and no timestamp previously
    if (client && oldClient !== client && !timestamps.client) {
      timestamps.client = new Date()
    }

    //update TOS
    await dispatch(updateUser({ 
      updateType: 'terms-of-service',
      _id,
      acceptedTermsOfService: {
        ...acceptedTOS,
        timestamps
      }
    }))

    //close modals
    setState({
      checkingAuthStatus: true,
      authenticated: true
    }, () => setModalVisible(false))
  }

  const onChangeTOS = (e) => {
    const { name } = e.target
    setAcceptedTOS(prev => ({
      ...prev,
      [name]: !prev[name]
    }))
  }

  return (
    <div id='splash-auth' ref={splashRef}>
      <AnimatePresence exitBeforeEnter>
        { checkingAuthStatus && (
          <div className='spinner-container'>
            <Spinner animation='border' variant='light' size='lg' role='status' />
            <p> We are currently checking your log in status</p>
          </div>
        )}
        {/* firebase check is done and user has not signed their respectful terms of service, for designer or maker*/}
        { (!checkingAuthStatus && !authenticated) && (
          <>
            <div className='tos-container'>
              <div className='navigation-container' >
                <button className={`navigation-buttons ${!modalVisible ? 'active' : ''}`} onClick={() => setModalVisible(false)}>
                </button>
                <button className={`navigation-buttons ${modalVisible ? 'active' : ''}`} onClick={() => setModalVisible(true)}>
                </button>
              </div>
                {!modalVisible && 
                  <PreAgreement
                    setModalVisible={setModalVisible}
                  />
                }
                {modalVisible && 
                  <AgreementContainer
                    show={modalVisible}
                    hide={() => setModalVisible(false)}
                    tos={acceptedTOS}
                    onChangeTOS={onChangeTOS}
                    submit={agreeToTOS}
                  />
                }
            </div>
          </>
        )}
      </AnimatePresence>
    </div>
  )
}

export default SplashAuth