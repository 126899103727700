import React, { Component } from 'react'
import checkmark from '../../images/icons/selected.svg'
import {Container, Row, Col, Button, Form, Image} from 'react-bootstrap'
import text_bg1 from '../../images/text_bg_1.svg'
import text_bg2 from '../../images/text_bg_2.svg'
import text_bg3 from '../../images/text_bg_3.svg'


class TextMoodboardOption extends Component{
    
    handleSelect = (e) => {
        console.log("here")
        e.preventDefault()
        this.props.onClick(this.props.id)
    }

    render()
    {
        return (
            <Col xs={this.props.cols*2} lg={this.props.cols} onClick={this.handleSelect} className={this.props.selected? "moodboard-text selected-text-option": "moodboard-text"}>
                <div>
                     {this.props.selected && <Image src={text_bg3} style={{"position":"absolute", "top":"0", "left":"0", "height":"100%","width":"100%"}}></Image>}
                        <p> #{this.props.text}</p>

                </div>
                
            </Col>
        )
    }
}

export default TextMoodboardOption