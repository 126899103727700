import React, { Component } from 'react'
import { connect } from 'react-redux'   
import { withRouter } from 'react-router-dom'
import {getTransactionsForCanvas} from '../../actions/transactionActions'

import {initiatePayment} from '../../actions/transactionActions'
import {Container, Row, Col, Button, Form, Image, Alert} from 'react-bootstrap'
import {CardElement, injectStripe} from 'react-stripe-elements';
import '../../stylesheets/account.css'
import checkmark from '../../images/icons/selected.svg'
import ClipLoader from 'react-spinners/ClipLoader';

class PaymentProduct extends Component{
    render(){
        return (
            <Col xs={12} className="product">
                <Col xs={12}>
                    <Row>
                        <Col xs={8} lg={6} className="product-desc">
                            <h5>Designer Fee</h5>
                            <p>Designer fee includes the cost of two iterations of design work with a naya furniture designer</p>
            
                            <h5>Fabrication Fee (If specified)</h5>
                            <p>Fabrication fee (If specified) includes the cost of fabrication of your current design and shipping to address</p>
                        </Col>
                        <Col xs={4} lg={6} className="prices">
                            <Row>
                                <Col xs={12}>
                                    <h3>${this.props.fabricationCost/100}</h3>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Col>
        )
    }
}




class PaymentCanvasTile extends Component{

    handleClick = (e) =>
    {
        e.preventDefault()
        localStorage.setItem('nayaCanvasID', this.props.data._id)
        this.props.history.push('canvas')
    }

    render()
    {
        let visual_moodboard = this.props.data.moodboards.filter(x => !x.isText)
        if(visual_moodboard.length>0)
        {
            visual_moodboard = visual_moodboard[0].options
        }
        let all_images = [...visual_moodboard]
        let src = "https://naya-studio-uploads.s3.amazonaws.com/1571845663383.jpeg"

        if(all_images.length> 0 )
        {
            src = all_images[0]
        }

        console.log(src)

        return (
            <Col xs={12} className="canvas-widget">
                <Col xs={12}>
                <Row>
                    <Image src={src} fluid></Image>
                </Row>
                </Col>
            </Col>
        )
    }
}

class PayFabricationFee extends Component{

    constructor(props)
    {
        super(props)
        
        this.success = false
        this.buttonStyle = {
            "borderRadius": "0px",
            "marginTop": "0px",
            "fontSize":"1rem"
        }
        this.state={
            errorMessage:null,
        }
    }

    componentDidMount()
    {
        require('../../stylesheets/payments.css')
        // document.body.style.backgroundColor = "#eee"
        this.props.getTransactionsForCanvas(localStorage.getItem('nayaCanvasID'))
    }

    handleSubmit = (e) =>
    {
        e.preventDefault()
        this.setState({errorMessage:null});
        // console.log(this.stripe)
        this.props.stripe.createToken({type: 'card', email: this.props.user.email})
            .then((response) => {
                console.log(response)
                if(response.error){
                    const errorMessage = (response.error && response.error.message)||'Error';
                    this.setState({errorMessage})
                    return;
                }
                let payload = {
                    canvasId    : this.props.canvas._id,
                    userId      : this.props.user.id,
                    reason      : "FABRICATION_FEE",
                    amount      : this.props.canvas.fabricationCost,
                    currency    : "usd",
                    token       : response.token.id
                }
                
                this.props.initiatePayment(payload)


            }
            
        );
    }

    renderSuccess = () =>
    {
        return <Col xs={!2} className="product-desc">
                <Col xs={{span:6, offset:3}}  lg={{span:4, offset:4}}>
                    <Image src={checkmark} fluid></Image>
                </Col>
                <h5 style={{"textAlign":"center"}}>Transaction Successful.</h5>
                <p style={{"textAlign":"center"}}>The receipt has been sent to your email: {this.props.user.email}</p>
                <Col xs={12}>
                <a style={{"textAlign":"center", "display": "block", "cursor":"pointer"}} onClick={(e) => {this.props.history.push('canvas')}}>Back to Canvas</a>
                </Col>
            </Col>
    }

    renderLoading = () =>
    {
        
        return <Col xs={!2} className="product-desc">
                    <Col xs={{span:6, offset:3}}  lg={{span:4, offset:4}}>
                    <ClipLoader
                            css={"display: block; margin: 0 auto;"}
                            sizeUnit={"px"}
                            size={150}
                            color={'#123abc'}
                            />                  
                    </Col>
                    <h5 style={{"textAlign":"center"}}>Your payment is processing</h5>
                    <p style={{"textAlign":"center"}}>A receipt will be sent to your email: {this.props.user.email}</p>
                </Col>
    }

    renderForm = () =>
    {
        let render = <Form onSubmit={this.handleSubmit} className="ElementsApp">
                        <CardElement 
                        {...createOptions('1rem')}

                        />
                        <Col xs={{span:6, offset:3}}>
                        <Button type="submit" style={this.buttonStyle} variant="dark">Confirm order</Button>
                        </Col>
                    </Form>
        return render
    }
    
    render()
    {

        let render = this.renderForm()
        if(!this.props.user.loggedIn){
            const url = this.props.canvas && this.props.canvas.id ? `/canvas/${this.props.canvas.id}` : `/canvas`;  
            this.props.history.push(url)
        }
        if(this.props.transactions)
        {
            let designer_fees = this.props.transactions.filter(transaction => transaction.description === "FABRICATION_FEE")
            if(designer_fees.length>0)
            {
                this.success = true
                render = this.renderSuccess()
            }
        }
        
        if(this.props.loading)
        {
            render = this.renderLoading()
        }
        const {errorMessage}= this.state;
        return (
        <Container id="payment">
            <Row>
                <Col xs={12} lg={12}>
                    <Row>
                        <Col xs={12} lg={{span:8, offset:2}} className="content-wrapper">
                            <h1>naya</h1>
                            <Col xs={12} lg={{span: 6, offset:3}}>
                                <PaymentCanvasTile data={this.props.canvas}></PaymentCanvasTile>
                            </Col>
                            <PaymentProduct fabricationCost = {this.props.canvas.fabricationCost}></PaymentProduct>
                            {
                                errorMessage &&
                                <Alert xs={12} lg={{span: 6, offset:3}} variant='danger' >
                                    {errorMessage}
                                </Alert>
                            }
                            {render}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        )
            
    }
}

const mapStateToProps = (state) => {
    return { 
        user: state.user,
        canvas: state.canvas,
        transactions: state.transactions[state.canvas._id],
        loading: state.loading.transactionProcessing
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return { 
        initiatePayment: (data) => dispatch(initiatePayment(data)),
        getTransactionsForCanvas: (id) => dispatch(getTransactionsForCanvas(id))
    }
}

const createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize: '1.5rem',
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          padding: '1rem',
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectStripe(PayFabricationFee)))
