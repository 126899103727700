/** React and Redux */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/** Redux Actions */
import { loginToken } from "../../actions/authActions";
import { getInitData } from "../../actions/getInitData";
import { createCanvas } from "../../actions/getInitData";

/** Components */
import NavBar from "../utilities/NavBar";
import ProgressBar from "../utilities/ProgressBar";
import BackButton from "../utilities/BackButton";
import Progress from "../utilities/Progress";
import CancelButton from "../utilities/CancelButton";
// import ContinueButton from "../utilities/ContinueButton";

/** Layout and Style */
import { Container, Row, Col, Image } from "react-bootstrap";
import "../../stylesheets/style.css";
import "../../stylesheets/landing.css";

/** Images and Media */
import noIdea from "../../images/landing/experience1.png";
import haveIdea from "../../images/landing/experience2.png";
import canComm from "../../images/landing/experience3.png";
import cantComm from "../../images/landing/qmark.svg";
import bg1 from "../../images/icons/bg_1.svg";

class Landing extends Component {
    /** Empty Constructor */
    constructor(props) {
        super(props);
        this.state = { optionSelected: false, questionNo: 1, answers: [] };
    }

    /** Called when DOM mounted */
    componentDidMount = () => {
        this.props.getInitData();
        let JWTToken = localStorage.getItem("nayaJwtToken");
        if (JWTToken !== undefined) {
            this.props.loginToken(JWTToken);
        }
    };

    handleSubmit = (e) => {
        // e.preventDefault()
        // console.log(e.target.value)
        this.props.createCanvas(e);
        this.props.setPathway(e);
        console.log(this.props.flow);
        this.setState({ optionSelected: true });
        // this.props.history.push(this.props.flow['landing'])
    };

    handleOption = (tag) => {
        var answers = this.state.answers;
        var question = this.state.questionNo;
        question++;
        answers.push(tag);

        this.setState({
            answers: answers,
            questionNo: question,
        });
        if (question >= 3) {
            if (answers[0] === "TRUE-1" && answers[1] === "TRUE-2") {
                this.handleSubmit("Commercial");
            } else {
                this.handleSubmit("Residential");
            }
        }
    };

    /** Renders option */
    renderOption = (optionText, optionImg, tag) => {
        let style = {};

        if (this.state[`mouseOver${optionText}`]) {
            style["filter"] = "invert(1)";
        }

        return (
            <Col
                xs={{ span: 8, offset: 0 }}
                md={{ span: 4, offset: 0 }}
                id='landing-option'
                onClick={() => this.handleOption(tag)}
            >
                <Col
                    xs={{ span: 12, offset: 0 }}
                    onMouseOver={() =>
                        this.setState({ [`mouseOver${optionText}`]: true })
                    }
                    onMouseLeave={() =>
                        this.setState({ [`mouseOver${optionText}`]: false })
                    }
                >
                    <img
                        src={bg1}
                        alt=''
                        style={{
                            position: "absolute",
                            top: "0rem",
                            left: "0",
                            height: "100%",
                            width: "100%",
                            display: "none",
                        }}
                    />
                    {this.state[`mouseOver${optionText}`] && (
                        <Image
                            src={bg1}
                            alt=''
                            style={{
                                position: "absolute",
                                top: "0rem",
                                left: "0",
                                height: "100%",
                                width: "100%",
                                opacity: "0.3",
                            }}
                            rel='preload'
                            fluid
                        />
                    )}

                    <Image
                        className='landing-option-img'
                        src={optionImg}
                        style={style}
                        fluid
                    />
                </Col>

                <Col xs={12}>
                    <p className='col-12 landing-option'>{optionText}</p>
                </Col>
            </Col>
        );
    };

    renderQuestion1 = () => {
        return (
            <Container id='landing'>
                <h2 className='col-12'>
                    Do you have an idea of furniture you would like to create?
                </h2>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}
                >
                    {this.renderOption("I have an idea", haveIdea, "TRUE-1")}
                    {this.renderOption("I don't know yet", noIdea, "FALSE-1")}
                </div>
            </Container>
        );
    };

    renderQuestion2 = () => {
        return (
            <Container id='landing'>
                <h2 className='col-12'>Do you know how to communicate it?</h2>
                <Row className='justify-content-center'>
                    {this.renderOption("Yes, I think so", canComm, "TRUE-2")}
                    {this.renderOption(
                        "No, I have no clue",
                        cantComm,
                        "FALSE-2"
                    )}
                </Row>
            </Container>
        );
    };

    /** Main DOM Render method */
    render() {
        let question;
        if (this.state.questionNo === 1) {
            question = this.renderQuestion1();
        } else {
            question = this.renderQuestion2();
        }
        return (
            <Container fluid>
                {this.state.questionNo === 1 ? (
                    <NavBar></NavBar>
                ) : (
                    <>
                        <ProgressBar width={20}></ProgressBar>
                        <Row style={{ justifyContent: "space-between" }}>
                            {/* <BackButton></BackButton> */}
                            <Col xs={3} lg={3}>
                                <p
                                    className='app-nav'
                                    onClick={() => {
                                        this.setState({ questionNo: 1 });
                                    }}
                                >
                                    Back
                                </p>
                            </Col>
                            {/* <CancelButton></CancelButton> */}
                            <Col xs={3} lg={3} className='cancel'>
                                <p
                                    className='app-nav'
                                    onClick={() => {
                                        this.setState({ questionNo: 1 });
                                    }}
                                >
                                    Exit
                                </p>
                            </Col>
                        </Row>
                    </>
                )}

                {question}
            </Container>
        );
    }

    componentDidUpdate() {
        console.log(this.props.flow);
        console.log(this.props.questions);
        if (
            this.props.flow["landing"] &&
            this.props.questions &&
            this.state.optionSelected
        ) {
            this.props.history.push(this.props.flow["landing"]);
        }
    }
}

/** Maps redux state to component props */
const mapStateToProps = (state, ownProps) => {
    return {
        history: ownProps["history"],
        flow: state.UI.flow,
        questions: state.UI.questions,
    };
};

/** Maps redux ations to component props */
const mapDispatchToProps = (dispatch) => {
    return {
        getInitData: () => dispatch(getInitData()),
        createCanvas: (pathway) => dispatch(createCanvas(pathway)),
        loginToken: (data) => dispatch(loginToken(data)),
        setPathway: (data) => dispatch({ type: "SET_PATHWAY", tag: data }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Landing));
