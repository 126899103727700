import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

class Logout extends React.Component {
  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const {role = null} = query;
    localStorage.removeItem('nayaJwtToken')
    this.props.signout();
    if(role === 'admin')
      this.props.history.push('/login-admin');
    else if(role === "MAKER" || role === "FABRICATOR" || role === "DESIGNER" || role === "DESIGNER_MAKER")
      this.props.history.push('/login-ecosystem');
    else
      this.props.history.push('/login');
  }
  render() {
    return (<></>)
  }
}

export default connect(null,
  dispatch => ({
    signout: () => dispatch({ type: "SIGN_OUT" })
  })
)(Logout);