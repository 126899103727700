import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Container, Row, Col, Button, Form} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {saveStory} from '../../actions/storyActions'
import {updateCanvas} from '../../actions/canvasActions'
import {setAuthCallback} from '../../actions/authActions';
import ProgressBar from '../utilities/ProgressBar'
import BackButton from '../utilities/BackButton'
import Progress from '../utilities/Progress'
import CancelButton from '../utilities/CancelButton'
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import '../../stylesheets/story.css'

import chance from 'chance'

import axios from 'axios'
import { API_ENDPOINT } from  '../../constants/secrets';
const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

class StoryPage extends Component{

    constructor(props)
    {
        super(props)
        this.state = {
            name: this.props.canvasName? this.props.canvasName : (new chance()).animal(),
            story: this.props.canvasStory? this.props.canvasStory : "",
            id: this.props.canvas_id,
            additional_info: this.props.additional_info? this.props.additional_info : "",
        }
    }

    componentDidMount(){
        console.log("NEXT PAGE:", this.props.nextPage)
    }


    handleSubmit = (e) =>
    {
        e.preventDefault()
        console.log(this.state)
        if(this.props.pathway === "COMPETITION")
        {
            this.props.updateCanvas({id: this.state.id, "pathway": "COMPETITION", "track": this.props.track})
        }
        this.props.saveStory(this.state)

        if(this.props.edit!==true){
            this.props.setAuthCallback(this.props.nextPage)
            this.props.history.push("/login");
        }
        else{
            this.props.toggleModal();
        }
    }

    handleChange = (e) =>
    {
        e.preventDefault()
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleTextChange = (e) => {
        this.setState({ story: e });
    };

    render()
    {
        return (
            <Container fluid={true}>
                {
                    this.props.edit!==true && (
                        <>
                        <ProgressBar width={(this.props.step*100)/this.props.stepMax}></ProgressBar>
                        <Row>
                            <BackButton></BackButton>
                            <Progress step={this.props.step} step_max={this.props.stepMax}></Progress>

                            {/* {window.innerHeight >= document.body.clientHeight && ( */}
                                <CancelButton></CancelButton>
                            {/* )} */}
                        </Row>
                        </>
                    )
                }
                
            <Container id="story">
                <Row>
                    <h2 className="col-12 question-h2">Please add any information you think is relevant</h2>
                    <h4 className="col-12 question-h4" style={{"textAlign":"center"}}>Are you looking for a certain kind of designer? Are you unsure about something related to your design (sustainability, durability, etc.)? Do you need this right away for a client? Could you not find what you were looking for and if so what was wrong with what you searched for?</h4>
                    <Col xs={12} lg={{span:6, offset:3}}>
                        <Row>
                            <Col xs={12}>
                                <p className="title">Project Title</p>
                                <Form.Control type="text"
                                                name="name" 
                                                value={this.state.name} 
                                                onChange={this.handleChange}></Form.Control>
                            </Col>

                            <Col xs={12}>
                                <p className="title">My Story</p>
                            </Col>
                            <Col xs={12}>
                                <ReactQuill
                                    value={this.state.story}
                                    style={{
                                        height: "100%",
                                        fontSize: "1rem",
                                        border: "none",
                                        outline: "none",
                                        "fontWeight":"300"
                                    }}
                                    onChange={this.handleTextChange}
                                />
                            </Col>

                            <Col xs={12} style={{marginTop:70}}>
                                <p className="title">Additional information</p>
                                <Form.Control as="textarea"
                                                name="additional_info" 
                                                value={this.state.additional_info} 
                                                style={{
                                                    height:200,
                                                    border:'1px solid #cccccc',
                                                    marginTop:2
                                                }}
                                                onChange={this.handleChange}></Form.Control>
                            </Col>

                        </Row>
                    </Col>

                    <Col xs={12}>
                        <Row className='justify-content-md-center'>
                                <Button variant="dark" onClick={this.handleSubmit} style={{marginTop:'40px'}}>{this.props.edit!==true ? 'Continue' : 'Submit'}</Button>
                        </Row>
                    </Col>   
                </Row>
            </Container>

            </Container>

            
        )
    }
}

const mapStateToProps = (state) => {

    return { 
        canvas_id                   : state.canvas.id,
        canvasName                  : state.canvas.name,
        canvasStory                 : state.canvas.story,
        additional_info             : state.canvas.additional_info,
        step                        : Object.keys(state.UI.flow).indexOf('story')+1,
        stepMax                     : Object.keys(state.UI.flow).length,
        nextPage                    : state.UI.flow['story'],
        user                        : state.user,
        pathway                     : state.UI.pathway,
        track                       : state.UI.track,
        user                        : state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return { 
        saveStory: (data) => dispatch(saveStory(data)),
        updateCanvas: (data) => dispatch(updateCanvas(data)),
        setAuthCallback: (data) => dispatch(setAuthCallback(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoryPage))