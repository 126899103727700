import { API_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const API_ANNOTATION_ENDPOINT = `${API_ENDPOINT}/api/annotation`


export const createAnnotation = (data) => {
    console.log(data)
    return (dispatch) => 
    {
        axios.post(API_ANNOTATION_ENDPOINT, data, config).then(response => {
            response = response.data
            if(response.status)
            {
                dispatch({type: 'CREATE_ANNOTATION_SUCCESS', payload: response.payload})
            }
            else
            {
                dispatch({type: 'CREATE_ANNOTATION_ERROR'})
            }
        })
        dispatch({type: 'CREATE_ANNOTATION_INIT'})
    }
}

export const editAnnotation = (data) => {
    return (dispatch) => 
    {
        axios.put(API_ANNOTATION_ENDPOINT, data, config).then(response => {
            response = response.data
            if(response.status)
            {
                dispatch({type: 'EDIT_ANNOTATION_SUCCESS', payload: response.payload})
            }
            else
            {
                dispatch({type: 'EDIT_ANNOTATION_ERROR'})
            }
        })
        dispatch({type: 'EDIT_ANNOTATION_INIT'})
    }
}


export const getAnnotation = (data) => {
    return (dispatch) => 
    {
        axios.get(API_ANNOTATION_ENDPOINT, {params : data}, config).then(response => {
            response = response.data
            if(response.status)
            {
                dispatch({type: 'GET_ANNOTATION_SUCCESS', payload: response.payload})
            }
            else
            {
                dispatch({type: 'GET_ANNOTATION_ERROR'})
            }
        })
        dispatch({type: 'GET_ANNOTATION_INIT'})
    }
}


export const deleteAnnotation = (data) => {
    return (dispatch) => 
    {
        axios.delete(API_ANNOTATION_ENDPOINT, {params : data}, config).then(response => {
            response = response.data
            if(response.status)
            {
                dispatch({type: 'DELETE_ANNOTATION_SUCCESS', payload: response.payload})
            }
            else
            {
                dispatch({type: 'DELETE_ANNOTATION_ERROR'})
            }
        })
        dispatch({type: 'DELETE_ANNOTATION_INIT'})
    }
}