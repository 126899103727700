import React, { Component } from "react";
import { connect } from "react-redux";
import { css } from "@emotion/core";
import {
    Container,
    Row,
    Col,
    Modal,
    Button,
    InputGroup,
    FormControl,
} from "react-bootstrap";
import {
    uploadFile,
    addSearchesToCanvas,
    addImagesToCanvas,
} from "../../actions/uploadActions";
import { addPinterestLink } from "../../actions/pinterestLinkActions";
import { searchInstagram, searchGoogle } from "../../actions/searchActions";
import { withRouter } from "react-router-dom";
import ProgressBar from "../utilities/ProgressBar";
import BackButton from "../utilities/BackButton";
import Progress from "../utilities/Progress";
import CancelButton from "../utilities/CancelButton";
// import ContinueButton from "../utilities/ContinueButton";
import "../../stylesheets/style.css";
import "../../stylesheets/search-upload.css";
import axios from "axios";
import { API_ENDPOINT, UPLOAD_ENDPOINT } from "../../constants/secrets";
import SearchUploadOption from "./SearchUploadOption";
import Gallery from "react-photo-gallery";

// importing icons
import documentIcon from "../../images/uploads/document.svg";
import audio from "../../images/uploads/audio.svg";
import video from "../../images/uploads/video.svg";
import close from "../../images/uploads/close.svg";
import cloudUpload from "../../images/icons/cloud_upload_icon.png";

import SyncLoader from "react-spinners/SyncLoader";

import Dropzone from "react-dropzone";

const style = {
    container: {
        cursor: "pointer",
        border: "1px dashed black",
        borderRadius: "5px",
        // backgroundColor: "black",
        padding: "50px",
        // minHeight: "500px",
        textAlign: "center",
    },
};

const spinnerStyle = css`
    margin-left: 50%;
    transform: translate(-50%, 0);
`;

class SearchPage extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.state = {
            showTooltip: false,
            show: false,
            gallery: [],
            selected_options: new Set([]),
            search_term: "",
            searches: {},
            otherDocuments: [],
            pinterest: this.props.pinterest,
        };
        this.uploads = this.props.uploads;
    }

    componentDidMount() {
        // populating the searches and uploads data from redux
        if (this.props.searches !== undefined) {
            let keywords = Object.keys(this.props.searches);
            let all_images = [];
            let tempSearches = {};
            keywords.map((key) => {
                if (key.trim() !== "") {
                    all_images = [...all_images, ...this.props.searches[key]];
                    tempSearches = {
                        ...tempSearches,
                        [key]: this.props.searches[key],
                    };
                }
            });
            this.setState({ searches: tempSearches });
            all_images.map((img) => {
                this.getMeta(img, this.addImage);
            });
        }
        this.props.uploads.map((doc) => {
            if (
                [
                    "jpg",
                    "jpeg",
                    "gif",
                    "png",
                    "svg",
                    "raw",
                    "webp",
                    "tiff",
                    "psd",
                    "bmp",
                    "heif",
                ].includes(doc.ext)
            ) {
                this.getMeta(doc.link, this.addImageUpload);
            } else {
                this.setState({
                    otherDocuments: [
                        ...this.state.otherDocuments,
                        {
                            id: doc._id,
                            link: doc.link,
                            name: doc.name,
                            ext: doc.ext,
                        },
                    ],
                });
            }
        });
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    };

    handleClose = () => this.setState({ show: false });

    handleShow = () => {
        this.props.searchInstagram(this.state.search_term);
        this.props.searchGoogle(this.state.search_term);

        this.setState({ show: true });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        // Adding the searches data to the canvas
        let all_images = {};
        let keywords = Object.keys(this.state.searches);
        keywords.map((key) => {
            if (key.trim() !== "") {
                all_images = { ...all_images, [key]: this.state.searches[key] };
            }
        });
        const searchesData = {
            searches: this.state.searches,
            id: this.props.canvas_id,
        };
        this.props.addSearchesToCanvas(searchesData);

        // Adding the uploads data to the canvas
        let tempUploadData = [];
        this.uploads.map((item) => {
            if (item.id === undefined) {
                tempUploadData.push(item._id);
            } else {
                tempUploadData.push(item.id);
            }
        });
        const uploadsData = {
            uploads: tempUploadData,
            id: this.props.canvas_id,
        };
        this.props.addImagesToCanvas(uploadsData);
        if (this.state.pinterest.trim().length !== 0) {
            const data = {
                id: this.props.canvas_id,
                pinterest: this.state.pinterest,
            };
            this.props.addPinterestLink(data);
        }

        if (this.props.edit !== true) {
            this.props.history.push("/" + this.props.nextPage);
        } else {
            this.props.toggleModal();
        }
    };

    handleSkip = () => {
        if (this.props.edit !== true) {
            this.props.history.push("/" + this.props.nextPage);
        } else {
            this.props.toggleModal();
        }
    };

    handleUpload = (fileData) => {
        const formData = new FormData();
        const file = fileData.file;
        formData.append("file", file, file.name);
        const UPLOAD_FILE_DATA_URI = UPLOAD_ENDPOINT;
        this.props.setLoading(true);
        axios
            .post(UPLOAD_FILE_DATA_URI, formData, { crossDomain: true })
            .then((response) => {
                this.uploads.push(response.data);
                this.props.setLoading(false);
                // Checking if the uploaded file is image or not
                // if it is an image, adding it to the gallery
                if (
                    [
                        "jpg",
                        "jpeg",
                        "gif",
                        "png",
                        "svg",
                        "raw",
                        "webp",
                        "tiff",
                        "psd",
                        "bmp",
                        "heif",
                    ].includes(response.data.ext)
                ) {
                    this.getMeta(response.data.link, this.addImageUpload);
                } else {
                    this.setState({
                        otherDocuments: [
                            ...this.state.otherDocuments,
                            {
                                id: response.data.id,
                                link: response.data.link,
                                name: response.data.name,
                                ext: response.data.ext,
                            },
                        ],
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.props.setLoading(false);
                console.log("Error");
            });
    };

    // handling files upload
    handleFilesAdd = (files) => {
        files.forEach((file) => {
            var files = this.state.files;
            const fileData = { file: file, uploaded: false };
            this.handleUpload(fileData);
        });
    };

    // Adding a searches image to the gallery
    addImage = (url, width, height) => {
        if (!this.state.selected_options.has(url)) {
            let selected_options = this.state.selected_options;
            selected_options.add(url);
            let links = this.state.searches[this.state.search_term];
            if (links) {
                links.push(url);
            } else {
                links = [url];
            }
            this.setState({
                gallery: [
                    ...this.state.gallery,
                    { src: url, height: height, width: width },
                ],
                selected_options: selected_options,
                searches: {
                    ...this.state.searches,
                    [this.state.search_term]: links,
                },
            });
        }
    };

    // Adding the uploaded images to the gallery
    addImageUpload = (url, width, height) => {
        if (!this.state.selected_options.has(url)) {
            let selected_options = this.state.selected_options;
            selected_options.add(url);
            this.setState({
                gallery: [
                    ...this.state.gallery,
                    { src: url, height: height, width: width },
                ],
                selected_options: selected_options,
            });
        }
    };

    removeImage = (url, width, height) => {
        if (this.state.selected_options.has(url)) {
            let selected_options = this.state.selected_options;
            selected_options.delete(url);
            this.uploads = this.uploads.filter((upload) => upload.link !== url);
            let gallery = this.state.gallery.filter((x) => x.src !== url);
            let searchFilter = this.state.searches[
                this.state.search_term
            ]?.filter((x) => x !== url);
            let searches = this.state.searches;
            if (this.props.edit !== true) {
                searches[this.state.search_term] = searchFilter;
            } else {
                let keywords = Object.keys(this.state.searches);
                keywords.map((key) => {
                    searches[key] = searches[key].filter((x) => x !== url);
                });
            }
            this.setState({
                gallery: gallery,
                selected_options: selected_options,
                searches: searches,
            });
        }
    };

    getMeta = (url, callBack) => {
        var img = new Image();

        img.onload = function () {
            callBack(url, this.width, this.height);
        };
        img.src = url;
    };

    addToGallery = (url) => {
        this.getMeta(url, this.addImage);
    };

    removeFromGallery = (url) => {
        this.getMeta(url, this.removeImage);
    };

    imageRenderer = ({ index, left, top, key, photo }) => {
        return (
            <SearchUploadOption
                onClick={this.addToGallery}
                onRemove={this.removeFromGallery}
                value={photo.src}
                selected={this.state.selected_options.has(photo.src)}
                margin={"2px"}
                left={left}
                top={top}
                photo={photo}
                key={key}
            ></SearchUploadOption>
        );
    };

    render() {
        let google_images = this.props.google_search_results.map(function (x) {
            return { src: x, width: 1, height: 1 };
        });
        let insta_images = this.props.instagram_search_results.map(function (
            x
        ) {
            return { src: x, width: 1, height: 1 };
        });
        return (
            <Container fluid={true}>
                {this.props.edit !== true && (
                    <>
                        <ProgressBar
                            width={(this.props.step * 100) / this.props.stepMax}
                        ></ProgressBar>
                        <Row>
                            <BackButton></BackButton>
                            <Progress
                                step={this.props.step}
                                step_max={this.props.stepMax}
                            ></Progress>
                            <CancelButton></CancelButton>
                        </Row>
                    </>
                )}
                <Container>
                    <Row>
                        <h2 className='col-12' style={{ marginBottom: 5 }}>
                            Upload anything related to your idea
                        </h2>
                        <h4
                            className='col-12 question-h4'
                            style={{
                                textAlign: "center",
                                fontFamily: "sofia-pro-soft, sans-serif",
                            }}
                        >
                            This could be pictures of your space, inspirational
                            or relevenat designs. The more information you
                            provide, the sooner your designer will get it!
                        </h4>
                        {this.state.showTooltip && (
                            <h4>
                                Labore duis aliqua eiusmod fugiat nulla est id
                                quis velit cillum est id adipisicing sit.
                            </h4>
                        )}
                        <Col xs={12} lg={{ span: 10, offset: 1 }}>
                            <Row
                                style={{
                                    justifyContent: "center",
                                    marginBottom: 20,
                                }}
                            >
                                <Col
                                    xs={12}
                                    lg={7}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <i
                                        className='fa fa-pinterest fa-3x'
                                        aria-hidden='true'
                                        style={{
                                            color: "#c8232c",
                                            marginRight: 10,
                                        }}
                                    ></i>
                                    <FormControl
                                        aria-describedby='basic-addon1'
                                        placeholder='Add a pinterest board link'
                                        name='pinterest'
                                        value={this.state.pinterest}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={{ span: 6, offset: 3 }}>
                            <p
                                style={{
                                    borderBottom: "1px solid grey",
                                    lineHeight: "0.1rem",
                                    textAlign: "center",
                                    marginBottom: "2rem",
                                }}
                            >
                                <span
                                    style={{
                                        padding: "0.1rem 1rem 0.1rem 1rem",
                                        backgroundColor: "white",
                                    }}
                                >
                                    Or
                                </span>
                            </p>
                        </Col>
                        <Col
                            className='media-drop-wrapper'
                            xs={12}
                            lg={{ span: 6, offset: 3 }}
                            style={{ padding: "0rem" }}
                        >
                            <Dropzone onDrop={this.handleFilesAdd}>
                                {({ getRootProps, getInputProps }) => (
                                    <Container
                                        style={style.container}
                                        {...getRootProps()}
                                    >
                                        {this.state.gallery.length > 0 && (
                                            <Gallery
                                                photos={this.state.gallery}
                                                direction={"column"}
                                                columns={2}
                                                renderImage={this.imageRenderer}
                                            ></Gallery>
                                        )}
                                        <div
                                            style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            {this.state.otherDocuments
                                                .length !== 0 &&
                                                this.state.otherDocuments.map(
                                                    (doc, index) => (
                                                        <div className='other-documents-item'>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    flexDirection:
                                                                        "column",
                                                                    justifyContent:
                                                                        "center",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                <img
                                                                    src={close}
                                                                    style={{
                                                                        width: 12,
                                                                        height: 20,
                                                                        alignSelf:
                                                                            "flex-end",
                                                                    }}
                                                                    onClick={() => {
                                                                        let temp = this.state.otherDocuments.filter(
                                                                            (
                                                                                x
                                                                            ) =>
                                                                                x.link !=
                                                                                doc.link
                                                                        );
                                                                        this.setState(
                                                                            {
                                                                                otherDocuments: temp,
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                                <img
                                                                    src={
                                                                        [
                                                                            "mp3",
                                                                            "wav",
                                                                            "wma",
                                                                            "aac",
                                                                            "m4a",
                                                                            "flac",
                                                                        ].includes(
                                                                            doc.ext
                                                                        )
                                                                            ? audio
                                                                            : [
                                                                                  "webm",
                                                                                  "mpg",
                                                                                  "mp2",
                                                                                  "mpeg",
                                                                                  "mpe",
                                                                                  "mpv",
                                                                                  "ogg",
                                                                                  "mp4",
                                                                                  "m4p",
                                                                                  "m4v",
                                                                                  "avi",
                                                                                  "wmv",
                                                                              ].includes(
                                                                                  doc.ext
                                                                              )
                                                                            ? video
                                                                            : documentIcon
                                                                    }
                                                                    style={{
                                                                        width: 40,
                                                                        height: 40,
                                                                    }}
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: 80,
                                                                    height: 20,
                                                                    whiteSpace:
                                                                        "break-spaces",
                                                                    textOverflow:
                                                                        "ellipsis",
                                                                    overflow:
                                                                        "hidden",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                {doc.name}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                        <Row className='justify-content-md-center'>
                                            <input {...getInputProps()} />
                                            {this.props.uploading ? (
                                                <SyncLoader
                                                    css={spinnerStyle}
                                                    color={"#000000"}
                                                    sizeUnit={"em"}
                                                    size={"1"}
                                                    style={{ margin: "auto" }}
                                                ></SyncLoader>
                                            ) : (
                                                <Col>
                                                    <Col
                                                        xs={{
                                                            span: 4,
                                                            offset: 4,
                                                        }}
                                                    >
                                                        <img
                                                            src={cloudUpload}
                                                            className={
                                                                "img-fluid"
                                                            }
                                                        ></img>
                                                    </Col>
                                                    <p
                                                        style={{
                                                            fontSize: "1.2rem",
                                                            fontWeight: "400",
                                                            marginBottom:
                                                                "2rem",
                                                        }}
                                                    >
                                                        Drag and drop some files
                                                        here or
                                                    </p>
                                                    {/* <p style={{"borderBottom":"1px solid grey", "lineHeight":"0.1rem"}}><span style={{"padding":"0.1rem 1rem 0.1rem 1rem", "backgroundColor":"rgb(245, 245, 245)"}}>Or</span></p> */}
                                                    <Button
                                                        style={{
                                                            marginTop: "0rem",
                                                            textTransform:
                                                                "none",
                                                        }}
                                                    >
                                                        Select Files
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                    </Container>
                                )}
                            </Dropzone>
                        </Col>
                        <Col xs={{ span: 6, offset: 3 }}>
                            <p
                                style={{
                                    borderBottom: "1px solid grey",
                                    lineHeight: "0.1rem",
                                    textAlign: "center",
                                    marginTop: "2rem",
                                    marginBottom: "2rem",
                                }}
                            >
                                <span
                                    style={{
                                        padding: "0.1rem 1rem 0.1rem 1rem",
                                        backgroundColor: "white",
                                    }}
                                >
                                    Or
                                </span>
                            </p>
                        </Col>
                        <Col xs={12} lg={{ span: 6, offset: 3 }}>
                            <Row style={{ justifyContent: "center" }}>
                                <Col xs={12}>
                                    <InputGroup className='mb-3'>
                                        <FormControl
                                            aria-describedby='basic-addon1'
                                            name='search_term'
                                            placeholder='Search Google Images'
                                            value={this.state.search_term}
                                            style={{ height: "3rem" }}
                                            onChange={this.handleChange}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                className='search-upload-btn'
                                                variant='outline-secondary'
                                                style={{
                                                    borderTopRightRadius: "5px",
                                                    borderBottomRightRadius:
                                                        "5px",
                                                    height: "3rem",
                                                }}
                                                onClick={this.handleShow}
                                            >
                                                Search
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12}>
                            <Row className='justify-content-md-center'>
                                <Button
                                    variant='dark'
                                    onClick={this.handleSubmit}
                                    style={{ marginTop: 20 }}
                                >
                                    {this.props.edit !== true
                                        ? "Continue"
                                        : "Submit"}
                                </Button>
                            </Row>
                            {this.props.edit !== true && (
                                <Row className='justify-content-md-center'>
                                    <p
                                        className='skip-button'
                                        onClick={this.handleSkip}
                                    >
                                        SKIP THIS QUESTION
                                    </p>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Container>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Search Results</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Google Images</h5>
                        {this.props.google_search_results.length > 0 && (
                            <Gallery
                                photos={google_images.splice(0, 9)}
                                direction={"column"}
                                columns={3}
                                renderImage={this.imageRenderer}
                            ></Gallery>
                        )}
                        <h5>Instagram Images</h5>
                        {this.props.instagram_search_results.length > 0 && (
                            <Gallery
                                photos={insta_images.splice(0, 9)}
                                direction={"column"}
                                columns={3}
                                renderImage={this.imageRenderer}
                            ></Gallery>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='dark'
                            onClick={this.handleClose}
                            style={{ marginTop: 20 }}
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        uploads: state.canvas.uploads,
        pinterest: state.canvas.pinterest,
        searches: state.canvas.searches,
        nextPage: state.UI.flow["search-upload"],
        step: Object.keys(state.UI.flow).indexOf("search-upload") + 1,
        stepMax: Object.keys(state.UI.flow).length,
        canvas_id: state.canvas.id,
        google_search_results: state.UI.google_search_results,
        instagram_search_results: state.UI.instagram_search_results,
        uploading: state.loading.uploading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFile: (data) => dispatch(uploadFile(data)),
        searchInstagram: (keyword) => dispatch(searchInstagram(keyword)),
        searchGoogle: (keyword) => dispatch(searchGoogle(keyword)),
        addSearchesToCanvas: (keyword) =>
            dispatch(addSearchesToCanvas(keyword)),
        addImagesToCanvas: (keyword) => dispatch(addImagesToCanvas(keyword)),
        addPinterestLink: (data) => dispatch(addPinterestLink(data)),
        setLoading: (state) =>
            dispatch({ type: "SET_LOADING", key: "uploading", value: state }),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SearchPage));
