import React, { Component } from "react";
import { connect } from "react-redux";
import { css } from "@emotion/core";

import { Container, Row, Col, Button } from "react-bootstrap";
import { uploadFile, addImagesToCanvas } from "../../actions/uploadActions";
import { searchInstagram, searchGoogle } from "../../actions/searchActions";
import { withRouter } from "react-router-dom";
import ProgressBar from "../utilities/ProgressBar";
import BackButton from "../utilities/BackButton";
import Progress from "../utilities/Progress";
import CancelButton from "../utilities/CancelButton";
import ContinueButton from "../utilities/ContinueButton";

import "../../stylesheets/style.css";
import "../../stylesheets/search-upload.css";
import axios from "axios";
import { API_ENDPOINT } from "../../constants/secrets";
import SearchUploadOption from "./SearchUploadOption";
import Gallery from "react-photo-gallery";
import SyncLoader from "react-spinners/SyncLoader";
const spinnerStyle = css`
    margin-left: 50%;
    transform: translate(-50%, 0);
`;
class UploadPage extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.state = {
            showTooltip: false,
            show: false,
            gallery: [],
            selected_options: this.props.selected_options,
            search_term: "",
        };
        this.uploads = this.props.uploads;
    }

    componentDidMount() {
        let so = [...this.state.selected_options];
        console.log(so);
        so.forEach((url) => this.getMeta(url, this.addImage));
    }

    componentDidUpdate() {
        let so = [...this.state.selected_options];
        so.forEach((url) => this.getMeta(url, this.addImage));
    }

    handleChange = (e) => {
        e.preventDefault();

        this.setState({ [e.target.name]: e.target.value });
    };

    handleClose = () => this.setState({ show: false });

    handleShow = () => {
        this.props.searchInstagram(this.state.search_term);
        this.props.searchGoogle(this.state.search_term);

        this.setState({ show: true });
    };

    /**
     * Handle Submit - Continue button
     */
    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.uploads);
        if (this.uploads.length > 0) {
            const data = {
                uploads: [...this.uploads.map((upload) => upload.id)],
                id: this.props.canvas_id,
            };
            this.props.addImagesToCanvas(data);
        }
        this.props.history.push("/" + this.props.nextPage);
    };

    /**
     * Handle Skip - Skip button
     */
    handleSkip = (e) => {
        e.preventDefault();
        this.props.history.push("/" + this.props.nextPage);
    };

    handleUpload = (e) => {
        e.preventDefault();
        var file = this.fileInput.current.files[0];
        const formData = new FormData();
        formData.append("file", file, file.name);
        const UPLOAD_FILE_DATA_URI = `${API_ENDPOINT}/api/upload`;
        this.props.setLoading(true);
        axios
            .post(UPLOAD_FILE_DATA_URI, formData, { crossDomain: true })
            .then((response) => {
                // console.log(this.getAspectRatio(response.data.link))
                this.props.setLoading(false);
                this.getMeta(response.data.link, this.addImage);
                this.uploads.push(response.data);
                // this.setState({gallery: [...this.state.gallery, {src: response.data.link, height: 1, width: 1}]})
            })
            .catch((e) => {
                console.log(e);
                this.props.setLoading(false);
                console.log("Error");
            });
    };

    addImage = (url, width, height) => {
        console.log(this.props);
        console.log("Chanding state");
        if (this.state.gallery.filter((obj) => obj.src === url).length === 0) {
            let selected_options = this.state.selected_options;
            selected_options.add(url);
            this.setState({
                gallery: [
                    ...this.state.gallery,
                    { src: url, height: height, width: width },
                ],
                selected_options: selected_options,
            });
        }
    };

    removeImage = (url, width, height) => {
        if (this.state.selected_options.has(url)) {
            let selected_options = this.state.selected_options;
            selected_options.delete(url);
            this.uploads = this.uploads.filter((upload) => upload.link !== url);
            let gallery = this.state.gallery.filter((x) => x.src !== url);
            this.setState({
                gallery: gallery,
                selected_options: selected_options,
            });
        }
    };

    getMeta = (url, callBack) => {
        var img = new Image();

        img.onload = function () {
            console.log("Image Loaded");
            callBack(url, this.width, this.height);
        };
        img.src = url;
    };

    addToGallery = (url) => {
        console.log("Adding to gallery");
        console.log(this.props);
        this.getMeta(url, this.addImage);
    };

    removeFromGallery = (url) => {
        console.log("Removing to gallery");
        console.log(this.props);
        this.getMeta(url, this.removeImage);
    };

    imageRenderer = ({ index, left, top, key, photo }) => {
        return (
            <SearchUploadOption
                onClick={this.addToGallery}
                onRemove={this.removeFromGallery}
                value={photo.src}
                selected={this.state.selected_options.has(photo.src)}
                margin={"2px"}
                left={left}
                top={top}
                photo={photo}
                key={key}
            ></SearchUploadOption>
        );
    };

    render() {
        return (
            <Container fluid={true}>
                <ProgressBar
                    width={(this.props.step * 100) / this.props.stepMax}
                ></ProgressBar>
                <Row>
                    <BackButton></BackButton>
                    <Progress
                        step={this.props.step}
                        step_max={this.props.stepMax}
                    ></Progress>
                    {window.innerHeight < document.body.clientHeight && (
                        <ContinueButton
                            onSubmit={this.handleSubmit}
                        ></ContinueButton>
                    )}

                    {/* {window.innerHeight >= document.body.clientHeight && ( */}
                        <CancelButton></CancelButton>
                    {/* )} */}
                </Row>
                <Container>
                    <Row>
                        <h2 className="col-12 question-h2">Upload images</h2>
                        <h4
                            className="col-12 question-h4"
                            style={{ textAlign: "center" }}
                        >
                            Uploading an image of your space will help the
                            designer find the best fit
                        </h4>
                        <Col xs={12} lg={{ span: 6, offset: 3 }}>
                            <Row>
                                <Col xs={12} lg={{ span: 6, offset: 3 }}>
                                    <Button
                                        variant="dark"
                                        className="search-upload-btn"
                                    >
                                        <label htmlFor="fileinput">
                                            {this.props.uploading ? (
                                                <SyncLoader
                                                    css={spinnerStyle}
                                                    color={"#ffffff"}
                                                    sizeUnit={"em"}
                                                    size={"1"}
                                                ></SyncLoader>
                                            ) : (
                                                "Upload"
                                            )}
                                            <input
                                                id="fileinput"
                                                ref={this.fileInput}
                                                onChange={this.handleUpload}
                                                className="file-input"
                                                type="file"
                                            ></input>
                                        </label>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            className="gallery-wrapper"
                            xs={12}
                            lg={{ span: 6, offset: 3 }}
                        >
                            {this.state.gallery.length > 0 && (
                                <Gallery
                                    photos={this.state.gallery}
                                    direction={"column"}
                                    columns={3}
                                    renderImage={this.imageRenderer}
                                ></Gallery>
                            )}
                        </Col>
                        <Col xs={12}>
                            <Row className="justify-content-md-center">
                                <Button
                                    variant="dark"
                                    onClick={this.handleSubmit}
                                >
                                    Continue
                                </Button>
                            </Row>
                            <Row className="justify-content-md-center">
                                <p
                                    className="skip-button"
                                    onClick={this.handleSkip}
                                >
                                    SKIP THIS QUESTION
                                </p>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    console.log("Resetting props");
    let selected_options = [];
    try {
        if (state.canvas.uploads) {
            selected_options = state.canvas.uploads.map(
                (upload) => upload.link
            );
        }
    } catch (error) {
        selected_options = [];
    }

    return {
        uploads: state.canvas.uploads,
        nextPage: state.UI.flow["upload"],
        step: Object.keys(state.UI.flow).indexOf("upload") + 1,
        stepMax: Object.keys(state.UI.flow).length,
        canvas_id: state.canvas.id,
        google_search_results: state.UI.google_search_results,
        instagram_search_results: state.UI.instagram_search_results,
        selected_options: new Set(selected_options),
        uploading: state.loading.uploading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFile: (data) => dispatch(uploadFile(data)),
        searchInstagram: (keyword) => dispatch(searchInstagram(keyword)),
        searchGoogle: (keyword) => dispatch(searchGoogle(keyword)),
        addImagesToCanvas: (keyword) => dispatch(addImagesToCanvas(keyword)),
        setLoading: (state) =>
            dispatch({ type: "SET_LOADING", key: "uploading", value: state }),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UploadPage));
