import { API_ENDPOINT } from "../constants/secrets";
import { getCanvas } from "./canvasActions";
import axios from "axios";

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
};

const createCanvas = (pathway) => {
    return (dispatch) => {
        //Get UI data and store in database
        const CREATE_CANVAS_URI = `${API_ENDPOINT}/api/canvas`;
        var client_type = null;
        if (pathway === "Commercial") {
            client_type = "DESIGN";
        } else if (pathway === "Residential") {
            client_type = "REGULAR";
        }
        axios
            .post(CREATE_CANVAS_URI, { client_type: client_type })
            .then((response) => {
                // console.log(response)
                dispatch({
                    type: "CREATE_CANVAS",
                    id: response.data.id,
                    canvas: response.data.canvas,
                });
            })
            .catch((e) => {
                dispatch({ type: "ERROR_CREATE_CANVAS" });
            });
    };
};

const getInitData = () => {
    return (dispatch) => {
        //Get UI data and store in database
        const INIT_DATA_URI = `${API_ENDPOINT}/api/ui`;
        const INIT_RESIDENTIAL_QUESTIONS_URI = `${API_ENDPOINT}/api/question?tag=rq`;
        const INIT_COMMERCIAL_QUESTIONS_URI = `${API_ENDPOINT}/api/question?tag=cq`;

        //Contest Specific
        // const INIT_CODESIGN_QUESTIONS_URI = `${API_ENDPOINT}/api/question?tag=rq`
        const INIT_DESIGNER_QUESTIONS_URI = `${API_ENDPOINT}/api/question?tag=cdq`;

        const INIT_TEXT_MOODBOARD_URI = `${API_ENDPOINT}/api/question?tag=tm`;
        const INIT_VISUAL_MOODBOARD_URI = `${API_ENDPOINT}/api/question?tag=vm`;

        axios
            .get(INIT_RESIDENTIAL_QUESTIONS_URI)
            .then((response) => {
                // console.log(response)
                dispatch({
                    type: "ADD_INIT_DATA",
                    key: "questionsResidential",
                    value: response.data.questions,
                });
            })
            .catch((e) => {
                // console.log(e)
                dispatch({ type: "ERROR_INIT_CANVAS", data: {} });
            });

        axios
            .get(INIT_COMMERCIAL_QUESTIONS_URI)
            .then((response) => {
                // console.log(response)
                dispatch({
                    type: "ADD_INIT_DATA",
                    key: "questionsCommercial",
                    value: response.data.questions,
                });
            })
            .catch((e) => {
                // console.log(e)
                dispatch({ type: "ERROR_INIT_CANVAS", data: {} });
            });

        axios
            .get(INIT_DESIGNER_QUESTIONS_URI)
            .then((response) => {
                // console.log(response)
                dispatch({
                    type: "ADD_INIT_DATA",
                    key: "questionsCodesign",
                    value: response.data.questions,
                });
            })
            .catch((e) => {
                // console.log(e)
                dispatch({ type: "ERROR_INIT_CANVAS", data: {} });
            });

        axios
            .get(INIT_TEXT_MOODBOARD_URI)
            .then((response) => {
                // console.log(response)
                let text_moodboard = {};
                response.data.questions.forEach((question_data) => {
                    text_moodboard[
                        question_data.main
                    ] = question_data.options.map((x) => x.text);
                });
                dispatch({
                    type: "ADD_INIT_DATA",
                    key: "text_moodboard",
                    value: text_moodboard,
                });
            })
            .catch((e) => {
                // console.log(e)
                dispatch({ type: "ERROR_INIT_CANVAS", data: {} });
            });

        axios
            .get(INIT_VISUAL_MOODBOARD_URI)
            .then((response) => {
                // console.log("VIS MODDBOARD INIT DATA:", response);
                let visual_moodboard = {};
                response.data.questions.forEach((question_data) => {
                    visual_moodboard[
                        question_data.main
                    ] = question_data.options.map((x) => x.link);
                });
                // console.log("VIS MODDBOARD INIT DATA:", visual_moodboard);
                dispatch({
                    type: "ADD_INIT_DATA",
                    key: "visual_moodboard",
                    value: visual_moodboard,
                });
            })
            .catch((e) => {
                // console.log(e)
                dispatch({ type: "ERROR_INIT_CANVAS", data: {} });
            });
    };
};

export { createCanvas, getInitData };
