import React, { Component } from "react";

/**
 * Components
 */
import Option from "./Option";
import ProgressBar from "../utilities/ProgressBar";
import BackButton from "../utilities/BackButton";
import Progress from "../utilities/Progress";
import CancelButton from "../utilities/CancelButton";
// import ContinueButton from "../utilities/ContinueButton";

/**
 * Redux Actions
 */
import { createAnswer, editAnswer } from "../../actions/quizActions";

/**
 * Higher Level Components
 */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withAlert } from "react-alert";
/**
 * Style
 */
import { Container, Row, Col, Button } from "react-bootstrap";
import "../../stylesheets/style.css";

/**
 * Images and Media
 */
import OtherOption from "./OtherOption";

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: this.props.selectedOptions,
            mode: this.props.mode,
            answerId: this.props.answerId,
            otherOptionSelected: this.props.isOther,
            otherOptionText: this.props.other,
        };
    }

    /**
     * Handles form submit - Continue button
     */
    handleSubmit = (e) => {
        e.preventDefault();

        let data = {
            question: this.props.id,
            options: [...this.state.selectedOptions],
            id: this.props.canvasId,
            isOther: this.state.otherOptionSelected,
        };

        if (this.state.otherOptionSelected) {
            data.other = this.state.otherOptionText;
        }

        if (this.state.mode === "CREATE") {
            this.props.createAnswer(data);
        } else if (this.state.mode === "EDIT") {
            this.props.editAnswer(data);
        }
        this.setState({mode:'EDIT'})

        this.props.history.push(this.props.nextPage);
    };

    /**
     * Handle question skip - Skip button
     */
    handleSkip = (e) => {
        e.preventDefault();

        let data = {
            question: this.props.id,
            options: this.props.selectedOptions,
            id: this.props.canvasId,
            isOther: this.props.isOther,
        };

        if (this.state.mode === "CREATE") {
            this.props.createAnswer(data);
        } else if (this.state.mode === "EDIT") {
            this.props.editAnswer(data);
        }

        this.props.history.push(this.props.nextPage);
    };

    /**
     * Select/Deselect an option
     */
    toggleOption = (optionId) => {
        let selectedOptions = this.state.selectedOptions;
        let isAlreadySelected = selectedOptions.has(optionId);
        let maxSelectionsReached = this.props.limit <= selectedOptions.size;
        let isSingleOptionQuestion = this.props.limit === 1;

        if (isAlreadySelected) {
            selectedOptions.delete(optionId);
        } else if (!maxSelectionsReached) {
            selectedOptions.add(optionId);
        } else if (isSingleOptionQuestion) {
            selectedOptions = new Set([optionId]);
        } else if (maxSelectionsReached) {
            this.props.alert.show("You cannot choose more options");
        }

        this.setState({
            selectedOptions: selectedOptions,
        });
    };

    renderTopNavigation = () => {};

    toggleOtherOption = () => {
        this.setState({ otherOptionSelected: !this.state.otherOptionSelected });
    };

    handleOtherOption = (text) => {
        this.setState({ otherOptionText: text });
    };

    render() {
        let optionsRender = this.props.options.map((option, index) => (
            <Option
                key={index}
                id={option["_id"]}
                selected={this.state.selectedOptions.has(option["_id"])}
                onClick={this.toggleOption}
                text={option.text}
                link={option.link}
            />
        ));
        let otherOption = (
            <OtherOption
                selected={this.state.otherOptionSelected}
                onClick={this.toggleOtherOption}
                onValue={this.handleOtherOption}
                text={this.state.otherOptionText}
            ></OtherOption>
        );
        return (
            <Container fluid={true}>
                <ProgressBar
                    width={(this.props.step * 100) / this.props.stepMax}
                ></ProgressBar>
                <Row>
                    <BackButton></BackButton>
                    <Progress
                        step={this.props.step}
                        step_max={this.props.stepMax}
                    ></Progress>
                    <CancelButton></CancelButton>
                </Row>

                <Container>
                    <Row className="justify-content-md-center">
                        <h2 className="col-12 question-h2">
                            {this.props.qText}
                        </h2>
                        <h4
                            className="col-12 question-h4"
                            style={{ textAlign: "center" }}
                        >
                            {this.props.qHelpText}
                        </h4>
                        {optionsRender}
                        {otherOption}
                        <Col xs={12}>
                            <Row className="justify-content-md-center">
                                <Button
                                    variant="dark"
                                    onClick={this.handleSubmit}
                                >
                                    Continue
                                </Button>
                            </Row>
                            <Row className="justify-content-md-center">
                                {
                                    window.location.pathname!=='/q3' && window.location.pathname!=='/q4' && this.state.mode!=='EDIT' && (
                                        <p
                                            className="skip-button"
                                            onClick={this.handleSkip}
                                        >
                                            SKIP THIS QUESTION
                                        </p>
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

/**
 *
 * @param {*} state
 * @param {*} ownProps
 * Maps the Redux state to component props
 */
const mapStateToProps = (state, ownProps) => {
    const qNumber = ownProps.id;
    const question = state.UI.questions[qNumber - 1];
    const options = question.options;
    let quizAnswers =
        state.canvas.quizAnswers !== undefined
            ? state.canvas.quizAnswers.filter((q) =>
                  q.question ? q.question._id === question._id : false
              )
            : [];
    let selectedOptions = new Set([]);
    let mode = "CREATE";
    let answerId = undefined;
    let isOther = false;
    let other = "OTHER";

    if (quizAnswers.length > 0) {
        answerId = quizAnswers[0]._id;
        selectedOptions = new Set(
            quizAnswers[0].options.map((option) => option._id)
        );
        mode = "EDIT";
        isOther = quizAnswers[0].isOther;
        other = quizAnswers[0].other;
    }

    if (question.main && question.main.includes("budget")) {
        other = "Enter Budget";
    }

    return {
        //Question Specific
        qText: question.main,
        qHelpText: question.help_text,
        options: options,
        id: question._id,
        selectedOptions: selectedOptions,
        isOther,
        other,
        answerId: answerId,
        mode: mode,
        limit: question.limit ? question.limit : options.length,
        question: question,

        //General Function
        canvasId: state.canvas.id,

        //General UI
        nextPage: state.UI.flow["q" + qNumber],
        step: Object.keys(state.UI.flow).indexOf("q" + qNumber) + 1,
        stepMax: Object.keys(state.UI.flow).length,
    };
};

/**
 *
 * @param {*} dispatch
 * Maps redux actions to component props
 */
const mapDispatchToProps = (dispatch) => {
    return {
        createAnswer: (data) => dispatch(createAnswer(data)),
        editAnswer: (data) => dispatch(editAnswer(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withAlert()(Question)));
