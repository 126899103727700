import React from 'react';
import { Container, Modal, Button, Row, Col } from "react-bootstrap";
/**
 * Redux Actions
 */
import { editAnswer } from "../../actions/quizActions";
import Option from './../quiz/Option';
import OtherOption from './../quiz/OtherOption';
import {getOption} from './../../actions/optionsActions';
/**
 * Higher Level Components
 */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withAlert } from "react-alert";

import TextMoodboard from './../moodboard/TextMoodboard';
import VisualMoodboard from './../moodboard/VisualMoodboard';
import SearchUploadPage from '../search/SearchUploadPage';
import Story from '../story/Story';

class EditCanvas extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      question:[],
      options:[],
      selectedOptions: new Set(),
      mode: 'EDIT',
      answerId: this.props.data!==undefined && this.props.data._id,
      otherOptionSelected: false,
      otherOptionText: 'OTHER',
      quizAnswer:undefined,
      limit:0 ,
    };
  }
  
  componentDidMount(){
    if(this.props.contentType==='ANSWER')
    {
      this.setState({question:this.props.data.question, limit:this.props.data.question.limit ? this.props.data.question.limit : this.props.data.question.options.length})
      if (this.state.question.main && this.state.question.main.includes("budget")) {
        this.setState({otherOptionText:"Enter Budget"})
      }
      this.props.quizAnswers!==undefined && this.props.quizAnswers.map((q)=>{
        q.question._id === this.props.data.question._id && this.setState({quizAnswer:q}, ()=>{
          this.setState({
            otherOptionSelected:this.state.quizAnswer.isOther,
            otherOption:this.state.quizAnswer.other,
          })
        })
      })
  
      this.props.data.options.map((option)=>{
        let temp = this.state.selectedOptions;
        temp.add(option._id)
        this.setState({selectedOptions:temp})
      })
  
      this.props.data.question.options.map((option) => {
        getOption({'id':option})
        .then((response)=>{
          this.setState({options:[...this.state.options, response.data.option]})
        })
        
      })
    }
    console.log('this.props.contentType',this.props.contentType)
    
  }

  /**
     * Select/Deselect an option
     */
    toggleOption = (optionId) => {
      let selectedOptions = this.state.selectedOptions;
      let isAlreadySelected = this.state.selectedOptions.has(optionId);
      let maxSelectionsReached = this.state.limit <= this.state.selectedOptions.size;
      let isSingleOptionQuestion = this.state.limit === 1;

      if (isAlreadySelected) {
          selectedOptions.delete(optionId);
      } else if (!maxSelectionsReached) {
          selectedOptions.add(optionId);
      } else if (isSingleOptionQuestion) {
          selectedOptions = new Set([optionId]);
      } else if (maxSelectionsReached) {
          this.props.alert.show("You cannot choose more options");
      }

      this.setState({
          selectedOptions: selectedOptions,
      });
  };

  toggleOtherOption = () => {
    this.setState({ otherOptionSelected: !this.state.otherOptionSelected });
  };

  handleOtherOption = (text) => {
      this.setState({ otherOptionText: text });
  };

  optionsRender = () => {
    let options = this.state.options.map((option, index) =>(
      <Option
        key={index}
        id={option["_id"]}
        selected={this.state.selectedOptions.has(option["_id"])}
        onClick={this.toggleOption}
        text={option.text}
        link={option.link}
      />
    ));

    return options;
  }

  otherOption = () => {
    return(
      <OtherOption
          selected={this.state.otherOptionSelected}
          onClick={this.toggleOtherOption}
          onValue={this.handleOtherOption}
          text={this.state.otherOptionText}
      ></OtherOption>
    )
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let data = {
      question: this.props.data.question._id,
      options: [...this.state.selectedOptions],
      id: this.props.data._id,
      isOther: this.state.otherOptionSelected,
      canvasId:this.props.canvasId
    };

    if (this.state.otherOptionSelected) {
      data.other = this.state.otherOptionText;
    }

    this.props.editAnswer(data);

    this.props.toggleModal()
  }

  editAnswer = () => {
    const {question} = this.state;
    return (
      <Container>
        <Row className="justify-content-md-center">
            <h2 className="col-12 question-h2">
                {question.main}
            </h2>
            <h4
                className="col-12 question-h4"
                style={{ textAlign: "center" }}
            >
                {question.help_text}
            </h4>
            {this.optionsRender()}
            {this.otherOption()}
            <Col xs={12}>
                <Row className="justify-content-md-center">
                    <Button
                        variant="dark"
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </Button>
                </Row>
            </Col>
        </Row>
    </Container>
    )
  }

  textMoodboard = () => {
    return(
      <TextMoodboard edit={true} toggleModal={this.props.toggleModal}></TextMoodboard>
    )
  }

  visualMoodboard = () => {
    return(
      <VisualMoodboard edit={true} toggleModal={this.props.toggleModal}></VisualMoodboard>
    )
  }

  story = () => {
    return(
      <Story edit={true} toggleModal={this.props.toggleModal}></Story>
    )
  }

  searchUpload = () => {
    return(
      <SearchUploadPage edit={true} toggleModal={this.props.toggleModal}/>
    )
  }

  renderData = () => {
    switch(this.props.contentType){
      case 'ANSWER':
        return this.editAnswer()
      case 'TEXT_MOODBOARD':
        return this.textMoodboard()
      case 'VISUAL_MOODBOARD':
        return this.visualMoodboard()
      case 'SEARCH':
        return this.searchUpload()
      case 'UPLOAD':
        return this.searchUpload()
      case 'PINTEREST':
        return this.searchUpload()
      case 'STORY':
        return this.story()
      default:
        break;
    }
  }

  render(){
    return(
      <Modal
        size="xl"
        show={this.props.isOpen}
        onHide={()=>this.props.toggleModal()}
        aria-labelledby="example-modal-sizes-title-sm"
        id="instructions-modal"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body
          style={{padding:'0px'}}
        >
          {this.renderData()}
        </Modal.Body>
    </Modal>
    )
  }
}

/**
 *
 * @param {*} state
 * @param {*} ownProps
 * Maps the Redux state to component props
 */
const mapStateToProps = (state, ownProps) => {
  let quizAnswers = state.canvas.quizAnswers 

  return {
    quizAnswers,

      //General Function
      canvasId: state.canvas.id,
  };
};

/**
 *
 * @param {*} dispatch
 * Maps redux actions to component props
 */
const mapDispatchToProps = (dispatch) => {
    return {
        editAnswer: (data) => dispatch(editAnswer(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withAlert()(EditCanvas)));