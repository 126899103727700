import { API_ENDPOINT } from  '../constants/secrets'
import { config } from '../services/cookieHelpers'
import axios from 'axios'

export const setCanvas = (data) => 
{
    return (dispatch) => {
        
        dispatch({ type: 'SET_CANVAS_ID', data: data});
    }
}

export const updateUser = (data) => 
{
  
    return (dispatch) => {
        const UPDATE_USER_DATA_URI = `${API_ENDPOINT}/users/update`

        axios.put(UPDATE_USER_DATA_URI, data, config)
        .then(response => response.data).then(response => {
            console.log(response)
            if(response.status)
            {
                dispatch({type: "REFRESH_USER", id: response.user._id, user: response.user})
            }
            else
            {
                dispatch({type: "ERROR_UPDATE_USER", message:response.message})
            }
        }).catch(e => {
            console.log(e)
            dispatch({type: "ERROR_UPDATE_USER", message:"Our systems are experiencing issues. Please try again after some time"})
        })

        dispatch({ type: 'UPDATE_USER_INIT', data: data});
    }
}

export const sendVerificationEmail = (id) => {
    axios.get(`${API_ENDPOINT}/users/verify`, {params: { id }})
        .then(response => {
            console.log(response)
        })
        .catch(e => {
            console.log(e.response)
        })
}

// DISPATCH VERIFY_SUCCESS, VERIFY_FAILURE, UPDATE USER STATE IN REDUCER
export const verifyToken = (token, id) => {

    return (dispatch) => {

        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/verify`, {token, id})
            .then(response => {
                
                dispatch({
                    type: "VERIFY_SUCCESS"
                })

                resolve();
                
            })
            .catch(e => {
                console.log(e.response);
                dispatch({
                    type: "VERIFY_ERROR",
                    message: e.response.message
                })

                reject();
            })
        })
        
    }

}

