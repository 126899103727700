import React, { Component } from 'react'
import {Container, Row, Image, Col, Button} from 'react-bootstrap'
import '../../stylesheets/option.css'
import checkmark from '../../images/icons/selected.svg'
import bg1 from '../../images/icons/bg_1.svg'
import bg2 from '../../images/icons/bg_2.svg'
import bg3 from '../../images/icons/bg_3.svg'
class Option extends Component{

    constructor(props)
    {
        super(props)
        this.state = {
            mouseOver: false
        }
    }
    handleSelect = (e) => {
        e.preventDefault()
        this.props.onClick(this.props.id)
    }

    render()
    {
        let style = {}
        let bgimg = bg1
        if(this.props.selected)
        {
            style = {"filter":"invert(1)"}
            bgimg = [bg1, bg2, bg3][this.props.text.length%3];
        }
        return (
            <Col xs={{span: 6, offset:0}} sm={{span: 4, offset:0}}  md={{span: 3, offset:0}} xl={{span: 2, offset:0}} onClick={this.handleSelect} onMouseOver={() => this.setState({mouseOver: true})} onMouseLeave={() => this.setState({mouseOver: false})}>
                <Col xs={12} className="option-wrapper">                    
                    {this.props.selected && <img src={checkmark} style={{"height": "20px", "position":"absolute", "top":"0", "right": "0"}}></img>}
                    <Row className='justify-content-md-center'>
                        <Col xs={12}>
                            <img alt="" src = {bg1} style = {{"display"   : "none"}} />
                            <img alt="" src = {bg2} style = {{"display"   : "none"}} />
                            <img alt="" src = {bg3} style = {{"display"   : "none"}} />
                            {this.state.mouseOver && <img src={bgimg} style={{"position":"absolute", "top":"-1rem", "left": "0", "opacity": "0.3"}} fluid></img>}
                            {this.props.selected && <img src={bgimg} style={{"position":"absolute", "top":"-1rem", "left": "0"}} fluid></img>}
                            <Image className="option-img" src={this.props.link} style={style} fluid></Image>
                        </Col>

                        <p className="col-12">{this.props.text}</p>
                    </Row>
                </Col>
            </Col>
        )
    }
}

export default Option;