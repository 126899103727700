import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Container,
    Row,
    Col,
    Button,
    InputGroup,
    FormControl,
} from "react-bootstrap";
import { uploadFile, addSearchesToCanvas } from "../../actions/uploadActions";
import { searchInstagram, searchGoogle } from "../../actions/searchActions";
import { withRouter } from "react-router-dom";
import ProgressBar from "../utilities/ProgressBar";
import BackButton from "../utilities/BackButton";
import Progress from "../utilities/Progress";
import CancelButton from "../utilities/CancelButton";
import ContinueButton from "../utilities/ContinueButton";

import "../../stylesheets/style.css";
import "../../stylesheets/search-upload.css";
import axios from "axios";
import { API_ENDPOINT } from "../../constants/secrets";
import SearchUploadOption from "./SearchUploadOption";
import Gallery from "react-photo-gallery";
import ClipLoader from "react-spinners/ClipLoader";

class SearchPage extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();

        this.state = {
            showTooltip: false,
            show: false,
            gallery: [],
            selected_options: this.props.selected_options,
            search_term: "",
            searches: {},
        };
        this.uploads = [];
    }

    componentDidMount() {
        let so = [...this.state.selected_options];
        console.log(so);
        so.forEach((url) => this.getMeta(url, this.addImage));
    }

    componentDidUpdate() {
        let so = [...this.state.selected_options];
        console.log(so);

        so.forEach((url) => this.getMeta(url, this.addImage));
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    };

    handleClose = () => this.setState({ show: false });

    handleShow = () => {
        this.props.searchInstagram(this.state.search_term);
        this.props.searchGoogle(this.state.search_term);
        if (
            Object.keys(this.state.searches).indexOf(this.state.search_term) < 0
        ) {
            this.setState({
                searches: {
                    ...this.state.searches,
                    [this.state.search_term]: [],
                },
            });
        }
    };

    /**
     * Handle Submit - Continue Button
     */

    handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            searches: this.state.searches,
            id: this.props.canvas_id,
        };
        this.props.addSearchesToCanvas(data);
        this.props.history.push("/" + this.props.nextPage);
    };

    /**
     * Handle Skip - Skip button
     */

    handleSkip = (e) => {
        e.preventDefault();
        const data = {
            searches: {},
            id: this.props.canvas_id,
        };
        this.props.addSearchesToCanvas(data);
        this.props.history.push("/" + this.props.nextPage);
    };

    handleUpload = (e) => {
        e.preventDefault();
        var file = this.fileInput.current.files[0];
        const formData = new FormData();
        formData.append("file", file, file.name);
        const UPLOAD_FILE_DATA_URI = `${API_ENDPOINT}/api/upload`;

        axios
            .post(UPLOAD_FILE_DATA_URI, formData, { crossDomain: true })
            .then((response) => {
                // console.log(this.getAspectRatio(response.data.link))
                this.getMeta(response.data.link, this.addImage);
                this.uploads.push(response.data);
                // this.setState({gallery: [...this.state.gallery, {src: response.data.link, height: 1, width: 1}]})
            })
            .catch((e) => {
                console.log(e);
                console.log("Error");
            });

        // this.props.uploadFile(formData)
    };

    addImage = (url, width, height) => {
        if (this.state.gallery.filter((obj) => obj.src == url).length == 0) {
            let selected_options = this.state.selected_options;
            selected_options.add(url);
            let links = this.state.searches[this.state.search_term];
            if (links) {
                links.push(url);
            } else {
                links = [url];
            }
            console.log("Adding to stae");
            this.setState({
                gallery: [
                    ...this.state.gallery,
                    { src: url, height: height, width: width },
                ],
                selected_options: selected_options,
                searches: {
                    ...this.state.searches,
                    [this.state.search_term]: links,
                },
            });
        }
    };

    removeImage = (url, width, height) => {
        if (this.state.selected_options.has(url)) {
            let selected_options = this.state.selected_options;
            selected_options.delete(url);
            this.uploads = this.uploads.filter((upload) => upload.link != url);
            let gallery = this.state.gallery.filter((x) => x.src != url);
            this.setState({
                gallery: gallery,
                selected_options: selected_options,
            });
        }
    };

    getMeta = (url, callBack) => {
        var img = new Image();

        img.onload = function () {
            console.log("Image Loaded");
            callBack(url, this.width, this.height);
        };
        img.src = url;
    };

    addToGallery = (url) => {
        console.log("Adding to gallery");
        console.log(this.props);
        this.getMeta(url, this.addImage);
    };

    removeFromGallery = (url) => {
        console.log("Removing to gallery");
        console.log(this.props);
        this.getMeta(url, this.removeImage);
    };

    imageRenderer = ({ index, left, top, key, photo }) => {
        return (
            <SearchUploadOption
                onClick={this.addToGallery}
                onRemove={this.removeFromGallery}
                value={photo.src}
                selected={this.state.selected_options.has(photo.src)}
                margin={"2px"}
                left={left}
                top={top}
                photo={photo}
                key={key}
            ></SearchUploadOption>
        );
    };

    render() {
        let google_images = this.props.google_search_results.map(function (x) {
            return { src: x, width: 1, height: 1 };
        });
        let insta_images = this.props.instagram_search_results.map(function (
            x
        ) {
            return { src: x, width: 1, height: 1 };
        });
        return (
            <Container fluid={true}>
                <ProgressBar
                    width={(this.props.step * 100) / this.props.stepMax}
                ></ProgressBar>
                <Row>
                    <BackButton></BackButton>
                    <Progress
                        step={this.props.step}
                        step_max={this.props.stepMax}
                    ></Progress>
                    {window.innerHeight < document.body.clientHeight && (
                        <ContinueButton
                            onSubmit={this.handleSubmit}
                        ></ContinueButton>
                    )}

                    {/* {window.innerHeight >= document.body.clientHeight && ( */}
                        <CancelButton></CancelButton>
                    {/* )} */}
                </Row>
                <Container>
                    <Row>
                        <h2 className="col-12 question-h2">
                            Search for Images
                        </h2>
                        <h4
                            className="col-12 question-h4"
                            style={{ textAlign: "center" }}
                        ></h4>
                        <Col xs={12} lg={{ span: 6, offset: 3 }}>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            aria-describedby="basic-addon1"
                                            name="search_term"
                                            value={this.state.search_term}
                                            onChange={this.handleChange}
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                className="search-upload-btn"
                                                variant="outline-secondary"
                                                onClick={this.handleShow}
                                            >
                                                Search
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            className="gallery-wrapper selected"
                            xs={12}
                            lg={{ span: 6 }}
                        >
                            <Col xs={12}>
                                <h5 style={{ textAlign: "center" }}>
                                    Selected Images
                                </h5>
                            </Col>
                            {this.state.gallery.length > 0 && (
                                <Gallery
                                    photos={this.state.gallery}
                                    direction={"column"}
                                    columns={3}
                                    renderImage={this.imageRenderer}
                                ></Gallery>
                            )}
                        </Col>
                        <Col
                            className="gallery-wrapper"
                            xs={12}
                            lg={{ span: 6 }}
                        >
                            <Col xs={12}>
                                <h5 style={{ textAlign: "center" }}>
                                    Search Results
                                </h5>
                            </Col>

                            {this.props.loading_google_search && (
                                <p>
                                    <span>
                                        <ClipLoader
                                            sizeUnit={"rem"}
                                            size={1}
                                            // color={'#123abc'}
                                            loading={this.state.loading}
                                        />
                                    </span>{" "}
                                    Searching Google...
                                </p>
                            )}
                            {this.props.loading_instagram_search && (
                                <p>
                                    <span>
                                        <ClipLoader
                                            sizeUnit={"rem"}
                                            size={1}
                                            // color={'#123abc'}
                                            loading={this.state.loading}
                                        />
                                    </span>{" "}
                                    Searching Instagram...
                                </p>
                            )}
                            {this.props.instagram_search_results.length > 0 && (
                                <Gallery
                                    photos={insta_images.splice(0, 9)}
                                    direction={"column"}
                                    columns={3}
                                    renderImage={this.imageRenderer}
                                ></Gallery>
                            )}
                            {this.props.google_search_results.length > 0 && (
                                <Gallery
                                    photos={google_images.splice(0, 9)}
                                    direction={"column"}
                                    columns={3}
                                    renderImage={this.imageRenderer}
                                ></Gallery>
                            )}
                        </Col>

                        <Col xs={12}>
                            <Row className="justify-content-md-center">
                                <Button
                                    variant="dark"
                                    onClick={this.handleSubmit}
                                >
                                    Continue
                                </Button>
                            </Row>
                            <Row className="justify-content-md-center">
                                <p
                                    className="skip-button"
                                    onClick={this.handleSkip}
                                >
                                    SKIP THIS QUESTION
                                </p>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    console.log("Resetting props");
    let selected_options = [];
    if (state.canvas.searches) {
        Object.keys(state.canvas.searches).forEach(
            (key) =>
                (selected_options = [
                    ...selected_options,
                    ...state.canvas.searches[key],
                ])
        );
    }
    return {
        uploads: state.UI.uploads,
        nextPage: state.UI.flow["search"],
        step: Object.keys(state.UI.flow).indexOf("search") + 1,
        stepMax: Object.keys(state.UI.flow).length,
        canvas_id: state.canvas.id,
        google_search_results: state.UI.google_search_results,
        instagram_search_results: state.UI.instagram_search_results,
        loading_google_search: state.loading.google_search,
        loading_instagram_search: state.loading.instagram_search,
        searches: state.canvas.searches,
        selected_options: new Set(selected_options),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFile: (data) => dispatch(uploadFile(data)),
        searchInstagram: (keyword) => dispatch(searchInstagram(keyword)),
        searchGoogle: (keyword) => dispatch(searchGoogle(keyword)),
        addSearchesToCanvas: (keyword) =>
            dispatch(addSearchesToCanvas(keyword)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SearchPage));
