import { API_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'
import { getCanvas } from './canvasActions'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const saveSketch = (data) => 
{

    return (dispatch) => {
        //Create Answer
        const CREATE_SKETCH_DATA_URI = `${API_ENDPOINT}/api/sketch`
        const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas/sketch`

        axios.post(CREATE_SKETCH_DATA_URI, data, config)
            .then(response => {
                console.log(response)
                data["sketch"] = response.data.id
                axios.post(UPDATE_CANVAS_DATA_URI, data)
                .then(response => {
                    console.log("Added sketch to canvas")
                    dispatch(getCanvas())
                })
                .catch(e => {
                    console.log(e)
                    console.log("Error")
                })
            }).catch(e => {
                console.log(e)
                console.log("Error")
            })
        dispatch({ type: 'ADD_SKETCH', data});
    }
}

export const editSketch = (data) => 
{
    return (dispatch) => {
        const CREATE_SKETCH_DATA_URI = `${API_ENDPOINT}/api/sketch`
        const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas/sketch`
        axios.put(CREATE_SKETCH_DATA_URI, data, config)
            .then(response => {
                dispatch(getCanvas())
            }).catch(e => {
                console.log(e)
            })
        dispatch({ type: 'EDIT_ANSWER', data});
    }
}

export const deleteSketch = (data) => 
{
    return (dispatch) => {
        //Create Answer
        const DELETE_SKETCH_DATA_URI = `${API_ENDPOINT}/api/sketch`
        console.log(data)
        axios.delete(DELETE_SKETCH_DATA_URI, {params:data}, config)
            .then(response => {
                dispatch(getCanvas())
            }).catch(e => {
                console.log(e)
            })
        dispatch({ type: 'DELETE_ANSWER', data});
    }
}