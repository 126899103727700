const loadState = () => {
    try {
        const serializedData = localStorage.getItem("nayaAppState");
        const restoreData = localStorage.getItem("nayaAppStateRestore");
        // if restore not needed skip restore
        if (!restoreData || restoreData === "false") return undefined;
        if (serializedData === null) {
            return undefined;
        }
        const state = JSON.parse(serializedData);

        return state;
    } catch (err) {
        console.log(err);
        return undefined;
    }
};

export default loadState;
