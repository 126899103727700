/** React and Redux */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { withRouter }       from 'react-router-dom'

/** Redux Actions */
import { loginToken }   from '../../actions/authActions'
import { getInitData }  from '../../actions/getInitData'
import { createCanvas } from '../../actions/getInitData'

/** Components */
import NavBar from '../utilities/NavBar'

/** Layout and Style */
import { Container, Row, Col, Image } from 'react-bootstrap'
import '../../stylesheets/style.css'

/** Images and Media */
import ResidentialIcon  from '../../images/icons/residential.svg'
import CommercialIcon   from '../../images/icons/commercial.svg'

import DesignerIcon  from '../../images/icons/designer.svg'
import NonDesignerIcon   from '../../images/icons/nonDesigner.svg'

import bg1              from '../../images/icons/bg_1.svg'

class Competition extends Component{
    /** Empty Constructor */
    constructor(props)
    {
        super(props)
        this.state = {optionSelected: false}
    }

    /** Called when DOM mounted */
    componentDidMount = () =>
    {

        this.props.getInitData()
        let JWTToken = localStorage.getItem('nayaJwtToken')
        if(JWTToken!== undefined)
        {
            this.props.loginToken(JWTToken)
        }
    }

    handleSubmit = (e) => {
        // e.preventDefault()
        // console.log(e.target.value)
        this.props.createCanvas()
        this.props.setPathway(e)
        console.log(this.props.flow)
        this.setState({optionSelected: true})
        // this.props.history.push(this.props.flow['landing'])
    }

    /** Renders option */
    renderOption = (optionText, optionImg, tag) =>
    {
        let style = {"borderRadius":"0"}

        // if(this.state[`mouseOver${tag}`])
        // {
        //     style["filter"] = "invert(1)"
        // }

        return <Col xs      = {{span:8, offset:2}} 
                    md      = {{span:4, offset:1}}
                    onClick = {() => this.handleSubmit(tag)}>

                <Col    xs              = {{span: 12, offset:0}} 
                        onMouseOver     = {() => this.setState({[`mouseOver${tag}`]: true})} 
                        onMouseLeave    = {() => this.setState({[`mouseOver${tag}`]: false})}>
                            
                            <img    src     = {bg1} 
                                    alt     = ""
                                    style   = {{
                                                "position"  : "absolute", 
                                                "top"       : "0rem", 
                                                "left"      : "0", 
                                                "height"    : "100%", 
                                                "width"     : "100%",
                                                "display"   : "none"
                                            }} />
                            {this.state[`mouseOver${tag}`] && 
                            <Image    src     = {bg1} 
                                    alt = ""
                                    style   = {{
                                                "position"  : "absolute", 
                                                "top"       : "0rem", 
                                                "left"      : "0", 
                                                "height"    : "100%", 
                                                "width"     : "100%", 
                                                "opacity"   : "0.3"
                                            }} 
                                    rel="preload"
                                    fluid />}
                            
                            <Image  className   = "landing-option-img" 
                                    src         = {optionImg} 
                                    style       = {style} 
                                    fluid />
                </Col>

                <Col xs={12}>
                    <p className="col-12 landing-option">
                        {optionText}
                    </p>
                </Col>

            </Col>
    }

    /** Main DOM Render method */
    render()
    {
        return (

            <Container fluid>
                <NavBar></NavBar>
                <Container>
                <Row style={{"position": "relative"}}>
                    <Col xs={12} lg={{span: 8, offset:2}} className="vertical-center">
                        <Row>
                            <h2 className="col-12">
                                Which track are you on?
                            </h2>
                            {this.renderOption("I am not a designer", NonDesignerIcon, 'Codesign')}
                            {this.renderOption("I am a designer", DesignerIcon, 'Designer')}
                        </Row>
                    </Col>
                </Row>
                </Container>
            </Container>
        )
    }

    componentDidUpdate()
    {
        console.log(this.props.flow)
        console.log(this.props.questions)
        if(this.props.flow['landing'] && this.props.questions && this.state.optionSelected)
        {
            this.props.history.push(this.props.flow['landing'])
        }
    }
}

/** Maps redux state to component props */
const mapStateToProps = (state, ownProps) => {
    
    return { 
        history: ownProps["history"],
        flow: state.UI.flow,
        questions: state.UI.questions
    }
}

/** Maps redux ations to component props */
const mapDispatchToProps = (dispatch) => {
    
    return { 
        getInitData     : ()        => dispatch(getInitData()), 
        createCanvas    : ()        => dispatch(createCanvas()),
        loginToken      : (data)    => dispatch(loginToken(data)),
        setPathway      : (data)    => dispatch({type: "SET_PATHWAY", tag: data})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Competition))