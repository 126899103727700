import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom'

import { Provider } from "react-redux";
import { createStore, applyMiddleware,compose } from "redux";
import rootReducer from "./reducers/root";
import { transitions, positions, Provider as AlertProvider } from "react-alert";

import AlertTemplate from "react-alert-template-basic";
import thunk from "redux-thunk";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import loadState from "./loadState";

// get state if stored locally
const loadedState = loadState();
const composeEnhancers = (process.env.NODE_ENV === 'development') && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
const store = createStore(rootReducer, loadedState, composeEnhancers(applyMiddleware(thunk)));

// optional cofiguration
const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: "1rem",
    // you can also just use 'scale'
    transition: transitions.SCALE,
};

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <AlertProvider template={AlertTemplate} {...options}>
                <App />
            </AlertProvider>
        </Provider>
    </Router>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
