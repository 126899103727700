import { API_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'
import { getCanvas } from './canvasActions'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  export const addShippingAddress = (data) => {
    return (dispatch) => {
        const ADD_SHIPPING_ADDRESS = `${API_ENDPOINT}/api/shippingaddress`;
        const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas/shippingaddress`;

        axios
        .post(ADD_SHIPPING_ADDRESS, {user_id: data.user_id,address: data.address}, { crossDomain: true })
        .then((response) => {
            console.log("Submitted new address");
            console.log("RESPONSE",response.data);
            let body = {
                id : data.canvas_id,
                shipping : response.data.address._id
            }
            axios.post(UPDATE_CANVAS_DATA_URI, body)
            .then(response => {
                console.log(response)
                console.log("Added dimensions to canvas")
                dispatch(getCanvas())
            })
            .catch(e => {
                console.log(e)
                console.log("Error")
            })
        })
        .catch((e) => {
            console.log("Error posting address");
            console.log(e);
        });
        dispatch({ type: 'ADD_SHIPPING', data});
    };
};

export const addExistingAddress = (data) => {
    return (dispatch) => {
        const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas/shippingaddress`;
            axios.post(UPDATE_CANVAS_DATA_URI, data)
            .then(response => {
                console.log(response)
                console.log("Added dimensions to canvas")
                dispatch(getCanvas())
            })
            .catch(e => {
                console.log(e)
                console.log("Error")
            })
            dispatch({ type: 'ADD_SHIPPING', data});
    }
}

export const deleteAddress = (data) => 
{
    return (dispatch) => {
        const DELETE_ADDRESS_DATA_URI = `${API_ENDPOINT}/api/shippingaddress`
        console.log(data)
        axios.delete(DELETE_ADDRESS_DATA_URI, {params:data}, config)
            .then(response => {
                dispatch(getCanvas())
            }).catch(e => {
                console.log(e)
            })
        dispatch({ type: 'DELETE_ANSWER', data});
    }
}