import { API_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'
import path from 'path'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const getCanvas = (data) => 
{
    return (dispatch) => {

        //Create Answer
        const GET_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas`
        if(data==undefined && localStorage.getItem('nayaCanvasID'))
        {
            data = {id: localStorage.getItem('nayaCanvasID')}
        }

        axios.get(GET_CANVAS_DATA_URI, {params: data} , config)
            .then(response => {
                // console.log(response)
                dispatch({ type: 'SET_CANVAS', data: response.data.canvas})
                dispatch({
                    type: "SET_APPROVAL_COMMENTS",
                    approval_comments: response.data.canvas.approval_comments,
                    id: data.id,
                });
            }).catch(e => {
                // console.log(e)
            })

        // dispatch({ type: 'SET_CANVAS', data: {
        //     dimensions: {
        //         length: 300,
        //         width: 300,
        //         height: 300
        //     }
        // }});
    }
}

export const updateCanvas = (data) => 
{

    return (dispatch) => {
        //Create Answer
        const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas`

        axios.put(UPDATE_CANVAS_DATA_URI, data, config)
            .then(response => {
                console.log(response)
                dispatch({ type: 'ADD_PATHWAY', data});
            }).catch(e => {
                console.log("Error")
            })
    }
}