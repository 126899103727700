import React from 'react';
import {Image, Container} from 'react-bootstrap';
import logo from '../../images/contest/logo.png';
import idea from '../../images/contest/idea.png';
import guidelines from '../../images/contest/guidelines.png';
import jury1 from '../../images/contest/jury1.png';
import jury2 from '../../images/contest/jury2.png';
import '../../stylesheets/contest.css';

class ContestPage extends React.Component{

  hero = () => {
    return(
      <Container id="hero" style={{background:'none'}}>
        <div className="introduction-text">
          <div className="text-container">
            <h6 style={{marginBottom:15, color:'#000000'}}>Naya Studio</h6>
            <h1 style={{marginBottom:15, color:'#000000'}}>Furniture Design Competition 2020</h1>
            <h6 style={{marginBottom:0, color:'#000000'}}>01 June - 15 July 2022</h6>
            <button className="btn" style={{backgroundColor:'#000000', color:'#FFFFFF'}}>Register now</button>
          </div>
        </div>
        <div className="logo-container">
          <Image src={logo} alt="logo" className="logo" fluid/>
        </div>
      </Container>
    )
  }

  submitIdea = () => {
    return(
      <div style={{backgroundColor:'#FFFFFF'}}>
      <Container id="submit-idea">
        <div className="submit-idea-image-conatiner">
          <Image src={idea} alt="idea" className="img" fluid/>
        </div>
        <div className="text-container-wrapper">
          <div className="text-container">
            <h1>Submit an idea</h1>
            <h6>It’s all about process. We will accept early stage sketches and final models. At naya, we believe everyone can be creative and contribute.</h6>
          </div>
        </div>
      </Container>
      </div>
    )
  }

  callForVisionary = () => {
    return(
      <div id="call-for-visionary">
        <Container style={{display:'flex'}}>
          <h1>This is a call for visionary designs that push the boundaries of originality and innovation in furniture during a time of crisis.</h1>
        </Container>
      </div>
    )
  }

  designGuidelines = () => {
    return(
      <div style={{backgroundColor:'#FFFFFF'}}>
      <Container id="design-guidelines">
        <div className="introduction-text">
          <div className="text-container">
            <h1>Design Guidelines</h1>
            {/* subject */}
            <h4>Subject</h4>
            <h6>Design for any piece of furniture in a household/office setting.</h6>
            {/* materials */}
            <h4>Materials</h4>
              <h6>Wood, Metal, Plastics, Glass, Concrete</h6>
              <h6>Sustainable Materials are encouraged</h6>
              <h6>Please limit upholstery</h6>
              <h6>Please limit electronics and limit mechanical parts or movement</h6>
            {/* deliverables */}
            <h4>Deliverables</h4>
            <h6>Renderings of the proposed design</h6>
            <h6>3D model(s) of the design, Sketches</h6>
            <h6>Images and Text (for non-designers)</h6>
            {/* recommended */}
            <h4>Recommended</h4>
            <h6>Documentation of design process (sketches, prototypes)</h6>
            <h6>Multiple renderings of the final design</h6>
            <h6>A video explaining the project and process</h6>
            <h6>Documentation of inspirations, themes, explorations</h6>
          </div>
        </div>
        <div className="logo-container">
          <Image src={guidelines} alt="logo" style={{margin:'auto', maxHeight:'100vh'}} fluid/>
        </div>
      </Container>
      </div>
    )
  }

  submissionDeadline = () => {
    return(
      <div id="submission-deadline">
        <h1>Submission Deadline 15th July 2020</h1>
        <div style={{
          display:'flex', 
          flexDirection:'row', 
          justifyContent:'center', 
          flexWrap:'wrap', 
          // marginLeft:0, marginRight:0, marginBottom:'auto', marginTop:'auto'
          marginBottom: 'auto',
          marginTop: 40,
        }}>
          <div className="deadline-container">
            <h3 style={{textAlign:'center'}}>Jun 1st</h3>
            <h6>Competition Open.</h6>
          </div>
          <div className="deadline-container">
            <h3>Jun 25th</h3>
            <h6>Early Submission Deadline.</h6>
          </div>
          <div className="deadline-container">
            <h3>July 15th</h3>
            <h6>General Submissions Deadline.</h6>
          </div>
          <div className="deadline-container">
            <h3>July 30th</h3>
            <h6>Results Announced.</h6>
          </div>
        </div>
      </div>
    )
  }

  jury = () => {
    return(
      <div id="jury">
        <h1 style={{width:'100%', paddingLeft:30, paddingBottom:30}}>Jury</h1>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', flexWrap:'wrap', margin: 'auto'}}>
          <div style={{flex:1, marginTop:20, marginBottom:20, marginLeft:40, marginRight:40, textAlign:'center', maxWidth:370, minWidth:250}}>
            <Image src={jury1} fluid style={{borderRadius:'50%', width:200}}/>
            <h1 style={{fontSize:36, marginBottom:20, marginTop:20}}>Adrian Gill</h1>
            <p>Adrian Gill is the Founder and CEO of Ad Hoc Industries, a creative agency that has pioneered a highly adaptable approach to building brands. With extensive creative, strategy, brand management experience, Adrian serves as board member and investor to several startups focusing on building consumer and brand equity. Adrian is the former Vice President of the $1.8B Global Footwear Division at PUMA. He led all creative design direction and was responsible for the strategic decisions regarding consumer trend and marketing strategy.</p>
          </div>
          <div style={{flex:1, marginTop:20, marginBottom:20, marginLeft:40, marginRight:40, textAlign:'center', maxWidth:370, minWidth:250}}>
            <Image src={jury2} fluid style={{borderRadius:'50%', width:200}}/>
            <h1 style={{fontSize:36, marginBottom:20, marginTop:20}}>Jefferey Schnapps</h1>
            <p>Jeffrey Schnapp is the founder/faculty director of metaLAB (at) Harvard and faculty co-director of the Berkman Klein Center for Internet and Society at Harvard University. Schnapp’s work in the domains of media, knowledge design, digital arts and humanities, and curatorial practice includes collaborations with the Triennale di Milano, the Cantor Center for the Visual Arts, the Wolfsonian-FIU, and the Canadian Center for Architecture. After three years of service as co-founder and CEO at Piaggio Fast Forward, Schnapp assumed the new position of Chief Visionary Officer effective June 2018.</p>
          </div>
          <div style={{flex:1, marginTop:20, marginBottom:20, marginLeft:40, marginRight:40, textAlign:'center', maxWidth:370, minWidth:250}}>
            <div style={{borderRadius:'50%', width:200, height:200, backgroundColor:'#C4C4C4', margin:'auto'}}/>
            <h1 style={{fontSize:36, marginBottom:20, marginTop:20}}>TBD</h1>
            <p></p>
          </div>
        </div>
      </div>
    )
  }

  criteria = () => {
    return(
      <div id="criteria">
        <h1>Criteria</h1>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', flexWrap:'wrap'}}>
          <div className="criteria-container">
            <h3>Originality</h3>
            <p style={{maxWidth:291, margin: 'auto'}}>How might we reimagine furniture to be unique and beautiful?</p>
          </div>
          <div className="criteria-container">
            <h3>Functional</h3>
            <p style={{maxWidth:291, margin: 'auto'}}>How might we reimagine furniture to be more versatile and do more than one thing?</p>
          </div>
          <div className="criteria-container">
            <h3>Adaptable</h3>
            <p style={{maxWidth:291, margin: 'auto'}}>How might we reimagine furniture to be affordable and easily installable?</p>
          </div>
        </div>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', flexWrap:'wrap', margin: 'auto'}}>
          <div className="criteria-container">
            <h3>Deployable</h3>
            <p style={{maxWidth:291, margin: 'auto'}}>How might we reimagine furniture to be affordable and easily installable?</p>
          </div>
          <div className="criteria-container">
            <h3>Innovation</h3>
            <p style={{maxWidth:291, margin: 'auto'}}>How might we reimagine furniture to push boundaries?</p>
          </div>
        </div>
        <h1 style={{marginBottom:5}}>5 Winners</h1>
        <p style={{textAlign:'center', margin: 'auto', marginTop:0, marginBottom:30}}>Will get their pieces built upto $1000</p>
      </div>
    )
  }

  footer = () => {
    return (
      <div id="section-last">
        <Container className="container">
          <div style={{marginTop:20, fontSize:48, flex:1}}>
            <a href="" className="register" style={{fontFamily:'sofia-pro, sans-serif'}}>Register</a>
          </div>
          <div className="text-list">
            <a href="" style={{fontFamily:'sofia-pro, sans-serif'}}>Legal</a>
            <a href="" style={{fontFamily:'sofia-pro, sans-serif'}}>Instagram</a>
            <a href="" style={{fontFamily:'sofia-pro, sans-serif'}}>Contact</a>
          </div>
        </Container>
      </div>
    )
  }

  render(){
    return(
      <div style={{padding:0, backgroundColor:'#F8F8F8'}} id="contest-page-container">
        {this.hero()}
        {this.submitIdea()}
        {this.callForVisionary()}
        {this.designGuidelines()}
        {this.submissionDeadline()}
        {this.jury()}
        {this.criteria()}
        {this.footer()}
      </div>
    )
  }
}

export default ContestPage;