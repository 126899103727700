import { API_ENDPOINT } from  '../constants/secrets'
import axios from 'axios'
import {getCanvas} from './canvasActions'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const postComment = (data) => 
{

    return (dispatch) => {
        //Create Answer
        const CREATE_COMMENT_DATA_URI = `${API_ENDPOINT}/api/comments`

        axios.post(CREATE_COMMENT_DATA_URI, data, config)
            .then(response => {
                console.log(response)
                if(response.data.status)
                {
                    dispatch({type: "SET_COMMENTS", id: response.data.canvas._id, comments: response.data.canvas.comments})
                }
                else
                {
                    dispatch({type: "ERROR_POST_COMMENT", message:response.data.message})
                }
            }).catch(e => {
                console.log(e)
                dispatch({type: "ERROR_POST_COMMENT", message:"Our systems are experiencing issues. Please try again after some time"})
            })
    }
}

export const editComment = (data) => 
{
    return (dispatch) => {
        //Create Answer
        const UPDATE_COMMENT_DATA_URI = `${API_ENDPOINT}/api/comments`
        console.log(data)
        axios.put(UPDATE_COMMENT_DATA_URI, data, config)
            .then(response => {
                dispatch(getCanvas())
            }).catch(e => {
                console.log(e)
            })
    }
}