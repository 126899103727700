import React from 'react';
import './../../stylesheets/budget.css';
import { Container, Row, Col, Button, Form, InputGroup, FormControl } from 'react-bootstrap';

import Slider from '@material-ui/core/Slider';
/** 
 * Redux Actions
 */
import { addBudget, editBudget } from '../../actions/budgetActions';

/**
 * Higher Level Components
 */
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'


class Budget extends React.Component {
  state = {
    values:[this.props.budget.minimum, this.props.budget.maximum]
  };

  handleSubmit = () => {
    if(this.props.mode==='CREATE'){
      const data = {
        minimum:this.state.values[0],
        maximum:this.state.values[1],
        id:this.props.canvas_id
      }
      this.props.addBudget(data)
      this.props.toggleModal();
    }
    else{
      const data = {
        minimum:this.state.values[0],
        maximum:this.state.values[1],
        id:this.props.budget._id
      }
      this.props.editBudget(data);
      this.props.toggleModal();
    }
  }
  
  handleChange = (event, newValue) => {
    this.setState({values:newValue})
  }

  handleInputChange = (e) => {
    let temp = this.state.values;
    if(e.target.name === "min"){
      temp[0] = e.target.value;
    }
    else{
      temp[1] = e.target.value;
    }
    this.setState({values: temp})
  }

  render() {
    
    return (
      <Container fluid={true} style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <h2 className="col-12 question-h2" style={{marginBottom:60}}>What kind of budget do you have?</h2>
            
            <div style={{display:'flex', justifyContent:'center', marginBottom:40}}>
              <Form.Group controlId="exampleForm.ControlInput1" style={{maxWidth:'10rem', marginRight:'1rem'}}>
                <Form.Label>Minimum</Form.Label>
                <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  value={this.state.values[0]}
                  placeholder="Minimum amount"
                  aria-describedby="basic-addon1"
                  type="number"
                  name="min"
                  min={1}
                  max={20000}
                  step={50}
                  onChange={this.handleInputChange}
                />
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlInput1" style={{maxWidth:'10rem'}}>
                <Form.Label>Maximum</Form.Label>
                <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  value={this.state.values[1]}
                  placeholder="Maximum amount"
                  aria-describedby="basic-addon1"
                  type="number"
                  name="max"
                  min={1}
                  max={20000}
                  step={50}
                  onChange={this.handleInputChange}
                />
                </InputGroup>
              </Form.Group>
            </div>
          <Slider
            defaultValue={20}
            min={50}
            max={20000}
            aria-labelledby="discrete-slider-always"
            step={50}
            value={this.state.values}
            onChange={this.handleChange}
            valueLabelDisplay="on"
          />
          <Col xs={12}>
            <Row className='justify-content-md-center'>
              <Button variant="dark" onClick={this.handleSubmit} style={{marginTop:20, marginBottom:40}}>Submit</Button>
            </Row>
          </Col>
      </Container>
    )
  }
}
/**
 * 
 * @param {*} state
 * Maps Redux state to component props 
 */
const mapStateToProps = (state) => {
  let budget = state.canvas.budget
  let mode='CREATE';
  if (budget === undefined || budget === null || Object.keys(budget).length === 0) {
      budget={
        minimum:0,
        maximum:20000
      }
  }
  else{
    mode='EDIT';
  }

  return {
      // Dimension specific 
      budget: budget,

      //General Function
      canvas_id: state.canvas.id,
      mode:mode,

  }
}

/**
* Maps Redux actions to component props
* @param {*} dispatch
*  
*/
const mapDispatchToProps = (dispatch) => {

  return {
      addBudget: (data) => dispatch(addBudget(data)),
      editBudget: (data) => dispatch(editBudget(data)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Budget))
