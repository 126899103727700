import React, { Component } from "react";

/**
 * Components
 */
import Option from "./TextMoodboardOption";

/**
 * Redux Actions
 */
import { createMoodboard, editMoodboard } from "../../actions/moodboardActions";

/**
 * Higher Level Components
 */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/**
 * Style
 */
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "../../stylesheets/style.css";
import "../../stylesheets/moodboard.css";

class TextMoodboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: this.props.selectedOptions,
            showTooltip: false,
            mode: this.props.mode,
            moodboardId: this.props.moodboardId,
        };
    }
    /**
     * Selects or deselects the options
     */
    toggleOption = (optionId) => {
        let selectedOptions = this.state.selectedOptions;
        if (selectedOptions.has(optionId)) {
            selectedOptions.delete(optionId);
        } else {
            selectedOptions.add(optionId);
        }
        this.setState({ selectedOptions });
    };

    /**
     * Handles form submit - Continue button
     */
    handleSubmit = (e) => {
        e.preventDefault();
        let data;
        switch (this.state.mode) {
            case "CREATE":
                data = {
                    options: [...this.state.selectedOptions],
                    isText: true,
                    id: this.props.canvasId,
                };
                this.props.createMoodboard(data);
                break;
            case "EDIT":
                data = {
                    options: [...this.state.selectedOptions],
                    isText: true,
                    id: this.props.moodboardId,
                };
                this.props.editMoodboard(data);
                break;
            default:
                break;
        }

        this.props.toggleModal();
    };

    /**
     * Handles form skip - Skip button
     */
    handleSkip = (e) => {
        e.preventDefault();
        let data;
        switch (this.state.mode) {
            case "CREATE":
                data = {
                    options: this.props.selectedOptions,
                    isText: true,
                    id: this.props.canvasId,
                };
                this.props.createMoodboard(data);
                break;
            case "EDIT":
                data = {
                    options: this.props.selectedOptions,
                    isText: true,
                    id: this.props.moodboardId,
                };
                this.props.editMoodboard(data);
                break;
            default:
                break;
        }
        // this.props.history.push("/" + this.props.nextPage);
        this.props.toggleModal();
    };

    handleKeyUp = (e) => {
        if (e.keyCode == 13) {
            this.toggleOption(e.target.value);
        }
    };

    /**
     * Main Render method
     */
    render() {
        const options = this.props.options;
        const categories = Object.keys(options);
        let options_render = [];

        for (let [key, category] of categories.entries()) {
            options_render.push(
                <h5 className="col-12" key={key}>
                    {category}
                </h5>
            );
            options_render = [
                ...options_render,
                ...options[category].map((option, index) => (
                    <Option
                        key={key + "" + index}
                        id={option}
                        cols={3}
                        selected={this.state.selectedOptions.has(option)}
                        onClick={this.toggleOption}
                        text={option}
                    ></Option>
                )),
            ];
        }

        let selected_options = [...this.state.selectedOptions];
        let selected_options_render = <div></div>;
        if (selected_options && selected_options.length > 0) {
            selected_options_render = selected_options.map((option, index) => (
                <p
                    key={option + "" + index}
                    id={option}
                    selected={true}
                    onClick={() => this.toggleOption(option)}
                >
                    #{option}
                </p>
            ));
        }

        return (
            <Container fluid={true}>
                <Container>
                    <Row>
                        <h2 className="col-12 question-h2">
                            Select the words that represent your furniture style
                        </h2>
                        <h4
                            className="col-12 question-h4"
                            style={{ textAlign: "center" }}
                        >
                            Choose words to describe the style of your furniture
                        </h4>
                        <Row>
                            <Col
                                xs={{ span: 10, offset: 1, order: 1 }}
                                lg={{ span: 5, offset: 0, order: 2 }}
                                className="text-moodboard-left"
                            >
                                <Col xs={{ span: 12 }}>
                                    <h6 className="col-12">
                                        Choose from the list of tags
                                    </h6>
                                    <h6 className="col-12">Or</h6>
                                    <h6 className="col-12">
                                        Add your own tag below
                                    </h6>
                                    <Form.Control
                                        type="text"
                                        onKeyUp={this.handleKeyUp}
                                        placeholder="Add your own tag..."
                                    ></Form.Control>

                                    <Row className="selected-options">
                                        <h6
                                            className="col-12"
                                            style={{ fontWeight: "700" }}
                                        >
                                            Selected Tags
                                        </h6>
                                        {selected_options_render}
                                    </Row>
                                </Col>
                            </Col>
                            <Col
                                xs={{ span: 10, offset: 1, order: 2 }}
                                lg={{ span: 7, offset: 0, order: 1 }}
                                className="text-moodboard-right"
                            >
                                <Row>{options_render}</Row>
                            </Col>
                        </Row>

                        <Col xs={12}>
                            <Row className="justify-content-md-center" style={this.props.edit && {marginTop:20}}>
                                <Button
                                    style={{marginTop:20}}
                                    variant="dark"
                                    onClick={this.handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

/**
 * Maps the Redux state to component props
 * @param {*} state
 */
const mapStateToProps = (state) => {
    const options = state.UI.text_moodboard;
    let selectedOptions = new Set([]);
    let text_moodboard = [];
    let mode = "CREATE";
    let moodboardId = undefined;
    if (state.canvas.moodboards !== undefined) {
        text_moodboard = state.canvas.moodboards.filter(
            (moodboard) => moodboard.isText
        );
        if (text_moodboard.length > 0) {
            selectedOptions = new Set(text_moodboard[0].options);
            mode = "EDIT";
            moodboardId = text_moodboard[0]._id;
        }
    }

    return {
        // Moodboard specific
        options,
        selectedOptions,
        moodboardId,
        mode,

        // General Function
        canvasId: state.canvas.id,

        //General UI
        nextPage: state.UI.flow["text-moodboard"],
        step: Object.keys(state.UI.flow).indexOf("text-moodboard") + 1,
        stepMax: Object.keys(state.UI.flow).length,
    };
};

/**
 * Maps redux actions to component props
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        createMoodboard: (data) => dispatch(createMoodboard(data)),
        editMoodboard: (data) => dispatch(editMoodboard(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TextMoodboard));
