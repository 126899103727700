import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Form, Modal, Row, Col, Button, Toast, Alert} from 'react-bootstrap';
import userIcon from '../../images/icons/userIcon.png';
import editIcon from '../../images/icons/editIcon.svg';
import '../../stylesheets/settings.css';
import axios from 'axios';
import {API_ENDPOINT} from '../../constants/secrets';
import ReactCrop from 'react-image-crop';
import {updateUser} from '../../actions/authActions';

class Settings extends React.Component{
  constructor(props)
  {
      super(props)
      this.state = {
          user_id:'',
          name:this.props.user.name,
          last_name:this.props.user.last_name,
          email: this.props.user.email,
          current_password:'', 
          new_password:'',
          confirm_password:'',
          show_password_message: false,
          password_msg:'',
          password_variant:'',
          showImageCropModal  : false,
          isImageUploading    : false,
          uploadedImageLink   : null,
          showProfilePic      : false,
          crop: {
              unit: '%',
              width: 30,
              aspect: 1 / 1,
              },
              croppedImageUrl   : null
      }
      this.fileInput = React.createRef();
  }

  componentDidMount(){
    this.populateData();
  }

  populateData() {
    this.setState({
      name: this.props.user.name ? this.props.user.name : '',
      email: this.props.user.email ? this.props.user.email : '',
      last_name: this.props.user.last_name ? this.props.user.last_name : '',
    })
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.user.id!==prevState.user_id){
        return { user_id: nextProps.user.id};
   }
   return null;
  }

  componentDidUpdate(prevProps, prevState) {
      if(prevState.user_id!==this.props.user.id){
          this.populateData();
      }
  }

  handleFileUpload = e => {
    this.setSelectedImage(e.target.files[0]);
  };

  onSelectFile = (e) => {
    this.setSelectedImage(e.target.files[0]);
  };

  setSelectedImage = (file) => {
    const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({
                    uploadedImageLink   : reader.result, 
                    showImageCropModal  : true, 
                    isImageUploading    : false})
        );
      reader.readAsDataURL(file);
      this.setState({isImageUploading: true});
  }

  handleUpload = (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append("file", this.state.croppedImageBlob)
    const UPLOAD_FILE_DATA_URI = `${API_ENDPOINT}/api/upload`
    axios.post(UPLOAD_FILE_DATA_URI, formData, {crossDomain: true})
        .then(response => {
            let data = {
                id: this.props.user.id,
                updateType: "PROFILE_PIC",
                profile_pic: response.data.link
            }
            this.props.updateUser(data)
            this.setState({showImageCropModal: false})

        }).catch(e => {
            console.log(e)
            this.setState({showImageCropModal: false})

        }) 
  }

  onImageLoaded = image => {
      this.imageRef = image;
    };

  onCropComplete = crop => {
      this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
      this.setState({ crop });
  };

  async makeClientCrop(crop) {
      if (this.imageRef && crop.width && crop.height) {
      const [croppedImageBlob, croppedImageUrl] =  await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
        );
      this.setState({ croppedImageUrl, croppedImageBlob });
      }
  }

  removeProfilePic = () => {
    let data = {
      id: this.props.user.id,
      updateType: "PROFILE_PIC",
      profile_pic: null
  }
  this.props.updateUser(data)
  }

  getCroppedImg(image, crop, fileName) {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
      );

      return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
              if (!blob) {
                  console.error('Canvas is empty');
                  return;
              }
              
              blob.name = fileName;
              window.URL.revokeObjectURL(this.fileUrl);
              this.fileUrl = window.URL.createObjectURL(blob);
              resolve([blob, this.fileUrl]);
          }, 'image/jpeg');
      });
  }
  
  renderProfilePic = () => {
    return(
      <div className="profile-container">
        <img src={this.props.user.profile_pic ? this.props.user.profile_pic : userIcon} alt="profile" className="profile-pic"></img>
        <div className="edit-icon-outer-container">
          <div className="edit-icon-container" >
            <label htmlFor="fileinput">
              <img src={editIcon} alt="edit" className="edit-icon"></img>
              <input  id          = "fileinput" 
                      accept      = "image/*" 
                      onChange    = {this.onSelectFile} 
                      className   = "file-input" 
                      type        = "file">

              </input>
            </label>
          </div>
        </div>
      </div>
    )
  }

  renderProfilePicActions = () => {
    return(
      <div className="action-buttons">
        <input
          ref="fileInput"
          onChange={this.handleFileUpload}
          type="file"
          style={{ display: "none" }}
        />
        <button onClick={() => this.refs.fileInput.click()} className="upload-btn">Upload</button>
        <button className="remove-btn" onClick={this.removeProfilePic}>Remove</button>
      </div>
    )
  }

  resetPassword = async() => {
    let data = {
      id: this.props.user.id,
      current_password: this.state.current_password,
      new_password: this.state.new_password
    }

    if(this.state.new_password === this.state.confirm_password)
    {
      const RESET_PASSWORD_URI = `${API_ENDPOINT}/auth/change-password`
      try {
        let resetPasswordStatus = await axios.post(RESET_PASSWORD_URI, data);
        resetPasswordStatus = resetPasswordStatus.data;
        if(resetPasswordStatus.status)
        {
            this.setState({show_password_message:true, password_msg:"Password changed successfully!", password_variant:'success', current_password:'', new_password:'', confirm_password:''});
        }
        else
        {
          this.setState({show_password_message:true, password_msg:resetPasswordStatus.message, password_variant:'danger'});
        }
      } catch (error) {
        this.setState({show_password_message:true, password_msg:"Password change failed. Please check your interenet connction.", password_variant:'sucdangeress'})

      }
    }
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  saveDetails = () => {
    const data = {
      id: this.props.user.id,
      name: this.state.name, last_name:this.state.last_name
    }
    this.props.updateUser(data);
  }

  isPasswordChangeButtonDisabled = () => {
    if(this.state.new_password.length > 0 && this.state.current_password.length > 0 && this.state.new_password === this.state.confirm_password){
      return false;
    }
    else {
      return true
    }
  }

  isSaveBtnDisabled = () => {
    if(this.state.name === this.props.user.name && this.state.last_name === this.props.user.last_name){
      return true;
    }
    else{
      return false;
    }
  }

  render(){
    if(this.props.user.loggedIn){
      return(
        <Container id="settings">
          <h6 onClick={()=>this.props.history.push('account')}>{'<'} Back</h6>
          <h2>Account</h2>
          <h4>Settings</h4>
          <div className="profile-pic-actions">
            {this.renderProfilePic()}
            {this.renderProfilePicActions()}
          </div>
          <div>
            <div style={{display:'flex'}}>
              <Form.Group style={{flex:1, marginRight:'1rem'}}>
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" name="name" placeholder="Enter first name" onChange={this.onChange} defaultValue={this.state.name}/>
              </Form.Group>
              <Form.Group style={{flex:1}}>
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" name="last_name" placeholder="Enter last name" onChange={this.onChange} defaultValue={this.state.last_name}/>
              </Form.Group>
            </div>
            <Form.Group>
              <Form.Label>Email Address</Form.Label>
              <Form.Control type="text" name="email" placeholder="Enter email address" onChange={this.onChange}  defaultValue={this.state.email} disabled/>
            </Form.Group>
            <div className="save-btn-container">
              <button className="save-btn" onClick={this.saveDetails} disabled={this.isSaveBtnDisabled()}>Save Changes</button>
            </div>

            {/* {
              !this.props.user.external_type && (
                <>
                <hr style={{ marginTop:'3rem', marginBottom:'3rem'}}/>

                <h4 style={{color:'#737373', marginBottom:'2rem'}}>Reset Password</h4>
                {
                  this.state.show_password_message && (
                    <Alert variant={this.state.password_variant} onClose={()=> {
                      this.setState({show_password_message:false, password_msg:"", password_variant:''})
                    }} dismissible>
                      <Alert.Heading style={{margin:0, fontSize:'1rem'}}>{this.state.password_msg}</Alert.Heading>
                    </Alert>
                  )
                }
                <Form.Group>
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control type="password" name="current_password" placeholder="Enter current password" onChange={this.onChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>New Password</Form.Label>
                  <Form.Control type="password" name="new_password" placeholder="Enter new password" onChange={this.onChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control type="password" name="confirm_password" placeholder="Re-enter new password" onChange={this.onChange} />
                  {
                    this.state.new_password.length > 0 && this.state.new_password !== this.state.confirm_password && (
                      <Form.Text style={{color:'red'}}>
                        Passwords do not match
                      </Form.Text>
                    )
                  }
                </Form.Group>

                <div className="save-btn-container">
                  <button className="save-btn" onClick={this.resetPassword} disabled={this.isPasswordChangeButtonDisabled()}>Change Password</button>
                </div>
                </>
              )
            } */}
            
          </div>

          <Modal
            size="sm"
            show={this.state.showImageCropModal}
            onHide={() => this.setState({showImageCropModal: false})}
            aria-labelledby="example-modal-sizes-title-sm"
            id="instructions-modal"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
                Crop Profile Image
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="justify-content-center">
                    <ReactCrop
                        src={this.state.uploadedImageLink}
                        crop={this.state.crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Col xs="12">
                    <Col xs={{span:10, offset:1}} lg={{span:8, offset:2}}>
                        <Row className="justify-content-center">
                            <Button variant="dark" onClick={this.handleUpload}>Done</Button>
                        </Row>
                    </Col>
                </Col>
            </Modal.Footer>
        </Modal>
        </Container>
      )
    }
    else{
        return <p>Logging In</p>
    }
  }
}

const mapStateToProps = (state) => {
  return { 
      user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  
  return { 
    updateUser: (data) => dispatch(updateUser(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings));