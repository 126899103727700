import React from 'react';
import {Container, Image, Row, Col, Button} from 'react-bootstrap';
import { withRouter } from "react-router-dom";

import Navbar from './SplashNav';
import Slider from "react-slick";
import Footer from "./footer"
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './splash.css';
import './page_config.css';

import PAGE_CONFIG from "./page_config.js"
let count = 0

class SplashPage  extends React.Component{

  // Checking if the device is a mobile phone or not
  isMobile = () => {
    if(window.innerWidth <500){
        return true 
    }
    else{
        return false
    }
  }

  hero = (data) => {

    let {title, subtitle, cta, redirect_path, css_slug} = data

    return(
      <div id="hero" className={css_slug} key={count++}>
        <Container fluid>
          <Col xs={12}>
            <Col xs={12} md={{span:10, offset:1}} lg={{span:11, offset:1}}>
              <h1 >{title}</h1>
              <h6 className={"col-md-10 offset-md-1 col-lg-5 offset-lg-0"}>{subtitle}</h6>
              <button className="btn col-8 col-md-6 offset-md-2 col-lg-3 offset-lg-0" onClick={() => {this.props.history.push(redirect_path)}}>{cta}</button>
            </Col>
          </Col>
        </Container>
      </div>
    )
  }

  text_icon_section = (data) => {

    let elements = []
    let {elements_data, css_slug, fluid, xs, lg} = data
    for(let i=0; i<elements_data.length; i++)
    {
      let {image_src, title, description} = elements_data[i]

      elements.push(<Col xs={{span:8, offset:0}} md={{span:5, offset:0}} lg={{span:3, offset:0}} className="item" key={title}>
                      <Col xs={xs} lg={lg}>
                        <Image src={image_src} fluid/>
                      </Col>
                      <h4>{title}</h4>
                      <p>{description}</p>
                    </Col>)
    }


    return(
      <div id={css_slug} className="text-icon-section" key={count++}>
        <Container fluid={fluid}>
          <h2 style={{paddingTop:10}}>{data["title"]}</h2>
          <Row className="justify-content-center">
            {elements}
          </Row>
        </Container>
      </div>
    )
  }

  testimonials = (data) => {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 1,
      speed: 1000,
      dots: true,
      autoplay: true,
      autoplaySpeed: 5000
    };

    let elements = []
    let {elements_data} = data
    for(let i=0; i<elements_data.length; i++)
    {
      let {text} = elements_data[i]

      elements.push(<div   className   = 'testimonial' style={{}} key={i}>
                      <h3>{text} </h3>
                  </div>)
    }

    return (
      <div  id="testimonials" key={count++}>
        <Container fluid>
          <Col xs={{span:10, offset:1}}>
            <Slider {...settings}>
              {elements}
            </Slider>
          </Col>
        </Container>
      </div>
    )
  }

  full_width_img = (data) => {

    let {title, description, css_slug, img_src} = data
    return(
      <div className={"full-width-image " + css_slug}  key={count++} style={{minHeight: this.isMobile() && 'min-content'}}>
        <div className="section4-container" style={{minHeight: this.isMobile() && 'min-content', backgroundColor: this.isMobile() && 'transparent', paddingLeft: this.isMobile() && 0, paddingRight: this.isMobile() && 0}}>
          <h1 style={{color: this.isMobile() && '#000000', textAlign: this.isMobile() && 'center', marginLeft: this.isMobile() && 0}}>{title}</h1>
          {
            this.isMobile() && (<Image src={img_src} fluid/>)
          }
          <h6 style={{color: this.isMobile() && '#000000', paddingTop: this.isMobile() && 10, paddingBottom: this.isMobile() && 30}} dangerouslySetInnerHTML={{__html: description}}></h6>
        </div>
      </div>
    )
  }

  full_width_img_with_btn = (data) => {
    let {title, cta, redirect_path, css_slug} = data
    return(
      <div id="footer" className={css_slug} key={count++}>
        <Container>
          <h1>{title}</h1>
          <button className="btn" onClick={() => {this.props.history.push(redirect_path)}}>{cta}</button>
        </Container>
      </div>
    )
  }

  redirect = (redirect_path, redirect_type) => {
    if(redirect_type === "EXTERNAL")
    {
      window.location = redirect_path
    }
    else
    {
      this.props.history.push(redirect_path)
    }
  }

  cta = (data) => {
    let {cta, redirect, redirect_type} = data
    return (
      <Col xs={12} className="section-cta">
          <Row>
            <Col xs={{span:8, offset:2}} lg={{span:2, offset:5}}>
                <Button onClick={() => this.redirect(redirect, redirect_type)}>{cta}</Button>
            </Col>
          </Row>
      </Col>
    )
  }

  render(){

    let {data} = PAGE_CONFIG

    let RENDERS = []
    for(let i=0; i<data.length; i++)
    {
      let section = data[i]
      switch(section["type"])
      {
        case "HERO":
          RENDERS.push(this.hero(section))
          break
        case "TEXT_ICON":
          RENDERS.push(this.text_icon_section(section))
          break;
        case "TESTIMONIALS":
          RENDERS.push(this.testimonials(section))
          break;
        case "FULL_WIDTH_IMAGE":
          RENDERS.push(this.full_width_img(section))
          break;
        case "FULL_WIDTH_IMAGE_WITH_BTN":
          RENDERS.push(this.full_width_img_with_btn(section))
          break;
        case "CTA":
          RENDERS.push(this.cta(section))
          break;
        default:
          break
      }
    }

    return(
      <Row>
        <Col xs={12} style={{padding: 0}}>
          
          <Navbar/>
          {RENDERS}
          <Footer></Footer>
          
        </Col>
      </Row>
    )
  }
}

export default withRouter(SplashPage);
