import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Container, Row, Col, Dropdown} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {API_ENDPOINT, HOME_PAGE_ENDPOINT} from '../../constants/secrets'
import axios from 'axios'
import CanvasWidget from './CanvasWidget'
import '../../stylesheets/account.css'


class CanvasManager extends Component{
    
    constructor(props)
    {
        super(props)
        this.state = {
            canvas_ids: [],
            canvases:[],
            stage_keywords : {
                "Show all projects": "ALL",
                "Pre-Email Onboarding": "PRE_EMAIL_ONBOARDING",
                "Post-Email Onboarding": "POST_EMAIL_ONBOARDING",
                "Canvas Complete": "CANVAS_COMPLETE",
                "Design Stage Bypass": "DESIGN_STAGE_BYPASS_REQUEST",
                "Waiting For Designer": "DESIGN_FEE_PAID",
                "Designer Assigned": "DESIGNER_ASSIGNED",
                "Ideas Clarification": "IDEA_CLARIFICATIONS",
                "Sketch Phase": "SKETCH_PHASE"
            },
            stages: [ 
                "Show all projects",
                "Pre-Email Onboarding",
                "Post-Email Onboarding",
                "Canvas Complete",
                "Design Stage Bypass",
                "Waiting For Designer",
                "Designer Assigned",
                "Ideas Clarification",
                "Sketch Phase"
            ], 
            currentFilter:"Show all projects",
            sort_ascending: true
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.canvas_ids.length!==prevState.canvas_ids.length){
            return { canvas_ids: nextProps.canvas_ids};
       }
       return null;
     }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.canvas_ids.length!==this.props.canvas_ids.length){
            this.getCanvases()
        }
    }

    componentDidMount(){
        this.getCanvases();
    }

    getCanvases = () => {
        this.setState({canvases:[]}, () => {
            let canvas_ids = new Set(this.props.canvas_ids)
            canvas_ids = [...canvas_ids]

            for(var i=0; i< canvas_ids.length; i++)
            {
                var GET_CANVAS_URL = `${API_ENDPOINT}/api/canvas`

                axios.get(GET_CANVAS_URL, {params: {id: this.props.type === "archived_canvas" ? canvas_ids[i].canvas : canvas_ids[i]}})
                .then(response => 
                    {
                        if(response.data.status)
                        {
                            let all_canvases = [...this.state.canvases, response.data.canvas]
                            all_canvases = all_canvases.sort(function(c1, c2){ if(c1.updatedAt < c2.updatedAt) { return 1} else {return -1}})
                            this.setState({canvases: all_canvases})
                        }
                    })
                .catch(e => 
                    {
                        console.log(e)
                    })
                
            }
        })
            
    }

    filterProjects = () => {
        if(this.state.currentFilter === "Show all projects"){
            return this.state.canvases;
        }
        else{
            let filtered_canvases = [];
            for(let i = 0; i < this.state.canvases.length; i++){
                if(this.state.canvases[i].stage_history.length > 0 && this.state.canvases[i].stage_history[this.state.canvases[i].stage_history.length-1].stage === this.state.stage_keywords[this.state.currentFilter]){
                    filtered_canvases.push(this.state.canvases[i])
                }
            }
            return filtered_canvases;
        }
    }

    getTitle = () => {
        switch(this.props.type){
            case 'user_canvas':
                return "My Projects";
            case 'design_canvas':
                return "My Design Projects";
            case 'fabrication_canvas':
                return "My Fabrication Projects";
            case 'archived_canvas':
                return "My Archived Projects";
            default :
                break;
        }
    }

    startNewProject = () => {
        window.location.href = HOME_PAGE_ENDPOINT;
    }

    CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href="#/action"
          ref={ref}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {/* Render custom icon here */}
          {/* &#x25bc; */}
          <i className="fas fa-filter action-icon"></i>
          {children}
        </a>
      ));

    onChangeFilter = (e) => {
        this.setState({currentFilter: e})
    }

    sort = () => {
        this.setState({sort_ascending: !this.state.sort_ascending});
        this.setState({canvases: this.state.canvases.reverse()})
    }

    render()
    {
        
        return (
            <Container id="canvas-manager">
            <div className="title-btn-container">
                <div className="title-filter-container">
                    <h2 >{this.getTitle()}</h2>
                    <Dropdown>
                        <Dropdown.Toggle as={this.CustomToggle} id="dropdown-custom-components"></Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                this.state.stages.map((stage, index) => (
                                    <>
                                    <Dropdown.Item href="#/action-1" key={index} onClick={()=>{
                                        this.onChangeFilter(stage)
                                    }} style={{color: this.state.currentFilter ===stage ? "#5714AC" : "#000000", fontWeight:this.state.currentFilter ===stage && 'bold'}}>{stage}</Dropdown.Item>
                                    {index!==this.state.stages.length-1 && <Dropdown.Divider />}
                                    </>
                                ))
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    <i className={this.state.sort_ascending ? "fas fa-sort-amount-desc action-icon" : "fas fa-sort-amount-asc action-icon"} onClick={this.sort}></i>
                </div>
                {
                    this.props.type === "user_canvas" && (
                        <button className="new-project-btn" onClick={this.startNewProject} style={{marginTop:0, marginBottom:'1rem'}}>+ Create New Project</button>
                )
            }
            </div>
            {
                this.props.type === "user_canvas" && (

                <Col className="create-project-btn-container">
                    <button className="new-project-btn-phone" onClick={this.startNewProject}>+ Have a new idea?</button>

                </Col>
                )
            }
            <Row>
                {/* {CanvasWidgets} */}
                {
                    this.filterProjects().length > 0 ?  this.filterProjects().map(canvas_data => <CanvasWidget key={canvas_data._id} data={canvas_data} type={this.props.type}></CanvasWidget>)
                    : <p>No projects found!</p>
                }
                <hr style={{"height": "2px", "color":"black", "width": "100%"}}></hr>
                
            </Row>
            </Container>
        )
    }
}



const mapStateToProps = (state) => {
    return { 
        user: state.user,
    }
}

export default withRouter(connect(mapStateToProps)(CanvasManager))
