import axios from 'axios';
import Firebase from '../services/firebase'
import cookie from 'cookie'
import { AUTH_BACKEND_ENDPOINT } from '../constants/secrets'

/**
 * Checks for required cookies
 * @returns {boolean} true - if user has cookies, and signed in through firebase with cookies
 * @returns {boolean} false - if user does not have cookies, or verification was unsuccessful
 */
export const checkStatus = async () => {
  if (!isLoggedIn()) {
    try {
      /* Extract CSST cookie */
      const parsedCookie = cookie.parse(document.cookie);
      // console.log(parsedCookie)

      const { csst = null } = parsedCookie;
      /* If there is no CSST cookie, return false */
      if (!csst) {
        // await logout();
        return false;
      }

      const response = await axios({
        url: `${AUTH_BACKEND_ENDPOINT}/status`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${csst}`
        },
        withCredentials: true,
      });

      /* If verification is successful, extract firebase custom token */
      const {
        status = false,
        frbToken = null
      } = response.data;

      /* If verification was unsuccessful, return false and show error message */
      if (!status || !frbToken) {
        let { message } = response.data
        console.log(message)

        return false;
      }

      /* Sign in user via Firebase */
      await Firebase.auth().signInWithCustomToken(frbToken)

    } catch (e) {

      console.error(e)
      return false;
    }
  }
  /* If everything works without erro, return trye */
  return true;
}

const isLoggedIn = () => {
  /* Returns true if user is logged in.*/
  return !!Firebase.auth().currentUser;
}

/**
 * Checks Naya Auth
 * @returns {object} returns user
 * @returns {object._id}
 * @returns {object.email}
 * @returns {object.user_type}
 * @returns {object.admin_permissions}
 */
export const getAuthUser = async () => {
  let { email, uid } = Firebase.auth().currentUser

  if (isLoggedIn() && (!!email || !!uid)) {
    try {
      /* Extract CSST cookie */
      const parsedCookie = cookie.parse(document.cookie);
      // console.log(parsedCookie)

      const { csst = null } = parsedCookie;
      /* If there is no CSST cookie, return false */
      if (!csst) {
        return false;
      }

      //Fetch user from backend and verify for 'admin'
      const response = await axios({
        url: `${AUTH_BACKEND_ENDPOINT}/user`,
        method: 'POST',
        data: {
          authEmail: email,
          uid: uid
        },
        headers: {
          authorization: `Bearer ${csst}`
        },
        withCredentials: true,
      });

      /* If verification is successful, extract user object */
      const {
        status = false,
        user = null
      } = response.data;

      /* If verification was unsuccessful, return false and show error message */
      if (!status || !user) {
        let { message } = response.data
        console.log(message)

        return false;
      }

      /* User object from backend*/
      return user;

    } catch (e) {

      console.error(e)
      return false;
    }
  }
}

/**
 * Communicates with auth backend to log out user using cookies
 * @returns {true} on successful log out
 */
export const logoutFirebase = async () => {
  if (!isLoggedIn()) {
    console.log(`Can not log out. User is not logged in.`)
    return false
  }

  try {
    /* Extract CSST cookie */
    const parsedCookie = cookie.parse(document.cookie);
    // console.log(parsedCookie)

    const { csst = null } = parsedCookie;
    /* If there is no CSST cookie, return false */
    if (!csst) {
      return false;
    }

    //Log user out
    const response = await axios({
      url: `${AUTH_BACKEND_ENDPOINT}/logout`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${csst}`
      },
      withCredentials: true,
    });

    const {
      status = false
    } = response.data;

    /* If logout was unsuccessful, return false and show error message */
    if (!status) {
      let { message } = response.data
      console.log(message)

      return false;
    }

    /* Successful logout*/
    await Firebase.auth().signOut()
    return true;

  } catch (e) {

    console.error(e)
    return false;
  }
}