import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Image, Modal } from "react-bootstrap";
import CanvasDraw from "react-canvas-draw";
import qMark from '../../images/qmark.svg'
import documentSVG from '../../images/uploads/document.svg'
import audio from '../../images/uploads/audio.svg'
import video from '../../images/uploads/audio.svg'
import { createAnnotation } from '../../actions/annotationActions';
import { deleteAnswer } from '../../actions/quizActions';
import { deleteDimensions } from '../../actions/dimensionsActions';
import { deleteSketch } from '../../actions/sketchActions';
import { deleteAddress } from '../../actions/shippingActions';
import { deleteBudget } from '../../actions/budgetActions';
import { editMoodboard, deleteMoodboard } from "../../actions/moodboardActions";
import { addSearchesToCanvas, addImagesToCanvas } from '../../actions/uploadActions';
import { addPinterestLink } from '../../actions/pinterestLinkActions'
import deleteIcon from '../../images/icons/delete.png';
import maximize from "../../images/icons/maximize.svg";
import download from "../../images/icons/download.svg";
import next from "../../images/icons/next-page.png";
import prev from "../../images/icons/prev-page.png";
// For pdfs
import { Document, Page, pdfjs } from "react-pdf";
import { CLIENT } from "../../constants/roles";

class CanvasTile extends Component {
    constructor(props) {
        super(props);
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        this.state = {
            className: "canvas-tile-wrapper",
            clientWidth: 100,
            clientHeight: undefined,
            showLightbox: false,
            lightboxImage: null,
            imageBoxWidth: "",
            imageBoxHeight: "auto",
            numPages: null,
            pageNumber: 1,
            fileObject:
                this.props.data !== undefined &&
                    this.props.data.link !== undefined
                    ? {
                        url:
                            // "https://cors-anywhere.herokuapp.com/" +
                            this.props.data.link,
                    }
                    : {},
            aspectRatio: 0,
            uploadIsImage: false,
        };

        this.replyToData = {
            text: undefined,
            image: undefined,
        };
        this.sketchImgContainer = React.createRef();
    }

    componentDidMount() {
        let classNameAddition = "";
        switch (this.props.contentType) {
            case "VISUAL_MOODBOARD":
                classNameAddition = classNameAddition + " image-tile";
                break;
            case "UPLOAD":
                classNameAddition = classNameAddition + " image-tile upload";
                break;
            case "SKETCH":
                classNameAddition = classNameAddition + " image-tile sketch";
                break;
            default:
                break;
        }

        if (this.divElement) {
            this.setState({
                className: this.state.className + classNameAddition,
                clientWidth: this.divElement.clientWidth,
            });
        } else {
            this.setState({
                className: this.state.className + classNameAddition,
            });
        }

        //check if upload is image
        if (this.props.contentType === 'UPLOAD') {
            if ([
                "jpg",
                "jpeg",
                "gif",
                "png",
                "svg",
                "raw",
                "webp",
                "tiff",
                "psd",
                "bmp",
                "heif",
            ].includes(this.props.data.ext.split(".")[this.props.data.ext.split(".").length - 1].toLowerCase())) {
                this.setState({...this.state, uploadIsImage: true})
            }
        }

        // find aspect ratios for images on load for SEARCH and UPLOAD
        if ((this.props.contentType === 'SEARCH') || this.state.uploadIsImage) {
            const img = document.createElement('img');
             
            img.onload = () => {
                const dimensions = {
                    height: img.naturalHeight,
                    width: img.naturalWidth
                }
                this.setState({aspectRatio: dimensions.width/dimensions.height});
            }

            switch (this.props.contentType) {
                case 'SEARCH':
                    img.src = this.props.data
                    break;
                case 'UPLOAD':
                    img.src = this.props.data.link
                    break;
                default:
                    break;
            }
        }
    }

    deleteAnswerTile = (id) => {
        this.props.deleteAnswer({ "id": id });
    }

    deleteTextMoodboard = (id) => {
        this.props.deleteMoodboard({ "id": id });
    }

    deleteVisualMoodboard = (link) => {
        let temp = [...this.props.selectedOptions]
        let filteredArray = temp.filter(i => i !== link)
        const data = {
            options: [...filteredArray],
            isText: false,
            id: this.props.moodboardId,
        };
        this.props.editMoodboard(data);
    }

    deleteUploads = (data) => {
        let tempUploadData = [];
        this.props.uploads.map((item) => {
            if (item.id === undefined) {
                tempUploadData.push(item._id)
            }
            else {
                tempUploadData.push(item.id)
            }
        })
        let filteredArray = tempUploadData.filter(i => i !== data._id);
        const uploadsData = {
            uploads: filteredArray,
            id: this.props.canvasId
        }
        this.props.addImagesToCanvas(uploadsData)
    }

    deleteSearches = (link) => {
        let searches = this.props.searches;
        let keywords = Object.keys(this.props.searches);
        keywords.map((key) => {
            searches[key] = searches[key].filter(x => x !== link)
        })
        const data = {
            searches: searches,
            id: this.props.canvasId
        };
        this.props.addSearchesToCanvas(data);
    }

    deleteDimensionsTile = (id) => {
        this.props.deleteDimensions({ "id": id });
    }

    deleteSketchTile = (id) => {
        this.props.deleteSketch({ "id": id });
    }

    deleteAddressTile = (id) => {
        this.props.deleteAddress({ "id": id });
    }

    deleteBudgetTile = (id) => {
        this.props.deleteBudget({ "id": id });
    }

    deletePinterestTile = () => {
        this.props.addPinterestLink({
            id: this.props.canvasId,
            pinterest: ''
        })
    }

    lightBoxImageDisplay = () => {
        if (this.state.rotation === 90 || this.state.rotation === 270)
            this.setState({
                imageBoxWidth: `${this.imgBox.current.clientWidth}px`,
                imageBoxHeight: `${this.imgBox.current.clientHeight}px`,
            });
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    renderContent = () => {
        const { rotation, imageBoxWidth, imageBoxHeight } = this.state;
        let render;
        
        switch (this.props.contentType) {
            case "TEXT":
                render = <p>{this.props.text}</p>;
                break;
            case "IMAGE":
                render = <Image src={this.props.image}></Image>;
                break;
            case "ANSWER":
                render =
                    <div className="tile-content-wrapper">
                        <Col xs={12}>
                            <h5>{this.props.data.question != null && this.props.data.question.main}</h5>
                            <Row className="justify-content-md-center">
                                {this.props.data.options.map(option =>
                                    <Col key={option.text + option.link} xs={6} lg={6}>
                                        <Image src={option.link} fluid></Image>
                                        <p className="option-text">{option.text}</p>
                                    </Col>)
                                }
                                {this.props.data.isOther &&
                                    <Col key={this.props.data.other} xs={6} lg={6}>
                                        <Image src={qMark} fluid></Image>
                                        <p className="option-text">{this.props.data.other}</p>
                                    </Col>}
                            </Row>

                        </Col>
                        <div style={{ position: 'absolute', bottom: 5, right: 20 }}>
                            <Image
                                src={deleteIcon}
                                className="deleteButton"
                                style={{ "width": "2rem", "height": "2rem", "cursor": "pointer" }}
                                onClick={() => { this.deleteAnswerTile(this.props.data._id) }}
                            ></Image>
                        </div>
                    </div>
                this.replyToData["text"] = this.props.data.question != null && this.props.data.question.main
                if (this.props.options && !this.props.options.isOther) {
                    this.replyToData["image"] = this.props.data.options[0].link
                }
                else {
                    this.replyToData["image"] = qMark
                }
                this.replyToData["type"] = "TEXT_IMG"
                break
            case "ADDITIONAL_INFO":
                if (this.props.data !== undefined) {
                    render =
                        <div className="tile-content-wrapper">
                            <Col xs={12}>
                                <h5>Additional Information</h5>
                                <Row className="justify-content-md-center">
                                    <p>{this.props.data}</p>
                                </Row>

                            </Col>
                        </div>
                }
                else {

                    render = (
                        <Col
                            xs={12}
                            onClick={() =>
                                this.props.toggleModal(undefined, 'STORY')
                            }
                        >
                            <h5>Additional Information</h5>
                            <p
                                className='col-12'
                                style={{
                                    textAlign: "center",
                                    fontSize: "7rem",
                                    marginBottom: "0rem",
                                    fontFamily: "none",
                                }}
                            >
                                +
                            </p>
                        </Col>
                    );
                }

                this.replyToData["text"] = "Additional Information"
                this.replyToData["type"] = "TEXT"
                break
            case "STORY":
                if (this.props.data !== undefined) {
                    render =
                        <div className="tile-content-wrapper">
                            <Col xs={12}>
                                <h5>Story</h5>
                                <Row className="justify-content-md-center">
                                    <p>
                                        <div dangerouslySetInnerHTML={{ __html: this.props.data.trim() }}></div>
                                    </p>
                                </Row>

                            </Col>
                        </div>
                }
                else {

                    render = (
                        <Col
                            xs={12}
                            onClick={() =>
                                this.props.toggleModal(undefined, 'STORY')
                            }
                        >
                            <h5>Story</h5>
                            <p
                                className='col-12'
                                style={{
                                    textAlign: "center",
                                    fontSize: "7rem",
                                    marginBottom: "0rem",
                                    fontFamily: "none",
                                }}
                            >
                                +
                            </p>
                        </Col>
                    );
                }

                this.replyToData["text"] = "Story"
                this.replyToData["type"] = "TEXT"
                break
            case "TEXT_MOODBOARD":
                if (this.props.data !== undefined) {
                    render =
                        <div className="tile-content-wrapper">
                            <Col xs={12}>
                                <h5>Furniture Style</h5>
                                <Row className="justify-content-md-center">
                                    {this.props.data.options.map(option =>
                                        <Col key={option} xs={6}>
                                            <p className="option-text">{option}</p>
                                        </Col>)}
                                </Row>
                            </Col>
                            <div style={{ position: 'absolute', bottom: 5, right: 20 }}>
                                <Image
                                    src={deleteIcon}
                                    className="deleteButton"
                                    style={{ "width": "2rem", "height": "2rem", "cursor": "pointer" }}
                                    onClick={() => { this.deleteTextMoodboard(this.props.data._id) }}
                                ></Image>
                            </div>
                        </div>
                    this.replyToData["text"] = "Furniture Style Inspirations"
                    this.replyToData["type"] = "TEXT"
                }
                else {
                    render = (
                        <Col
                            xs={12}
                            onClick={() =>
                                this.props.getContentType(
                                    this.props.contentType
                                )
                            }
                        >
                            <h5>Furniture style</h5>
                            <p
                                className='col-12'
                                style={{
                                    textAlign: "center",
                                    fontSize: "7rem",
                                    marginBottom: "0rem",
                                    fontFamily: "none",
                                }}
                            >
                                +
                            </p>
                        </Col>
                    );
                }
                break;
            case "VISUAL_MOODBOARD":
                render =
                    // <div className="tile-content-wrapper">
                    <Col xs={12} className="visual-moodboard-tile">
                    <Image src={this.props.data} fluid></Image>
                        <Image
                            src={deleteIcon}
                            className="delete-button-image"
                            style={{ "width": "2rem", "height": "2rem", "cursor": "pointer" }}
                            onClick={() => { this.deleteVisualMoodboard(this.props.data) }}
                        ></Image>
                    </Col>
                this.replyToData["image"] = this.props.data
                this.replyToData["type"] = "IMAGE"
                break
                
            case "UPLOAD":
                render = (
                    <>
                        {[
                            "jpg",
                            "jpeg",
                            "gif",
                            "png",
                            "svg",
                            "raw",
                            "webp",
                            "tiff",
                            "psd",
                            "bmp",
                            "heif",
                        ].includes(this.props.data.ext.split(".")[this.props.data.ext.split(".").length-1].toLowerCase()) ? (
                            <Col
                                xs={12}
                                style={{ minHeight: "300px", padding: "0" }}
                                className='img-wrapper'
                            >
                                <Image
                                    // src={this.props.data.src_set ? window.innerWidth<400 ? this.props.data.src_set[0] : window.innerWidth<1000 ? this.props.data.src_set[1] : this.props.data.src_set[2] : this.props.data.link}
                                    src={this.props.data.link}
                                    // src="https://storage.googleapis.com/naya-studio-uploads/uploads/1613806127738.pdf"
                                    // srcset={this.props.data.src_set}
                                    // sizes='12vw'
                                    className='img'
                                ></Image>
                                <Image
                                    src={maximize}
                                    className='option'
                                    onClick={() =>
                                        this.setState({
                                            showLightbox: true,
                                            lightboxImage: this.props.data.link,
                                        })
                                    }
                                ></Image>
                                <a href={this.props.data.link} download>
                                    <Image
                                        src={download}
                                        style={{marginRight:'2.5rem'}}
                                        className='option'
                                    ></Image>
                                </a>
                                <Image
                                    src={deleteIcon}
                                    className="delete-button-image option"
                                    style={{marginRight:'5rem'}}
                                    onClick={() => { this.deleteUploads(this.props.data) }}
                                ></Image>
                                <Modal
                                    size='xl'
                                    show={
                                        this.state.showLightbox &&
                                        this.state.lightboxImage
                                    }
                                    onHide={() =>
                                        this.setState({
                                            showLightbox: false,
                                            lightboxImage: null,
                                        })
                                    }
                                    aria-labelledby='example-modal-sizes-title-xl'
                                    id='instructions-modal'
                                >
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Body style={{ overflowY: "auto" }}>
                                        <Row
                                            className='justify-content-center'
                                            style={{ height: imageBoxWidth }}
                                        >
                                            <img
                                                ref={this.imgBox}
                                                src={this.state.lightboxImage}
                                                onLoad={
                                                    this.lightBoxImageDisplay
                                                }
                                                style={{
                                                    maxWidth: "100%",
                                                    height: imageBoxHeight,
                                                    transform: `rotate(${rotation}deg)`,
                                                }}
                                                alt='lightbox'
                                            ></img>
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                            </Col>
                        ) : [
                              "mp3",
                              "wav",
                              "wma",
                              "aac",
                              "m4a",
                              "flac",
                          ].includes(this.props.data.ext) ? (
                            <Col xs={12} className='img-wrapper'>
                                <a
                                    href={this.props.data.link}
                                    download
                                    style={{
                                        textDecoration: "none",
                                        fontWeight: 100,
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        alt=''
                                        src={audio}
                                        className='document-icon'
                                    />
                                    <div className='document-name'>
                                        {this.props.data.name}
                                    </div>
                                </a>
                            </Col>
                        ) : [
                              "webm",
                              "mpg",
                              "mp2",
                              "mpeg",
                              "mpe",
                              "mpv",
                              "ogg",
                              "mp4",
                              "m4p",
                              "m4v",
                              "avi",
                              "wmv",
                          ].includes(this.props.data.ext) ? (
                            <Col xs={12} className='img-wrapper'>
                                <a
                                    href={this.props.data.link}
                                    download
                                    style={{
                                        textDecoration: "none",
                                        fontWeight: 100,
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        alt=''
                                        src={video}
                                        className='document-icon'
                                    />
                                    <div className='document-name'>
                                        {this.props.data.name}
                                    </div>
                                </a>
                            </Col>
                        ) : this.props.data.ext === "pdf" ? (
                            console.log("PDF:", this.props.data.link) || (
                                <Document
                                    // file=" C:\Users\7440\Desktop\naya\file.pdf"
                                    // file={this.props.data.link}
                                    file={this.state.fileObject}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                    onLoadError={(error) =>
                                        alert(
                                            "Error while loading document! " +
                                                error.message
                                        )
                                    }
                                    onSourceError={(error) =>
                                        alert(
                                            "Error while retrieving document source! " +
                                                error.message
                                        )
                                    }
                                >
                                    <Col
                                        xs={12}
                                        style={{
                                            minHeight: "300px",
                                            padding: "0",
                                        }}
                                        className='img-wrapper'
                                    >
                                        <Image
                                            src={maximize}
                                            className='maximize'
                                            onClick={() => {
                                                this.setState({
                                                    showLightbox: true,
                                                });
                                            }}
                                        ></Image>
                                        <a href={this.props.data.link} download target="_blank" rel="noopener noreferrer">
                                            <Image
                                                src={download}
                                                style={{marginRight:'2.5rem'}}
                                                className='maximize'
                                            ></Image>
                                        </a>
                                        <Image
                                            src={prev}
                                            className='prev-page'
                                            onClick={() => {
                                                if (
                                                    this.state.pageNumber -
                                                        1 !==
                                                    0
                                                ) {
                                                    this.setState({
                                                        pageNumber:
                                                            this.state
                                                                .pageNumber - 1,
                                                    });
                                                } else {
                                                    this.setState({
                                                        pageNumber: this.state
                                                            .numPages,
                                                    });
                                                }
                                            }}
                                        ></Image>
                                        <Image
                                            src={next}
                                            className='next-page'
                                            onClick={() => {
                                                if (
                                                    this.state.pageNumber + 1 >
                                                    this.state.numPages
                                                ) {
                                                    this.setState({
                                                        pageNumber: 1,
                                                    });
                                                } else {
                                                    this.setState({
                                                        pageNumber:
                                                            this.state
                                                                .pageNumber + 1,
                                                    });
                                                }
                                            }}
                                        ></Image>
                                        <Page
                                            pageNumber={this.state.pageNumber}
                                        />
                                        <Modal
                                            size='xl'
                                            show={this.state.showLightbox}
                                            onHide={() =>
                                                this.setState({
                                                    showLightbox: false,
                                                })
                                            }
                                            aria-labelledby='example-modal-sizes-title-xl'
                                            id='instructions-modal'
                                        >
                                            <Modal.Header
                                                closeButton
                                            ></Modal.Header>
                                            <Modal.Body
                                                style={{
                                                    overflowY: "hidden",
                                                    overflowX: "hidden",
                                                }}
                                            >
                                                <Row
                                                    className='justify-content-center'
                                                    // style={{height:imageBoxWidth}}
                                                >
                                                    <Page
                                                        pageNumber={
                                                            this.state
                                                                .pageNumber
                                                        }
                                                    />
                                                </Row>
                                            </Modal.Body>
                                        </Modal>
                                    </Col>
                                </Document>
                            )
                        ) : (
                            <Col xs={12} className='img-wrapper'>
                                <a
                                    href={this.props.data.link}
                                    download
                                    style={{
                                        textDecoration: "none",
                                        fontWeight: 100,
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        alt=''
                                        src={documentSVG}
                                        className='document-icon'
                                    />
                                    <div className='document-name'>
                                        {this.props.data.name}
                                    </div>
                                </a>
                            </Col>
                        )}
                    </>
                );
                this.replyToData["image"] = this.props.data.link;
                this.replyToData["type"] = "IMAGE";
                break;
            case "SEARCH":
                //renders google searches
                render =
                    <Col xs={12} className="visual-moodboard-tile">
                    <Image src={this.props.data} className="img"></Image>
                        <Image
                            src={deleteIcon}
                            className="delete-button-image"
                            style={{ "width": "2rem", "height": "2rem", "cursor": "pointer" }}
                            onClick={() => { this.deleteSearches(this.props.data) }}
                        ></Image>
                    </Col>
                this.replyToData["image"] = this.props.data
                this.replyToData["type"] = "IMAGE"
                break
            case "SKETCH":
                if (this.props.data !== undefined) {
                    // console.log(this.props.data)
                    // console.log('loadableCanvas')
                    // console.log(this.loadableCanvas)
                    render =
                        <Col xs={12} ref={(divElement) => this.divElement = divElement} style={{ "minHeight": "100%" }} className="tile-content-wrapper">
                            {this.props.data.sketchBg && <img src={this.props.data.sketchBg}
                                ref={this.sketchImgContainer}
                                alt=""
                                onLoad={this.handleSketchImageLoaded}
                                style={{ "maxWidth": "100%" }}></img>}
                            <CanvasDraw className="canvas-sketch"
                                disabled
                                canvasWidth={200}
                                canvasHeight={200}
                                backgroundColor={"transparent"}
                                ref={canvasDraw => (this.loadableCanvas = canvasDraw)}
                                saveData={this.props.data.sketchData}
                                imgSrc={this.props.data.sketchBg}
                                immediateLoading={true} 
                                />
                            <div style={{ position: 'absolute', bottom: 5, right: 20 }}>
                                <Image
                                    src={deleteIcon}
                                    className="deleteButton"
                                    style={{ "width": "2rem", "height": "2rem", "cursor": "pointer", position: 'initial' }}
                                    onClick={() => { this.deleteSketchTile(this.props.data._id) }}
                                ></Image>
                            </div>
                        </Col>

                    this.replyToData["type"] = "SKETCH"
                    this.replyToData["image"] = this.props.data.sketchData
                }
                else {
                    render =
                        <Col xs={12} onClick={() => this.props.history.push('/sketch')}>
                            <h5>Add Sketch</h5>
                            <p className="col-12" style={{ textAlign: "center", fontSize: "7rem", marginBottom: "0rem", fontFamily: 'none' }}>+</p>
                        </Col>
                }
                break
            case "DIMENSIONS":
                if (this.props.data !== undefined) {
                    render =
                        <Col xs={12} className="tile-content-wrapper">
                            <h5>Dimensions</h5>
                            <p className="col-12" style={{ textAlign: "center", fontSize: "0.9rem", marginBottom: "0rem" }}>Length</p>
                            <p className="col-12" style={{ textAlign: "center" }}>{this.props.data.length} {this.props.data.units}</p>
                            <p className="col-12" style={{ textAlign: "center", fontSize: "0.9rem", marginBottom: "0rem" }}>Width</p>
                            <p className="col-12" style={{ textAlign: "center" }}>{this.props.data.width} {this.props.data.units}</p>
                            <p className="col-12" style={{ textAlign: "center", fontSize: "0.9rem", marginBottom: "0rem" }}>Height</p>
                            <p className="col-12" style={{ textAlign: "center" }}>{this.props.data.height} {this.props.data.units}</p>
                            <div style={{ position: 'absolute', bottom: 5, right: 20 }}>
                                <Image
                                    src={deleteIcon}
                                    className="deleteButton"
                                    style={{ "width": "2rem", "height": "2rem", "cursor": "pointer" }}
                                    onClick={() => { this.deleteDimensionsTile(this.props.data._id) }}
                                ></Image>
                            </div>
                        </Col>
                    this.replyToData["text"] = "Dimensions"
                    this.replyToData["type"] = "TEXT"
                }
                else {
                    render =
                        <Col xs={12} onClick={() => this.props.getContentType(this.props.contentType)}>
                            <h5>Add Dimensions</h5>
                            <p className="col-12" style={{ textAlign: "center", fontSize: "7rem", marginBottom: "0rem", fontFamily: 'none' }}>+</p>
                        </Col>
                }
                break;
            case "BUDGET":
                if (this.props.data !== undefined) {
                    render =
                        <Col xs={12} className="tile-content-wrapper">
                            <h5>Budget</h5>
                            <p className="col-12" style={{ textAlign: "center", fontSize: "0.9rem", marginBottom: "0rem" }}>Minimum</p>
                            <p className="col-12" style={{ textAlign: "center" }}>$ {this.props.data.minimum}</p>
                            <p className="col-12" style={{ textAlign: "center", fontSize: "0.9rem", marginBottom: "0rem" }}>Maximum</p>
                            <p className="col-12" style={{ textAlign: "center" }}>$ {this.props.data.maximum}</p>
                            <div style={{ position: 'absolute', bottom: 5, right: 20 }}>
                                <Image
                                    src={deleteIcon}
                                    className="deleteButton"
                                    style={{ "width": "2rem", "height": "2rem", "cursor": "pointer" }}
                                    onClick={() => { this.deleteBudgetTile(this.props.data._id) }}
                                ></Image>
                            </div>
                        </Col>
                    this.replyToData["text"] = "Budget"
                    this.replyToData["type"] = "TEXT"
                }
                else {
                    render =
                        <Col xs={12} onClick={() => this.props.getContentType(this.props.contentType)}>
                            <h5>Add Budget</h5>
                            <p className="col-12" style={{ textAlign: "center", fontSize: "7rem", marginBottom: "0rem", fontFamily: 'none' }}>+</p>
                        </Col>
                }

                break;

            case "PINTEREST":
                if (this.props.data !== undefined) {
                    render =
                        <Col xs={12} className="tile-content-wrapper">
                            <h5>Pinterest Board</h5>
                            <Col xs={{ span: 8, offset: 2 }} style={{ textAlign: "center" }}>
                                <a href={this.props.data} alt="pinterest" className="col-12" style={{ textAlign: "center", fontSize: "2rem", marginBottom: "0rem" }}><i className="fa fa-pinterest fa-3x" aria-hidden="true" style={{ color: "#c8232c" }}></i></a>
                            </Col>
                            <div style={{ position: 'absolute', bottom: 5, right: 20 }}>
                                <Image
                                    src={deleteIcon}
                                    className="deleteButton"
                                    style={{ "width": "2rem", "height": "2rem", "cursor": "pointer" }}
                                    onClick={() => { this.deletePinterestTile(this.props.data._id) }}
                                ></Image>
                            </div>
                        </Col>
                    this.replyToData["text"] = "Pinterest Board"
                    this.replyToData["type"] = "TEXT"
                }

                break
            case "SHIPPING":
                if (this.props.data !== undefined) {
                    render =
                        <Col xs={12} className="tile-content-wrapper">
                            <h5>Shipping Address</h5>
                            <p className="col-12" style={{ textAlign: "center", fontSize: 13, fontWeight: 'bold' }}>{this.props.data.address.address_name}</p>
                            <p className="col-12" style={{ textAlign: "center", fontSize: 12, margin: 0 }}>{this.props.data.length} {this.props.data.address.address_line_1}</p>
                            <p className="col-12" style={{ textAlign: "center", fontSize: 12, margin: 0 }}>{this.props.data.length} {this.props.data.address.address_line_2}</p>
                            <p className="col-12" style={{ textAlign: "center", fontSize: 12, margin: 0 }}>{this.props.data.address.locality}</p>
                            <p className="col-12" style={{ textAlign: "center", fontSize: 12, margin: 0 }}>{this.props.data.address.city}</p>
                            <p className="col-12" style={{ textAlign: "center", fontSize: 12, margin: 0 }}>{this.props.data.address.zip}</p>
                            <p className="col-12" style={{ textAlign: "center", fontSize: 12, margin: 0 }}>Phone : {this.props.data.address.phone}</p>
                            <div style={{ position: 'absolute', bottom: 5, right: 20 }}>
                                <Image
                                    src={deleteIcon}
                                    className="deleteButton"
                                    style={{ "width": "2rem", "height": "2rem", "cursor": "pointer" }}
                                    onClick={() => { this.deleteAddressTile(this.props.data._id) }}
                                ></Image>
                            </div>
                        </Col>
                    this.replyToData["text"] = "Shipping"
                    this.replyToData["type"] = "TEXT"
                }
                else {
                    render =
                        <Col xs={12} onClick={() => this.props.getContentType(this.props.contentType)}>
                            <h5>Add Shipping Address</h5>
                            <p className="col-12" style={{ textAlign: "center", fontSize: "7rem", marginBottom: "0rem", fontFamily: 'none' }}>+</p>
                        </Col>
                }

                break;
            case "TRACKING":
                if (this.props.data !== undefined) {
                    render =
                        <Col xs={12} onClick={() => window.open(this.props.data.url, "_blank")}>
                            <h5>Tracking</h5>
                            <p className="col-12" style={{ textAlign: "center", fontSize: 13, fontWeight: 'bold' }}>{this.props.data.carrier}</p>
                            <p className="col-12" style={{ textAlign: "center", fontSize: 13, margin: 0 }}>{this.props.data.tracking_number}</p>
                        </Col>
                }
                break;
            default:
                break;
        }
        return render;
    };

    handleSketchImageLoaded = (e) => {
        //keeps aspect ratio for sketch image while maintaining container height
        //this.divElement is sketch ref
        let height =
            (this.sketchImgContainer.current.clientHeight *
                this.divElement.clientWidth) /
            this.sketchImgContainer.current.clientWidth;
        this.setState({
            imgLoaded: true,
            clientWidth: this.divElement.clientWidth,
            clientHeight: height,
        });
    };

    handleReplyTo = (e) => {
        e.preventDefault();
        this.props.replyToFunction({
            ...this.replyToData,
            refId: this.props.refId,
        });
    };

    handleAnnotate = (e) => {
        e.preventDefault();
        if (this.replyToData.image) {
            this.props.createAnnotation({
                annotationSrc: this.replyToData.image,
            });
        }
        this.props.history.push("/annotate");
    };

    editTool = () => {
        if (this.props.contentType !== "SKETCH") {
            this.props.getContentType(this.props.contentType);
        } else {
            this.props.history.push("/sketch");
        }
    };

    handleEditQuestions = () => {
        // e.preventDefault();
        if (this.props.contentType === "SKETCH") {
            // this.props.history.push('./sketch')

            window.location.href = "/sketch";
        } else {
            this.props.toggleModal(this.props.data, this.props.contentType);
        }
    };

    handleEdit = (e) => {
        e.preventDefault();
        if (
            ["DIMENSIONS", "BUDGET", "TEXT_MOODBOARD", "SHIPPING"].includes(
                this.props.contentType
            )
        ) {
            this.editTool();
        }
        else if (["STORY", "ADDITIONAL_INFO"].includes(
            this.props.contentType
        )) {
            this.props.toggleModal(undefined, 'STORY')
        }
        else {
            this.handleEditQuestions();
        }
    };

    //fix for extra long story tile content affecting neighboring tile's height
    checkIsStoryLong = () => {
        if (this.props.contentType === 'STORY' && typeof this.props.data === 'string') {
            if (this.props.data.length > 550) {
                return true
            }
        }
        return false
    }

    //used for column length based on aspect ratio
    /*
        Aspect Ratio Definitions:
            9:16 = 0.5625
            3:4 = 0.75
            1:1 = 1
            5:4 = 1.25
            4:3 = 1.3333
            3:2 = 1.5
            16:9 = 1.7777
            UW = >2.0
    */
    setXLColLength = () => {
        if (this.checkIsStoryLong())
            return '12'
        if ((this.props.contentType === 'SEARCH') || this.state.uploadIsImage) {
            if (this.state.aspectRatio > 1.65) {
                if (this.state.aspectRatio > 2.2) {
                    return '12'
                }
                return '6'
            }
        }
        return '3'
    }

    setLGColLength = () => {
        if (this.checkIsStoryLong())
            return '12'
        if ((this.props.contentType === 'SEARCH') || this.state.uploadIsImage) {
            if (this.state.aspectRatio > 1.65) {
                if (this.state.aspectRatio > 2.2) {
                    return '12'
                }
                return '8'
            }
        }
        return '4'
    }

    render() {
        return (
            <>
                <Col xs={12} md={this.checkIsStoryLong() ? '12' : '6'} lg={this.setLGColLength()} xl={this.setXLColLength()} className={this.state.className} >
                    <Col xs={12} className='canvas-tile'>
                        <Row>{this.renderContent()}</Row>
                    </Col>
                    <Col xs={12} className='tile-controls'>
                        <Row className='justify-content-end' >
                            {this.props.data !== undefined && (
                                <>
                                    <Col>
                                        <a href='' onClick={this.handleReplyTo}>
                                            reply
                                    </a>
                                    </Col>
                                    <Col>
                                        {
                                            this.props.canvas.users &&
                                            this.props
                                                .canvas
                                                .users
                                                .some(user =>
                                                    user.user === this.props.user.id &&
                                                    user.role === CLIENT
                                                ) &&
                                            (
                                                <a href='' onClick={this.handleEdit}>
                                                    edit
                                                </a>
                                            )
                                        }
                                    </Col>
                                </>
                            )}
                            {this.replyToData.type == "IMAGE" ? (
                                <Col>
                                    <a href='' onClick={this.handleAnnotate}>
                                        annotate
                                    </a>
                                </Col>
                            ) : null}
                        </Row>
                    </Col>
                </Col>
            </>
        );
    }

    componentDidUpdate() {
        if (this.loadableCanvas && this.props.data.sketchData) {
            this.loadableCanvas.loadSaveData(this.props.data.sketchData);
        }
    }
}

/**
 * Maps the Redux state to component props
 * @param {*} state
 */
const mapStateToProps = (state) => {
    let visual_moodboard = [];
    let selectedOptions = new Set([]);
    let moodboardId;

    if (state.canvas.moodboards !== undefined) {
        visual_moodboard = state.canvas.moodboards.filter(
            (moodboard) => !moodboard.isText
        );
        if (visual_moodboard.length > 0) {
            selectedOptions = new Set(visual_moodboard[0].options);
            moodboardId = visual_moodboard[0]._id;
        }
    }
    return {
        shipping: state.canvas.shipping,
        // Moodboard specific
        selectedOptions,
        moodboardId,

        // uploads
        uploads: state.canvas.uploads,

        // searches
        searches: state.canvas.searches,

        //General Function
        canvasId: state.canvas.id,
        user: state.user,
        canvas: state.canvas

    };
};

const mapDispatchToProps = (dispatch) => {

    return {
        createAnnotation: (data) => dispatch(createAnnotation(data)),
        deleteAnswer: (data) => dispatch(deleteAnswer(data)),
        deleteMoodboard: (data) => dispatch(deleteMoodboard(data)),
        deleteDimensions: (data) => dispatch(deleteDimensions(data)),
        deleteSketch: (data) => dispatch(deleteSketch(data)),
        deleteAddress: (data) => dispatch(deleteAddress(data)),
        deleteBudget: (data) => dispatch(deleteBudget(data)),
        editMoodboard: (data) => dispatch(editMoodboard(data)),
        addSearchesToCanvas: (keyword) => dispatch(addSearchesToCanvas(keyword)),
        addImagesToCanvas: (keyword) => dispatch(addImagesToCanvas(keyword)),
        addPinterestLink: (data) => dispatch(addPinterestLink(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CanvasTile))
