import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCanvas } from "../../actions/canvasActions";
import { getTransactionsForCanvas } from "../../actions/transactionActions";
import CanvasTile from "./CanvasTile";
import { Container, Row, Col, Button, Image, Modal } from "react-bootstrap";
import CommentTileInput from "./CommentTileInput";
import CommentTile from "./CommentTile";
import NavBar from "../utilities/NavBar";
import Tool from "../tools/Tool";
import EditCanvas from "./EditCanvas";
import { setAuthCallback } from "../../actions/authActions";
import { postTrigger } from "../../actions/triggerActions";
import close from '../../images/icons/close.svg';
import less from '../../images/icons/less.png';
import more from '../../images/icons/more.png';
import expand from '../../images/icons/expand.png';
import collapse from '../../images/icons/collapse.png';
import ProgressIndicator from './ProgressIndicator';

import axios from "axios";
import { API_ENDPOINT } from '../../constants/secrets';
import { CLIENT } from "../../constants/roles";
const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"
    }
}

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


class Canvas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            replyTo: undefined,
            showToolModal: false, //variable to decide if the independent tool modal should be displayed or not
            toolContentType: "", // storess the value for which question is the to be answered
            editAnswer: false, // boolean variable that holds the value which mentions whether to display the edit modal or now
            editData: [], // data to be passed to the EditCanvas component
            contentType: "", //to pass to the EditCanvas component
            showAllComments: true, // handles show and hide of comments
            showNextSteps: true,
            tileExpand: true,
            showErrorModal: false,
            errorModalMessage: null,
        };
        this.commentTileRef = React.createRef();
    }
    componentDidMount() {
        this.props.setAuthCallback("account");
        require("../../stylesheets/canvas.css");
        document.body.style.backgroundColor = "#fff";
        if (
            localStorage.getItem("nayaCanvasID") !== undefined &&
            localStorage.getItem("nayaCanvasID") !== ""
        ) {
            this.props.getCanvas({ id: localStorage.getItem("nayaCanvasID") });
            this.props.getTransactionsForCanvas(
                localStorage.getItem("nayaCanvasID")
            );
            window.setTimeout(() => {
                this.props.getCanvas({
                    id: localStorage.getItem("nayaCanvasID"),
                });
                this.props.getTransactionsForCanvas(
                    localStorage.getItem("nayaCanvasID")
                );
            }, 5000);
        }
    }

    // toggles the tool modal
    toggleToolModal = () => {
        this.setState({ showToolModal: !this.state.showToolModal });
    };

    // get the content type from the canvas tile to pass it to the tool to add answer/details
    getContentType = (type) => {
        this.setState({ toolContentType: type }, () => {
            this.setState({ showToolModal: !this.state.showToolModal });
        });
    };

    // toggle edit modal
    toggleEditAnswerModal = (data, type) => {
        this.setState({ editData: data, contentType: type }, () => {
            this.setState({ editAnswer: !this.state.editAnswer });
        });
    };

    submitForAdmin = (data) => {
        const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas`

        axios.put(UPDATE_CANVAS_DATA_URI, data, config)
            .then(response => {
                console.log(response)
                this.props.sendUpdateCanvasDispatch()
            }).catch(e => {
                console.log("Error")
            })
        this.props.history.push('/submit')
    }

    renderTransactionSection = () => {
        let DesignerFee = [];
        let FabricationFee = [];
        let DesignerExtraFee = [];

        if (this.props.transactions) {
            DesignerFee = this.props.transactions.filter(
                (transaction) => transaction.description === "DESIGNER_FEE"
            );
            FabricationFee = this.props.transactions.filter(
                (transaction) => transaction.description === "FABRICATION_FEE"
            );
            DesignerExtraFee = this.props.transactions.filter(
                (transaction) =>
                    transaction.description === "DESIGNER_EXTRA_FEE"
            );
        }

        if (
            this.props.canvas.fabricationCost &&
            this.props.canvas.fabricationCost !== 0
        ) {
            return (
                <Col xs={12}>
                    <h6>Start Fabrication</h6>
                    <p>
                        To start fabrication, please proceed to pay the deposit
                        for fabrication by clicking below
                    </p>
                    <Button
                        variant='dark'
                        onClick={this.handleRedirect}
                        value='pay-fabrication-fee'
                        style={{
                            borderRadius: "0px",
                            marginTop: "0px",
                            marginLeft: "0px",
                        }}
                    >
                        Pay Fabrication/Design Fees{" "} 
                        {this.props.canvas.fabricationCost}
                    </Button>
                    <p>
                        If you would like to continue designing, you can pay for
                        additional design work be clicking below
                    </p>
                    <Button
                        variant='dark'
                        onClick={this.handleRedirect}
                        value='pay-addl-design-fee'
                        style={{
                            borderRadius: "0px",
                            marginTop: "0px",
                            marginLeft: "0px",
                        }}
                    >
                        Additional Design Round $49
                    </Button>
                </Col>
            );
        } else {
            if (DesignerFee.length === 0) {
                return (
                    <Col xs={12}>
                        <h6>Co:Design with a designer</h6>
                        <p>
                            To collaborate with a designer and maker, please pay
                            the designer fee by clicking on the link below
                        </p>
                        <Button
                            variant='dark'
                            onClick={this.handleRedirect}
                            value='pay-design-fee'
                            style={{
                                borderRadius: "0px",
                                marginTop: "0px",
                                marginLeft: "0px",
                            }}
                        >
                            Pay Designer Fee $49
                        </Button>
                    </Col>
                );
            } else {
                return (
                    <Col xs={12}>
                        <h6>Continue Co:Design</h6>
                        <p>
                            If you would like to continue designing, you can pay
                            for additional design work by clicking below
                        </p>
                        <Button
                            variant='dark'
                            onClick={this.handleRedirect}
                            value='pay-addl-design-fee'
                            style={{
                                borderRadius: "0px",
                                marginTop: "0px",
                                marginLeft: "0px",
                            }}
                        >
                            Additional Design Round $49
                        </Button>
                    </Col>
                );
            }
        }
    };

    refreshCanvas = (canvas_id) => {
        this.props.getCanvas({ id: canvas_id });
        this.props.getTransactionsForCanvas(canvas_id);
    };

    confirmDelivery = () => {
        console.log("Confirm Delivery!");
        const canvas_id = this.props.canvas._id;
        let firstClient = null;
        if (this.props.canvas.users.some(user => user.role === CLIENT)) {
            firstClient = this.props
                .canvas
                .users
                .filter(
                    user =>
                        user.role === CLIENT
                )[0].user;
        }
        postTrigger(
            {
                triggerType: "DELIVERED",
                payload: canvas_id,
                data: {
                    canvas_id: canvas_id,
                    user_id: firstClient,
                },
            },
            (response) => {
                console.log("Posted trigger successfully");
                console.log(response.data);
                setTimeout(() => {
                    this.refreshCanvas(canvas_id);
                }, 5000);
            }
        );
    };

    readyForTender = () => {
        console.log("Ready for Tender!");
        const canvas_id = this.props.canvas._id;
        let firstClient = null;
        if (this.props.canvas.users.some(user => user.role === CLIENT)) {
            firstClient = this.props
                .canvas
                .users
                .filter(
                    user =>
                        user.role === CLIENT
                )[0].user;
        }
        postTrigger(
            {
                triggerType: "READY_FOR_TENDER",
                payload: canvas_id,
                data: {
                    canvas_id: canvas_id,
                    user_id: firstClient,
                },
            },
            (response) => {
                console.log("Posted trigger successfully");
                console.log(response.data);
                setTimeout(() => {
                    this.refreshCanvas(canvas_id);
                }, 5000);
            }
        );
    };

    feedbackRedirect = () => {
        console.log("Leave Feedback redirect");
        // TODO: add leave feedback redirect
    };

    renderQuickActionButton = () => {
        let DesignerFee = [];
        let FabricationFee = [];
        let DesignerExtraFee = [];

        if (this.props.transactions) {
            DesignerFee = this.props.transactions.filter(
                (transaction) => transaction.description === "DESIGNER_FEE"
            );
            FabricationFee = this.props.transactions.filter(
                (transaction) => transaction.description === "FABRICATION_FEE"
            );
            DesignerExtraFee = this.props.transactions.filter(
                (transaction) =>
                    transaction.description === "DESIGNER_EXTRA_FEE"
            );
        }

        var stage_history = null;
        var current_stage = null;
        if (this.props.canvas.stage_history)
            stage_history = this.props.canvas.stage_history.map((value) => {
                return value.stage;
            });
        current_stage = stage_history[stage_history.length - 1];

        // console.log(stage_history);

        var waiting_stages = [
            "DESIGN_FEE_PAID",
            "DESIGNER_ASSIGNED",
            "READY_FOR_TENDER",
            "FABRICATOR_MATCHMAKING",
            "FABRICATION_ASSIGNED",
        ];

        if (stage_history && stage_history.includes("DELIVERED")) {
            return (
                <Button
                    variant='dark'
                    onClick={this.feedbackRedirect}
                    value='feedback'
                    style={{
                        borderRadius: "0px",
                        marginTop: "0px",
                        marginLeft: "0px",
                        width: "100%",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                    }}
                >
                    Leave Feedback
                </Button>
            );
        } else if (
            stage_history &&
            stage_history[stage_history.length - 1] === "SHIPPED"
        ) {
            return (
                <Button
                    variant='dark'
                    onClick={this.confirmDelivery}
                    value='confirm-delivery'
                    style={{
                        borderRadius: "0px",
                        marginTop: "0px",
                        marginLeft: "0px",
                        width: "100%",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                    }}
                >
                    Confirm Delivery
                </Button>
            );
        } else if (
            this.props.canvas.fabricationCost &&
            this.props.canvas.fabricationCost !== 0
        ) {
            return (
                <Button
                    variant='dark'
                    onClick={this.handleRedirect}
                    value='pay-fabrication-fee'
                    style={{
                        borderRadius: "0px",
                        marginTop: "0px",
                        marginLeft: "0px",
                        width: "100%",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                    }}
                >
                    Pay Fabrication/Design Fees $
                    {this.props.canvas.fabricationCost / 100}
                </Button>
            );
        } else if (

            (stage_history &&
                (stage_history[stage_history.length - 1] ===
                    "DESIGN_STAGE_BYPASS_REQUEST" ||
                    (stage_history[stage_history.length - 2] ===
                        "DESIGN_STAGE_BYPASS_REQUEST" &&
                        stage_history[stage_history.length - 1] ===
                        "CANVAS_COMPLETE")))
            ||
            (this.props.canvas.client_type == 'DESIGN' && !this.props.canvas.submitted)
        ) {
            // return (
            //     <Button
            //         variant='dark'
            //         onClick={this.readyForTender}
            //         value='ready-for-tender'
            //         style={{
            //             borderRadius: "0px",
            //             marginTop: "0px",
            //             marginLeft: "0px",
            //             width: "100%",
            //             fontWeight: "200",
            //             fontSize: "0.9rem",
            //         }}
            //     >
            //         Ready For Tender
            //     </Button>
            if (!this.props.canvas.submitted) {
                return (
                    <Button
                        variant='dark'
                        onClick={() => this.submitForAdmin({ "id": this.props.canvas._id, "submitted": true })}
                        value='ready-for-tender'
                        style={{
                            borderRadius: "0px",
                            marginTop: "0px",
                            marginLeft: "0px",
                            width: "100%",
                            fontWeight: "200",
                            fontSize: "0.9rem",
                        }}
                    >
                        Submit
                    </Button>

                );
            }
        } else if (
            DesignerFee.length === 0 &&
            !(
                stage_history &&
                stage_history.includes("DESIGN_STAGE_BYPASS_REQUEST")
            )
        ) {
            return (
                // <Button
                //     variant='dark'
                //     onClick={this.handleRedirect}
                //     value='pay-design-fee'
                //     style={{
                //         borderRadius: "0px",
                //         marginTop: "0px",
                //         marginLeft: "0px",
                //         width: "100%",
                //         fontWeight: "200",
                //         fontSize: "0.9rem",
                //     }}
                // >
                //     Pay Designer Fee $49
                // </Button>

                <Button
                        variant='dark'
                        onClick={() => this.submitForAdmin({"id": this.props.canvas._id, "submitted": true})}
                        value='ready-for-tender'
                        style={{
                            borderRadius: "0px",
                            marginTop: "0px",
                            marginLeft: "0px",
                            width: "100%",
                            fontWeight: "200",
                            fontSize: "0.9rem",
                        }}
                    >
                        Submit
                </Button>
            );
        } else if (current_stage && waiting_stages.includes(current_stage)) {
            return (
                <Button
                    variant='dark'
                    value='pay-design-fee'
                    style={{
                        borderRadius: "0px",
                        marginTop: "0px",
                        marginLeft: "0px",
                        width: "100%",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                    }}
                    disabled
                >
                    <div id='waiting-button'>
                        <p className='fade-text'>Waiting for response</p>
                        <p className='fade-text'>Please check again later</p>
                        <p className='spacer'>Waiting for response</p>
                    </div>
                </Button>
            );
        }
    };

    renderDesignFeeButton = () => {
        let render = <div></div>;
        try {
            if (this.props.transactions) {
                let DesignerFee = this.props.transactions.filter(
                    (transaction) => transaction.description === "DESIGNER_FEE"
                );
                if (DesignerFee.length > 0) {
                    render = (
                        <p>
                            You have already paid the design fee for two
                            iterations
                        </p>
                    );
                } else {
                    console.log("Pos4");
                    render = (
                        <Button
                            variant='dark'
                            onClick={this.handleRedirect}
                            value='/pay-design-fee'
                            style={{ borderRadius: "0px" }}
                        >
                            Pay Designer Fee
                        </Button>
                    );
                }
            } else {
                console.log("Pos4");
                render = (
                    <Button
                        variant='dark'
                        onClick={this.handleRedirect}
                        value='pay-design-fee'
                        style={{
                            borderRadius: "0px",
                            marginTop: "0px",
                            marginLeft: "0px",
                        }}
                    >
                        Pay Designer Fee
                    </Button>
                );
            }
        } catch (error) {
            return (
                <p>
                    Error Retreiving Information: Please check your internet
                    connection.
                </p>
            );
        }

        return render;
    };

    renderExtraDesignFeeButton = () => {
        let render = <div></div>;
        try {
            if (this.props.transactions) {
                let DesignerFee = this.props.transactions.filter(
                    (transaction) => transaction.description === "DESIGNER_FEE"
                );
                let DesignerExtraFee = this.props.transactions.filter(
                    (transaction) =>
                        transaction.description === "DESIGNER_EXTRA_FEE"
                );
                if (DesignerFee.length > 0) {
                    if (DesignerExtraFee.length > 0) {
                        render = (
                            <p>
                                You have already paid the design fee for{" "}
                                {DesignerFee.length} additional iterations
                            </p>
                        );
                    } else {
                        console.log("Pos4");
                        render = (
                            <Button
                                variant='dark'
                                onClick={this.handleRedirect}
                                value='pay-addl-design-fee'
                                style={{
                                    borderRadius: "0px",
                                    marginTop: "0px",
                                    marginLeft: "0px",
                                }}
                            >
                                Pay for additional design work
                            </Button>
                        );
                    }
                }
            }
        } finally {
            return render;
        }
    };

    renderFabricationFeeButton = () => {
        let render = <div></div>;
        try {
            if (this.props.transactions) {
                let DesignerFee = this.props.transactions.filter(
                    (transaction) => transaction.description === "DESIGNER_FEE"
                );
                let FabricationFee = this.props.transactions.filter(
                    (transaction) =>
                        transaction.description === "FABRICATION_FEE"
                );
                let DesignerExtraFee = this.props.transactions.filter(
                    (transaction) =>
                        transaction.description === "DESIGNER_EXTRA_FEE"
                );
                if (FabricationFee.length > 0) {
                    render = (
                        <Col xs={12}>
                            Your piece is being fabricated. We will be in touch
                            when it is finished
                        </Col>
                    );
                } else if (
                    DesignerFee.length > 0 &&
                    this.props.canvas.fabricationCost &&
                    this.props.canvas.fabricationCost !== 0
                ) {
                    render = (
                        <Button
                            variant='dark'
                            onClick={this.handleRedirect}
                            value='pay-fabrication-fee'
                            style={{
                                borderRadius: "0px",
                                marginTop: "0px",
                                marginLeft: "0px",
                            }}
                        >
                            Start Fabrication
                        </Button>
                    );
                }
            }
        } finally {
            return render;
        }
    };

    renderNextSteps = () => {
        let render = (
            <Col style={{ marginTop: '2rem' }}>
                <Row style={{ justifyContent: 'flex-end' }}>
                    <img src={close} style={{ width: '1rem', height: '1rem', cursor: 'pointer' }} onClick={() => { this.setState({ showNextSteps: false }) }}></img>
                </Row>
                <Row>
                    <hr
                        style={{
                            flex: 1,
                            height: "2px",
                            backgroundColor: "white",
                        }}
                    ></hr>
                    <h4
                        style={{
                            marginLeft: 10,
                            marginRight: 10,
                            fontWeight: "bold",
                        }}
                    >
                        Next Steps
                    </h4>
                    <hr
                        style={{
                            flex: 1,
                            height: "2px",
                            backgroundColor: "white",
                        }}
                    ></hr>
                </Row>
                <Row>
                    <div style={{ fontWeight: 100, marginBottom: 10 }}>
                        <span style={{ fontWeight: "bold" }}>
                            Collaborate and Design:{" "}
                        </span>
                        You can provide more details about your idea including
                        dimensions, styles, pictures, etc.
                    </div>
                    <div style={{ fontWeight: 100, marginBottom: 10 }}>
                        <span style={{ fontWeight: "bold" }}>
                            Pay Designer Fee:{" "}
                        </span>
                        Once you are ready to go, pay the designer fee using the
                        button on the top right to get connected to a designer.
                        This will take about 48 hours.
                    </div>
                    <div style={{ fontWeight: 100, marginBottom: 10 }}>
                        <span style={{ fontWeight: "bold" }}>
                            Manage your projects:{" "}
                        </span>
                        The designer will then guide and collaborate with you
                        through the whole process. The designer will respond
                        within 48 hours to your comments.
                    </div>
                </Row>
                <Row>
                    <hr
                        style={{
                            flex: 1,
                            height: "2px",
                            backgroundColor: "white",
                        }}
                    ></hr>
                </Row>
            </Col>
        );

        return render;
    };

    handleRedirect = (e) => {
        e.preventDefault();
        let redirectPage = e.target.value;
        if(
            (redirectPage && redirectPage.startsWith('pay')) &&
            (
                !this.props.user ||
                (this.props.user && !this.props.user.loggedIn)
            )
        )
            return this.setState({
                showErrorModal:true,
                errorModalMessage: 'You need to login to proceed to payment'
            });

        if (redirectPage === "pay-design-fee") {
            this.props.history.push("/checkout");
        } else {
            this.props.history.push("/" + redirectPage);
        }
    };
    renderSketch = () => {
        let render = <div></div>;
        try {
            let sketch = this.props.canvas.sketch;
            if (sketch && sketch.sketchData) {
                render = (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        toggleModal={this.toggleEditAnswerModal}
                        refId={sketch._id}
                        contentType={"SKETCH"}
                        data={sketch}
                    ></CanvasTile>
                );
            } else {
                render = (
                    <CanvasTile
                        getContentType={this.getContentType}
                        contentType={"SKETCH"}
                        data={undefined}
                    ></CanvasTile>
                );
            }
            return render;
        } catch (error) {
            console.log(error);
            return render;
        }
    };

    renderSearches = () => {
        let render = <div></div>;
        try {
            let searches = this.props.canvas.searches;
            if (searches !== undefined) {
                let keywords = Object.keys(searches);
                let all_images = [];
                keywords.map((key) => {
                    if (key.trim() !== "") {
                        all_images = [...all_images, ...searches[key]];
                    }
                });
                render = all_images.map((img) => (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        key={img}
                        refId={img.slice(0, 10)}
                        contentType={"SEARCH"}
                        data={img}
                        toggleModal={this.toggleEditAnswerModal}
                        getContentType={this.getContentType}
                    ></CanvasTile>
                ));
            }
            return render;
        } catch (error) {
            // console.log(error)
            return render;
        }
    };

    renderUploads = () => {
        let render = <div></div>;
        try {
            let uploads = this.props.canvas.uploads;
            if (uploads !== undefined && uploads.length > 0) {
                render = uploads.map((upload) => (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        key={upload._id}
                        refId={upload._id}
                        contentType={"UPLOAD"}
                        toggleModal={this.toggleEditAnswerModal}
                        getContentType={this.getContentType}
                        data={upload}
                    ></CanvasTile>
                ));
            }
            return render;
        } catch (error) {
            console.log(error);
            return render;
        }
    };
    renderTextMoodboard = () => {
        let render = <div></div>;
        try {
            let textMoodboard = this.props.canvas.moodboards.filter(
                (moodboard) => moodboard.isText
            )[0];
            if (textMoodboard !== undefined) {
                textMoodboard = (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        toggleModal={this.toggleEditAnswerModal}
                        refId={textMoodboard._id}
                        contentType={"TEXT_MOODBOARD"}
                        data={textMoodboard}
                        getContentType={this.getContentType}
                    ></CanvasTile>
                );
            } else {
                textMoodboard = (
                    <CanvasTile
                        getContentType={this.getContentType}
                        contentType={"TEXT_MOODBOARD"}
                        data={undefined}
                    ></CanvasTile>
                );
            }

            return textMoodboard;
        } catch (error) {
            console.log(error);
            return render;
        }
    };
    renderVisualMoodboard = () => {
        let render = <div></div>;
        try {
            let visualMoodboard = this.props.canvas.moodboards.filter(
                (moodboard) => !moodboard.isText
            )[0];

            if (visualMoodboard !== undefined) {
                visualMoodboard = visualMoodboard.options.map((option) => (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        toggleModal={this.toggleEditAnswerModal}
                        refId={option}
                        key={option}
                        contentType={"VISUAL_MOODBOARD"}
                        data={option}
                    ></CanvasTile>
                ));
            }
            return visualMoodboard;
        } catch (error) {
            console.log(error);
            return render;
        }
    };
    renderQuizAnswers = () => {
        let render;
        try {
            if (
                this.props.canvas.quizAnswers !== undefined &&
                this.props.canvas.quizAnswers.length > 0
            ) {
                render = this.props.canvas.quizAnswers.map((answer) => (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        toggleModal={this.toggleEditAnswerModal}
                        refId={answer._id}
                        key={answer._id}
                        contentType={"ANSWER"}
                        data={answer}
                    ></CanvasTile>
                ));
            }
        } catch (error) {
            console.log(error);
            render = undefined;
        }
        return render;
    };

    renderDimensions = () => {
        let render;

        try {
            let dimensions = this.props.canvas.dimensions;
            if (
                dimensions &&
                dimensions.length &&
                dimensions.height &&
                dimensions.width
            ) {
                let dimension = this.props.canvas.dimensions;
                render = (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        refId={dimension._id}
                        key={dimension._id}
                        contentType={"DIMENSIONS"}
                        data={dimension}
                        getContentType={this.getContentType}
                    ></CanvasTile>
                );
            } else {
                render = (
                    <CanvasTile
                        getContentType={this.getContentType}
                        contentType={"DIMENSIONS"}
                        data={undefined}
                    ></CanvasTile>
                );
            }
        } catch (error) {
            console.log(error);
            render = undefined;
        }
        return render;
    };

    renderBudget = () => {
        let render;
        try {
            let budget = this.props.canvas.budget;
            if (budget && budget.maximum) {
                render = (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        refId={budget._id}
                        key={budget._id}
                        contentType={"BUDGET"}
                        data={budget}
                        getContentType={this.getContentType}
                    ></CanvasTile>
                );
            } else {
                render = (
                    <CanvasTile
                        getContentType={this.getContentType}
                        contentType={"BUDGET"}
                        data={undefined}
                    ></CanvasTile>
                );
            }
        } catch (error) {
            console.log(error);
            render = undefined;
        }
        return render;
    };

    renderPinterest = () => {
        let render;
        try {
            let pinterest = this.props.canvas.pinterest;
            if (pinterest.trim().length !== 0 || pinterest) {
                render = (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        refId={pinterest}
                        key={pinterest}
                        contentType={"PINTEREST"}
                        data={pinterest}
                        getContentType={this.getContentType}
                        toggleModal={this.toggleEditAnswerModal}
                    ></CanvasTile>
                );
            }
            // else {
            //     render = (
            //         <CanvasTile
            //             getContentType={this.getContentType}
            //             contentType={"PINTEREST"}
            //             data={undefined}
            //         ></CanvasTile>
            //     );
            // }
        } catch (error) {
            console.log(error);
            render = undefined;
        }
        return render;
    };

    renderComments = () => {
        let comments_list = [];
        let render;
        try {
            let comments = this.props.comments[
                localStorage.getItem("nayaCanvasID")
            ];


            if (comments !== undefined && comments.length > 0) {
                let len = comments.length;
                for (let index = 0; index < len; index++) {
                    const commentData = comments[index];
                    if (
                        commentData.comment_text !== "" ||
                        commentData.comment_image_uploads.length > 0 ||
                        commentData.comment_model_uploads.length > 0
                    ) {
                        // console.log(commentData)
                        comments_list.push(
                            <CommentTile
                                replyToFunction={this.replyTo}
                                data={commentData}
                                key={commentData._id}
                                refId={commentData._id}
                            ></CommentTile>
                        );
                    }
                }
            }
            if (this.state.showAllComments || comments_list.length < 5) {
                render = comments_list.reverse();
            }
            else {
                let temp = comments_list.reverse();
                render = temp.slice(0, 5);
            }
        } catch (error) {
            // console.log(error)
            render = <div></div>;
        }
        // console.log(render)
        return render;
    };

    renderApprovalComments = () => {
        let render;
        try {
            let comments = this.props.approval_comments[
                localStorage.getItem("nayaCanvasID")
            ];
            if (comments !== undefined && comments.length > 0) {
                render = [];
                for (let index = 0; index < comments.length; index++) {
                    const commentData = comments[index];
                    if (
                        commentData.comment_text !== "" ||
                        commentData.comment_image_uploads.length > 0 ||
                        commentData.comment_model_uploads.length > 0
                    ) {
                        // console.log(commentData)
                        render.push(
                            <CommentTile
                                data={commentData}
                                key={commentData._id}
                                refId={commentData._id}
                                key={commentData._id}
                                type='APPROVAL_COMMENT'
                            ></CommentTile>
                        );
                    }
                }
            }
        } catch (error) {
            // console.log(error)
            render = <div></div>;
        }
        // console.log(render)
        return render;
    };

    renderShipping = () => {
        let render;

        try {
            let shipping = this.props.canvas.shipping;
            if (shipping && shipping.address) {
                render = (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        refId={shipping._id}
                        key={shipping._id}
                        contentType={"SHIPPING"}
                        getContentType={this.getContentType}
                        data={shipping}
                    ></CanvasTile>
                );
            } else {
                render = (
                    <CanvasTile
                        getContentType={this.getContentType}
                        contentType={"SHIPPING"}
                        data={undefined}
                    ></CanvasTile>
                );
            }
        } catch (error) {
            console.log(error);
            render = undefined;
        }
        return render;
    };

    renderAdditionalInfo = () => {
        let render;
        try {
            if (
                this.props.canvas.additional_info &&
                this.props.canvas.additional_info !== ""
            ) {
                render =
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        toggleModal={this.toggleEditAnswerModal}
                        contentType={"ADDITIONAL_INFO"}
                        data={this.props.canvas.additional_info}
                    ></CanvasTile>
            }
            else {
                render =
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        toggleModal={this.toggleEditAnswerModal}
                        contentType={"ADDITIONAL_INFO"}
                        data={undefined}
                    ></CanvasTile>
            }
        } catch (error) {
            console.log(error);
            render = undefined;
        }
        return render;
    }


    renderStory = () => {
        let render;
        try {
            if (
                this.props.canvas.story &&
                this.props.canvas.story !== ""
            ) {
                render =
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        toggleModal={this.toggleEditAnswerModal}
                        contentType={"STORY"}
                        data={this.props.canvas.story}
                    ></CanvasTile>
            }
            else {
                render =
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        toggleModal={this.toggleEditAnswerModal}
                        contentType={"STORY"}
                        data={undefined}
                    ></CanvasTile>
            }
        } catch (error) {
            console.log(error);
            render = undefined;
        }
        return render;
    }

    renderAddContentBoard = () => {
        let render = <div></div>;

        // console.log(this.state.replyTo)

        try {
            if (this.props.user !== undefined && this.props.user.id !== "") {
                render = (
                    <CommentTileInput
                        replyTo={this.state.replyTo}
                        clearReplyTo={() =>
                            this.setState({ replyTo: undefined })
                        }
                        getRef={(ref) => (this.commentTileRef = ref)}
                    ></CommentTileInput>
                );
            }
            return render;
        } catch (error) {
            return render;
        }
    };

    replyTo = (comment) => {
        this.setState({ replyTo: comment });
        window.setTimeout(() => {
            this.commentTileRef.scrollIntoView();
        }, 200);
    };

    renderCanvasTiles = () => {
        let QuizAnswersRender = this.renderQuizAnswers();
        let TextMoodboardRender = this.renderTextMoodboard();
        let VisualMoodboardRender = this.renderVisualMoodboard();
        let UploadsRender = this.renderUploads();
        let SketchRender = this.renderSketch();
        let SearchRender = this.renderSearches();
        let DimensionsRender = this.renderDimensions();
        let ShippingRenderer = this.renderShipping();
        let BudgetRender = this.renderBudget();
        let PinterestRender = this.renderPinterest();
        let AdditionalInfoRender = this.renderAdditionalInfo();
        let StoryRender = this.renderStory();
        return (
            <Row>
                {QuizAnswersRender}
                {VisualMoodboardRender}
                {SearchRender}
                {UploadsRender}
                {TextMoodboardRender}
                {PinterestRender}
                {DimensionsRender}
                {ShippingRenderer}
                {BudgetRender}
                {SketchRender}
                {StoryRender}
                {AdditionalInfoRender}
            </Row>
        );
    };

    displayMoreComments = () => {
        let comments = this.props.comments[
            localStorage.getItem("nayaCanvasID")
        ];

        if (comments !== undefined && comments.length > 5) {
            return true;
        }
        else {
            return false;
        }
    }

    getStage = () => {
        let data = this.props.canvas.stage_history[this.props.canvas.stage_history.length - 1]
        if (data === undefined) {
            return 0;
        }
        else {
            if (['START', 'SKETCH_PHASE', 'WHITE_MODEL_PHASE', 'MCF', 'FABRICATION', 'COMPLETE'].indexOf(data.stage) === -1) {
                return 0;
            }
            else {
                return ['START', 'SKETCH_PHASE', 'WHITE_MODEL_PHASE', 'MCF', 'FABRICATION', 'COMPLETE'].indexOf(data.stage);
            }
        }
    }

    generateTrackingUrl = (carrier, tracking_num) => {
        switch (carrier) {
            case ("UPS"):
                return (`https://www.ups.com/WebTracking/track?track=yes&trackNums=${tracking_num}`);
            case ("USPS"):
                return (`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking_num}`)
            case ("Fedex"):
                return (`http://www.fedex.com/Tracking?tracknumbers=${tracking_num}`)
            case ("DHL"):
                return (`http://www.dhl.com/en/express/tracking.html?AWB=${tracking_num}&brand=DHL`)
            default:
                return
        }
    }

    renderTrackingTab = () => {
        if (this.props.canvas) {
            if (this.props.canvas.tracking) {
                const { carrier, tracking_number } = this.props.canvas.tracking;
                console.log(carrier, tracking_number);
                return (
                    <Col xs={12} style={{ marginTop: '2rem' }}>
                        <Row style={{ alignItems: "center" }}>
                            <CanvasTile contentType='TRACKING' data={{ url: this.generateTrackingUrl(carrier, tracking_number) }} data={{ carrier: carrier, tracking_number: tracking_number }} />
                        </Row>
                    </Col>
                )
            }
        }
    }

    render() {
        const {showErrorModal, errorModalMessage}= this.state;
        return (
            <Container fluid>
                <Modal show={showErrorModal} onHide={()=>this.setState({showErrorModal:false,errorModalMessage:null})} >
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>{errorModalMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={()=>this.props.history.push('/login')} style={{margin:'0px'}}>
                            Login
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Edit canvas modal */}
                {this.state.editAnswer && (
                    <EditCanvas
                        isOpen={this.state.editAnswer}
                        toggleModal={this.toggleEditAnswerModal}
                        data={this.state.editData}
                        contentType={this.state.contentType}
                        canvasId={this.props.canvas.id}
                    />
                )}
                <NavBar></NavBar>
                {/* Tools modal */}
                {this.state.showToolModal && (
                    <Tool
                        isOpen={this.state.showToolModal}
                        toggleModal={this.toggleToolModal}
                        contentType={this.state.toolContentType}
                    />
                )}
                <Container id='canvas'>
                    <ProgressIndicator stage={this.getStage()}></ProgressIndicator>
                    <Row style={{ marginTop: '2rem' }}>
                        <Col xs={12}>
                            <Row>
                                <Col
                                    xs={12}
                                    md={6}
                                    lg={8}
                                    className='canvas-name'
                                >
                                    {this.props.canvas.name &&
                                        this.props.canvas.name !== "" && (
                                            <h1> {this.props.canvas.name}</h1>
                                        )}
                                    {(!this.props.canvas.name ||
                                        this.props.canvas.name === "") && (
                                            <h1> Canvas</h1>
                                        )}
                                    {
                                        this.props.canvas.users &&
                                        this.props
                                            .canvas
                                            .users
                                            .some(user =>
                                                user.user === this.props.user.id &&
                                                user.role === CLIENT
                                            ) &&
                                        (
                                            <div className='edit-title'>
                                                <a
                                                    href=''
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.toggleEditAnswerModal(
                                                            undefined,
                                                            "STORY"
                                                        );
                                                    }}
                                                >
                                                    edit
                                            </a>
                                            </div>
                                        )}
                                </Col>
                                {this.props.pathway !== "COMPETITION" && (
                                    <Col xs={12} md={6} lg={4}>
                                        {this.renderQuickActionButton()}
                                    </Col>
                                )}
                            </Row>

                            {/* <hr
                                style={{
                                    width: "100%",
                                    height: "2px",
                                    backgroundColor: "black",
                                }}
                            ></hr> */}

                            {/* canvas story */}
                            {/* {this.props.canvas.story &&
                                this.props.canvas.story !== "" && (
                                    <Col xs={12} className='canvas-story'>
                                        <Row
                                            style={{ flexDirection: "column" }}
                                        >
                                            <hr
                                                style={{
                                                    width: "100%",
                                                    height: "2px",
                                                    backgroundColor: "black",
                                                }}
                                            ></hr>
                                            <p
                                                style={{
                                                    fontWeight: "200",
                                                    fontSize: "1.2rem",
                                                }}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.props
                                                            .canvas.story,
                                                    }}
                                                ></div>
                                            </p>{" "}
                                            { this.props.canvas.users &&
                                                this.props
                                                    .canvas
                                                    .users
                                                    .some(user =>
                                                        user.user === this.props.user.id &&
                                                        user.role === CLIENT
                                                    ) && (
                                                <div className='edit-story'>
                                                    <a
                                                        href=''
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.toggleEditAnswerModal(
                                                                undefined,
                                                                "STORY"
                                                            );
                                                        }}
                                                    >
                                                        edit
                                                    </a>
                                                </div>
                                            )}
                                        </Row>
                                    </Col>
                                )} */}

                            {/* additional info */}
                            {/* {this.props.canvas.additional_info &&
                                this.props.canvas.additional_info !== "" && (
                                    <Col xs={12} className='canvas-story'>
                                        <Row
                                            style={{ flexDirection: "column" }}
                                        >
                                            <hr
                                                style={{
                                                    width: "100%",
                                                    height: "2px",
                                                    backgroundColor: "black",
                                                }}
                                            ></hr>
                                            <p
                                                style={{
                                                    fontWeight: "200",
                                                    fontSize: "1.2rem",
                                                }}
                                            >
                                                {
                                                    this.props.canvas
                                                        .additional_info
                                                }
                                            </p>{" "}
                                            {
                                                this.props.canvas.users &&
                                                this.props
                                                    .canvas
                                                    .users
                                                    .some(user =>
                                                        user.user === this.props.user.id &&
                                                        user.role === CLIENT
                                                    ) &&
                                            (
                                                <div className='edit-story'>
                                                    <a
                                                        href=''
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.toggleEditAnswerModal(
                                                                undefined,
                                                                "STORY"
                                                            );
                                                        }}
                                                    >
                                                        edit
                                                    </a>
                                                </div>
                                            )}
                                        </Row>
                                    </Col>
                                )} */}
                            {/* {
                                this.state.showNextSteps && (
                                    <Col xs={12} className="next-steps">
                                        <Row>
                                            {this.renderNextSteps()}
                                        </Row>
                                    </Col>
                                )
                            } */}
                            {/* <hr
                                style={{
                                    width: "100%",
                                    height: "2px",
                                    backgroundColor: "black",
                                }}
                            ></hr> */}
                        </Col>
                        {/* <hr style={{"width": "100%", "height": "2px", "backgroundColor": "black"}}></hr> */}
                        {/* Render Tracking Data */}
                        {
                            this.renderTrackingTab()
                        }

                        {/* renering comments */}
                        <Col xs={12} style={{ marginTop: '2rem' }}>
                            <Row style={{ alignItems: "center" }}>
                                {this.renderAddContentBoard()}
                                {this.renderComments()}
                            </Row>
                        </Col>

                        <Col xs={12}>
                            {
                                this.displayMoreComments() && (
                                    <Row style={{ marginBottom: '1.5rem', cursor: 'pointer', marginTop: '2rem' }}
                                        onClick={() => {
                                            this.setState({ showAllComments: !this.state.showAllComments })
                                        }}>
                                        <hr
                                            style={{
                                                flex: 1,
                                                height: "2px",
                                                backgroundColor: "white",
                                            }}
                                        ></hr>
                                        <Image src={this.state.showAllComments ? less : more} fluid style={{ width: '2rem', height: '2rem', marginLeft: 10 }} />
                                        <h4
                                            style={{
                                                marginLeft: 10,
                                                marginRight: 10,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {
                                                this.state.showAllComments ? "show fewer comments" : "show all comments"
                                            }
                                        </h4>
                                        <hr
                                            style={{
                                                flex: 1,
                                                height: "2px",
                                                backgroundColor: "white",
                                            }}
                                        ></hr>
                                    </Row>
                                )
                            }
                        </Col>

                        <Col xs={12}>
                            <Row style={{ alignItems: 'center', marginTop: '3rem' }}>
                                <Image src={!this.state.tileExpand ? expand : collapse} onClick={() => { this.setState({ tileExpand: !this.state.tileExpand }) }} style={{ width: '2rem', cursor: 'pointer', marginRight: '0.5rem' }} />
                                <h6 style={{ fontSize: '1.5rem', fontWeight: 700, color: '#000' }}>Your Design Brief</h6>
                            </Row>
                        </Col>
                        {
                            this.state.tileExpand && (
                                <Col xs={12}>
                                    {this.renderCanvasTiles()}
                                </Col>
                            )
                        }
                        {/* <hr
                            style={{
                                width: "100%",
                                height: "2px",
                                backgroundColor: "black",
                            }}
                        ></hr> */}
                    </Row>
                </Container>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        canvas: state.canvas,
        comments: state.comments,
        transactions: state.transactions[state.canvas._id],
        pathway: state.UI.pathway,
        approval_comments: state.approval_comments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCanvas: (data) => dispatch(getCanvas(data)),
        setAuthCallback: (data) => dispatch(setAuthCallback(data)),
        sendUpdateCanvasDispatch: (data) => dispatch({ type: 'ADD_PATHWAY', data: data }),
        getTransactionsForCanvas: (id) =>
            dispatch(getTransactionsForCanvas(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Canvas));
