import React, { Component } from "react";

import {setAuthCallback} from '../../actions/authActions';

/**
 * Components
 */
import ProgressBar from "../utilities/ProgressBar";
import BackButton from "../utilities/BackButton";
import Progress from "../utilities/Progress";
import CancelButton from "../utilities/CancelButton";
import Option from "./VisualMoodboardOption";
// import ContinueButton from "../utilities/ContinueButton";

/**
 * Redux Options
 */
import { createMoodboard, editMoodboard } from "../../actions/moodboardActions";

/**
 * Higher Order Components
 */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/**
 * Style
 */
import { Container, Row, Col, Button } from "react-bootstrap";
import "../../stylesheets/style.css";

class VisualMoodboard extends Component {
    constructor(props) {
        super(props);
        let probability_map = {};
        let classes = Object.keys(this.props.options);

        for (let i = 0; i < classes.length; i++) {
            probability_map[classes[i]] = 1.0 / classes.length;
        }

        this.state = {
            selectedOptions: this.props.selectedOptions,
            nOptions: 9,
            options: [],
            probability_map: probability_map,
            mode: this.props.mode,
            moodboardId: this.props.moodboardId,
        };
    }

    componentDidMount() {
        this.setState({
            options: [
                ...this.props.selectedOptions,
                ...this.getRandomImages(this.state.probability_map),
            ],
        });
    }

    /**
     * Selects or deselects the options
     */
    toggleOption = (option_id) => {
        let selectedOptions = this.state.selectedOptions;
        if (selectedOptions.has(option_id)) {
            selectedOptions.delete(option_id);
        } else {
            selectedOptions.add(option_id);
        }
        console.log(selectedOptions);
        this.setState({ selectedOptions });
    };

    /**
     * Handles form submit - Continue button
     */
    handleSubmit = (e) => {
        e.preventDefault();
        let data;
        switch (this.state.mode) {
            case "CREATE":
                data = {
                    options: [...this.state.selectedOptions],
                    isText: false,
                    id: this.props.canvasId,
                };
                this.props.createMoodboard(data);
                break;
            case "EDIT":
                data = {
                    options: [...this.state.selectedOptions],
                    isText: false,
                    id: this.props.moodboardId,
                };
                this.props.editMoodboard(data);
                break;
            default:
                break;
        }
        if(this.props.edit!==true){
            this.props.setAuthCallback(this.props.nextPage)
            this.props.history.push("/login");
        }
        else{
            this.props.toggleModal()
        }
    };

    /**
     * Handles form skip - Skip button
     */
    handleSkip = (e) => {
        e.preventDefault();
        let data;
        switch (this.state.mode) {
            case "CREATE":
                data = {
                    options: this.props.selectedOptions,
                    isText: false,
                    id: this.props.canvasId,
                };
                this.props.createMoodboard(data);
                break;
            case "EDIT":
                data = {
                    options: this.props.selectedOptions,
                    isText: false,
                    id: this.props.moodboardId,
                };
                this.props.editMoodboard(data);
                break;
            default:
                break;
        }
        this.props.setAuthCallback(this.props.nextPage)
        this.props.history.push("/login");
    };

    /**
     * Gets 9 random images from set
     */
    getRandomImages = (probability_map) => {
        var classes = Object.keys(this.props.options);
        var return_set = new Set([]);
        while (
            [...return_set].length <
            this.state.nOptions - [...this.state.selectedOptions].length
        ) {
            // for(let i=0; i<classes.length; i++)
            // {
            let i = Math.floor(Math.random() * classes.length);
            console.log("Random");
            console.log(i);
            let random_proba = Math.random();
            console.log(
                random_proba + " > " + probability_map[classes[i]] + "?"
            );
            // if(random_proba > probability_map[classes[i]])
            // {
            let extract_image = this.props.options[classes[i]][
                Math.floor(
                    Math.random() * this.props.options[classes[i]].length
                )
            ];
            if (!this.state.selectedOptions.has(extract_image)) {
                console.log("Selected");

                console.log(i);
                return_set.add(extract_image);
            }
            // }
            if (
                [...return_set].length ==
                this.state.nOptions - [...this.state.selectedOptions].length
            ) {
                break;
            }
            // }
        }
        return [...return_set];
    };

    /**
     *
     */
    changeOptions = (e) => {
        e.preventDefault();
        this.setState({
            options: [
                ...this.state.selectedOptions,
                ...this.getRandomImages(this.state.probability_map),
            ],
        });
    };

    /**
     * Main Render method
     */
    render() {
        const optionsRender = this.state.options.map((option, index) => (
            <Option
                key={index}
                id={option}
                selected={this.state.selectedOptions.has(option)}
                onClick={this.toggleOption}
                link={option}
            ></Option>
        ));

        return (
            <Container fluid={true}>
                {
                    this.props.edit!==true && (
                        <>
                        <ProgressBar
                            width={(this.props.step * 100) / this.props.stepMax}
                        ></ProgressBar>
                        <Row>
                            <BackButton></BackButton>
                            <Progress
                                step={this.props.step}
                                step_max={this.props.stepMax}
                            ></Progress>
                            <CancelButton></CancelButton>
                        </Row>
                        </>
                    )
                }
                
                <Container>
                    <Row>
                        <h2 className="col-12 question-h2">
                            Pick the images that match your personal or furniture style
                        </h2>
                        <h4
                            className="col-12 question-h4"
                            style={{ textAlign: "center" }}
                        >
                            Choose as many or as few as you like. If you don't find anything you like, click on "Show me more"
                        </h4>
                        <Col xs={12} lg={{ span: 6, offset: 3 }}>
                            <Row>
                                <Col xs={12}>
                                    <Col xs={12} lg={{ span: 6, offset: 3 }}>
                                        <Button
                                            id="show-me-more-btn"
                                            onClick={this.changeOptions}
                                        >
                                            Show me more
                                        </Button>
                                    </Col>
                                </Col>
                                {optionsRender}
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="justify-content-md-center">
                                <Button
                                    variant="dark"
                                    onClick={this.handleSubmit}
                                >
                                    {this.props.edit!==true ? 'Continue' : 'Submit'}
                                </Button>
                            </Row>
                            {/* Skip option is commented out as the question is compulsory */}
                            {/* <Row className="justify-content-md-center">
                                <p
                                    className="skip-button"
                                    onClick={this.handleSkip}
                                >
                                    SKIP THIS QUESTION
                                </p>
                            </Row> */}
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

/**
 * Maps the Redux state to component props
 * @param {*} state
 */
const mapStateToProps = (state) => {
    const options = state.UI.visual_moodboard;
    let visual_moodboard = [];
    let selectedOptions = new Set([]);
    let mode = "CREATE";
    let moodboardId;

    if (state.canvas.moodboards !== undefined) {
        visual_moodboard = state.canvas.moodboards.filter(
            (moodboard) => !moodboard.isText
        );
        if (visual_moodboard.length > 0) {
            selectedOptions = new Set(visual_moodboard[0].options);
            mode = "EDIT";
            moodboardId = visual_moodboard[0]._id;
        }
    }

    return {
        // Moodboard specific
        options,
        selectedOptions,
        moodboardId,
        mode,

        //General Function
        canvasId: state.canvas.id,

        //General UI
        nextPage: state.UI.flow["visual-moodboard"],
        step: Object.keys(state.UI.flow).indexOf("visual-moodboard") + 1,
        stepMax: Object.keys(state.UI.flow).length,
    };
};

/**
 * Maps redux actions to component props
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        createMoodboard: (data) => dispatch(createMoodboard(data)),
        editMoodboard: (data) => dispatch(editMoodboard(data)),
        setAuthCallback: (data) => dispatch(setAuthCallback(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(VisualMoodboard));
