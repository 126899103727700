import React, { Component } from 'react'
import axios from 'axios'

/** 
 * Higher Level Components
 */
import { connect } from 'react-redux'   
import { withRouter } from 'react-router-dom'

/**
 * Redux Actions
 */
import {loginLocal, signUpLocal, loginToken} from '../../actions/authActions'

/**
 * Components
 */
import ProgressBar from '../utilities/ProgressBar'
import BackButton from '../utilities/BackButton'
import Progress from '../utilities/Progress'
import CancelButton from '../utilities/CancelButton'
import BounceLoader from 'react-spinners/BounceLoader';

/**
 * Stu;e
 */
import {Container, Row, Col, Button, Form, Alert} from 'react-bootstrap'
import '../../stylesheets/style.css'
import '../../stylesheets/pre-canvas.css'

/**
 * Media and Images
 */
import { API_ENDPOINT } from  '../../constants/secrets';


/**
 * Default Config
 */
const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
}

axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


class PreCanvas extends Component{
    
    constructor(props)
    {
        super(props)
        this.state = {
            SignUpModal: true,
            email: '',
            password: '',
            user_type: 'USER',
            tnm_signup: false,
            toggleEmailField: false,
            prevPage:'',
        }
    }

    /**
     * Handles Login/SignUp
     */
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.loginLocal(this.state)
        // this.props.history.push("/" + this.props.nextPage)
    }

    /**
     * Show Sign up form
     */
    showSignUp = (e) => {
        e.preventDefault()
        this.setState({SignUpModal: true})
    }

    /**
     * Show login form
     */
    showLogin = (e) => {
        e.preventDefault()
        this.setState({SignUpModal: false})
    }

    /**
     * Handles functionality for login
     */
    handleLoginLocal = (e) => {
        e.preventDefault()
        console.log(this.state.tnm_login)
        if(this.state.tnm_login)
        {
            const data = {
                email: this.state.email,
                password: this.state.password
            }
            this.props.loginLocal(data)
        }
        else
        {
            this.props.loginError("Please read our terms and conditions and click on the checkbox below to continue.")
        }
    }

    /**
     * Handles functionality for sign up
     */
    handleSignupLocal = (e) => {
        e.preventDefault()
        if(this.state.tnm_signup)
        {
            const data = {
                email: this.props.user.email? this.props.user.email:this.state.email,
                password: this.state.password
            }
            this.props.signUpLocal(data)
        }
        else
        {
            this.props.signupError("Please read our terms and conditions and click on the checkbox below to continue.")
        }
        
    }

    /**
     * Handles form changes
     */
    handleChange = (e) => {
        // e.preventDefault()
        // e.stopPropagation()
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log(value)

        this.setState({
            [name]: value
        });
    }


    /**
     * Sign In form render
     */
    signInModal = () =>
    {
            
            return (
                    <Container fluid>
                        <ProgressBar width={(this.props.step*100)/this.props.stepMax}></ProgressBar>
                        <Row>
                            <BackButton></BackButton>
                            <Col xs={6}></Col>
                            <CancelButton></CancelButton>
                        </Row>
                        <Container className="pre-canvas-wrapper">
                            <Row>
                                <Col xs={12}>
                                    <div className="pre-canvas-h2">
                                        <h2 className="col-12">Login to save your project</h2>
                                        <Col xs={12} lg={{span:6, offset:3}}>
                                            {this.props.errorMessage !== undefined && this.props.errorMessage !== "jwt malformed"  && this.props.errorMessage !== "jwt expired" && <Alert variant={"danger"}>{this.props.errorMessage}</Alert>}

                                            <Form>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Email address</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email"  name='email' value={this.state.email} onChange={this.handleChange}/>
                                                </Form.Group>

                                                <Form.Group controlId="formBasicPassword">
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control type="password" placeholder="Password" name='password' value={this.state.password} onChange={this.handleChange} />
                                                </Form.Group>
                                                <Col xs={12}>
                                                    <Row className='justify-content-md-center'>
                                                        <Button variant="dark" onClick={this.handleSubmit}>Login</Button>
                                                    </Row>
                                                    <Row className='justify-content-md-center'>
                                                        <p className='skip-button' onClick={this.showSignUp}>DON'T HAVE AN ACCOUNT? CLICK HERE TO SIGN UP</p>
                                                    </Row>
                                                </Col>
                                                </Form>
                                            </Col>  
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>)
    }
    
    /**
     * Sign Up form Render
     */
    signUpModal = () =>
    {
            
            return <Container fluid>
                        <ProgressBar width={(this.props.step*100)/this.props.stepMax}></ProgressBar>
                        <Row>
                            <BackButton></BackButton>
                            <Progress step={this.props.step} step_max={this.props.stepMax}></Progress>
                            <CancelButton></CancelButton>
                        </Row>
                    <Container>
                        <Row>
                        <Col xs={12}>
                                <div className="pre-canvas-h2">
                                    <h2 className="col-12">Sign up for a Naya account</h2>

                                    <Col xs={12} lg={{span:6, offset:3}}>
                                    {this.props.signUpError !== undefined && <Alert variant={"danger"}>{this.props.signUpError}</Alert>}
                                        <Form>
                                            {(this.props.user && this.props.user.email) && !this.state.toggleEmailField? 
                                            <div>
                                                <p>Email</p>
                                                <p style={{"fontWeight":"400", "marginBottom":"0.5rem"}}>{this.props.user.email}</p>
                                                <p style={{"fontWeight":"400", "fontSize":"0.9rem", "color":"grey"}}>Not you? <a href="#" onClick={() => this.setState({toggleEmailField: true})}>Change</a></p>
                                            </div>: 
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control type="email" placeholder="Enter email"  name='email' value={this.state.email} onChange={this.handleChange}/>
                                            </Form.Group>}

                                            <Form.Group controlId="formBasicPassword">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="password" placeholder="Password" name='password' value={this.state.password} onChange={this.handleChange} />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control type="password" placeholder="Password" name='password' value={this.state.password} onChange={this.handleChange} />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicCheckbox">
                                                <Form.Check 
                                                        type="checkbox" 
                                                        label="I agree to the terms and condtions"
                                                        name="tnm_signup"
                                                        defaultChecked={this.state.tnm_signup}
                                                        onChange={this.handleChange} />
                                            </Form.Group>
                                              
                                            <Col xs={12}>
                                                <Row className='justify-content-md-center'>
                                                    <Button variant="dark" onClick={this.handleSignupLocal}>Sign Up</Button>
                                                </Row>
                                                <Row className='justify-content-md-center'>
                                                    <p className='skip-button'   onClick={this.showLogin}>ALREADY HAVE AN ACCOUNT? CLICK HERE TO LOGIN</p>
                                                </Row>
                                                <Row className='justify-content-md-center'>
                                                    <p className='skip-button'   onClick={() => this.props.history.push(this.props.nextPage)}>CONTINUE WITHOUT ACCOUNT</p>
                                                </Row>
                                            </Col>
                                            </Form>
                                        </Col>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    </Container>
    }

    /**
     * Main Render Method
     */
    render()
    {
        let renderedForm = 
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="pre-canvas-h2">
                        <BounceLoader></BounceLoader>
                    </div>
                </Col>
            </Row>
        </Container>

        if(this.props.user || this.props.user.id)
        {
            if(!this.state.SignUpModal)
            {
                renderedForm = this.signInModal()
            }
            else
            {
                renderedForm = this.signUpModal()
            }
        }

        return (
            renderedForm
        )
    }

    /**
     * Checks if login was successful
     */
    checkForUser = () =>
    {
        console.log("Checking for user");
        console.log(this.props.user)
        console.log(this.props.user.email)
        console.log(this.props.user && this.props.user.email)
        if(this.props.user && this.props.user.email)
        {
            console.log("This should be working")
        }
        if(this.props.user && this.props.user.id)
        {
            console.log("User is logged in");

            const ADD_CANVAS_TO_USER_URI = `${API_ENDPOINT}/api/user/canvas`
            
            const data ={
                canvas_id: this.props.canvas._id,
                user_id: this.props.user.id
            }

            

            axios.post(ADD_CANVAS_TO_USER_URI, data , config)
                .then(response => {
                    console.log(response)
                    if(response.data.status)
                    {
                        this.props.history.push(this.props.nextPage)
                    }
                }).catch(e => {
                    console.log(e)
                })
        }
        else if(this.props.user && this.props.user.email)
        {
            console.log("Saving Lead Status");

            const ADD_CANVAS_TO_LEAD_URI = `${API_ENDPOINT}/api/lead`
            
            const data ={
                canvas: this.props.canvas._id,
                email: this.props.user.email
            }

            
            axios.put(ADD_CANVAS_TO_LEAD_URI, data , config)
            .then(response => {
                console.log(response)
            }).catch(e => {
                console.log(e)
            })
        }
    }

    /**
     * Called immediately after component intialized
     */
    componentDidMount()
    {
        this.checkForUser()
    }

    /**
     * Called immediately after render
     */
    componentDidUpdate()
    {
        this.checkForUser()
    }
}


/**
 * Maps Redux state to component props
 * @param {*} state 
 */
const mapStateToProps = (state, ownProps) => {
    console.log("CURRENT PAGE:",window.location.pathname.substring(1))
    return { 

        // nextPage: 'canvas',
        //General UI
        nextPage: state.UI.flow[window.location.pathname.substring(1)],
        step: Object.keys(state.UI.flow).indexOf(window.location.pathname.substring(1)) + 1,
        stepMax: Object.keys(state.UI.flow).length,
        canvas: state.canvas,
        canvas_id: state.canvas._id,
        // step: Object.keys(state.UI.flow).length-1,
        // stepMax: Object.keys(state.UI.flow).length,
        user: state.user,
        errorMessage: state.errors.loginError,
        signUpError: state.errors.signupError,
    }
}

/**
 * Maps Redux actions to component props
 * @param {*} dispatch 
 */
const mapDispatchToProps = (dispatch) => {
    
    return { 
        signUpLocal: (data) => dispatch(signUpLocal(data)), 
        loginLocal: (data) => dispatch(loginLocal(data)), 
        loginToken: (data) => dispatch(loginToken(data)),
        loginError: (message) => dispatch({type:"LOGIN_ERROR", message: message}),
        signupError: (message) => dispatch({type:"SIGNUP_ERROR", message: message})

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PreCanvas))