import { API_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'
import { getCanvas } from './canvasActions'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const createAnswer = (data) => 
{

    return (dispatch) => {
        //Create Answer
        const CREATE_ANSWER_DATA_URI = `${API_ENDPOINT}/api/answer`
        const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas/answer`
        console.log(data)
        axios.post(CREATE_ANSWER_DATA_URI, data, config)
            .then(response => {
                console.log(response)
                data["answer"] = response.data.id
                axios.post(UPDATE_CANVAS_DATA_URI, data)
                .then(response => {
                    console.log("Added answer to canvas")
                    dispatch(getCanvas())
                })
            }).catch(e => {
                console.log(e)
            })
        dispatch({ type: 'CREATE_ANSWER', data});
    }
}

export const editAnswer = (data) => 
{
    return (dispatch) => {
        //Create Answer
        const CREATE_ANSWER_DATA_URI = `${API_ENDPOINT}/api/answer`
        console.log(data)
        axios.put(CREATE_ANSWER_DATA_URI, data, config)
            .then(response => {
                dispatch(getCanvas())
            }).catch(e => {
                console.log(e)
            })
        dispatch({ type: 'EDIT_ANSWER', data});
    }
}

export const deleteAnswer = (data) => 
{
    return (dispatch) => {
        //Create Answer
        const DELETE_ANSWER_DATA_URI = `${API_ENDPOINT}/api/answer`
        console.log(data)
        axios.delete(DELETE_ANSWER_DATA_URI, {params:data}, config)
            .then(response => {
                dispatch(getCanvas())
            }).catch(e => {
                console.log(e)
            })
        dispatch({ type: 'DELETE_ANSWER', data});
    }
}