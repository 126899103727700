const initLoading = {
}

let DEBUG = false

const loadingReducer = (state = initLoading, action) => {
    let newState

    if(DEBUG) console.log("LOADING REDUCER:")
    if(DEBUG) console.log("ACTION:")
    if(DEBUG) console.log(action)

    if(DEBUG) console.log("CURRENT STATE:")
    if(DEBUG) console.log(state)

    switch(action.type)
    {
        case 'SET_LOADING':
            newState = {...state, [action.key]: action.value}
            break;
        case 'LOGIN_INIT':
            newState = {...state, login: true}
            break
        case 'LOGIN_LOCAL':
            newState = {...state, login: false}
            break
        case 'LOGIN_ERROR':
            newState = {...state, login: false}
            break
        case 'GOOGLE_SEARCH_INIT':
            newState = {...state, google_search: true}
            break;
        case 'INSTA_SEARCH_INIT':
            newState = {...state, instagram_search: true}
            break;
        case 'GOOGLE_SEARCH':
            newState = {...state, google_search: false}
            break;
        case 'INSTA_SEARCH':
            newState = {...state, instagram_search: false}
            break;
        default:
            newState = state
    }

    if(DEBUG) console.log("NEW STATE:")
    if(DEBUG) console.log(newState)

    return newState
}

export default loadingReducer