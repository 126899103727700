import React, { Component } from "react";
import queryString from 'query-string';
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCanvas } from "./actions/canvasActions";
import { getInitData } from "./actions/getInitData";
import { loginCookie } from "./actions/authActions";
import { Elements, StripeProvider } from "react-stripe-elements";

import Question from "./components/quiz/Question";
import Dimension from "./components/dimension/Dimension";
import Auth from "./components/auth/Auth";
import Account from "./components/account/Account";
import Settings from "./components/account/Settings";
import Verify from "./components/account/Verify";

import TextMoodboard from "./components/moodboard/TextMoodboard";
import VisualMoodboard from "./components/moodboard/VisualMoodboard";
import SearchUploadPage from "./components/search/SearchUploadPage";
import SearchPage from "./components/search/SearchPage";
import UploadPage from "./components/search/UploadPage";

import SketchPage from "./components/sketch/Sketch";
import PreCanvas from "./components/canvas/PreCanvas";
import Landing from "./components/landing/Landing";
import Competition from "./components/landing/Competition";
import CompetitionLanding from "./components/landing/CompetitionLanding";
import ContestPage from "./components/contest/ContestPage";
// import Instructions from "./components/landing/Instructions";

import Canvas from "./components/canvas/Canvas";
import PayDesignFee from "./components/transactions/PayDesignFee";
import PayAddlDesignFee from "./components/transactions/PayAddlDesignFee";
import PayFabricationFee from "./components/transactions/PayFabricationFee";
import ResetPassword from "./components/auth/ResetPassword";

import Checkout from "./components/checkout/Checkout";
import Submit from "./components/canvas/Submit";

import AdditionalDetails from "./components/additional-details/AdditionalDetails";

import TOSContainer from "./components/tos/TOSContainer";
import "./App.css";
import Story from "./components/story/Story";
import Annotate from "./components/sketch/Annotate";

import AndSplash from "./components/splash-page/SplashPage";
import SustainabilitySplash from "./components/sustainability-splash/SplashPage";
import Logout from "./components/account/Logout";

//for cookies auth
import cookie from 'cookie'
import Firebase from './services/firebase'
import { checkStatus, getAuthUser, logoutFirebase } from './actions/firebaseActions'
import { HOME_PAGE_ENDPOINT, ECOSYSTEM_APP_ENDPOINT, AUTH_FRONTEND_ENDPOINT } from './constants/secrets'
import SplashAuth from './components/auth/SplashAuth'
import { getRedirectUri } from "./services/qsRedirect";

let count = 0;

function QuestionRouter({ match }) {
    let QuestionRender = (
        <Question key={count++} id={match.params.q_id}></Question>
    );
    return QuestionRender;
}

function ResetPasswordRouter({ match }) {
    let ResetPasswordRender = (
        <ResetPassword token={match.params.token}></ResetPassword>
    );
    return ResetPasswordRender;
}

function CanvasRouter({ match }) {
    localStorage.setItem("nayaCanvasID", match.params.canvas_id);
    return <Canvas id={match.params.canvas_id}></Canvas>;
}

const LoginAndRedirect = ({ location, history }) => {
    const parsed = queryString.parse(location.search);
    const { jwt, canvasId, page } = parsed;
    localStorage.setItem("nayaJwtToken", jwt)
    switch (page) {
        case 'canvas':
            history.push(`/canvas/${canvasId}`);
            break;
        case 'account':
            history.push(`/account`);
            break;
        default:
            history.push(`/`);
            break;
    }
    return null;
}
class App extends Component {
    state = {
        //true if user is logged in, has user type match up with their terms of service agreement.
        authenticated: false,
        //checkingAuthStatus happens when the system is checking firebase and mongo if a user is logged in or not. false when the check is done.
        checkingAuthStatus: true,
    }

    componentDidMount() {
        const parsedCookie = cookie.parse(document.cookie);
        const { csst = null } = parsedCookie;
        if (!csst)
            logoutFirebase()

        Firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                //verification
                const verified = await getAuthUser()

                if (!verified) {
                    console.log('User does not exist in our database. Please contact us at info@naya.studio')
                    this.setState({
                        checkingAuthStatus: false
                    })
                    await logoutFirebase()
                    return window.location.href = `${AUTH_FRONTEND_ENDPOINT}?${getRedirectUri()}`
                }

                this.props.getCanvas(undefined);
                this.props.getInitData();
                this.props.loginCookie()

            } else {
                this.setState({ authenticated: false })
                //await is important here and not sure why the linter is throwing a fit, 
                const status = await checkStatus()

                // if not logged in, redirect to sign in
                if (!status) {
                    window.location.href = `${AUTH_FRONTEND_ENDPOINT}?${getRedirectUri()}`
                }
            }
        })
    }

    //checks for user_type and if they have accepted our terms of service for their user_type
    //i.e. if DESIGNER then they need to have accepted the designer terms of service.
    componentDidUpdate(prevProps) {
        const {
            user: {
                acceptedTermsOfService: {
                    designer: prevDesigner, //boolean
                    maker: prevMaker, //boolean
                    client: prevClient //boolean
                },
                user_type: prevUserType, //[USER, ADMIN, DESIGNER, MAKER, DESIGNER_MAKER]
                id: prevId //previous _id
            }
        } = prevProps

        const {
            checkingAuthStatus,
            user: {
                acceptedTermsOfService: {
                    designer, //boolean
                    maker, //boolean
                    client //boolean
                },
                user_type, //[USER, ADMIN, DESIGNER, MAKER, DESIGNER_MAKER]
                id, //_id
            }
        } = this.props

        //When user redux store loads from endpoint and after the user has changed terms of service
        if (
            prevId !== id
            || prevClient !== client
            || prevUserType !== user_type
            || checkingAuthStatus
        ) {

            //if user is admin, allowed to site
            if (user_type === 'ADMIN') {
                return this.setState({
                    checkingAuthStatus: false,
                    authenticated: true
                })
            }

            //if user is a designer and maker and have agreed to both designer and maker Terms of Services, allowed to site.
            if (user_type === 'DESIGNER_MAKER' && (designer && maker)) {
                return this.setState({
                    checkingAuthStatus: false,
                    authenticated: true
                })
            }

            //if user is designer and have agreed to the designer Terms of Service, allowed to site.
            if (user_type === 'DESIGNER' && designer) {
                return this.setState({
                    checkingAuthStatus: false,
                    authenticated: true
                })
            }

            //if user is fabricator/maker and have agreed to the maker Terms of Service, allowed to site.
            if (user_type === 'MAKER' && maker) {
                return this.setState({
                    checkingAuthStatus: false,
                    authenticated: true
                })
            }

            //if user is client and have agreed to the client Terms of Service, allowed to site
            if (user_type === 'USER' && client) {
                return this.setState({
                    checkingAuthStatus: false,
                    authenticated: true
                })
            }

            // if user is ecosystem and has not signed their respective terms of service, redirect them to ecosystem.naya.studio
            if (user_type === 'DESIGNER' || user_type === 'MAKER' || user_type === 'DESIGNER_MAKER')
                return window.location.href = `${ECOSYSTEM_APP_ENDPOINT}?${getRedirectUri()}`

            //sent to click checkbox for agree to Terms of Service page (SplashAuth.js)
            this.setState({
                checkingAuthStatus: false,
                authenticated: false,
            })
        }
    }

    render() {
        return (
            <>
                { !this.state.authenticated 
                    && <SplashAuth
                        checkingAuthStatus={this.state.checkingAuthStatus}
                        authenticated={this.state.authenticated}
                        setState={newState => this.setState(newState)}
                    />
                }
                
                <Switch>
                    <Route
                        path='/login_redirect'
                        component={LoginAndRedirect}
                    />

                    <Route path='/dimensions'>
                        <Dimension key={count++}></Dimension>
                    </Route>

                    <Route path='/pay-design-fee/:type'>
                        <StripeProvider apiKey='pk_live_VZafJg0kvPtVpoD7cN5a1Bry00KDq5atyX'>
                            <Elements>
                                <PayDesignFee></PayDesignFee>
                            </Elements>
                        </StripeProvider>
                    </Route>

                    <Route path='/pay-addl-design-fee'>
                        <StripeProvider apiKey='pk_live_VZafJg0kvPtVpoD7cN5a1Bry00KDq5atyX'>
                            <Elements>
                                <PayAddlDesignFee></PayAddlDesignFee>
                            </Elements>
                        </StripeProvider>
                    </Route>
                    <Route path='/pay-fabrication-fee'>
                        <StripeProvider apiKey='pk_live_VZafJg0kvPtVpoD7cN5a1Bry00KDq5atyX'>
                            <Elements>
                                <PayFabricationFee></PayFabricationFee>
                            </Elements>
                        </StripeProvider>
                    </Route>
                    <Route path='/logout' component={Logout} />
                    <Route path='/login'>
                        <Auth key={count++}></Auth>
                    </Route>

                    <Route path='/login-admin'>
                        <Auth key={count++} external='admin-panel'></Auth>
                    </Route>

                    <Route path='/login-ecosystem'>
                        <Auth key={count++} external='ecosystem'></Auth>
                    </Route>

                    <Route path='/account'>
                        <Account></Account>
                    </Route>

                    <Route path='/settings'>
                        <Settings></Settings>
                    </Route>

                    <Route path='/verify'>
                        <Verify></Verify>
                    </Route>

                    <Route path='/text-moodboard'>
                        <TextMoodboard key={count++}></TextMoodboard>
                    </Route>

                    <Route path='/visual-moodboard'>
                        <VisualMoodboard key={count++}></VisualMoodboard>
                    </Route>

                    <Route path='/search-upload'>
                        <SearchUploadPage key={count++}></SearchUploadPage>
                    </Route>

                    <Route path='/upload'>
                        <UploadPage key={count++}></UploadPage>
                    </Route>

                    <Route path='/search'>
                        <SearchPage key={count++}></SearchPage>
                    </Route>

                    <Route path='/upload'>
                        <UploadPage key={count++}></UploadPage>
                    </Route>

                    <Route path='/upload'>
                        <SearchPage key={count++}></SearchPage>
                    </Route>

                    <Route path='/sketch'>
                        <SketchPage key={count++}></SketchPage>
                    </Route>

                    <Route path='/pre-canvas'>
                        <PreCanvas key={count++}></PreCanvas>
                    </Route>

                    <Route path='/story'>
                        <Story key={count++}></Story>
                    </Route>

                    <Route path='/annotate'>
                        <Annotate></Annotate>
                    </Route>

                    <Route path='/canvas/:canvas_id' component={CanvasRouter} />

                    <Route path='/canvas'>
                        <Canvas></Canvas>
                    </Route>

                    <Route path={`/q:q_id`} component={QuestionRouter} />

                    <Route
                        path='/reset-password/:token'
                        component={ResetPasswordRouter}
                    />

                    <Route path='/competition'>
                        <Competition></Competition>
                    </Route>

                    <Route path='/competition-landing'>
                        <CompetitionLanding></CompetitionLanding>
                    </Route>

                    <Route path='/contest'>
                        <ContestPage></ContestPage>
                    </Route>

                    <Route path='/checkout'>
                        <Checkout></Checkout>
                    </Route>

                    <Route path='/submit'>
                        <Submit></Submit>
                    </Route>

                    <Route path='/TOS/maker'>
                        <TOSContainer type='maker'></TOSContainer>
                    </Route>

                    <Route path='/TOS/designer'>
                        <TOSContainer type='designer'></TOSContainer>
                    </Route>

                    <Route path='/additional-details'>
                        <AdditionalDetails></AdditionalDetails>
                    </Route>

                    <Route path='/AnD'>
                        <AndSplash></AndSplash>
                    </Route>

                    <Route path='/sustainability'>
                        <SustainabilitySplash></SustainabilitySplash>
                    </Route>

                    <Route path='/'>
                        <Landing></Landing>
                    </Route>
                </Switch>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        canvas: state.canvas,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCanvas: (data) => dispatch(getCanvas(data)),
        getInitData: () => dispatch(getInitData()),
        loginCookie: () => dispatch(loginCookie()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
