import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Container, Row, Col, Image} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {loginToken} from '../../actions/authActions'
import CanvasManager from './CanvasManager'
import '../../stylesheets/account.css'
import NavBar from '../utilities/NavBar'
import ProfilePic from './ProfilePic'
import ProfileDetails from './ProfileDetails'

class Account extends Component{

    componentOnMount() {
        console.log(this.props)
    }

    renderCanvasManager = () =>
    {
        let CanvasManagerApp = [<div></div>]
        // Displaying user canvases
        if(this.props.user !== undefined && this.props.user.user_canvas !== undefined && this.props.user.user_canvas.length>0)
        {
                CanvasManagerApp.push( <CanvasManager canvas_ids={this.props.user.user_canvas} type="user_canvas"></CanvasManager>)
        }
        // Displaying design canvases
        if(this.props.user !== undefined && this.props.user.design_canvas !== undefined && this.props.user.design_canvas.length>0)
        {
                CanvasManagerApp.push(<CanvasManager canvas_ids={this.props.user.design_canvas} type="design_canvas"></CanvasManager>)
        }
        // Displaying fabrication canvases
        if(this.props.user !== undefined && this.props.user.fabrication_canvas !== undefined && this.props.user.fabrication_canvas.length>0)
        {
                CanvasManagerApp.push(<CanvasManager canvas_ids={this.props.user.fabrication_canvas} type="fabrication_canvas"></CanvasManager>)
        }
        // Displaying fabrication canvases
        if(this.props.user !== undefined && this.props.user.archived_canvas !== undefined && this.props.user.archived_canvas.length>0)
        {
                CanvasManagerApp.push(<CanvasManager canvas_ids={this.props.user.archived_canvas} type="archived_canvas"></CanvasManager>)
        }
        return CanvasManagerApp

    }

    render()
    {
        return (
            <Container fluid>
                <NavBar></NavBar>
                <Container id="account">
                    <Row>
                        {/* <h2 className="col-12">Welcome!</h2> */}
                        <Col xs={12}>
                            <Row >
                                <Col lg={{span:8, offset:2}} style={{"textAlign":"center"}}>
                                    {/* <Image src={userIcon} fluid></Image> */}
                                    <Row className="align-items-center">
                                        <Col xs={12} lg={6}>
                                            <ProfilePic></ProfilePic>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <ProfileDetails></ProfileDetails>
                                        </Col>
                                    </Row>
                                    {/* <p>{this.props.user.email.split("@")[0]}</p> */}
                                </Col>
                            </Row>
                        </Col>
                        {/* <hr style={{"height": "1px", "backgroundColor":"black", "width": "100%"}}></hr> */}
                        <Col style={{paddingTop:'2rem'}}>
                        {this.renderCanvasManager()}
                        </Col>
                    </Row>
                </Container>
            </Container>
        )
    }
}



const mapStateToProps = (state) => {
    return { 
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return { 
        loginToken: (data) => dispatch(loginToken(data)), 
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account))