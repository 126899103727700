import { API_ENDPOINT } from  '../constants/secrets'

import axios from 'axios'

const config = {
  headers: {
      "Access-Control-Allow-Origin": "*"    }
}
  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const setLead = (data) => 
{
    return (dispatch) => {
    const CREATE_LEAD = `${API_ENDPOINT}/api/lead`

        axios.post(CREATE_LEAD, data, config)
        .then(response => response.data).then(response => {
            console.log(response)
        }).catch(e => {
            console.log(e)
        })
    }
}

export const updateLead = (data) => 
{
  
    return (dispatch) => {
        const UPDATE_LEAD = `${API_ENDPOINT}/api/lead`

        axios.put(UPDATE_LEAD, data, config)
        .then(response => response.data).then(response => {
            console.log(response)
        }).catch(e => {
            console.log(e)
        })
    }
}



