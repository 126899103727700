let API_ENDPOINT;
let GOOGLE_SEARCH_ENDPOINT;
let INSTA_SEARCH_ENDPOINT;
let FRONTEND_ENDPOINT;
let PROJECT_BACKEND_ENDPOINT;
let UPLOAD_ENDPOINT;
let SHARING_API;
let SHARING_PORTAL_FRONTEND;
let ADMIN_PANEL_FRONTEND;
let ECOSYSTEM_APP_ENDPOINT;
let HOME_PAGE_ENDPOINT;
let AUTH_BACKEND_ENDPOINT
let AUTH_FRONTEND_ENDPOINT

if (process.env.REACT_APP_STAGING) {
    FRONTEND_ENDPOINT = "http://canary.naya.studio";
    API_ENDPOINT = "https://stg-api.naya.studio";
    GOOGLE_SEARCH_ENDPOINT =
        "https://www.googleapis.com/customsearch/v1?cx=017582732796115664878%3Amjnrwzjj1j4&imgColorType=color&searchType=image&key=AIzaSyAM6brFB5bwrhn6DRw-Wj_IendGbvNbVY8&q=";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-staging-ziylmcndpq-ue.a.run.app/upload";
    PROJECT_BACKEND_ENDPOINT =
        "https://naya-project-backend-staging-ziylmcndpq-ue.a.run.app";
    ADMIN_PANEL_FRONTEND = "https://stg-control.naya.studio";
    ECOSYSTEM_APP_ENDPOINT = "https://stg-eco.naya.studio";
    HOME_PAGE_ENDPOINT = "https://naya.studio";
    AUTH_BACKEND_ENDPOINT = 'https://stg-auth.backend.naya.studio'
    AUTH_FRONTEND_ENDPOINT = 'https://stg-auth.naya.studio'
} else if (process.env.NODE_ENV === "production") {
    FRONTEND_ENDPOINT = "http://app.naya.studio";
    API_ENDPOINT = "https://api.naya.studio";
    GOOGLE_SEARCH_ENDPOINT =
        "https://www.googleapis.com/customsearch/v1?cx=017582732796115664878%3Amjnrwzjj1j4&imgColorType=color&searchType=image&key=AIzaSyAM6brFB5bwrhn6DRw-Wj_IendGbvNbVY8&q=";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-production-ziylmcndpq-ue.a.run.app/upload";
    PROJECT_BACKEND_ENDPOINT =
        "https://naya-project-backend-production-ziylmcndpq-ue.a.run.app";
    SHARING_API = "https://naya-sharing-backend-ziylmcndpq-ue.a.run.app";
    SHARING_PORTAL_FRONTEND =
        "https://naya-sharing-portal-ziylmcndpq-ue.a.run.app";
    ADMIN_PANEL_FRONTEND = "https://admin.naya.studio";
    ECOSYSTEM_APP_ENDPOINT ='https://ecosystem.naya.studio';
    HOME_PAGE_ENDPOINT = "https://naya.studio";
    AUTH_BACKEND_ENDPOINT = 'https://auth.backend.naya.studio'
    AUTH_FRONTEND_ENDPOINT = 'https://auth.naya.studio'
} else {
    FRONTEND_ENDPOINT = "http://localhost:3000";
    API_ENDPOINT = "http://localhost:3005";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-development-ziylmcndpq-ue.a.run.app/upload";
    PROJECT_BACKEND_ENDPOINT =
        "https://naya-project-backend-development-ziylmcndpq-ue.a.run.app";
    GOOGLE_SEARCH_ENDPOINT =
        "https://www.googleapis.com/customsearch/v1?cx=017582732796115664878%3Amjnrwzjj1j4&imgColorType=color&searchType=image&key=AIzaSyAM6brFB5bwrhn6DRw-Wj_IendGbvNbVY8&q=";
    SHARING_API = "http://localhost:3010";
    SHARING_PORTAL_FRONTEND = "http://localhost:3001";
    ADMIN_PANEL_FRONTEND = "http://localhost:3000";
    ECOSYSTEM_APP_ENDPOINT = "http://localhost:3003";
    HOME_PAGE_ENDPOINT = "http://localhost:3008";
    AUTH_BACKEND_ENDPOINT = "http://localhost:3015"
    AUTH_FRONTEND_ENDPOINT = "http://localhost:3013"
}

const ECOSYSTEM_SIGNUP_ENDPOINT = `${ECOSYSTEM_APP_ENDPOINT}/signup`;

export {
    API_ENDPOINT,
    GOOGLE_SEARCH_ENDPOINT,
    FRONTEND_ENDPOINT,
    UPLOAD_ENDPOINT,
    PROJECT_BACKEND_ENDPOINT,
    SHARING_API,
    SHARING_PORTAL_FRONTEND,
    ADMIN_PANEL_FRONTEND,
    ECOSYSTEM_APP_ENDPOINT,
    ECOSYSTEM_SIGNUP_ENDPOINT,
    HOME_PAGE_ENDPOINT,
    AUTH_BACKEND_ENDPOINT,
    AUTH_FRONTEND_ENDPOINT,
};
