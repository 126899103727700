const initState = {
    text_moodboard: {
        string: ["thin", "keep", "determine", "satisfied", "cool", "vegetable"],
        direction: [
            "pot",
            "thousand",
            "alive",
            "pick",
            "small",
            "business",
            "headed",
            "region",
        ],
        pound: [
            "tomorrow",
            "right",
            "when",
            "figure",
            "not",
            "getting",
            "cut",
            "pain",
        ],
        with: [
            "coach",
            "only",
            "then",
            "major",
            "greater",
            "effect",
            "active",
            "seven",
        ],
    },
    // visual_moodboard: {
    //     African: [
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578141950.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578147015.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578151462.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578155266.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578159400.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578163803.jpeg",
    //     ],
    //     Arabic: [
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578195363.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578200073.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578204722.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578209439.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578215289.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578223934.jpeg",
    //     ],
    //     Italian: [
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578248178.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578253488.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578259283.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578265363.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578276071.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578280997.jpeg",
    //     ],
    //     Japanese: [
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578336390.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578343515.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578349053.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578354188.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578360473.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578366064.jpeg",
    //     ],
    //     Scandinavian: [
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578380394.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578384852.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578396673.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578402937.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578410172.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578415927.jpeg",
    //     ],
    //     Spanish: [
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578430493.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578434688.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578438876.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578444085.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578448100.jpeg",
    //         "https://naya-studio-uploads.s3.amazonaws.com/1571578452679.jpeg",
    //     ],
    // },
    
    google_search_results: [],
    instagram_search_results: [],
    // normal user flow
    flowResidential: {
        'landing'           : 'q3',
        'q3'                : 'q4',
        'q4'                : 'visual-moodboard',
        'visual-moodboard'  : 'q5',
        'q5'                : 'search-upload',
        'search-upload'      : 'q7',
        'q7'                : 'story',
        // 'story'             : 'additional-details',
        // 'additional-details':'canvas'
        'story':'canvas'
    },
    // designer flow
    flowCommercial: {
        'landing':'search-upload',
        'search-upload':'q2',
        'q2':'story',
        // 'story':'additional-details',
        'story':'canvas'

        // 'additional-details':'canvas'
    },
    flowCodesign: {
        landing: "q1",
        q1: "q2",
        q2: "q3",
        q3: "q5",
        q5: "dimensions",
        dimensions: "text-moodboard",
        "text-moodboard": "visual-moodboard",
        "visual-moodboard": "search",
        search: "upload",
        upload: "sketch",
        sketch: "story",
        story: "pre-canvas",
    },
    flowDesigner: {
        landing: "upload",
        upload: "story",
        story: "pre-canvas",
    },
    flow: {},
    pathway: "NORMAL",
    track: "NONE",
    uploads: [],
    authError: null,
    authLoading: false,
    text_moodboard_selections: [],
    authCallback: "account",
};

let DEBUG = false;

const UIReducer = (state = initState, action) => {
    let newState
    
    if(DEBUG) console.log("ACTION:")
    if(DEBUG) console.log(action)
    
    if(DEBUG) console.log("CURRENT_STATE:")
    if(DEBUG) console.log(state)
    
    switch(action.type)
    {
        case 'SET_PATHWAY':
            if(action.tag === "Residential")
            {
                newState = {...state, 'questions': state.questionsResidential, 'flow': state.flowResidential}
            }
            else if(action.tag === "Commercial")
            {
                newState = {...state, 'questions': state.questionsCommercial, 'flow': state.flowCommercial}
            }
            else if(action.tag === "Codesign")
            {
                newState = {...state, 'questions': state.questionsCodesign, 'flow': state.flowCodesign, 'pathway': "COMPETITION", "track":"CO_DESIGN"}
            }
            else if(action.tag === "Designer")
            {
                newState = {...state, 'questions': [], 'flow': state.flowDesigner, 'pathway': "COMPETITION", "track":"DESIGNER"}
            }
            else
            {
                newState = state
            }
            break;
        case "ADD_INIT_DATA":
            newState = { ...state, [action.key]: action.value };
            break;
        case "CREATE_CANVAS":
            localStorage.setItem("nayaCanvasID", action.id);
            newState = {
                ...state,
                canvas_id: action.id,
                canvas: action.canvas,
            };
            break;
        case "CREATE_ANSWER":
            newState = state;
            break;
        case "SET_CANVAS":
            newState = {
                ...state,
                canvas: action.data,
                canvas_id: action.data._id,
            };
            break;
        case "CREATE_MOODBOARD":
            newState = state;
            break;
        case "ADD_DIMENSIONS":
            newState = state;
            break;
        case "ADD_SHIPPING":
            newState = state;
            break;
        case "ADD_SKETCH":
            newState = state;
            break;
        case "SET_CANVAS_ID":
            newState = { ...state, canvas_id: action.data };
            break;
        case "LOGIN_TOKEN":
            newState = { ...state, user: action.data.user };
            break;
        case "LOGIN_LOCAL":
            localStorage.setItem("nayaJwtToken", action.data.token);
            newState = { ...state, user: action.data.user };
            break;
        case "SIGNUP_LOCAL":
            localStorage.setItem("nayaJwtToken", action.data.token);
            newState = { ...state, user: action.data.user };
            break;
        case "GOOGLE_SEARCH_INIT":
            newState = { ...state, google_search_results: [] };
            break;
        case "INSTA_SEARCH_INIT":
            newState = { ...state, instagram_search_results: [] };
            break;
        case "GOOGLE_SEARCH":
            newState = { ...state, google_search_results: action.data };
            break;
        case "INSTA_SEARCH":
            newState = { ...state, instagram_search_results: action.data };
            break;
        case "LOGIN_ERROR":
            newState = { ...state, authError: action.data };
            break;
        case "LOGIN_INIT":
            newState = { ...state, authLoading: action.data };
            break;
        case "AUTH_CALLBACK":
            newState = { ...state, authCallback: action.data };
            break;
        default:
            newState = state;
    }

    if (DEBUG) console.log("NEW STATE:");
    if (DEBUG) console.log(newState);

    return newState;
};

export default UIReducer;
