const initComments = {
}

let DEBUG = false
const commentsReducer = (state = initComments, action) => {
    let newState

    if(DEBUG) console.log("COMMENTS REDUCER:")
    if(DEBUG) console.log("ACTION:")
    if(DEBUG) console.log(action)

    if(DEBUG) console.log("CURRENT STATE:")
    if(DEBUG) console.log(state)

    switch(action.type)
    {
        case "SET_COMMENTS":
            newState = {...state, [action.id]: action.comments}
            break
        case "SET_CANVAS":
                newState = {...state, [action.data._id]: action.data.comments}
                break
        case "UPDATE_CANVAS":
            newState = {...state, [action.id]: action.canvas.comments}
        default:
            newState = state
    }

    if(DEBUG) console.log("NEW STATE:")
    if(DEBUG) console.log(newState)

    return newState
}

export default commentsReducer