import React, { Component } from 'react'
import {Container, Row, Image, Col, Form} from 'react-bootstrap'
import '../../stylesheets/option.css'
import checkmark from '../../images/icons/selected.svg'
import bg1 from '../../images/icons/bg_1.svg'
import bg2 from '../../images/icons/bg_2.svg'
import bg3 from '../../images/icons/bg_3.svg'
import qMark from '../../images/qmark.svg'

class OtherOption extends Component{

    constructor(props)
    {
        super(props)
        this.state = {
            mouseOver: false,
            otherText: ''
        }
    }

    handleSelect = (e) => {
        e.preventDefault()
        this.props.onClick()
        this.props.onValue(this.state.otherText == ''?"OTHER":this.state.otherText)

    }


    handleChange = (e) =>
    {
        e.preventDefault()
        e.stopPropagation()
        this.props.onValue(e.target.value)
        this.setState({otherText: e.target.value})
    }

    render()
    {
        let style = {}
        let bgimg = bg1
        if(this.props.selected)
        {
            style = {"filter":"invert(1)"}
            bgimg = [bg1, bg2, bg3][this.state.otherText.length%3];
        }
        return (
            <Col xs={{span: 6, offset:0}} sm={{span: 4, offset:0}}  md={{span: 3, offset:0}} lg={{span: 2, offset:0}}onMouseOver={() => this.setState({mouseOver: true})} onMouseLeave={() => this.setState({mouseOver: false})}>
                <Col xs={12} className="option-wrapper">                    
                    {this.props.selected && <img src={checkmark} style={{"height": "20px", "position":"absolute", "top":"0", "right": "0"}}></img>}
                    <Row className='justify-content-md-center'>
                        <Col xs={12} onClick={this.handleSelect} >
                            {this.state.mouseOver && <img src={bgimg} style={{"position":"absolute", "top":"-1rem", "left": "0", "opacity": "0.3"}} fluid></img>}
                            {this.props.selected && <img src={bgimg} style={{"position":"absolute", "top":"-1rem", "left": "0"}} fluid></img>}
                            <Image className="option-img" src={qMark} style={style} fluid></Image>
                        </Col>

                        {!this.props.selected && <p className="col-12">{this.props.text}</p>}
                        {this.props.selected && <Form.Control type="text" value={this.props.otherText} placeholder={"TYPE HERE..."} onChange={this.handleChange} className="question-other-input"></Form.Control>}
                    </Row>
                </Col>
            </Col>
        )
    }
}

export default OtherOption